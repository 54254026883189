/* eslint-disable no-nested-ternary */
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { AiFillEdit, AiOutlineFieldNumber } from 'react-icons/ai';
import { IoIosBusiness } from 'react-icons/io';
import {
  HiOutlineDocument,
  HiOutlineDocumentAdd,
  HiOutlineDocumentRemove,
  HiOutlineLocationMarker,
} from 'react-icons/hi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { BsBuilding } from 'react-icons/bs';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ModalConexao from '../../../components/ModalConexao';
import api from '../../../services/api';
import DropDownPagination from '../../../components/DropDownPagination';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import Input from '../../../components/Input';
import { maskCep, maskCnpj } from '../../../components/Input/mask';
import { Loading } from '../../../components/Loading';
import getValidationErrors from '../../../utils/getValidationErrors';
import { useToast } from '../../../hooks/toast';
import { SelectCustom } from '../../../components/SelectCustom';
import { EditFilialModal } from '../../../components/EditFilialModal';
import { useAuth } from '../../../hooks/auth';

import {
  Container,
  Aba,
  ContainerAba,
  ContainerCadastro,
  AnimationContainer,
  ContainerButton,
  ContainerTable,
  ContainerPagination,
  ContainerSearch,
  ContainerSemResultados,
  SearchCustom,
  ContentForm,
  ButtonEdit,
} from './styles';

export interface SignUpFormData {
  codFilial: string;
  nome: string;
  nomeFantasia: string;
  cnpj: string;
  endereco: string;
  numero: string;
  bairro: string;
  cidade: string;
  estado: string;
  cep: string;
}

export interface ResponseGet {
  ATIVO: boolean;
  BAIRRO: string;
  CEP: string;
  CIDADE: string;
  CODIGO_FILIAL: string;
  ENDERECO: string;
  ESTADO: string;
  ID: number;
  NUMERO: string;
  NOME_EMPRESA: string;
  NOME_FANTASIA_EMPRESA: string;
  CNPJ: string;
}

interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

const pageAccess = {
  ID: 1,
  NOME: 'CADASTRO-FILIAIS',
};

const headers = [
  { name: 'Código da empresa', field: 'CODIGO_FILIAL', sortable: true },
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'Nome fantasia', field: 'NOME_FANTASIA', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  { name: 'CEP', field: 'CEP', sortable: true },
  { name: 'Endereço', field: 'ENDERECO', sortable: true },
  { name: 'Número', field: 'NUMERO', sortable: true },
  { name: 'Bairro', field: 'BAIRRO', sortable: true },
  { name: 'Cidade', field: 'CIDADE', sortable: true },
  { name: 'Estado', field: 'ESTADO', sortable: true },
  { name: 'Status cadastral', field: 'ATIVO', sortable: true },
];

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

export const UF = [
  'AC',
  'AL',
  'AP',
  'AM',
  'BA',
  'CE',
  'DF',
  'ES',
  'GO',
  'MA',
  'MT',
  'MS',
  'MG',
  'PA',
  'PB',
  'PR',
  'PE',
  'PI',
  'RJ',
  'RN',
  'RS',
  'RO',
  'RR',
  'SC',
  'SP',
  'SE',
  'TO',
];

const CadastroFilial: React.FC = () => {
  const { modulos } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [cadastrar, setCadastrar] = useState(true);

  const [searchCodigo, setSearchCodigo] = useState('');
  const [searchCep, setSearchCep] = useState('');
  const [searchEndereco, setSearchEndereco] = useState('');
  const [searchCidade, setSearchCidade] = useState('');

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [trTableSelected, setTrTableSelected] = useState<ResponseGet>();
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });

  const CustomTooltipUpdateStatus = withStyles((theme) => ({
    arrow: {
      color: !trTableSelected
        ? '#009900'
        : trTableSelected.ATIVO
        ? '#ff3333'
        : '#8080ff',
    },
    tooltip: {
      backgroundColor: !trTableSelected
        ? '#009900'
        : trTableSelected.ATIVO
        ? '#ff3333'
        : '#8080ff',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);
  const CustomTooltipEdit = withStyles((theme) => ({
    arrow: {
      color: !trTableSelected ? '#009900' : '#ff9900',
    },
    tooltip: {
      backgroundColor: !trTableSelected ? '#009900' : '#ff9900',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  const getItens = useCallback(() => {
    setLoading(true);
    api
      .get(`filiais/showFiliais`)
      .then((data) => {
        setResponse(data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        if (navigator.onLine) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao carregar dados, por favor atualize a página!',
          });
        }
      });
  }, [addToast]);

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);
    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title: 'Você não tem permissão para acessar essa página!',
      });
    } else {
      getItens();
    }
  }, [getItens, modulos, history]);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          codFilial: Yup.string().required('Código é obrigatório!'),
          nome: Yup.string().required('Nome é obrigatório!'),
          nomeFantasia: Yup.string().required('Nome fantasia é obrigatório!'),
          cnpj: Yup.string().required('CNPJ é obrigatório!'),
          endereco: Yup.string().required('Endereço é obrigatório!'),
          numero: Yup.string().required('Número é obrigatório!'),
          bairro: Yup.string().required('Bairro é obrigatório!'),
          cidade: Yup.string().required('Cidade é obrigatória!'),
          estado: Yup.string().required('Estado é obrigatório!'),
          cep: Yup.string().required('CEP é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .post('/filiais/createFiliais', {
            CODIGO_FILIAL: data.codFilial,
            ENDERECO: data.endereco,
            NUMERO: data.numero,
            BAIRRO: data.bairro,
            CIDADE: data.cidade,
            ESTADO: data.estado,
            CEP: data.cep,
            NOME_EMPRESA: data.nome,
            NOME_FANTASIA_EMPRESA: data.nomeFantasia,
            CNPJ: data.cnpj,
          })
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Cadastro efetuado',
              description: 'Filial cadastrada com sucesso!',
            });
            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro',
              description:
                'Erro ao carregar dados, por favor atualize a página!',
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Existem campos obrigatórios em branco.',
        });
      }
    },
    [addToast, history],
  );

  const handleChangeStatusFilial = useCallback(async () => {
    setLoading(true);
    await api
      .put('/filiais/updateFiliais', {
        CODIGO_FILIAL: trTableSelected?.CODIGO_FILIAL,
        ATIVO: !trTableSelected?.ATIVO,
      })
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Filial editada',
          description: 'Filial editada com sucesso!',
        });
        setTimeout(() => {
          history.go(0);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro na edição',
          description: 'Erro ao editar filial!',
        });
      });
  }, [trTableSelected, addToast, history]);

  const handleConfirmChangeStatusFilial = useCallback(
    (data: ResponseGet) => {
      Swal.fire({
        title: `Tem certeza que deseja alterar o status da empresa "${
          data.CODIGO_FILIAL ? data.CODIGO_FILIAL : 'SEM CÓDIGO'
        }"?`,
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleChangeStatusFilial();
        }
      });
    },
    [handleChangeStatusFilial],
  );

  const handlePermitionRegister = useCallback(() => {
    setCadastrar(false);
    setSearchCodigo('');
    setSearchCep('');
    setSearchEndereco('');
  }, []);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response;

    if (searchCodigo) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.CODIGO_FILIAL &&
          res.CODIGO_FILIAL.toString().includes(searchCodigo.toString()),
      );
    }
    if (searchCep) {
      // let CEP = searchCep.replace(/\./g, '');
      // CEP = CEP.replace(/-/g, '');

      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.CEP &&
          res.CEP.toString()
            .toLowerCase()
            .includes(searchCep.toString().toLowerCase()),
      );
    }
    if (searchEndereco) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.ENDERECO &&
          res.ENDERECO.toString()
            .toLowerCase()
            .includes(searchEndereco.toString().toLowerCase()),
      );
    }
    if (searchCidade) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.CIDADE &&
          res.CIDADE.toString()
            .toLowerCase()
            .includes(searchCidade.toString().toLowerCase()),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (a[sorting.field] !== null && b[sorting.field] !== null) {
          if (typeof a[sorting.field] === 'object') {
            return (
              reversed *
              a[sorting.field]
                .join(', ')
                .localeCompare(b[sorting.field].join(', '))
            );
          }
          if (typeof a[sorting.field] === 'string') {
            return reversed * a[sorting.field].localeCompare(b[sorting.field]);
          }
          return (
            reversed *
            a[sorting.field]
              .toString()
              .localeCompare(b[sorting.field].toString())
          );
        }
        return a;
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    searchCodigo,
    searchCep,
    searchEndereco,
    searchCidade,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchCodigo, searchCep, searchEndereco, searchCidade]);

  return (
    <>
      <ModalConexao />
      <Container>
        {accessUser.ESCRITA && (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button
                style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
                type="button"
                onClick={() => setCadastrar(true)}
              >
                Listar Filiais
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={!cadastrar}>
              <button
                style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7 }}
                type="button"
                onClick={handlePermitionRegister}
              >
                Cadastrar Filial
              </button>
            </ContainerAba>
          </Aba>
        )}

        {cadastrar && !!response.length && (
          <ContainerSearch>
            <SearchCustom
              onSearch={(value: string) => {
                setSearchCodigo(value);
              }}
              nomePlaceHolder="Buscar código"
            />
            <SearchCustom
              onSearch={(value: string) => {
                setSearchCep(value);
              }}
              nomePlaceHolder="Buscar CEP"
              typeProps="cep"
            />
            <SearchCustom
              onSearch={(value: string) => {
                setSearchEndereco(value);
              }}
              nomePlaceHolder="Buscar endereço"
            />
            <SearchCustom
              onSearch={(value: string) => {
                setSearchCidade(value);
              }}
              nomePlaceHolder="Buscar cidade"
            />
          </ContainerSearch>
        )}

        <ContainerCadastro cor={!cadastrar}>
          {!cadastrar && (
            <AnimationContainer>
              <header>
                <h1>Dados da filial</h1>
                <BsBuilding size={24} />
              </header>

              <Form ref={formRef} onSubmit={handleSubmit}>
                <ContentForm>
                  <Input
                    name="codFilial"
                    icon={IoIosBusiness}
                    type="number"
                    widthProps="49.5%"
                    placeholder="Código da empresa"
                  />
                  <Input
                    name="nome"
                    icon={IoIosBusiness}
                    widthProps="49.5%"
                    placeholder="Nome"
                  />
                </ContentForm>

                <ContentForm>
                  <Input
                    name="nomeFantasia"
                    icon={IoIosBusiness}
                    widthProps="49.5%"
                    placeholder="Nome fantasia"
                  />
                  <Input
                    name="cnpj"
                    icon={IoIosBusiness}
                    widthProps="49.5%"
                    placeholder="CNPJ"
                    mask="cnpj"
                  />
                </ContentForm>

                <ContentForm>
                  <Input
                    name="endereco"
                    icon={HiOutlineLocationMarker}
                    widthProps="49.5%"
                    placeholder="Endereço"
                  />
                  <Input
                    name="numero"
                    type="number"
                    widthProps="49.5%"
                    icon={AiOutlineFieldNumber}
                    placeholder="Número"
                  />
                </ContentForm>
                <ContentForm>
                  <Input
                    name="bairro"
                    icon={HiOutlineLocationMarker}
                    widthProps="49.5%"
                    placeholder="Bairro"
                  />
                  <Input
                    name="cidade"
                    icon={HiOutlineLocationMarker}
                    widthProps="49.5%"
                    placeholder="Cidade"
                  />
                </ContentForm>
                <ContentForm>
                  <SelectCustom
                    name="estado"
                    icon={HiOutlineLocationMarker}
                    widthProps="49.5%"
                    defaultValue="Estado"
                    optionsData={UF}
                  />
                  <Input
                    name="cep"
                    icon={HiOutlineLocationMarker}
                    widthProps="49.5%"
                    placeholder="CEP"
                    mask="cep"
                  />
                </ContentForm>
                <ContainerButton>
                  <button type="submit">Cadastrar</button>
                </ContainerButton>
              </Form>
            </AnimationContainer>
          )}
          {cadastrar &&
            (response.length === 0 ? (
              <ContainerSemResultados>
                <h2>Não existem filiais cadastradas ainda.</h2>
              </ContainerSemResultados>
            ) : (
              <div className="divTable">
                <ContainerTable>
                  <table id="tableListagemFiliais">
                    <HeaderTable
                      headers={headers}
                      onSorting={(field: string, order: string) =>
                        setSorting({ field, order })
                      }
                    />
                    <tbody>
                      {responseData.map((res) => (
                        <tr
                          key={res.ID}
                          onClick={() =>
                            trTableSelected === res
                              ? setTrTableSelected(undefined)
                              : setTrTableSelected(res)}
                          style={{
                            background:
                              trTableSelected && trTableSelected.ID === res.ID
                                ? '#737373'
                                : '',
                          }}
                        >
                          <td>{res.CODIGO_FILIAL ? res.CODIGO_FILIAL : '-'}</td>
                          <td>{res.NOME_EMPRESA ? res.NOME_EMPRESA : '-'}</td>
                          <td>
                            {res.NOME_FANTASIA_EMPRESA
                              ? res.NOME_FANTASIA_EMPRESA
                              : '-'}
                          </td>
                          <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                          <td>{res.CEP ? maskCep(res.CEP) : '-'}</td>
                          <td>{res.ENDERECO ? res.ENDERECO : '-'}</td>
                          <td>{res.NUMERO ? res.NUMERO : '-'}</td>
                          <td>{res.BAIRRO ? res.BAIRRO : '-'}</td>
                          <td>{res.CIDADE ? res.CIDADE : '-'}</td>
                          <td>{res.ESTADO ? res.ESTADO : '-'}</td>
                          <td style={{ padding: 5 }}>
                            <div
                              style={{
                                background: `${
                                  !res.ATIVO
                                    ? 'rgb(255, 51, 51)'
                                    : 'rgb(128, 128, 255)'
                                }`,
                                width: '100%',
                                height: '100%',
                                borderRadius: 4,
                              }}
                            >
                              {!!res.ATIVO && 'Ativo'}
                              {!res.ATIVO && 'Inativo'}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ContainerTable>

                <ContainerPagination>
                  {responseData.length > 0 && (
                    <div id="divPaginacao">
                      <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page: number) => setCurrentPage(page)}
                      />
                      <DropDownPagination
                        onChangeItems={(value: string) => {
                          setCurrentPage(1);
                          if (value === 'Todos') {
                            setITEMS_PER_PAGE(1);
                          } else {
                            setITEMS_PER_PAGE(Number(value));
                          }
                        }}
                        objetoEnum={dropDown}
                        minWidth={30}
                      />

                      <div id="divBtnTable">
                        {accessUser.ESCRITA && (
                          <>
                            <CustomTooltipEdit
                              title={
                                !trTableSelected
                                  ? 'Selecione uma empresa primeiro'
                                  : 'Editar'
                              }
                              arrow
                              TransitionComponent={Zoom}
                            >
                              <span>
                                <ButtonEdit
                                  disabled={!trTableSelected}
                                  style={{
                                    cursor: !trTableSelected
                                      ? 'not-allowed'
                                      : '',
                                    background: !trTableSelected
                                      ? '#009900'
                                      : '',
                                    filter: !trTableSelected
                                      ? 'brightness(1)'
                                      : '',
                                  }}
                                  onClick={() => setShowModalEdit(true)}
                                >
                                  <AiFillEdit size={26} />
                                </ButtonEdit>
                              </span>
                            </CustomTooltipEdit>

                            <CustomTooltipUpdateStatus
                              title={
                                !trTableSelected
                                  ? 'Selecione uma empresa primeiro'
                                  : trTableSelected.ATIVO
                                  ? 'Inativar empresa'
                                  : 'Ativar empresa'
                              }
                              arrow
                              TransitionComponent={Zoom}
                            >
                              <span>
                                <ButtonEdit
                                  disabled={!trTableSelected}
                                  style={{
                                    cursor: !trTableSelected
                                      ? 'not-allowed'
                                      : '',
                                    background: !trTableSelected
                                      ? '#009900'
                                      : trTableSelected.ATIVO
                                      ? '#ff3333'
                                      : '#8080ff',
                                    filter: !trTableSelected
                                      ? 'brightness(1)'
                                      : '',
                                  }}
                                  onClick={() =>
                                    trTableSelected &&
                                    handleConfirmChangeStatusFilial(
                                      trTableSelected,
                                    )}
                                >
                                  {!trTableSelected ? (
                                    <HiOutlineDocument size={26} />
                                  ) : trTableSelected.ATIVO ? (
                                    <HiOutlineDocumentRemove size={26} />
                                  ) : (
                                    <HiOutlineDocumentAdd size={26} />
                                  )}
                                </ButtonEdit>
                              </span>
                            </CustomTooltipUpdateStatus>
                          </>
                        )}

                        <ReactHTMLTableToExcel
                          id="export-excel"
                          className="btn"
                          table="tableListagemFiliais"
                          filename={`listagem-filiais-${moment().format(
                            'DD-MM-YYYY',
                          )}`}
                          sheet="tablexls"
                          buttonText="Exportar Excel"
                        />
                      </div>
                    </div>
                  )}
                </ContainerPagination>
              </div>
            ))}
        </ContainerCadastro>
      </Container>

      {loading && <Loading />}
      {showModalEdit && trTableSelected && (
        <EditFilialModal
          data={trTableSelected}
          onClose={(e) => setShowModalEdit(!e)}
        />
      )}
    </>
  );
};

export default CadastroFilial;
