/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { BiUpArrow, BiDownArrow, BiEdit } from 'react-icons/bi';
import { BsArrowReturnRight } from 'react-icons/bs';
import { GoDiffAdded } from 'react-icons/go';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import Lottie from 'lottie-react-web';
import { availableModules } from '../../../../hooks/availableModules';
import { CheckboxCustom } from '../../../../components/CheckboxCustom';
import { ResponseGet } from '../index';
import { ResponsePost } from '../../../../components/ModalRegisterSectorFunction';
import animationListModules from './listModules.json';

import {
  Container,
  ContainerAside,
  SectionModule,
  ContainerDiv,
} from './styles';

interface PainelProps {
  isActivePainel: boolean;
  titlePainel: string;
  verificationMap: number | undefined;
  dataPainelModulesUser: ResponseGet | ResponsePost;
  onStatusPainel: (e: string) => void;
  onSelectPainelProps: (e: number[]) => void;
  onSelectCheckboxProps: (e: CheckboxInterface[]) => void;
}

export interface CheckboxInterface {
  ID: number;
  NOME: string;
  LABEL: string;
  STATUS: boolean;
}

interface ModulosInterface {
  ID: number;
  NOME: string;
}

export const PainelModules: React.FC<PainelProps> = ({
  isActivePainel,
  titlePainel,
  verificationMap,
  dataPainelModulesUser,
  onStatusPainel = (e: string) => {
    return e;
  },
  onSelectPainelProps = (e: number[]) => {
    return e;
  },
  onSelectCheckboxProps = (e: CheckboxInterface[]) => {
    return e;
  },
}) => {
  const { modules } = availableModules();
  const [selectPainelIten, setSelectPainelIten] = useState<number[]>([]);
  const [checkboxStatus, setCheckboxStatus] = useState<CheckboxInterface[]>([]);
  const [showModules, setShowModules] = useState<'registerBox' | 'editBox'>(
    'registerBox',
  );
  const CustomTooltipRegister = withStyles((theme) => ({
    arrow: {
      color: '#00b300',
    },
    tooltip: {
      backgroundColor: '#00b300',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);
  const CustomTooltipEdit = withStyles((theme) => ({
    arrow: {
      color: '#eba417',
    },
    tooltip: {
      backgroundColor: '#eba417',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  useEffect(() => {
    selectPainelIten && onSelectPainelProps(selectPainelIten);
  }, [onSelectPainelProps, selectPainelIten]);

  useEffect(() => {
    onStatusPainel(showModules);
    setSelectPainelIten([]);
    setCheckboxStatus([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showModules]);

  useEffect(() => {
    checkboxStatus && onSelectCheckboxProps(checkboxStatus);
  }, [checkboxStatus, onSelectCheckboxProps]);

  useEffect(() => {
    if (!verificationMap) {
      setSelectPainelIten([]);
      setCheckboxStatus([]);
    }
  }, [verificationMap]);

  const handleRemoveMenuSelect = useCallback(
    (dataIndex: number) => {
      const selectPainelItenAux = [...selectPainelIten];

      selectPainelItenAux.splice(dataIndex, 1);

      setSelectPainelIten([...selectPainelItenAux]);
    },
    [selectPainelIten],
  );

  const handleChecked = useCallback(
    (moduloData: ModulosInterface, statusData: boolean, label: string) => {
      const indexAux = checkboxStatus.findIndex(
        (checkbox) => checkbox.ID === moduloData.ID && checkbox.LABEL === label,
      );

      if (indexAux === -1) {
        setCheckboxStatus([
          ...checkboxStatus,
          {
            ID: moduloData.ID,
            NOME: moduloData.NOME,
            LABEL: label,
            STATUS: statusData,
          },
        ]);
      } else {
        const checkboxStatusAux = [...checkboxStatus];

        checkboxStatusAux.splice(indexAux, 1);
        checkboxStatusAux.push({
          ID: moduloData.ID,
          NOME: moduloData.NOME,
          LABEL: label,
          STATUS: statusData,
        });

        setCheckboxStatus([...checkboxStatusAux]);
      }
    },
    [checkboxStatus],
  );

  const checkStatusCheckbox = useCallback(
    (dataModule: ModulosInterface, type: string): boolean => {
      const indexModule =
        dataPainelModulesUser.modulos &&
        dataPainelModulesUser.modulos.idModulos.findIndex(
          (idModule) => idModule === dataModule.ID,
        );

      if (
        indexModule !== undefined &&
        indexModule !== -1 &&
        type === 'Leitura'
      ) {
        const aux =
          dataPainelModulesUser.modulos &&
          dataPainelModulesUser.modulos.leitura[indexModule];

        return aux || false;
      }
      if (
        indexModule !== undefined &&
        indexModule !== -1 &&
        type === 'Escrita'
      ) {
        const aux =
          dataPainelModulesUser.modulos &&
          dataPainelModulesUser.modulos.escrita[indexModule];

        return aux || false;
      }
      return false;
    },
    [dataPainelModulesUser.modulos],
  );

  const responseUserModules = useMemo(() => {
    let responseFilter: ModulosInterface[] = [];

    if (showModules === 'registerBox') {
      if (dataPainelModulesUser.modulos?.idModulos.length) {
        responseFilter = modules.filter(
          (module) =>
            !dataPainelModulesUser.modulos?.idModulos.includes(module.ID),
        );
      } else {
        responseFilter = modules;
      }
    } else if (dataPainelModulesUser.modulos?.idModulos.length) {
      responseFilter = modules.filter((module) =>
        dataPainelModulesUser.modulos?.idModulos.includes(module.ID),
      );
    } else {
      responseFilter = [];
    }

    return responseFilter;
  }, [dataPainelModulesUser, modules, showModules]);

  return (
    <Container isActive={isActivePainel} isSelected={showModules}>
      <header>
        <h2>
          {showModules === 'registerBox' && verificationMap
            ? `Vincular ${titlePainel}`
            : showModules === 'editBox' && verificationMap
            ? `Editar ${titlePainel}`
            : `${titlePainel}`}
        </h2>
        {verificationMap && (
          <article>
            <CustomTooltipRegister
              title="Vincular módulo(s)"
              arrow
              TransitionComponent={Zoom}
            >
              <button
                type="button"
                onClick={() => setShowModules('registerBox')}
              >
                <GoDiffAdded />
              </button>
            </CustomTooltipRegister>

            <CustomTooltipEdit
              title="Editar vinculação"
              arrow
              TransitionComponent={Zoom}
            >
              <button type="button" onClick={() => setShowModules('editBox')}>
                <BiEdit />
              </button>
            </CustomTooltipEdit>
          </article>
        )}
      </header>
      <ContainerDiv>
        {verificationMap &&
          responseUserModules.map((modulo) => (
            <SectionModule
              key={modulo.ID}
              style={{
                background: selectPainelIten.find(
                  (moduloTemp) => moduloTemp === modulo.ID,
                )
                  ? '#312e38'
                  : '',
                borderColor: selectPainelIten.find(
                  (moduloTemp) => moduloTemp === modulo.ID,
                )
                  ? '#000000'
                  : '',
              }}
            >
              <article>
                {modulo.NOME}
                <button
                  type="button"
                  onClick={() => {
                    const indexMenu = selectPainelIten.findIndex(
                      (moduloTemp) => moduloTemp === modulo.ID,
                    );
                    if (indexMenu !== -1) {
                      handleRemoveMenuSelect(indexMenu);
                    } else {
                      setSelectPainelIten([...selectPainelIten, modulo.ID]);
                    }
                  }}
                >
                  {selectPainelIten.find(
                    (moduloTemp) => moduloTemp === modulo.ID,
                  ) ? (
                    <BiUpArrow />
                  ) : (
                    <BiDownArrow />
                  )}
                </button>
              </article>

              <ContainerAside
                isOpen={
                  !!selectPainelIten.find(
                    (moduloTemp) => moduloTemp === modulo.ID,
                  )
                }
              >
                <BsArrowReturnRight />

                <div className="checkboxDiv">
                  <span>
                    <CheckboxCustom
                      labelProps="Leitura"
                      colorProps="primary"
                      idProps={modulo.ID}
                      defaultCheckProps={checkStatusCheckbox(modulo, 'Leitura')}
                      checkedProps={(status: boolean) =>
                        handleChecked(modulo, status, 'Leitura')}
                    />
                  </span>
                  <span>
                    <CheckboxCustom
                      labelProps="Escrita"
                      colorProps="secondary"
                      idProps={modulo.ID}
                      defaultCheckProps={checkStatusCheckbox(modulo, 'Escrita')}
                      checkedProps={(status: boolean) =>
                        handleChecked(modulo, status, 'Escrita')}
                    />
                  </span>
                </div>
              </ContainerAside>
            </SectionModule>
          ))}

        {!responseUserModules?.length && isActivePainel && (
          <article className="listEmpty">
            <Lottie
              options={{
                animationData: animationListModules,
                loop: true,
              }}
              width={200}
              speed={1}
            />
          </article>
        )}
      </ContainerDiv>
    </Container>
  );
};
