import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { SiOpenaccess } from 'react-icons/si';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import { PainelModules, CheckboxInterface } from './PainelModules';
import { ResponsePost } from '../../../components/ModalRegisterSectorFunction';
import { useAuth, NivelAcessoModulos } from '../../../hooks/auth';

import { Container, Content } from './styles';
import { Painel } from './Painel';

export interface ModulosResponseGet {
  idModulos: number[];
  nomeModulos: string[];
  leitura: boolean[];
  escrita: boolean[];
}

export interface ResponseGet {
  AVATAR: string;
  Ativo: number;
  CPF: string;
  DataCadastro: string;
  DataNascimento: string;
  EMAIL: string;
  ID: number;
  ID_NIVEL_ACESSO: number;
  ID_SETOR: number;
  NOME: string;
  NOME_NIVEL_ACESSO: string;
  NOME_SETOR: string;
  Telefone: string;
  modulos: ModulosResponseGet;
  param: string;
  privarDados: boolean;
  token: string;
}

export interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

const pageAccess = {
  ID: 9,
  NOME: 'NIVEL-ACESSO-CUSTOMIZAVEL',
};

const UserAccess: React.FC = () => {
  const { user, modulos, updateUser } = useAuth();
  const { addToast } = useToast();
  const { go, push } = useHistory();
  const [loading, setLoading] = useState(false);
  const [responseUsers, setResponseUsers] = useState<ResponseGet[]>([]);
  const [responsePostSetorModal, setResponsePostSetorModal] = useState<
    ResponsePost[]
  >([]);
  const [responsePostFuncaoModal, setResponsePostFuncaoModal] = useState<
    ResponsePost[]
  >([]);
  const [statusPainel, setStatusPainel] = useState('registerBox');
  const [selectSetor, setSelectSetor] = useState<number>();
  const [selectNivel, setSelectNivel] = useState<number>();
  const [selectUser, setSelectUser] = useState<number>();
  const [selectMenu, setSelectMenu] = useState<number[]>([]);
  const [checkboxStatus, setCheckboxStatus] = useState<CheckboxInterface[]>([]);
  const [selectUserModules, setSelectUserModules] = useState<
    ResponseGet | ResponsePost
  >({
    ID: -1,
    NOME: '',
  });
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });

  const getItens = useCallback(async () => {
    setLoading(true);

    try {
      const dataUsers = await api.get('users/findAll');
      const dataSetor = await api.get('setor/showSetor');
      const dataFuncao = await api.get('nivelAcesso/getNivelAcesso');

      setResponseUsers(dataUsers.data);
      setResponsePostSetorModal(dataSetor.data);
      setResponsePostFuncaoModal(dataFuncao.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar dados, por favor atualize a página!',
      });
    }
  }, [addToast]);

  useEffect(() => {
    let semAcesso = true;

    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);

    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      push('/dashboard');
      Swal.fire({
        icon: 'info',
        title: 'Você não tem permissão para acessar essa página!',
      });
    } else {
      getItens();
    }
  }, [getItens, modulos, push]);

  useEffect(() => {
    if (!selectUser) {
      setSelectMenu([]);
      setCheckboxStatus([]);
    }
  }, [selectUser]);

  const handleSubmit = useCallback(async () => {
    setLoading(true);

    let auxLeitura: number | undefined;
    let auxEscrita: number | undefined;

    const checkboxStatusLeitura: boolean[] = selectMenu.map((menu) => {
      const checkboxLeituraAux = checkboxStatus.find(
        (checkbox) => checkbox.ID === menu && checkbox.LABEL === 'Leitura',
      );

      if (
        checkboxLeituraAux?.STATUS === undefined &&
        selectUserModules.modulos
      ) {
        auxLeitura = selectUserModules.modulos.idModulos.findIndex(
          (idModulo) => {
            return idModulo === menu;
          },
        );
      }

      return checkboxLeituraAux?.STATUS === undefined
        ? auxLeitura !== undefined &&
            auxLeitura !== -1 &&
            selectUserModules !== undefined &&
            selectUserModules.modulos !== undefined &&
            selectUserModules.modulos.leitura[auxLeitura]
        : checkboxLeituraAux.STATUS;
    });

    const checkboxStatusEscrita: boolean[] = selectMenu.map((menu) => {
      const checkboxEscritaAux = checkboxStatus.find(
        (checkbox) => checkbox.ID === menu && checkbox.LABEL === 'Escrita',
      );

      if (
        checkboxEscritaAux?.STATUS === undefined &&
        selectUserModules.modulos
      ) {
        auxEscrita = selectUserModules.modulos.idModulos.findIndex(
          (idModulo) => {
            return idModulo === menu;
          },
        );
      }

      return checkboxEscritaAux?.STATUS === undefined
        ? auxEscrita !== undefined &&
            auxEscrita !== -1 &&
            selectUserModules !== undefined &&
            selectUserModules.modulos !== undefined &&
            selectUserModules.modulos.escrita[auxEscrita]
        : checkboxEscritaAux.STATUS;
    });

    const endPointUrl =
      statusPainel === 'registerBox'
        ? 'nivelAcessoModuloCliente/createNivelAcessoModulo'
        : 'nivelAcessoModuloCliente/updateNivelAcessoModulo';

    if (statusPainel === 'registerBox') {
      await api
        .post(endPointUrl, {
          idModulo: selectMenu,
          idUsersAppColaborador: selectUser,
          leitura: checkboxStatusLeitura,
          escrita: checkboxStatusEscrita,
        })
        .then((res) => {
          setLoading(false);

          const modulosAux: NivelAcessoModulos[] = [...modulos];

          res.data.ID_MODULO.forEach((idModuleEdit: number, index: number) => {
            const data = {
              ID_MODULO: idModuleEdit,
              NOME_MODULO: res.data.NOME_MODULO[index],
              LEITURA: res.data.LEITURA[index],
              ESCRITA: res.data.ESCRITA[index],
            };

            modulosAux.push(data);
          });

          updateUser(user, modulosAux);

          if (res.data.ID_MODULO.length > 1) {
            addToast({
              type: 'success',
              title: 'Módulos vinculados!',
              description:
                'Os módulos foram cadastrados/vinculados com sucesso.',
            });

            setTimeout(() => {
              go(0);
            }, 1500);
          } else {
            addToast({
              type: 'success',
              title: 'Módulo vinculado!',
              description: 'O módulo foi cadastrado/vinculado com sucesso.',
            });

            setTimeout(() => {
              go(0);
            }, 1500);
          }
        })
        .catch(() => {
          setLoading(false);

          addToast({
            type: 'error',
            title: 'Erro ao salvar dados!',
            description:
              'Erro ao salvar nível de acesso, por favor tente novamente.',
          });
        });
    } else {
      await api
        .put(endPointUrl, {
          idModulo: selectMenu,
          idUsersAppColaborador: selectUser,
          leitura: checkboxStatusLeitura,
          escrita: checkboxStatusEscrita,
          novoModulo: selectMenu,

          // ativo?: boolean;
          // novoUsersAppColaborador?: number;
        })
        .then((res) => {
          setLoading(false);

          const modulosAux: NivelAcessoModulos[] = [...modulos];

          res.data.ID_MODULO.forEach((idModuleEdit: number, index: number) => {
            const findIndexModule = modulos.findIndex(
              (modulo) => modulo.ID_MODULO === idModuleEdit,
            );
            const findModule = modulos.find(
              (modulo) => modulo.ID_MODULO === idModuleEdit,
            );

            const data = {
              ID_MODULO: idModuleEdit,
              NOME_MODULO: res.data.NOME_MODULO[index],
              LEITURA: res.data.LEITURA[index],
              ESCRITA: res.data.ESCRITA[index],
            };

            if (findIndexModule !== -1 && findModule)
              modulosAux[findIndexModule] = data;
          });

          updateUser(user, modulosAux);

          if (res.data.ID_MODULO.length > 1) {
            addToast({
              type: 'success',
              title: 'Módulos editados!',
              description: 'Os módulos foram editados com sucesso.',
            });
          } else {
            addToast({
              type: 'success',
              title: 'Módulo editado!',
              description: 'O módulo foi editado com sucesso.',
            });
          }
        })
        .catch(() => {
          setLoading(false);

          addToast({
            type: 'error',
            title: 'Erro ao salvar dados!',
            description:
              'Erro ao salvar nível de acesso, por favor tente novamente.',
          });
        });
    }
  }, [
    addToast,
    checkboxStatus,
    go,
    modulos,
    selectMenu,
    selectUser,
    selectUserModules,
    statusPainel,
    updateUser,
    user,
  ]);

  const filterUsers = useMemo(() => {
    return responseUsers.filter(
      (user) =>
        user.ID_SETOR === selectSetor && user.ID_NIVEL_ACESSO === selectNivel,
    );
  }, [responseUsers, selectNivel, selectSetor]);

  const statusProgressLine = useMemo(():
    | 'default'
    | 'setor'
    | 'funcao'
    | 'usuario'
    | 'modulo' => {
    let statusAux: 'default' | 'setor' | 'funcao' | 'usuario' | 'modulo' =
      'default';

    if (selectSetor) statusAux = 'setor';
    if (selectSetor && selectNivel) statusAux = 'funcao';
    if (selectSetor && selectNivel && selectUser) statusAux = 'usuario';
    if (selectSetor && selectNivel && selectUser && !!selectMenu.length)
      statusAux = 'modulo';

    return statusAux;
  }, [selectNivel, selectMenu, selectSetor, selectUser]);

  return (
    <Container progressLine={statusProgressLine}>
      <header>
        <h1>Nível de acesso</h1>
        <SiOpenaccess />
      </header>

      <Content>
        <Painel
          isActivePainel
          titlePainel="Setor"
          dataType="setor"
          titleModalPainel="setor"
          verificationMap={1}
          responsePostSetorModalProps={responsePostSetorModal}
          responsePostFuncaoModalProps={responsePostFuncaoModal}
          accessUserProps={accessUser}
          onSelectPainelProps={(e: number) =>
            setSelectSetor(e === -1 ? undefined : e)
          }
          onSelectPainelAllProps={(e) => setSelectUserModules(e)}
        />

        <Painel
          isActivePainel={!!selectSetor}
          titlePainel="Função"
          dataType="funcao"
          titleModalPainel="função"
          verificationMap={selectSetor}
          responsePostSetorModalProps={responsePostSetorModal}
          responsePostFuncaoModalProps={responsePostFuncaoModal}
          accessUserProps={accessUser}
          onSelectPainelProps={(e: number) =>
            setSelectNivel(e === -1 ? undefined : e)
          }
          onSelectPainelAllProps={(e) => setSelectUserModules(e)}
        />

        <Painel
          isActivePainel={!!selectSetor && !!selectNivel}
          titlePainel="Usuário"
          dataType="usuario"
          dataPainelUser={filterUsers}
          responsePostSetorModalProps={responsePostSetorModal}
          responsePostFuncaoModalProps={responsePostFuncaoModal}
          verificationMap={selectSetor && selectNivel}
          accessUserProps={accessUser}
          onSelectPainelProps={(e: number) =>
            setSelectUser(e === -1 ? undefined : e)
          }
          onSelectPainelAllProps={(e) => setSelectUserModules(e)}
        />

        <PainelModules
          isActivePainel={!!selectSetor && !!selectNivel && !!selectUser}
          titlePainel="Módulos"
          verificationMap={selectSetor && selectNivel && selectUser}
          dataPainelModulesUser={selectUserModules}
          onStatusPainel={(e: string) => setStatusPainel(e)}
          onSelectPainelProps={(e: number[]) => setSelectMenu(e)}
          onSelectCheckboxProps={(e: CheckboxInterface[]) =>
            setCheckboxStatus(e)}
        />
      </Content>

      {!!selectMenu.length && accessUser.ESCRITA && (
        <button type="button" onClick={handleSubmit}>
          Salvar
        </button>
      )}

      {loading && <Loading />}
    </Container>
  );
};

export default UserAccess;
