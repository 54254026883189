import React from 'react';
import { FiEye } from 'react-icons/fi';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { ContainerTh } from './styles';

interface HeaderInterface {
  name: string;
  field: string;
  id: number;
  textInfo?: string;
  larguraInfo?: number;
}

interface DadosInterface {
  headers: HeaderInterface[];
  onChange: (arg: number) => void;
  onModal: (arg: void) => void;
}

const HeaderPonto: React.FC<DadosInterface> = ({
  headers,
  onChange,
  onModal,
}: DadosInterface) => {
  return (
    <thead>
      <tr>
        {headers.map((head: HeaderInterface, index: number) => (
          <ContainerTh key={head.field + index.toString()}>
            {head.name}
            {
              // head.field === 'ferias' ||
              (head.field === 'afasta' ||
                head.field === 'ponto' ||
                head.field === 'falta' ||
                head.field === 'atraso' ||
                head.field === 'horaDivergente' ||
                head.field === 'pontoDivergente' ||
                head.field === 'he' ||
                head.field === 'abono') && (
                <button
                  type="button"
                  onClick={() => {
                    onChange(head.id);
                  }}
                >
                  <FiEye />
                </button>
              )
            }
            {head.field === 'ponto' && (
              <button
                type="button"
                onClick={() => {
                  onModal();
                }}
              >
                <BsQuestionCircleFill />
              </button>
            )}
          </ContainerTh>
        ))}
      </tr>
    </thead>
  );
};

export default HeaderPonto;
