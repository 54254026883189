import React, {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { IconBaseProps } from 'react-icons';
import { useField } from '@unform/core';
import { FiAlertCircle, FiEye, FiEyeOff } from 'react-icons/fi';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import Swal from 'sweetalert2';
import { Container, Error } from './styles';

import {
  maskCpf,
  maskTel,
  maskCnpj,
  maskCep,
  maskDateOfBirth,
  isCpf,
  isDate,
  moedaReal,
  maskRegistration,
} from './mask';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  icon?: React.ComponentType<IconBaseProps>;
  tipo?: string;
  mask?: string;
  widthProps?: string;
  tooltip?: boolean;
  editProps?: boolean;
  placeholderProps?: string;
  onValue?: (arg: string) => void;
}

const Input: React.FC<InputProps> = ({
  name,
  icon: Icon,
  tipo = '',
  mask,
  widthProps = '100%',
  placeholderProps = '',
  tooltip = true,
  editProps = false,
  onValue,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [whatMask, setWhatMask] = useState<string | number | null>();
  const [eyeClick, setEyeClick] = useState(tipo === 'password');
  const inputRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, error, registerField } = useField(name);
  const CustomTooltipEdit = withStyles((theme) => ({
    arrow: {
      color: '#3e3946',
    },
    tooltip: {
      backgroundColor: '#3e3946',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    if (mask === 'cpf' && isCpf(inputRef.current?.value) === true) {
      setIsFilled(true);
      return;
    }

    if (
      mask === 'cpf' &&
      isCpf(inputRef.current?.value) === false &&
      inputRef.current?.value !== ''
    ) {
      Swal.fire({
        icon: 'info',
        title: 'Esse CPF não é válido! ',
      });
      setWhatMask('');
      // setIsModalVisibleCPF(true);
      setIsFilled(false);
      return;
    }

    if (mask === 'dateOfBirth' && isDate(inputRef.current?.value) === true) {
      setWhatMask(inputRef.current?.value);
      setIsFilled(true);
      return;
    }
    if (
      mask === 'dateOfBirth' &&
      isCpf(inputRef.current?.value) === false &&
      inputRef.current?.value !== ''
    ) {
      setWhatMask('');

      Swal.fire({
        icon: 'info',
        title: 'Data inválida! ',
      });

      setIsFilled(false);
      return;
    }

    // Se for vazio será false se estiver preenchido será true.
    setIsFilled(!!inputRef.current?.value);
  }, []);// eslint-disable-line

  const handleInputChange = useCallback(
    (e) => {
      if (onValue) {
        switch (mask) {
          case 'cpf':
            onValue(maskCpf(e.target.value));
            break;
          case 'cnpj':
            onValue(maskCnpj(e.target.value));
            break;
          case 'phone':
            onValue(maskTel(e.target.value));
            break;
          case 'cep':
            onValue(maskCep(e.target.value));
            break;
          case 'dateOfBirth':
            onValue(maskDateOfBirth(e.target.value));
            break;
          case 'coin':
            onValue(moedaReal(e.target.value));
            break;
          case 'registration':
            onValue(maskRegistration(e.target.value));
            break;

          default:
            onValue(e.target.value);
        }
      } else {
        switch (mask) {
          case 'cpf':
            setWhatMask(maskCpf(e.target.value));
            return;
          case 'cnpj':
            setWhatMask(maskCnpj(e.target.value));
            return;
          case 'phone':
            setWhatMask(maskTel(e.target.value));
            return;
          case 'cep':
            setWhatMask(maskCep(e.target.value));
            return;
          case 'dateOfBirth':
            setWhatMask(maskDateOfBirth(e.target.value));
            return;
          case 'coin':
            setWhatMask(moedaReal(e.target.value));
            return;
          case 'registration':
            setWhatMask(maskRegistration(e.target.value));
            break;

          default:
            setWhatMask(e.target.value);
        }
      }
    },
    [mask, onValue],
  );

  const checkMaskInputLength = useMemo((): number => {
    let inputLength = 255;
    switch (mask) {
      case 'cpf':
        inputLength = 14;
        break;
      case 'cnpj':
        inputLength = 18;
        break;
      case 'phone':
        inputLength = 16;
        break;
      case 'cep':
        inputLength = 10;
        break;
      case 'dateOfBirth':
        inputLength = 10;
        break;
      case 'coin':
        inputLength = 10;
        break;
      case 'registration':
        inputLength = 6;
        break;
      default:
        inputLength = 255;
    }

    return inputLength;
  }, [mask]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return editProps ? (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      widthProps={widthProps}
    >
      {Icon && <Icon size={20} />}

      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        maxLength={checkMaskInputLength}
        value={whatMask || ''}
        onChange={(eForChange) => {
          handleInputChange(eForChange);
        }}
        type={eyeClick && tipo === 'password' ? 'password' : 'text'}
        {...rest}
      />

      {tipo === 'password' && (
        <button
          type="button"
          onClick={() => {
            setEyeClick(!eyeClick);
          }}
        >
          {eyeClick && <FiEye />}
          {!eyeClick && <FiEyeOff />}
        </button>
      )}
      {error && tooltip && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size="20" />
        </Error>
      )}
      {!tooltip && (
        <CustomTooltipEdit
          title={placeholderProps}
          arrow
          TransitionComponent={Zoom}
        >
          <span
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 15,
            }}
          >
            {error ? (
              <FiAlertCircle
                color="#c53030"
                size="20"
                style={{ marginRight: '0' }}
              />
            ) : (
              <AiOutlineQuestionCircle size="20" style={{ marginRight: '0' }} />
            )}
          </span>
        </CustomTooltipEdit>
      )}
    </Container>
  ) : (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      widthProps={widthProps}
    >
      {Icon && <Icon size={20} />}
      <input
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        defaultValue={defaultValue}
        ref={inputRef}
        maxLength={checkMaskInputLength}
        value={whatMask || ''}
        onChange={(eForChange) => {
          handleInputChange(eForChange);
        }}
        type={eyeClick && tipo === 'password' ? 'password' : 'text'}
        {...rest}
      />
      {tipo === 'password' && (
        <button
          type="button"
          onClick={() => {
            setEyeClick(!eyeClick);
          }}
        >
          {eyeClick && <FiEye size={18} />}
          {!eyeClick && <FiEyeOff size={18} />}
        </button>
      )}
      {error && tooltip && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size="20" />
        </Error>
      )}
      {error && !tooltip && (
        <FiAlertCircle color="#c53030" size="20" style={{ marginRight: '0' }} />
      )}
    </Container>
  );
};

export default Input;
