import styled, { keyframes } from 'styled-components';
import { lighten, shade } from 'polished';

export const Container = styled.div`
  margin-bottom: 15px;
`;

export const ContainerAccess = styled.div`
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  background-color: #3e3c44;
  padding: 20px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  animation: ${appearFromLeft} 1s;
`;

export const ContentSubBody = styled.main`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;

  div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    div {
      p {
        margin: 0;
        padding: 9.2px;
        border: 1px solid #e5e5e5;
        background: #3e3c44;
      }

      p:first-child {
        border-right: none;
        background: ${lighten(0.1, '#3e3c44')};
      }
    }

    button {
      margin: 0;
      width: auto;
      min-width: 150px;
      max-width: 200px;
      height: auto;
      padding: 13px;
      border-radius: 7px;

      & + button {
        margin-left: 20px;
        background: #cc0000;

        transition: background 0.2s;

        &:hover {
          background: ${shade(0.2, '#cc0000')};
        }
      }
    }
  }
`;
