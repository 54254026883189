export const formatarDataAnoMesDia = (data: string): string => {
  const ano = data.substring(0, 4);
  const mes = data.substring(5, 7);
  const dia = data.substring(8, 10);
  return `${dia}-${mes}-${ano}`;
};

export const formatarDataAno = (data: string): string => {
  const ano = data.substring(0, 4);
  const mes = data.substring(5, 7);
  const dia = data.substring(8, 10);
  return `${ano}-${mes}-${dia}`;
};

export const formatarData = (data: string | undefined): string => {
  if (data === undefined) {
    return '';
  }
  const dia = data.substring(0, 2);
  const mes = data.substring(3, 5);
  const ano = data.substring(6, 10);
  return `${ano}-${mes}-${dia}`;
};

export const formatarDataAnoMes = (data: string | undefined): string => {
  if (data === undefined) {
    return '';
  }
  const mes = data.substring(3, 5);
  const ano = data.substring(6, 10);
  return `${ano}${mes}`;
};

export const formatarDataMesAno = (data: string): string => {
  const ano = data.substring(0, 4);
  const mes = data.substring(4, 6);
  return `${mes}-${ano}`;
};

export const MostrarHoraMinSeg = (data: string): string => {
  const hora = data.substring(11, 19);
  return hora;
};

export const formatarHoraMin = (data: string): string => {
  const hora = data.substring(0, 2);
  const min = data.substring(3, 5);
  return `${hora}:${min}`;
};

export const formatarMinEmHoraMin = (data: number): string => {
  let min;
  let hora;
  const numero = Math.abs(data);
  if (Math.trunc(numero / 60) < 10) {
    hora = `0${Math.trunc(numero / 60)}`;
  } else {
    hora = Math.trunc(numero / 60);
  }
  if (numero % 60 < 10) {
    min = `0${numero % 60}`;
  } else {
    min = numero % 60;
  }
  return `${hora}:${min}`;
};

export const formatarStringToNumber = (data: string): number => {
  if (data !== null) {
    const hora = data.substring(0, 2);
    const min = data.substring(3, 5);
    return Number(hora) * 60 + Number(min);
  }
  return 0;
};

export const formatarDiasDaSemana = (data: string): string => {
  switch (data) {
    case 'SEGUNDA-FEIRA':
      return 'seg';
    case 'TERÇA-FEIRA':
      return 'ter';
    case 'QUARTA-FEIRA':
      return 'qua';
    case 'QUINTA-FEIRA':
      return 'qui';
    case 'SEXTA-FEIRA':
      return 'sex';
    case 'SÁBADO':
      return 'sab';
    case 'DOMINGO':
      return 'dom';
    default:
      return '';
  }
};

export const CalcularFalta = (
  horasQueFalta: number,
  data: string,
  date: string,
  ht: string,
): number => {
  if (data === null && new Date(`${date}T23:59:59`) <= new Date()) {
    const hora = ht.substring(0, 2);
    const min = ht.substring(3, 5);
    return Number(hora) * 60 + Number(min);
  }
  return horasQueFalta;
};

export const CalcularFaltaDiasCompleto = (
  horaATrabalhar: string,
  horaFalta: number,
): number => {
  if (horaFalta > 0) {
    const hora = formatarStringToNumber(horaATrabalhar);
    return Math.trunc(horaFalta / hora);
  }
  return 0;
};

export const formatarMaxData = (data: string | undefined): string => {
  if (data === undefined) {
    return '';
  }
  const dia = data.substring(0, 2);
  const mes = data.substring(3, 5);
  const ano = data.substring(6, 10);
  const mesNumber = 1 + Number(mes);

  return `${ano} ${mesNumber} ${dia}`;
};
