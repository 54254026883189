import React, { useCallback } from 'react';
import Dropzone from 'react-dropzone';
import { DropContainer, UploadMessage } from './styles';

interface UploadProps {
  paddingProps: string;
  onUpload?(e: File): void;
}

const Upload: React.FC<UploadProps> = ({
  paddingProps,
  onUpload = () => {
    ('');
  },
}) => {
  const renderDragMessage = useCallback(
    (isDragActive, isDragReject) => {
      if (!isDragActive) {
        return (
          <UploadMessage paddingProps={paddingProps}>
            Arraste arquivos .CSV aqui...
          </UploadMessage>
        );
      }

      if (isDragReject) {
        return (
          <UploadMessage type="error" paddingProps={paddingProps}>
            Arquivo não suportado
          </UploadMessage>
        );
      }

      return (
        <UploadMessage type="success" paddingProps={paddingProps}>
          Solte os arquivos aqui
        </UploadMessage>
      );
    },
    [paddingProps],
  );

  return (
    <Dropzone
      accept={['.csv', 'text/csv', 'application/vnd.ms-excel']}
      onDropAccepted={(files: any) => onUpload(files)}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
        <DropContainer
          {...getRootProps()}
          className="dropzone"
          isDragActive={isDragActive}
          isDragReject={isDragReject}
        >
          <input {...getInputProps()} />
          {renderDragMessage(isDragActive, isDragReject)}
        </DropContainer>
      )}
    </Dropzone>
  );
};

export default Upload;
