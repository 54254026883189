import styled from 'styled-components';
import { lighten } from 'polished';

interface ContainerProps {
  progressLine: 'default' | 'setor' | 'funcao' | 'usuario' | 'modulo';
}

const calcProgressLine = {
  default: '0',
  setor: 'calc((100% - 100px) / 4)',
  funcao: 'calc(((100% - 100px) / 4) * 2)',
  usuario: 'calc(((100% - 100px) / 4) * 3)',
  modulo: 'calc(((100% - 100px) / 4) * 4)',
};

const borderProgressLine = {
  default: '0',
  setor: '7px 0 0 0',
  funcao: '7px 0 0 0',
  usuario: '7px 0 0 0',
  modulo: '7px 7px 0 0',
};

const backgroundProgressLine = {
  default: '',
  setor: lighten(0.1, '#6e677e'),
  funcao: lighten(0.25, '#6e677e'),
  usuario: lighten(0.35, '#6e677e'),
  modulo: lighten(0.45, '#6e677e'),
};

export const Container = styled.div<ContainerProps>`
  background: #555062;
  width: auto;
  margin: 30px;
  border-radius: 7px;
  padding: 20px;
  overflow: auto;

  &:before {
    transition: 0.5s;

    content: '';
    position: absolute;
    width: ${(props) => calcProgressLine[props.progressLine]};
    height: 4px;
    background: ${(props) => backgroundProgressLine[props.progressLine]};
    border-radius: ${(props) => borderProgressLine[props.progressLine]};
  }

  > header {
    background: #6e677e;
    border-radius: 7px;
    padding: 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    h1 {
      margin: 0;
      font-size: 2rem;
    }

    svg {
      width: 26px;
      height: 26px;
    }
  }

  > button {
    width: 100%;
    margin-top: 20px;
    border: none;
    border-radius: 7px;
    padding: 10px;
    font-size: 1.4rem;
    background: #439133;
    box-shadow: 1px 1px 1px 0px black;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const Content = styled.div`
  margin-top: 20px;

  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.4rem;
`;
