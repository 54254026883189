import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { FiEye, FiFile } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import { useAuth } from '../../../hooks/auth';
import HeaderTable from '../../../components/HeaderTable';
import Search from '../../../components/Search';
import Modal from '../VFiltroPonto/ModalPdf/indexModal';
import ModalConexao from '../../../components/ModalConexao';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import { maskCpf } from '../../../components/Input/mask';
import { ResponseRelatorio } from '../VFiltroPonto/Interface/index';
import { formatarDataMesAno } from '../../../components/Formulas/formulas';
import Pagination from '../../../components/Pagination';
import DropDownPagination from '../../../components/DropDownPagination';
import {
  Container,
  ContainerSearchInf,
  Aba,
  ContainerAba,
  ContainerTable,
  ContainerTableMeses,
  ContainerTableEstatica,
  ContainerSemDados,
  ContainerPagination,
} from './styles';

interface ResponseGet {
  CENTRO_CUSTO: string;
  COMPETENCIA_PONTO: string;
  // CONTRATO: string;
  CPF: string;
  CPFSEMFORMATO: string;
  EMPRESA: string;
  NOME: string;
  NOME_CLIENTE: string;
  NOME_EMPRESA: string;
  // POSTO: string;
}

const pageAccess = {
  ID: 14,
  NOME: 'PONTO-ELETRONICO',
};

const VisualizarPonto: React.FC = () => {
  const { modulos } = useAuth();
  const history = useHistory();
  const [searchEmpresa, setSearchEmpresa] = useState('');
  const [searchCliente, setSearchCliente] = useState('');
  const [searchNome, setSearchNome] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  // const [searchPosto, setSearchPosto] = useState('');
  // const [searchContrato, setSearchContrato] = useState('');
  const [responseUnic, setResponseUnic] = useState<ResponseGet[]>([]);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [responseUser, setResponseUser] = useState<ResponseGet[]>([]);
  const [responsePdf, setResponsePdf] = useState<ResponseRelatorio[]>([]);
  const [visualizarMeses, setVisualizarMeses] = useState(false);
  const [visualizarMes, setVisualizarMes] = useState(false);
  const [loading, setLoading] = useState(false);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const headers = [
    { name: 'Empresa', field: 'EMPRESA', sortable: true },
    // { name: 'Cliente', field: 'NOME_CLIENTE', sortable: true },
    { name: 'Nome', field: 'NOME', sortable: true },
    { name: 'CPF', field: 'CPF', sortable: true },
    // { name: 'Contrato', field: 'CONTRATO', sortable: true },
    // { name: 'Posto', field: 'COMPETENCIA_PONTO', sortable: true },
    { name: 'Visualizar', field: 'visualizarFim', sortable: false },
  ];

  const dropDown = [
    { valor: '20', id: '20' },
    { valor: '40', id: '40' },
    { valor: '80', id: '80' },
    { valor: '160', id: '160' },
    { valor: 'Todos', id: '1' },
  ];

  const headersCompetencia = [
    { name: 'Competência', field: 'competencia' },
    { name: 'Gerar PDF', field: 'gerarPDF' },
  ];

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);

    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;
    }

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        text: 'Você não tem permissão para acessar essa página!',
      });
    } else {
      setLoading(true);
      api
        .get(`/pontoEletronico/getEscalaWebBuscalAllEmpresas`)
        .then((data) => {
          setLoading(false);
          setResponse(data.data);

          const unicValues: any = [
            ...new Map(
              data.data.map((item: ResponseGet) => [item.CPFSEMFORMATO, item]),
            ).values(),
          ];

          setResponseUnic(unicValues);
        })
        .catch(() => {
          setLoading(false);
          if (navigator.onLine) {
            Swal.fire({
              icon: 'info',
              title: 'Erro ao carregar dados, por favor atualize a página!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          }
        });
    }
  }, [history, modulos]);

  const filterUserResponse = useCallback(
    (data: ResponseGet): ResponseGet[] => {
      let computedFilter: ResponseGet[] = response;
      computedFilter = computedFilter.filter(
        (res) =>
          res.CPFSEMFORMATO === data.CPFSEMFORMATO && res.COMPETENCIA_PONTO,
      );
      return computedFilter;
    },
    [response],
  );

  // const arrayUnique = useCallback((data: ResponseGet[]): ResponseGet[] => {
  //   const result: ResponseGet[] = [];
  //   const map = new Map();
  //   data.forEach((item) => {
  //     if (!map.has(item.CPFSEMFORMATO) && item.CPFSEMFORMATO !== null) {
  //       map.set(item.CPFSEMFORMATO, true);
  //       result.push({
  //         NOME: item.NOME,
  //         COMPETENCIA_PONTO: item.COMPETENCIA_PONTO,
  //         EMPRESA: item.EMPRESA,
  //         CPFSEMFORMATO: item.CPFSEMFORMATO,
  //         NOME_CLIENTE: item.NOME_CLIENTE,
  //         NOME_EMPRESA: item.NOME_EMPRESA,
  //         CONTRATO: item.CONTRATO,
  //         CENTRO_CUSTO: item.CENTRO_CUSTO,
  //         CPF: item.CPF,
  //         POSTO: item.POSTO,
  //       });
  //     }
  //   });
  //   return result;
  // }, []);

  const arrayUniqueCompetencia = useCallback(
    (data: ResponseGet[]): ResponseGet[] => {
      const result: ResponseGet[] = [];
      const map = new Map();
      data.forEach((item) => {
        if (
          !map.has(item.COMPETENCIA_PONTO) &&
          item.COMPETENCIA_PONTO !== null
        ) {
          map.set(item.COMPETENCIA_PONTO, true);
          result.push({
            NOME: item.NOME,
            COMPETENCIA_PONTO: item.COMPETENCIA_PONTO,
            EMPRESA: item.EMPRESA,
            CPFSEMFORMATO: item.CPFSEMFORMATO,
            NOME_CLIENTE: item.NOME_CLIENTE,
            NOME_EMPRESA: item.NOME_EMPRESA,
            // CONTRATO: item.CONTRATO,
            CENTRO_CUSTO: item.CENTRO_CUSTO,
            CPF: item.CPF,
            // POSTO: item.POSTO,
          });
        }
      });
      return result;
    },
    [],
  );

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = responseUnic; // arrayUnique(response);

    if (searchEmpresa) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.EMPRESA.toLowerCase().includes(searchEmpresa.toLowerCase()),
      );
    }
    if (searchCliente) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME_CLIENTE.toLowerCase().includes(searchCliente.toLowerCase()),
      );
    }
    if (searchNome) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }
    // if (searchContrato) {
    //   computedResponses = computedResponses.filter((res: ResponseGet) =>
    //     res.CONTRATO.toLowerCase().includes(searchContrato.toLowerCase()),
    //   );
    // }
    // if (searchPosto) {
    //   computedResponses = computedResponses.filter((res: ResponseGet) =>
    //     res.POSTO.toLowerCase().includes(searchPosto.toLowerCase()),
    //   );
    // }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (a[sorting.field] !== null && b[sorting.field] !== null) {
          if (typeof a[sorting.field] === 'object') {
            return (
              reversed *
              a[sorting.field]
                .join(', ')
                .localeCompare(b[sorting.field].join(', '))
            );
          }
          if (typeof a[sorting.field] === 'string') {
            return reversed * a[sorting.field].localeCompare(b[sorting.field]);
          }
          return (
            reversed *
            a[sorting.field]
              .toString()
              .localeCompare(b[sorting.field].toString())
          );
        }
        return a;
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    responseUnic,
    searchEmpresa,
    searchCliente,
    searchNome,
    searchCPF,
    // searchContrato,
    // searchPosto,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    searchEmpresa,
    searchCliente,
    searchNome,
    searchCPF,
    // searchContrato,
    // searchPosto,
  ]);

  const handleGerarRelatorio = useCallback(
    async (cpf: string, competencia: string) => {
      try {
        setLoading(true);
        await api
          .get(
            `/pontoEletronico/getEscalaWebCompetenciaFuncionario/${competencia}/${cpf}`,
          )
          .then((data) => {
            setResponsePdf(data.data);
            setVisualizarMes(true);
            setLoading(false);
          });
      } catch (err) {
        setLoading(true);
        Swal.fire({
          icon: 'info',
          title: 'Erro ao carregar dados, tente novamente!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [],
  );

  return (
    <>
      <ModalConexao />

      {!visualizarMeses && (
        <Container>
          <ContainerSearchInf>
            <Search
              onSearch={(value: string) => {
                setSearchEmpresa(value);
              }}
              nomePlaceHolder="Buscar empresa"
            />
            <Search
              onSearch={(value: string) => {
                setSearchNome(value);
              }}
              nomePlaceHolder="Buscar nome"
            />
            <Search
              onSearch={(value: string) => {
                setSearchCPF(value);
              }}
              nomePlaceHolder="Buscar CPF"
            />
          </ContainerSearchInf>

          <ContainerTable>
            <table>
              <HeaderTable
                headers={headers}
                onSorting={(field: string, order: string) =>
                  setSorting({ field, order })
                }
              />
              <tbody>
                {responseData.map((res) => (
                  <tr key={res.CPF}>
                    <td>{res.EMPRESA}</td>
                    {/* <td>{res.NOME_CLIENTE}</td> */}
                    <td>{res.NOME}</td>
                    <td style={{ minWidth: 120 }}>{res.CPF}</td>
                    {/* <td>{res.CONTRATO}</td> */}
                    {/* <td>{res.POSTO}</td> */}
                    <td>
                      <button
                        type="button"
                        onClick={() => {
                          setVisualizarMeses(true);
                          setResponseUser(
                            arrayUniqueCompetencia(filterUserResponse(res)),
                          );
                        }}
                      >
                        <FiEye />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContainerTable>

          <ContainerPagination>
            {responseData.length > 0 && (
              <div id="divPaginacao">
                <Pagination
                  total={totalItems}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDownPagination
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === 'Todos') {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </div>
            )}
          </ContainerPagination>
        </Container>
      )}
      {visualizarMeses && responseUser.length > 0 && (
        <>
          <Aba>
            <ContainerAba className="aba" cor={false}>
              <button
                type="button"
                onClick={() => {
                  setVisualizarMeses(false);
                  setSearchEmpresa('');
                  setSearchNome('');
                  // setSearchCliente('');
                  // setSearchContrato('');
                  setSearchCPF('');
                  // setSearchPosto('');
                }}
              >
                Voltar
              </button>
            </ContainerAba>
          </Aba>
          <ContainerTableEstatica>
            <table>
              <tbody>
                <tr key="a">
                  <td style={{ minWidth: 250 }}>
                    Empresa: {responseUser[0].NOME_EMPRESA}
                  </td>
                  {/* <td style={{ minWidth: 240, maxWidth: 250 }}>
                    Cliente: {responseUser[0].NOME_CLIENTE}
                  </td> */}
                </tr>
                <tr key="b">
                  <td style={{ minWidth: 250 }}>
                    Nome: {responseUser[0].NOME}
                  </td>
                  <td style={{ minWidth: 100 }}>
                    CPF: {maskCpf(responseUser[0].CPFSEMFORMATO)}
                  </td>
                </tr>
                {/* <tr key="c">
                  <td style={{ maxWidth: 250 }}>
                    Posto: {responseUser[0].POSTO}
                  </td>
                  <td>Contrato: {responseUser[0].CONTRATO}</td>
                </tr> */}
              </tbody>
            </table>
          </ContainerTableEstatica>
          <ContainerTableMeses>
            <table>
              <HeaderTable headers={headersCompetencia} />
              <tbody>
                {responseUser.map(
                  (res, index) =>
                    res.COMPETENCIA_PONTO && (
                      <tr key={`${res.CPF + index + res.COMPETENCIA_PONTO}`}>
                        <td>{formatarDataMesAno(res.COMPETENCIA_PONTO)}</td>
                        <td>
                          <button
                            type="button"
                            onClick={() => {
                              handleGerarRelatorio(
                                res.CPFSEMFORMATO,
                                res.COMPETENCIA_PONTO,
                              );
                            }}
                          >
                            <FiFile />
                          </button>
                        </td>
                      </tr>
                    ),
                )}
              </tbody>
            </table>
          </ContainerTableMeses>
        </>
      )}
      {visualizarMeses && !(responseUser.length > 0) && (
        <>
          <Aba>
            <ContainerAba className="aba" cor={false}>
              <button
                type="button"
                onClick={() => {
                  setVisualizarMeses(false);
                  setSearchEmpresa('');
                  setSearchNome('');
                  // setSearchCliente('');
                  // setSearchContrato('');
                  setSearchCPF('');
                  // setSearchPosto('');
                }}
              >
                Voltar
              </button>
            </ContainerAba>
          </Aba>
          <ContainerSemDados>
            <h3>Não existe competência para esse funcionário.</h3>
          </ContainerSemDados>
        </>
      )}
      {visualizarMes && (
        <>
          {responsePdf ? (
            <Modal
              dados={responsePdf}
              onClose={() => setVisualizarMes(false)}
            />
          ) : null}
        </>
      )}
      {loading && <Loading />}
    </>
  );
};

export default VisualizarPonto;
