import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  flex: 1;

  svg {
    margin-right: 20px;
  }

  ul .ant-menu.ant-menu-sub.ant-menu-inline {
    border-radius: 5px;
    background: ${shade(0.2, '#004d00')} !important;
  }

  ul li.ant-menu-item {
    border-radius: 5px;

    &:hover {
      background: ${shade(0.2, '#004d00')};
    }
  }

  .ant-menu-submenu-title {
    border-radius: 5px;
    &:hover {
      background: ${shade(0.2, '#004d00')};
    }
  }

  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
    .ant-menu-item-selected {
    background: ${shade(0.2, '#004d00')};
    border-radius: 5px;
  }
`;
