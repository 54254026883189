import React, {
  useState,
  useMemo,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import * as Yup from 'yup';
import HeaderTable from '../../../../components/HeaderTable';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { maskCpf } from '../../../../components/Input/mask';
import { SelectCustom } from '../../../../components/SelectCustom';
import { useToast } from '../../../../hooks/toast';
import { ResponseGetUser, ResponseGetEscalaAgendamento } from '../index';
import { ResponseGet } from '../../Escala';
import api from '../../../../services/api';
import getValidationErrors from '../../../../utils/getValidationErrors';

import {
  Container,
  ContainerSearch,
  SearchCustom,
  ContainerTable,
  ContainerAgendamento,
} from './styles';

interface SignUpFormData {
  nome: string;
  cpf: string;
  dataInicial: string;
  dataFinal: string;
  escalas: string;
}

interface RegisterProps {
  responseUsersProp: ResponseGetUser[];
  responseEscalasProp: ResponseGet[];
  responseEscalaAgendamentoProp: ResponseGetEscalaAgendamento[];
  onLoading: (e: boolean) => void;
  onResponsePost: (e: ResponseGetEscalaAgendamento) => void;
}

const headers = [
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'CPF', field: 'CPF', sortable: true },
  { name: 'Filial', field: 'filial', sortable: true },
];

export const Register: React.FC<RegisterProps> = ({
  responseUsersProp,
  responseEscalasProp,
  responseEscalaAgendamentoProp,
  onLoading = (e: boolean) => {
    return e;
  },
  onResponsePost = (e: ResponseGetEscalaAgendamento) => {
    return e;
  },
}) => {
  const { addToast } = useToast();
  const [searchUser, setSearchUser] = useState('');
  const [searchCpf, setSearchCpf] = useState('');
  const [searchFilial, setSearchFilial] = useState('');
  const formRef = useRef<FormHandles>(null);
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [selectUser, setSelectUser] = useState<ResponseGetUser>();
  const [responseUsersAux, setResponseUsersAux] = useState<ResponseGetUser[]>(
    [],
  );

  useEffect(() => {
    const aux = responseUsersProp.filter((user) => {
      const agendamentoFind = !responseEscalaAgendamentoProp.find(
        (agendamento) => {
          return agendamento.ATIVO === true && agendamento.Id_User === user.ID;
        },
      );

      return agendamentoFind;
    });

    setResponseUsersAux(aux);
  }, [responseEscalaAgendamentoProp, responseUsersProp]);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        onLoading(true);

        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome é obrigatório'),
          cpf: Yup.string().required('CPF é obrigatório'),
          dataInicial: Yup.string().required('Data inicial é obrigatória'),
          dataFinal: Yup.string().required('Data final é obrigatória'),
          escalas: Yup.string().required('Escala é obrigatória'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let CPF = data.cpf.replace(/\./g, '');
        CPF = CPF.replace(/-/g, '');

        await api
          .post('escalaAgendamento/saveEscalaAgendamento', {
            idUser: selectUser?.ID,
            dataI: data.dataInicial.split('/').reverse().join('-'),
            dataF: data.dataFinal.split('/').reverse().join('-'),
            escalaId: Number(data.escalas),
            cpf: CPF,
            filial: selectUser?.filial,
          })
          .then((res) => {
            onLoading(false);

            const dataResponse = { ...res.data, nome_user: selectUser?.NOME };

            onResponsePost(dataResponse);

            addToast({
              type: 'success',
              title: 'Sucesso',
              description: 'Cadastro efetuado com sucesso',
            });
          })
          .catch(() => {
            onLoading(false);

            addToast({
              type: 'error',
              title: 'Erro',
              description:
                'Ocorreu um erro ao realizar o cadastro. Tente novamente.',
            });
          });
      } catch (err) {
        onLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        } else if (navigator.onLine) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao carregar dados, por favor atualize a página!',
          });
        }
      }
    },
    [addToast, onLoading, onResponsePost, selectUser],
  );

  const responseData = useMemo(() => {
    let computedResponse: ResponseGetUser[] = responseUsersAux || [];

    if (searchUser) {
      computedResponse = computedResponse.filter(
        (item) =>
          item.NOME &&
          item.NOME.toLowerCase().includes(searchUser.toLowerCase()),
      );
    }

    if (searchCpf) {
      let CPF = searchCpf.replace(/\./g, '');
      CPF = CPF.replace(/-/g, '');

      computedResponse = computedResponse.filter(
        (item) => item.CPF && item.CPF.toString().includes(CPF.toString()),
      );
    }

    if (searchFilial) {
      computedResponse = computedResponse.filter(
        (item) =>
          item.filial &&
          item.filial.toLowerCase().includes(searchFilial.toLowerCase()),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponse = computedResponse.sort((a: any, b: any): any => {
        if (a[sorting.field] !== null && b[sorting.field] !== null) {
          if (typeof a[sorting.field] === 'object') {
            return (
              reversed *
              a[sorting.field]
                .join(', ')
                .localeCompare(b[sorting.field].join(', '))
            );
          }
          if (typeof a[sorting.field] === 'string') {
            return reversed * a[sorting.field].localeCompare(b[sorting.field]);
          }
          return (
            reversed *
            a[sorting.field]
              .toString()
              .localeCompare(b[sorting.field].toString())
          );
        }
        return a;
      });
    }

    return computedResponse;
  }, [
    responseUsersAux,
    searchCpf,
    searchFilial,
    searchUser,
    sorting.field,
    sorting.order,
  ]);

  return (
    <Container>
      <ContainerSearch>
        <SearchCustom
          nomePlaceHolder="Buscar usuário"
          onSearch={(value) => setSearchUser(value)}
        />
        <SearchCustom
          nomePlaceHolder="Buscar CPF"
          onSearch={(value) => setSearchCpf(value)}
        />
        <SearchCustom
          nomePlaceHolder="Buscar filial"
          onSearch={(value) => setSearchFilial(value)}
        />
      </ContainerSearch>

      <ContainerTable>
        <table>
          <HeaderTable
            headers={headers}
            onSorting={(field: string, order: string) =>
              setSorting({ field, order })
            }
          />
          <tbody>
            {responseData.map((user) => (
              <tr key={user.ID} onClick={() => setSelectUser(user)}>
                <td>{user.NOME ? user.NOME : '-'}</td>
                <td>{user.CPF ? maskCpf(user.CPF) : '-'}</td>
                <td>{user.filial ? user.filial : '-'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTable>

      {selectUser && (
        <ContainerAgendamento>
          <Form ref={formRef} onSubmit={handleSubmit}>
            <section>
              <Input
                type="text"
                name="nome"
                value={selectUser.NOME ? selectUser.NOME : '-'}
                disabled
              />
              <Input
                type="text"
                name="cpf"
                value={selectUser.CPF ? maskCpf(selectUser.CPF) : '-'}
                disabled
              />
              <Input
                type="text"
                name="dataInicial"
                placeholder="Data inicial"
                mask="dateOfBirth"
              />
              <Input
                type="text"
                name="dataFinal"
                placeholder="Data final"
                mask="dateOfBirth"
              />
            </section>

            <SelectCustom
              name="escalas"
              returnType="id"
              defaultValue="Escalas"
              optionsDataAll={responseEscalasProp}
              // onValue={(e) => setIdEscala(Number(e))}
            />

            <Button type="submit">Cadastrar</Button>
          </Form>
        </ContainerAgendamento>
      )}
    </Container>
  );
};
