import styled from 'styled-components';

export const ContainerItem = styled.div`
  max-width: 1120px;
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    border: 1px solid #009900;
    background-color: #008000;
    //height: 100%;
    margin: 0 !important;
  }
  .active {
    background: #006600;
    padding: 10px !important;
  }
  li.page-item {
    //padding: 10px 7px 10px 7px;
    display: list-item;
    /* &:active {
      background-color: #006600;
    } */
    /**ocultar link e mudar a cor*/
    a {
      text-decoration: none;
      outline: none;
      border: none;
      color: #ffffff;
      padding: 10px 7px 10px 7px;
      /** mudar cor na hora do click */
      &:active {
        color: #ffffff;
        background-color: #006600;
      }
    }
    /**mudar com do elemento clicado */
    span.page-link {
      color: #ffffff;
      font-weight: bold;
      background-color: transparent;
    }
    /** span class= sr-only -- para ocultar o texto -> current */
    .sr-only {
      overflow: hidden;
      position: absolute;
      width: 1px;
      height: 1px;
      padding: 0;
      margin: -1px;
      clip: rect(0, 0, 0, 0);
      border: 0;
    }
  }
`;
