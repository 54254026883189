import styled from 'styled-components';

interface ContainerProps {
  porcentagem: number;
}

export const Container = styled.div`
  width: 200px;
  height: 20px;
  border-radius: 10px;
  box-shadow: inset 0 0 5px #000;
  background-color: #ffffff;
`;

export const ContainerBarra = styled.div<ContainerProps>`
  width: ${(props) => props.porcentagem}%;
  height: 100%;
  background-color: #006600;
  border-radius: 8px;
  transition: width 0.3s ease-in-out;
`;
