import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { AiFillCloseCircle, AiOutlineFieldTime } from 'react-icons/ai';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { BsBuilding } from 'react-icons/bs';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { MdDateRange } from 'react-icons/md';
import Input from '../Input';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  SignUpFormData,
  ResponseGet,
} from '../../pages/Cadastros/CadastroFerias';
import InputDate from '../DatePicker';
import { Loading } from '../Loading';
import { ResponseGetFilial } from '../../pages/Cadastros/PreCadastroFuncionario';
import { SelectCustom } from '../SelectCustom';
import { findFilialName } from '../../interfaces/functionsFilterFind';

import {
  ContainerModal,
  Container,
  ButtonClose,
  Content,
  Title,
  ButtonSubmit,
  ContainerForm,
} from './styles';

interface ModalProps {
  data: ResponseGet;
  filiais: ResponseGetFilial[];
  onClose(e: boolean): void;
}

export const EditFeriasModal: React.FC<ModalProps> = ({
  data,
  filiais,
  onClose = (e: boolean): boolean => {
    return e;
  },
}: ModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const { addToast } = useToast();
  // const [editNome, setEditNome] = useState(data?.NOME);
  // const [editMatricula, setEditMatricula] = useState(data?.MATRICULA);
  // const [editFuncao, setEditFuncao] = useState(data?.FUNCAO);
  // const [editDataAdmissao, setEditDataAdmissao] = useState(data?.DT_ADMISSAO);
  const [editEmpresa, setEditEmpresa] = useState(data?.EMPRESA);
  const [editNomeEmpresa, setEditNomeEmpresa] = useState(data?.NOME_EMPRESA);
  // const [editCnpj, setEditCnpj] = useState(data?.CNPJ);
  // const [editSituacao, setEditSituacao] = useState(data?.SITUACAO);
  const [editDataInicialFerias, setEditDataInicialFerias] = useState(
    data?.DATA_INICIAL_FERIAS,
  );
  const [editDataFinalFerias, setEditDataFinalFerias] = useState(
    data?.DATA_FINAL_FERIAS,
  );
  const [
    editDataInicialPeriodoFerias,
    setEditDataInicialPeriodoFerias,
  ] = useState(data?.DATA_INICIAL_PERIODO_FERIAS);
  const [editDataFinalPeriodoFerias, setEditDataFinalPeriodoFerias] = useState(
    data?.DATA_FINAL_PERIODO_FERIAS,
  );
  const [
    editDataMaximaSindicatoFerias,
    setEditDataMaximaSindicatoFerias,
  ] = useState(data?.DATA_MAXIMA_SINDICATO_FERIAS);
  const [editDataRecebimento, setEditDataRecebimento] = useState(
    data?.DATA_RECEBIMENTO,
  );
  const [editDiasFaltas, setEditDiasFaltas] = useState(
    data?.DIAS_FALTAS.toString(),
  );
  const [editDiasFeriasVencidas, setEditDiasFeriasVencidas] = useState(
    data?.DIAS_FERIAS_VENCIDAS.toString(),
  );
  const [editDiasPagos, setEditDiasPagos] = useState(
    data?.DIAS_PAGOS.toString(),
  );
  const [editSindicato, setEditSindicato] = useState(data?.SINDICATO);
  const [editAbonoPecuniario, setEditAbonoPecuniario] = useState(
    data?.ABONO_PECUNIARIO.toString(),
  );
  const [editSituacaoFuturaFerias, setEditSituacaoFuturaFerias] = useState(
    data?.SITUACAO_FUTURA_FERIAS,
  );
  const [editTempoMaximoFerias, setEditTempoMaximoFerias] = useState(
    data?.TEMPO_MAXIMO_PARA_FERIAS,
  );
  const [editCodSituacaoFerias, setEditCodSituacaoFerias] = useState(
    data?.COD_SITUACAO_FERIAS.toString(),
  );
  const [editCodSindicato, setEditCodSindicato] = useState(
    data?.COD_SINDICATO.toString(),
  );
  const [editCodSitucaoSindicato, setEditCodSituacaoSindicato] = useState(
    data?.COD_SIT_SINDICATO.toString(),
  );
  const [
    editCompetenciaInicialPeriodoFerias,
    setEditCompetenciaInicialPeriodoFerias,
  ] = useState(data?.COMPETENCIA_INICIAL_PERIODO_FERIAS);
  const [
    editCompetenciaFinalPeriodoFerias,
    setEditCompetenciaFinalPeriodoFerias,
  ] = useState(data?.COMPETENCIA_FINAL_PERIODO_FERIAS);

  // const [editDataAdmissaoValid, setEditDataAdmissaoValid] = useState(false);
  const [
    editDataInicialPeriodoFeriasValid,
    setEditDataInicialPeriodoFeriasValid,
  ] = useState(false);
  const [
    editDataFinalPeriodoFeriasValid,
    setEditDataFinalPeriodoFeriasValid,
  ] = useState(false);
  const [
    editCompetenciaInicialPeriodoFeriasValid,
    setEditCompetenciaInicialPeriodoFeriasValid,
  ] = useState(false);
  const [
    editCompetenciaFinalPeriodoFeriasValid,
    setEditCompetenciaFinalPeriodoFeriasValid,
  ] = useState(false);
  const [editDataInicialFeriasValid, setEditDataInicialFeriasValid] = useState(
    false,
  );
  const [editDataFinalFeriasValid, setEditDataFinalFeriasValid] = useState(
    false,
  );
  const [editDataRecebimentoValid, setEditDataRecebimentoValid] = useState(
    false,
  );
  const [
    editDataMaximaSindicatoFeriasValid,
    setEditDataMaximaSindicatoFeriasValid,
  ] = useState(false);
  const [filiaisTemp, setFiliaisTemp] = useState<ResponseGetFilial[]>([]);

  useEffect(() => {
    const findDataFilial = filiais.find(
      (filial) => filial.CODIGO_FILIAL === editEmpresa,
    );

    findDataFilial === undefined
      ? setFiliaisTemp([
          ...filiais,
          {
            ATIVO: false,
            BAIRRO: 'INATIVO',
            CEP: 'INATIVO',
            CIDADE: 'INATIVO',
            CODIGO_FILIAL: editEmpresa,
            ENDERECO: 'INATIVO',
            ESTADO: 'INATIVO',
            ID: -1,
            NUMERO: 'INATIVO',
            NOME_EMPRESA: 'INATIVO',
            NOME_FANTASIA_EMPRESA: 'INATIVO',
            CNPJ: 'INATIVO',
          },
        ])
      : setFiliaisTemp([...filiais]);
  }, [editEmpresa, filiais]);

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose(true);
      }
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    async (dataTemp: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});
        let validEditDataInicialPeriodoFerias = true;
        let validEditDataFinalPeriodoFerias = true;
        let validEditCompetenciaInicialPeriodoFerias = true;
        let validEditCompetenciaFinalPeriodoFerias = true;
        let validEditDataInicialFerias = true;
        let validEditDataFinalFerias = true;
        let validEditDataRecebimento = true;
        let validEditDataMaximaSindicatoFerias = true;

        if (
          editDataInicialPeriodoFerias === '' ||
          editDataInicialPeriodoFerias === undefined
        ) {
          setEditDataInicialPeriodoFeriasValid(true);
        } else {
          setEditDataInicialPeriodoFeriasValid(false);
          validEditDataInicialPeriodoFerias = false;
        }
        if (
          editDataFinalPeriodoFerias === '' ||
          editDataFinalPeriodoFerias === undefined
        ) {
          setEditDataFinalPeriodoFeriasValid(true);
        } else {
          setEditDataFinalPeriodoFeriasValid(false);
          validEditDataFinalPeriodoFerias = false;
        }
        if (
          editCompetenciaInicialPeriodoFerias === '' ||
          editCompetenciaInicialPeriodoFerias === undefined
        ) {
          setEditCompetenciaInicialPeriodoFeriasValid(true);
        } else {
          setEditCompetenciaInicialPeriodoFeriasValid(false);
          validEditCompetenciaInicialPeriodoFerias = false;
        }
        if (
          editCompetenciaFinalPeriodoFerias === '' ||
          editCompetenciaFinalPeriodoFerias === undefined
        ) {
          setEditCompetenciaFinalPeriodoFeriasValid(true);
        } else {
          setEditCompetenciaFinalPeriodoFeriasValid(false);
          validEditCompetenciaFinalPeriodoFerias = false;
        }
        if (
          editDataInicialFerias === '' ||
          editDataInicialFerias === undefined
        ) {
          setEditDataInicialFeriasValid(true);
        } else {
          setEditDataInicialFeriasValid(false);
          validEditDataInicialFerias = false;
        }
        if (editDataFinalFerias === '' || editDataFinalFerias === undefined) {
          setEditDataFinalFeriasValid(true);
        } else {
          setEditDataFinalFeriasValid(false);
          validEditDataFinalFerias = false;
        }
        if (editDataRecebimento === '' || editDataRecebimento === undefined) {
          setEditDataRecebimentoValid(true);
        } else {
          setEditDataRecebimentoValid(false);
          validEditDataRecebimento = false;
        }
        if (
          editDataMaximaSindicatoFerias === '' ||
          editDataMaximaSindicatoFerias === undefined
        ) {
          setEditDataMaximaSindicatoFeriasValid(true);
        } else {
          setEditDataMaximaSindicatoFeriasValid(false);
          validEditDataMaximaSindicatoFerias = false;
        }

        const schema = Yup.object().shape({
          empresa: Yup.string().required('Empresa é obrigatória!'),
          nomeEmpresa: Yup.string().required('Nome da empresa é obrigatória!'),
          sindicato: Yup.string().required('Sindicato é obrigatório!'),
          codigoSindicato: Yup.string().required(
            'Código do sindicato é obrigatório!',
          ),
          diasPagos: Yup.string().required('Dias pagos são obrigatórios!'),
          diasFeriasVencidas: Yup.string().required(
            'Dias de férias vencidas são obrigatórios!',
          ),
          abonoPecuniario: Yup.string().required(
            'Abono pecuniário é obrigatório!',
          ),
          diasFaltas: Yup.string().required('Dias de faltas são obrigatórios!'),
          situacaoFuturaFerias: Yup.string().required(
            'Situação futura de férias é obrigatória!',
          ),
          tempoMaximoFerias: Yup.string().required(
            'Tempo máximo de férias é obrigatório!',
          ),
          codigoSituacaoFerias: Yup.string().required(
            'Código da situação de férias é obrigatório!',
          ),
          codigoSituacaoSindicato: Yup.string().required(
            'Código da situação do sindicato é obrigatório!',
          ),
        });

        await schema.validate(dataTemp, {
          abortEarly: false,
        });

        if (
          !validEditDataInicialPeriodoFerias &&
          !validEditDataFinalPeriodoFerias &&
          !validEditCompetenciaInicialPeriodoFerias &&
          !validEditCompetenciaFinalPeriodoFerias &&
          !validEditDataInicialFerias &&
          !validEditDataFinalFerias &&
          !validEditDataRecebimento &&
          !validEditDataMaximaSindicatoFerias
        ) {
          setShowLoading(true);
          api
            .put(
              `/cadastroIndividual/updateFerias?cpf=${data.CPFSEMFORMATO}&dataInicialPeriodoFerias=${data.DATA_INICIAL_PERIODO_FERIAS}&dataFinalPeriodoFerias=${data.DATA_FINAL_PERIODO_FERIAS}`,
              {
                EMPRESA: editEmpresa,
                NOME_EMPRESA: editNomeEmpresa,
                CNPJ: data.CNPJ,
                NOME: data.NOME,
                MATRICULA: data.MATRICULA,
                DT_ADMISSAO: data.DT_ADMISSAO,
                SITUACAO: data.SITUACAO,
                FUNCAO: data.FUNCAO,
                SINDICATO: editSindicato,
                COD_SINDICATO: editCodSindicato,
                DIAS_PAGOS: editDiasPagos,
                DIAS_FERIAS_VENCIDAS: editDiasFeriasVencidas,
                ABONO_PECUNIARIO: editAbonoPecuniario,
                DIAS_FALTAS: editDiasFaltas,
                COMPETENCIA_INICIAL_PERIODO_FERIAS: editCompetenciaInicialPeriodoFerias,
                COMPETENCIA_FINAL_PERIODO_FERIAS: editCompetenciaFinalPeriodoFerias,
                DATA_INICIAL_FERIAS: editDataInicialFerias,
                DATA_FINAL_FERIAS: editDataFinalFerias,
                DATA_RECEBIMENTO: editDataRecebimento,
                SITUACAO_FUTURA_FERIAS: editSituacaoFuturaFerias,
                TEMPO_MAXIMO_PARA_FERIAS: editTempoMaximoFerias,
                COD_SITUACAO_FERIAS: editCodSituacaoFerias,
                COD_SIT_SINDICATO: editCodSitucaoSindicato,
                DATA_MAXIMA_SINDICATO_FERIAS: editDataMaximaSindicatoFerias,

                NOVA_DATA_INICIAL_PERIODO_FERIAS: editDataInicialPeriodoFerias,
                NOVA_DATA_FINAL_PERIODO_FERIAS: editDataFinalPeriodoFerias,
              },
            )
            .then(() => {
              setShowLoading(false);
              addToast({
                type: 'success',
                title: 'Férias editadas!',
                description:
                  'As férias foram editadas com sucesso e já podem ser visualizadas na listagem.',
              });

              setTimeout(() => {
                history.go(0);
              }, 1500);
            })
            .catch(() => {
              setShowLoading(false);
              addToast({
                type: 'error',
                title: 'Erro na edição',
                description:
                  'Ocorreu um erro ao editar as férias, tente novamente.',
              });
            });
        } else {
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description: 'Existem campos obrigatórios em branco.',
          });
        }
      } catch (err) {
        setShowLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro na edição',
            description:
              'Existem campos obrigatórios não preenchidos, favor verificar.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description:
              'Ocorreu um erro ao editar as férias, tente novamente.',
          });
        }
      }
    },
    [
      addToast,
      data.CNPJ,
      data.CPFSEMFORMATO,
      data.DATA_FINAL_PERIODO_FERIAS,
      data.DATA_INICIAL_PERIODO_FERIAS,
      data.DT_ADMISSAO,
      data.FUNCAO,
      data.MATRICULA,
      data.NOME,
      data.SITUACAO,
      editAbonoPecuniario,
      editCodSindicato,
      editCodSituacaoFerias,
      editCodSitucaoSindicato,
      editCompetenciaFinalPeriodoFerias,
      editCompetenciaInicialPeriodoFerias,
      editDataFinalFerias,
      editDataFinalPeriodoFerias,
      editDataInicialFerias,
      editDataInicialPeriodoFerias,
      editDataMaximaSindicatoFerias,
      editDataRecebimento,
      editDiasFaltas,
      editDiasFeriasVencidas,
      editDiasPagos,
      editEmpresa,
      editNomeEmpresa,
      editSindicato,
      editSituacaoFuturaFerias,
      editTempoMaximoFerias,
      history,
    ],
  );

  return (
    <ContainerModal id="modal" onClick={handleOutSideClick}>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Title>Editar férias</Title>
          <ContainerForm>
            <Content>
              <SelectCustom
                name="empresa"
                widthProps="49%"
                icon={BsBuilding}
                defaultValue="Código da empresa"
                value={editEmpresa}
                onValue={(value) => setEditEmpresa(value)}
                optionsData={filiaisTemp.map((filial) => {
                  return filial.CODIGO_FILIAL;
                })}
              />
              <Input
                name="nomeEmpresa"
                icon={BsBuilding}
                widthProps="49%"
                placeholder="Nome da empresa"
                placeholderProps="Nome da empresa"
                editProps
                tooltip={false}
                value={findFilialName(filiaisTemp, editEmpresa)}
                disabled
              />
            </Content>
            <Content>
              <Input
                name="sindicato"
                icon={IoDocumentTextOutline}
                widthProps="49%"
                placeholder="Sindicato"
                placeholderProps="Sindicato"
                editProps
                tooltip={false}
                value={editSindicato}
                onValue={(value) => setEditSindicato(value)}
              />
              <Input
                name="codigoSindicato"
                icon={IoDocumentTextOutline}
                widthProps="49%"
                placeholder="Código do sindicato"
                placeholderProps="Código do sindicato"
                editProps
                tooltip={false}
                type="number"
                value={editCodSindicato}
                onValue={(value) => setEditCodSindicato(value)}
              />
            </Content>
            <Content>
              <Input
                name="diasPagos"
                icon={MdDateRange}
                widthProps="49%"
                placeholder="Dias pagos"
                placeholderProps="Dias pagos"
                editProps
                tooltip={false}
                type="number"
                value={editDiasPagos}
                onValue={(value) => setEditDiasPagos(value)}
              />
              <Input
                name="diasFeriasVencidas"
                icon={MdDateRange}
                widthProps="49%"
                placeholder="Dias de férias vencidas"
                placeholderProps="Dias de férias vencidas"
                editProps
                tooltip={false}
                type="number"
                value={editDiasFeriasVencidas}
                onValue={(value) => setEditDiasFeriasVencidas(value)}
              />
            </Content>
            <Content>
              <Input
                name="abonoPecuniario"
                icon={IoDocumentTextOutline}
                widthProps="49%"
                placeholder="Abono pecuniário"
                placeholderProps="Abono pecuniário"
                editProps
                tooltip={false}
                type="number"
                value={editAbonoPecuniario}
                onValue={(value) => setEditAbonoPecuniario(value)}
              />
              <Input
                name="situacaoFuturaFerias"
                icon={IoDocumentTextOutline}
                widthProps="49%"
                placeholder="Situação futura de férias"
                placeholderProps="Situação futura de férias"
                editProps
                tooltip={false}
                value={editSituacaoFuturaFerias}
                onValue={(value) => setEditSituacaoFuturaFerias(value)}
              />
            </Content>
            <Content>
              <Input
                name="diasFaltas"
                icon={MdDateRange}
                widthProps="49%"
                placeholder="Dias de faltas"
                placeholderProps="Dias de faltas"
                editProps
                tooltip={false}
                type="number"
                value={editDiasFaltas}
                onValue={(value) => setEditDiasFaltas(value)}
              />
              <Input
                name="tempoMaximoFerias"
                icon={AiOutlineFieldTime}
                widthProps="49%"
                placeholder="Tempo máximo de férias"
                placeholderProps="Tempo máximo de férias"
                editProps
                tooltip={false}
                value={editTempoMaximoFerias}
                onValue={(value) => setEditTempoMaximoFerias(value)}
              />
            </Content>
            <Content>
              <Input
                name="codigoSituacaoFerias"
                icon={IoDocumentTextOutline}
                widthProps="49%"
                placeholder="Código de situação das férias"
                placeholderProps="Código da situação das férias"
                editProps
                tooltip={false}
                type="number"
                value={editCodSituacaoFerias}
                onValue={(value) => setEditCodSituacaoFerias(value)}
              />
              <Input
                name="codigoSituacaoSindicato"
                icon={IoDocumentTextOutline}
                widthProps="49%"
                placeholder="Código de situação do sidicato"
                placeholderProps="Código de situação do sindicato"
                editProps
                tooltip={false}
                type="number"
                value={editCodSitucaoSindicato}
                onValue={(value) => setEditCodSituacaoSindicato(value)}
              />
            </Content>
            <Content>
              <InputDate
                nameProps="dataInicialPeriodoFerias"
                placeholderProps="Data inicial do período de férias"
                editProps
                validProps={editDataInicialPeriodoFeriasValid}
                defaultValueProps={editDataInicialPeriodoFerias}
                widthProps="49%"
                dateFormatReturn={(e: string) =>
                  setEditDataInicialPeriodoFerias(e)
                }
              />
              <InputDate
                nameProps="dataFinalPeriodoFerias"
                placeholderProps="Data final do período de férias"
                validProps={editDataFinalPeriodoFeriasValid}
                defaultValueProps={editDataFinalPeriodoFerias}
                editProps
                widthProps="49%"
                dateFormatReturn={(e: string) =>
                  setEditDataFinalPeriodoFerias(e)
                }
              />
            </Content>
            <Content>
              <InputDate
                nameProps="competenciaInicialPeriodoFerias"
                placeholderProps="Competência inicial do período de férias"
                validProps={editCompetenciaInicialPeriodoFeriasValid}
                defaultValueProps={editCompetenciaInicialPeriodoFerias}
                pickerProps="month"
                editProps
                widthProps="49%"
                dateFormatReturn={(e: string) =>
                  setEditCompetenciaInicialPeriodoFerias(e)}
              />
              <InputDate
                nameProps="competenciaFinalPeriodoFerias"
                placeholderProps="Competência final do período de férias"
                validProps={editCompetenciaFinalPeriodoFeriasValid}
                defaultValueProps={editCompetenciaFinalPeriodoFerias}
                editProps
                pickerProps="month"
                widthProps="49%"
                dateFormatReturn={(e: string) =>
                  setEditCompetenciaFinalPeriodoFerias(e)}
              />
            </Content>
            <Content>
              <InputDate
                nameProps="dataInicialFerias"
                placeholderProps="Data inicial das férias"
                validProps={editDataInicialFeriasValid}
                defaultValueProps={editDataInicialFerias}
                editProps
                widthProps="49%"
                dateFormatReturn={(e: string) => setEditDataInicialFerias(e)}
              />
              <InputDate
                nameProps="dataFinalFerias"
                placeholderProps="Data final das férias"
                validProps={editDataFinalFeriasValid}
                defaultValueProps={editDataFinalFerias}
                editProps
                widthProps="49%"
                dateFormatReturn={(e: string) => setEditDataFinalFerias(e)}
              />
            </Content>
            <Content>
              <InputDate
                nameProps="dataRecebimento"
                placeholderProps="Data do recebimento"
                validProps={editDataRecebimentoValid}
                defaultValueProps={editDataRecebimento}
                editProps
                widthProps="49%"
                dateFormatReturn={(e: string) => setEditDataRecebimento(e)}
              />
              <InputDate
                nameProps="dataMaximaSindicatoFerias"
                placeholderProps="Data máxima do sindicato de férias"
                validProps={editDataMaximaSindicatoFeriasValid}
                defaultValueProps={editDataMaximaSindicatoFerias}
                editProps
                widthProps="49%"
                dateFormatReturn={(e: string) =>
                  setEditDataMaximaSindicatoFerias(e)
                }
              />
            </Content>
          </ContainerForm>
          <ButtonSubmit type="submit">Salvar</ButtonSubmit>
        </Form>
        <ButtonClose onClick={() => onClose(true)}>
          <AiFillCloseCircle size={28} />
        </ButtonClose>
      </Container>

      {showLoading && <Loading />}
    </ContainerModal>
  );
};
