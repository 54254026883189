import React, { useEffect, useState, useCallback, useMemo } from 'react';
import {
  FiEye,
  FiCheckCircle,
  FiXCircle,
  FiFileText,
  FiSend,
  FiSave,
} from 'react-icons/fi';
import { AiFillDislike, AiFillLike } from 'react-icons/ai';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../../../hooks/auth';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import Search from '../../../components/Search';
import HeaderTable from '../../../components/HeaderTable';
import { maskCpf } from '../../../components/Input/mask';
import ModalConexao from '../../../components/ModalConexao';
import ModalArquivo from './Modal/modalArquivo';

import {
  Container,
  ContainerSearch,
  ContainerTable,
  ContainerValidar,
  ContainerTableValidar,
  Content,
  ButtonBorder,
  ButtonBorderIconTrue,
  ButtonBorderIconFalse,
  ContainerPendente,
  ContainerVisualizarVazio,
  ButtonBorderIconFalseRespostaDp,
  ButtonBorderIconTrueRespostaDp,
} from './styles';

interface ValidacaoAdmissao {
  APROVADO_DP: boolean;
  CODIGO_VALIDACAO: string;
  CPF: string;
  DESC_DOCUMENTACAO_A_ENVIAR: string;
  DESC_DOCUMENTACAO_ENVIADA: string;
  DOC_ACEITO: boolean;
  ID_DOC: number;
  ID_DOCUMENTACAO_A_ENVIAR: number;
  ID_DOCUMENTACAO_ENVIADO: number;
  IdUSER: number;
  LINK_IMAGEM: string;
  MOTIVO_REJEICAO_RH: string;
  NOME: string;
  SITUACAO_DOCUMENTACAO: string;
  TELEFONE: string;
}

interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

const pageAccess = {
  ID: 11,
  NOME: 'PRE-CADASTRO-ADMISSAO',
};

const DocumentosAdmissao: React.FC = () => {
  const { user, modulos } = useAuth();
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [response, setResponse] = useState<ValidacaoAdmissao[]>([]);
  const [copiaResponse, setCopiaResponse] = useState<ValidacaoAdmissao[]>([]);
  const [dataValidacao, setDataValidacao] = useState<ValidacaoAdmissao[]>([]);
  const [loading, setLoading] = useState(false);
  const [visualizarValidacao, setVisualizarValidacao] = useState(false);
  const [dadoIcon, setDadoIcon] = useState(false);
  const [docId, setDocId] = useState<number>(0);
  const [userId, setUserId] = useState<number>(0);
  const [userNome, setUserNome] = useState('');
  const [userCPF, setUserCPF] = useState('');
  const [visualizarVazio, setVisualizarVazio] = useState(false);
  const [modal, setModal] = useState(false);
  const [fileData, setFileData] = useState<FileList>();
  const [refresh, setRefresh] = useState(false);
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });

  const headers = [
    { name: 'Documento', field: 'doc' },
    { name: 'Arquivo', field: 'arq' },
    { name: 'Validação RH', field: 'validar' },
    { name: 'Motivo', field: 'motivo' },
  ];

  const headersValidacao = [
    { name: 'Nome', field: 'name' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Código', field: 'codigo' },
    { name: 'Resposta DP', field: 'respostaDP' },
    { name: 'Validar Documentação', field: 'validar' },
  ];

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);

    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title: 'Você não tem permissão para acessar essa página!',
      });
    } else {
      setLoading(true);
      api
        .get(`/documentacao`)
        .then((data) => {
          setResponse(data.data);
          setCopiaResponse(JSON.parse(JSON.stringify(data.data)));
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'info',
            title: 'Erro ao carregar dados, por favor atualize a página!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    }
  }, [history, modulos, refresh]);

  useMemo(() => {
    dataValidacao.forEach((data: ValidacaoAdmissao) => {
      if (data.ID_DOC === docId) {
        // eslint-disable-next-line no-param-reassign
        data.DOC_ACEITO = dadoIcon;
      }
      return data;
    });
    setDataValidacao(dataValidacao);
  }, [dataValidacao, docId, dadoIcon]);

  const handlePreencher = useCallback(
    (dados: ValidacaoAdmissao) => {
      let computedResponses: ValidacaoAdmissao[] = [];
      computedResponses = response;
      computedResponses = computedResponses.filter(
        (cpf) => cpf.CPF === dados.CPF,
      );
      setDataValidacao(computedResponses);
    },
    [response],
  );

  const handleValidateUnicDoc = useCallback(
    (dado: ValidacaoAdmissao): boolean => {
      let computedResponses: ValidacaoAdmissao[] = [];
      let aux = true;
      computedResponses = response;
      computedResponses = computedResponses.filter(
        (cpf) => cpf.CPF === dado.CPF,
      );

      computedResponses.forEach((element) => {
        if (element.SITUACAO_DOCUMENTACAO !== 'PENDENTE') aux = false;
      });

      return aux;
    },
    [response],
  );

  const handlePreencherESalvarDados = useCallback(async () => {
    const arrayDocId: number[] = [];
    const arrayDocAceito: number[] = [];
    const arrayMotivo: string[] = [];
    let motivoVazio = false;
    dataValidacao.forEach((data: ValidacaoAdmissao) => {
      copiaResponse.forEach((dados) => {
        if (
          data.DOC_ACEITO !== null &&
          (data.DOC_ACEITO !== dados.DOC_ACEITO ||
            data.MOTIVO_REJEICAO_RH !== dados.MOTIVO_REJEICAO_RH) &&
          data.ID_DOC === dados.ID_DOC &&
          data.CPF === dados.CPF
        ) {
          if (data.DOC_ACEITO !== null) {
            arrayDocId.push(data.ID_DOC);
            arrayDocAceito.push(+data.DOC_ACEITO);
            if (
              (data.MOTIVO_REJEICAO_RH === '' ||
                data.MOTIVO_REJEICAO_RH === null) &&
              data.DOC_ACEITO === false
            ) {
              motivoVazio = true;
            } else {
              if (data.DOC_ACEITO === true) {
                // eslint-disable-next-line no-param-reassign
                data.MOTIVO_REJEICAO_RH = '';
              }
              arrayMotivo.push(data.MOTIVO_REJEICAO_RH);
            }
          }
        }
      });
    });
    if (motivoVazio) {
      Swal.fire({
        icon: 'error',
        title: 'Preencha o Motivo!',
        showConfirmButton: false,
        timer: 1500,
      });
      motivoVazio = false;
      return;
    }
    try {
      setLoading(true);
      await api
        .patch('documentacao/aprovaDoc/', {
          user: userId,
          aprovado: arrayDocAceito,
          idDoc: arrayDocId,
          motivo_rejeicao_rh: arrayMotivo,
        })
        .then(() => {
          Swal.fire({
            icon: 'success',
            title: 'Validação salva com Sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
          setRefresh(!refresh);
          setVisualizarVazio(false);
          setLoading(false);
        })
        .catch(() => {
          Swal.fire({
            icon: 'error',
            title: 'Erro, ao salvar a validação.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
          setLoading(false);
          setVisualizarValidacao(true);
        });
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Erro, ao salvar a validação. Tente Novamente.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setLoading(false);
      setVisualizarValidacao(true);
    }
    setDataValidacao(dataValidacao);
  }, [dataValidacao, userId, copiaResponse, refresh]);

  function arrayUnique(data: ValidacaoAdmissao[]): ValidacaoAdmissao[] {
    const result: ValidacaoAdmissao[] = [];
    const map = new Map();
    data.forEach((item) => {
      if (!map.has(item.CPF) && item.CPF !== null) {
        map.set(item.CPF, true);
        result.push({
          IdUSER: item.IdUSER,
          NOME: item.NOME,
          TELEFONE: item.TELEFONE,
          CODIGO_VALIDACAO: item.CODIGO_VALIDACAO,
          CPF: item.CPF,
          LINK_IMAGEM: item.LINK_IMAGEM,
          DOC_ACEITO: item.DOC_ACEITO,
          SITUACAO_DOCUMENTACAO: item.SITUACAO_DOCUMENTACAO,
          ID_DOCUMENTACAO_ENVIADO: item.ID_DOCUMENTACAO_ENVIADO,
          ID_DOCUMENTACAO_A_ENVIAR: item.ID_DOCUMENTACAO_A_ENVIAR,
          DESC_DOCUMENTACAO_ENVIADA: item.DESC_DOCUMENTACAO_ENVIADA,
          DESC_DOCUMENTACAO_A_ENVIAR: item.DESC_DOCUMENTACAO_A_ENVIAR,
          MOTIVO_REJEICAO_RH: item.MOTIVO_REJEICAO_RH,
          APROVADO_DP: item.APROVADO_DP,
          ID_DOC: item.ID_DOC,
        });
      }
    });
    return result;
  }

  const responseData = useMemo(() => {
    let computedResponses: ValidacaoAdmissao[] = [];
    computedResponses = arrayUnique(response);

    if (search) {
      computedResponses = computedResponses.filter((res: ValidacaoAdmissao) =>
        res.NOME.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ValidacaoAdmissao) =>
        res.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }

    return computedResponses;
  }, [response, search, searchCPF]);

  const handlePreencherMotivo = useCallback(
    (dado: string, dadosCadatrais: ValidacaoAdmissao) => {
      // eslint-disable-next-line no-param-reassign
      dadosCadatrais.MOTIVO_REJEICAO_RH = dado;
    },
    [],
  );

  const handleErrorVisualizarValidacao = useCallback(() => {
    Swal.fire({
      icon: 'info',
      title: 'Por favor, cancele a visualização da validação de documentos!',
      showClass: {
        popup: 'animate__animated animate__fadeInDown',
      },
      hideClass: {
        popup: 'animate__animated animate__fadeOutUp',
      },
    });
  }, []);

  const verificaDoc = useCallback(
    (dados: ValidacaoAdmissao[]): boolean => {
      let computedResponses: ValidacaoAdmissao[] = [];
      let aux = true;
      computedResponses = copiaResponse;
      computedResponses = computedResponses.filter(
        (cpf) => cpf.CPF === dados[0].CPF,
      );

      computedResponses.forEach((element) => {
        if (element.SITUACAO_DOCUMENTACAO === 'PENDENTE') aux = false;
      });

      return aux;
    },
    [copiaResponse],
  );

  const handleEnviarParaDP = useCallback(async () => {
    if (verificaDoc(dataValidacao)) {
      try {
        if (fileData) {
          setLoading(true);
          const data = new FormData();
          data.append('doc', fileData[0]);
          data.append('userId', userId.toString());
          data.append('cpf', userCPF);
          data.append('documentoId', '24');
          data.append('user_rh_nome', user.nome);
          data.append('user_rh_id', user.id.toString());
          data.append('user_rh_mei', '');

          await api
            .post('documentacao/sendDocumentDP', data)
            .then(() => {
              history.go(0);
              Swal.fire({
                icon: 'success',
                title:
                  'Dados do funcionario enviados para o departamento pessoal!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
              setFileData(undefined);
              setLoading(false);
            })
            .catch(() => {
              Swal.fire({
                icon: 'error',
                title: 'Erro ao enviar dados para o departamento pessoal!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
              setLoading(false);
            });
        }
      } catch (err) {
        Swal.fire({
          icon: 'error',
          title:
            'Erro ao enviar dados para o departamento pessoal, tente novamente!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
        setLoading(false);
      }
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Todos os documentos obrigatórios devem estar aceitos!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [
    dataValidacao,
    fileData,
    history,
    user.id,
    user.nome,
    userCPF,
    userId,
    verificaDoc,
  ]);

  return (
    <>
      <ModalConexao />
      <Container>
        {!visualizarValidacao && (
          <div>
            <ContainerSearch>
              <Search
                onSearch={(value: string) => {
                  setSearch(value);
                }}
                nomePlaceHolder="Buscar Nome"
              />
              <Search
                onSearch={(value: string) => {
                  setSearchCPF(value);
                }}
                nomePlaceHolder="Buscar CPF"
              />
              <ContainerPendente>
                <ReactHTMLTableToExcel
                  id="export-excel"
                  className="btn"
                  table="pendentes"
                  filename="pre-cadastro-admissao"
                  sheet="tablexls"
                  buttonText="Exportar Excel"
                />
                {/* <h3>Pendentes: {responseData.length}</h3> */}
              </ContainerPendente>
            </ContainerSearch>
            <ContainerTable>
              <table id="pendentes">
                <HeaderTable headers={headersValidacao} />
                <tbody>
                  {responseData.map((res: ValidacaoAdmissao) => (
                    <tr key={res.CPF}>
                      <td style={{ minWidth: 300 }}>{res.NOME}</td>
                      <td>{maskCpf(res.CPF)}</td>
                      <td>{res.CODIGO_VALIDACAO}</td>
                      <td style={{ minWidth: 150 }}>
                        <div className="aceitoFlex">
                          <div className="aceito">
                            {(res.APROVADO_DP === null ||
                              res.APROVADO_DP === true) && (
                              <AiFillDislike
                                style={{
                                  color: '#737373',
                                  marginLeft: 4,
                                  marginRight: 4,
                                }}
                                size={19}
                              />
                            )}
                            {!(
                              res.APROVADO_DP === null ||
                              res.APROVADO_DP === true
                            ) && (
                              <ButtonBorderIconFalseRespostaDp>
                                <AiFillDislike
                                  style={{ color: '#f2f2f2' }}
                                  size={19}
                                />
                              </ButtonBorderIconFalseRespostaDp>
                            )}
                          </div>
                          <div className="aceito">
                            {res.APROVADO_DP && (
                              <ButtonBorderIconTrueRespostaDp>
                                <AiFillLike
                                  style={{ color: '#f2f2f2' }}
                                  size={19}
                                />
                              </ButtonBorderIconTrueRespostaDp>
                            )}
                            {!res.APROVADO_DP && (
                              <AiFillLike
                                style={{
                                  color: '#737373',
                                }}
                                size={19}
                              />
                            )}
                          </div>
                        </div>
                        <span hidden>{res.APROVADO_DP && 'Aceito'}</span>
                        <span hidden>{!res.APROVADO_DP && 'Rejeitado'}</span>
                      </td>
                      <td>
                        <button
                          type="button"
                          onClick={() => {
                            if (
                              visualizarValidacao &&
                              visualizarVazio !== true
                            ) {
                              handleErrorVisualizarValidacao();
                            } else {
                              setVisualizarValidacao(true);
                              setUserId(res.IdUSER);
                              handlePreencher(res);
                              setUserNome(res.NOME);
                              setUserCPF(res.CPF);
                              if (handleValidateUnicDoc(res)) {
                                setVisualizarVazio(true);
                              } else {
                                setVisualizarVazio(false);
                              }
                            }
                          }}
                        >
                          <FiEye />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </ContainerTable>
          </div>
        )}
        {visualizarValidacao && (
          <Container>
            {visualizarVazio && (
              <ContainerVisualizarVazio>
                <h3>
                  Ainda não existem documentos para validação referentes a esse
                  usuário.
                </h3>
                <div>
                  <button
                    type="button"
                    onClick={() => {
                      setVisualizarValidacao(false);
                      setVisualizarVazio(false);
                    }}
                  >
                    Voltar
                  </button>
                </div>
              </ContainerVisualizarVazio>
            )}
            {!visualizarVazio && (
              <ContainerValidar>
                <div>
                  <p>{`Validação de documentos do(a): ${userNome}`}</p>
                </div>
                <ContainerTableValidar>
                  <table>
                    <HeaderTable headers={headers} />
                    <tbody>
                      {dataValidacao.map((res: ValidacaoAdmissao) => {
                        return (
                          res.SITUACAO_DOCUMENTACAO !== 'PENDENTE' && (
                            <tr key={res.ID_DOC}>
                              <td>{`${res.ID_DOC} - ${res.DESC_DOCUMENTACAO_ENVIADA}`}</td>
                              <td style={{ width: 30 }}>
                                <a
                                  href={res.LINK_IMAGEM}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FiFileText />
                                </a>
                              </td>
                              <td style={{ minWidth: 150 }}>
                                {accessUser.ESCRITA ? (
                                  <>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setDadoIcon(false);
                                        setDocId(res.ID_DOC);
                                      }}
                                    >
                                      {(res.DOC_ACEITO === null ||
                                        res.DOC_ACEITO === true) && (
                                        <FiXCircle
                                          style={{
                                            color: '#737373',
                                            marginTop: -1,
                                            marginLeft: -1,
                                          }}
                                          size={25}
                                        />
                                      )}
                                      {!(
                                        res.DOC_ACEITO === null ||
                                        res.DOC_ACEITO === true
                                      ) && (
                                        <ButtonBorderIconFalse>
                                          <FiXCircle
                                            style={{ color: '#f2f2f2' }}
                                            size={25}
                                          />
                                        </ButtonBorderIconFalse>
                                      )}
                                    </button>
                                    <button
                                      type="button"
                                      onClick={() => {
                                        setDadoIcon(true);
                                        setDocId(res.ID_DOC);
                                      }}
                                    >
                                      {res.DOC_ACEITO && (
                                        <ButtonBorderIconTrue>
                                          <FiCheckCircle
                                            style={{ color: '#f2f2f2' }}
                                            size={25}
                                          />
                                        </ButtonBorderIconTrue>
                                      )}
                                      {!res.DOC_ACEITO && (
                                        <FiCheckCircle
                                          style={{
                                            color: '#737373',
                                            marginTop: -1,
                                            marginLeft: -1,
                                          }}
                                          size={25}
                                        />
                                      )}
                                    </button>
                                  </>
                                ) : (
                                  <>
                                    <button
                                      type="button"
                                      disabled
                                      style={{ cursor: 'auto' }}
                                    >
                                      {(res.DOC_ACEITO === null ||
                                        res.DOC_ACEITO === true) && (
                                        <FiXCircle
                                          style={{
                                            color: '#737373',
                                            marginTop: -1,
                                            marginLeft: -1,
                                          }}
                                          size={25}
                                        />
                                      )}
                                      {!(
                                        res.DOC_ACEITO === null ||
                                        res.DOC_ACEITO === true
                                      ) && (
                                        <ButtonBorderIconFalse>
                                          <FiXCircle
                                            style={{ color: '#f2f2f2' }}
                                            size={25}
                                          />
                                        </ButtonBorderIconFalse>
                                      )}
                                    </button>
                                    <button
                                      type="button"
                                      disabled
                                      style={{ cursor: 'auto' }}
                                    >
                                      {res.DOC_ACEITO && (
                                        <ButtonBorderIconTrue>
                                          <FiCheckCircle
                                            style={{ color: '#f2f2f2' }}
                                            size={25}
                                          />
                                        </ButtonBorderIconTrue>
                                      )}
                                      {!res.DOC_ACEITO && (
                                        <FiCheckCircle
                                          style={{
                                            color: '#737373',
                                            marginTop: -1,
                                            marginLeft: -1,
                                          }}
                                          size={25}
                                        />
                                      )}
                                    </button>
                                  </>
                                )}
                              </td>
                              {(res.DOC_ACEITO || res.DOC_ACEITO === null) && (
                                <td style={{ minWidth: 200 }} />
                              )}
                              {res.DOC_ACEITO === false && (
                                <td style={{ minWidth: 200 }}>
                                  {accessUser.ESCRITA ? (
                                    <textarea
                                      rows={3}
                                      maxLength={255}
                                      style={{ resize: 'none', minWidth: 300 }}
                                      defaultValue={res.MOTIVO_REJEICAO_RH}
                                      onBlur={(e) =>
                                        handlePreencherMotivo(
                                          e.target.value,
                                          res,
                                        )}
                                    />
                                  ) : (
                                    <textarea
                                      rows={3}
                                      maxLength={255}
                                      style={{
                                        resize: 'none',
                                        minWidth: 300,
                                        color: '#fff',
                                      }}
                                      value={res.MOTIVO_REJEICAO_RH}
                                      disabled
                                    />
                                  )}
                                </td>
                              )}
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </ContainerTableValidar>
                <Content>
                  <ButtonBorder cor={false}>
                    <button
                      type="button"
                      onClick={() => {
                        setVisualizarValidacao(false);
                      }}
                    >
                      Cancelar
                    </button>
                  </ButtonBorder>
                  {accessUser.ESCRITA && (
                    <>
                      <ButtonBorder cor>
                        <button
                          type="button"
                          onClick={() => {
                            setModal(true);
                          }}
                        >
                          <FiSend />
                          Enviar DP
                        </button>
                      </ButtonBorder>
                      <ButtonBorder cor>
                        <button
                          type="button"
                          onClick={() => {
                            window.scrollTo(0, 0);
                            handlePreencherESalvarDados();
                          }}
                        >
                          <FiSave />
                          Salvar
                        </button>
                      </ButtonBorder>
                    </>
                  )}
                </Content>
              </ContainerValidar>
            )}
          </Container>
        )}
        {modal && (
          <ModalArquivo
            onClose={() => setModal(false)}
            onSave={(file) => {
              setFileData(file);
              verificaDoc(dataValidacao);
              handleEnviarParaDP();
            }}
            nome={userNome}
          />
        )}
        {loading && <Loading />}
      </Container>
    </>
  );
};

export default DocumentosAdmissao;
