import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { useToast } from '../../../../hooks/toast';
import DropDownAccessLevel from '../../../../components/DropDownAccessLevel';
import Button from '../../../../components/Button';
import { Loading } from '../../../../components/Loading';
import api from '../../../../services/api';
import { ResponseGet } from '../../UserAccess';
import { ResponsePost } from '../../../../components/ModalRegisterSectorFunction';

import {
  Container,
  ContainerAccess,
  AnimationContainer,
  ContentSubBody,
} from './styles';

interface Props {
  responsePostSetorProps: ResponsePost[];
  responsePostFuncaoProps: ResponsePost[];
  setorProps: number;
  nivelAcessoProps: number;
  userEditProps: ResponseGet;
  dataUsersProps: ResponseGet[];
  onRefProps: (e: any) => void;
  onDataUsersEdit: (e: ResponseGet[]) => void;
  onClose: (e: boolean) => void;
}

export const RegisterEditContainer: React.FC<Props> = ({
  responsePostSetorProps,
  responsePostFuncaoProps,
  setorProps,
  nivelAcessoProps,
  userEditProps,
  dataUsersProps,
  onRefProps = (e: any) => {
    return e;
  },
  onDataUsersEdit = (e: ResponseGet[]) => {
    return e;
  },
  onClose = (e: boolean) => {
    return e;
  },
}) => {
  const formRef = useRef<FormHandles>(null);
  const registerEditRef = useRef<any>(null);
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [setor, setSetor] = useState<number>();
  const [nivelAcesso, setNivelAcesso] = useState<number>();
  const [dataUsers, setDataUsers] = useState<ResponseGet[]>([]);
  const [userEdit, setUserEdit] = useState<ResponseGet>(userEditProps);

  useEffect(() => {
    setUserEdit(userEditProps);
  }, [userEditProps]);

  useEffect(() => {
    onRefProps(registerEditRef);
  }, [onRefProps]);

  useEffect(() => {
    setSetor(setorProps);
    setNivelAcesso(nivelAcessoProps);
    setDataUsers(dataUsersProps);
  }, [
    dataUsersProps,
    nivelAcessoProps,
    responsePostFuncaoProps,
    responsePostSetorProps,
    setorProps,
  ]);

  const filterEditDataPainel = useCallback(
    (idUser: number, idSetor: number, idFuncao: number) => {
      const findSetor = responsePostSetorProps.find(
        (setorTemp) => setorTemp.ID === idSetor,
      );

      const findFuncao = responsePostFuncaoProps.find(
        (setorTemp) => setorTemp.ID === idFuncao,
      );

      const indexUserEdit = dataUsers.findIndex((user) => {
        return user.ID === idUser;
      });

      if (findSetor && findFuncao && findSetor.NOME && findFuncao.NOME) {
        const dataUserEditAux = {
          AVATAR: dataUsers[indexUserEdit].AVATAR,
          Ativo: dataUsers[indexUserEdit].Ativo,
          CPF: dataUsers[indexUserEdit].CPF,
          DataCadastro: dataUsers[indexUserEdit].DataCadastro,
          DataNascimento: dataUsers[indexUserEdit].DataNascimento,
          EMAIL: dataUsers[indexUserEdit].EMAIL,
          ID: dataUsers[indexUserEdit].ID,
          ID_NIVEL_ACESSO: idFuncao,
          ID_SETOR: idSetor,
          NOME: dataUsers[indexUserEdit].NOME,
          NOME_NIVEL_ACESSO: findFuncao.NOME,
          NOME_SETOR: findSetor.NOME,
          Telefone: dataUsers[indexUserEdit].Telefone,
          modulos: dataUsers[indexUserEdit].modulos,
          param: dataUsers[indexUserEdit].param,
          privarDados: dataUsers[indexUserEdit].privarDados,
          token: dataUsers[indexUserEdit].token,
        };

        dataUsers[indexUserEdit] = dataUserEditAux;
      }

      onDataUsersEdit([...dataUsers]);
    },
    [
      dataUsers,
      onDataUsersEdit,
      responsePostFuncaoProps,
      responsePostSetorProps,
    ],
  );

  const handleSubmitEdit = useCallback(async () => {
    try {
      setLoading(true);
      formRef.current?.setErrors({});

      await api
        .put('/nivelAcesso/updateAcesso', {
          user_id: userEditProps.ID,
          NovoNivelAcesso: nivelAcesso,
          NovoSetor: setor,
          setor: userEdit.ID_SETOR,
        })
        .then(() => {
          setLoading(false);

          addToast({
            type: 'success',
            title: 'Dados atualizados!',
            description: 'Nível de acesso do usuário atualizado com sucesso.',
          });

          userEditProps &&
            setor &&
            nivelAcesso &&
            filterEditDataPainel(userEditProps.ID, setor, nivelAcesso);

          if (setor && userEdit.ID_SETOR === userEditProps.ID_SETOR) {
            const useAux: ResponseGet = userEdit;
            useAux.ID_SETOR = setor;
            setUserEdit(useAux);
          }
          setNivelAcesso(1);
          setSetor(1);
        })
        .catch(() => {
          setLoading(false);

          addToast({
            type: 'error',
            title: 'Erro!',
            description: 'Erro ao atualizar nível de acesso do usuário.',
          });
        });
    } catch (err) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro!',
        description: 'Erro ao salvar nível de acesso do usuário.',
      });
    }
  }, [
    userEditProps,
    nivelAcesso,
    setor,
    userEdit,
    addToast,
    filterEditDataPainel,
  ]);

  function setorAndNivelStringToNumber(valor: string, tipo: string): number {
    if (tipo === 'setor') {
      const data = responsePostSetorProps.filter(
        (dados) => valor === dados.NOME,
      );
      return data[0].ID;
    }
    const data = responsePostFuncaoProps.filter(
      (dados) => valor === dados.NOME,
    );
    return data[0].ID;
  }

  return (
    <Container ref={registerEditRef}>
      <ContainerAccess>
        <AnimationContainer>
          <Form ref={formRef} onSubmit={handleSubmitEdit}>
            <ContentSubBody>
              <div>
                <div>
                  <p>Nome</p>
                  <p>{userEditProps?.NOME}</p>
                </div>

                <DropDownAccessLevel
                  onChangeItems={(value: string) => {
                    setSetor(setorAndNivelStringToNumber(value, 'setor'));
                  }}
                  objetoEnum={responsePostSetorProps}
                  minWidth={150}
                  label="Setor"
                />
                <DropDownAccessLevel
                  onChangeItems={(value: string) => {
                    setNivelAcesso(setorAndNivelStringToNumber(value, 'NA'));
                  }}
                  objetoEnum={responsePostFuncaoProps}
                  minWidth={150}
                  label="Função"
                />
              </div>
              <div>
                <Button type="submit">Atualizar</Button>
                <Button type="button" onClick={() => onClose(true)}>
                  Cancelar edição
                </Button>
              </div>
            </ContentSubBody>
          </Form>
        </AnimationContainer>
      </ContainerAccess>

      {loading && <Loading />}
    </Container>
  );
};
