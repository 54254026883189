import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  position: absolute;
  flex: 1;
  justify-content: 'center';
  align-items: 'center';
  max-width: auto;
  z-index: 1;
  position: absolute;
`;

export const Content = styled.div`
  background-color: #000000;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  height: 100%;
  width: 100%;
  max-width: auto;
  margin: 0 auto;
  display: flex;
  position: fixed !important;
  align-items: center;
  justify-content: center;
  opacity: 0.6;
`;

export const ContentItens = styled.div`
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  height: 100%;
  width: 100%;
  max-width: auto;
  margin: 0 auto;
  display: flex;
  position: fixed !important;
  align-items: center;
  justify-content: center;
`;

const appearFromCentro = keyframes`
  from {
    opacity: 0;
    width:0px;
    /* height:0px; */
  }
  to{
    opacity: 1;
    width: 170px;
    /* height:170px; */
  }
`;

export const ContentItemCentro = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromCentro} 667ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftDown = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`;

export const ContentItemLeftDown = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromLeftDown} 667ms;
    animation-delay: 667ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightTop = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`;

export const ContentItemRightTop = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromRightTop} 667ms;
    animation-delay: 667ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftTop = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`;

export const ContentItemLeftTop = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromLeftTop} 667ms;
    animation-delay: 1333ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightDown = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`;

export const ContentItemRightDown = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromRightDown} 667ms;
    animation-delay: 1333ms;
    animation-fill-mode: backwards;
  }
`;

// ////////////////////////////////////////////////////////////

const appearFromCentro1 = keyframes`
  from {
    opacity: 0;
    width:0px;
    /* height:0px; */
  }
  to{
    opacity: 1;
    width: 170px;
    /* height:170px; */
  }
`;

export const ContentItemCentro1 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromCentro1} 667ms;
    animation-delay: 2.1s;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftDown1 = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`;

export const ContentItemLeftDown1 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromLeftDown1} 667ms;
    animation-delay: 2767ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightTop1 = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`;

export const ContentItemRightTop1 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromRightTop1} 667ms;
    animation-delay: 2767ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftTop1 = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`;

export const ContentItemLeftTop1 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromLeftTop1} 667ms;
    animation-delay: 3434ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightDown1 = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`;

export const ContentItemRightDown1 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromRightDown1} 667ms;
    animation-delay: 3434ms;
    animation-fill-mode: backwards;
  }
`;
// ////////////////////////////////////////////////////////////

const appearFromCentro2 = keyframes`
  from {
    opacity: 0;
    width:0px;
    /* height:0px; */
  }
  to{
    opacity: 1;
    width: 170px;
    /* height:170px; */
  }
`;

export const ContentItemCentro2 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromCentro2} 667ms;
    animation-delay: 4.3s;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftDown2 = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`;

export const ContentItemLeftDown2 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromLeftDown2} 667ms;
    animation-delay: 4967ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightTop2 = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`;

export const ContentItemRightTop2 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromRightTop2} 667ms;
    animation-delay: 4967ms;
    animation-fill-mode: backwards;
  }
`;

/** const appearFromLeftTop2 = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`; */

export const ContentItemLeftTop2 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromLeftTop1} 667ms;
    animation-delay: 5634ms;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightDown2 = keyframes`
  from {
    opacity: 0;
    width:0px;
    height:0px;
  }
  to{
    opacity: 1;
    width: 170px;
    height:170px;
  }
`;

export const ContentItemRightDown2 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromRightDown2} 667ms;
    animation-delay: 5634ms;
    animation-fill-mode: backwards;
  }
`;

// ////////////////////////////////////////////////////////////

const appearFromCentro3 = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const ContentItemCentro3 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromCentro3} 3s infinite;
    animation-delay: 6.3s;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftDown3 = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const ContentItemLeftDown3 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromLeftDown3} 3s infinite;
    animation-delay: 6.3s;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightTop3 = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const ContentItemRightTop3 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromRightTop3} 3s infinite;
    animation-delay: 6.3s;
    animation-fill-mode: backwards;
  }
`;

const appearFromLeftTop3 = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const ContentItemLeftTop3 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromLeftTop3} 3s infinite;
    animation-delay: 6.3s;
    animation-fill-mode: backwards;
  }
`;

const appearFromRightDown3 = keyframes`
  0% {
    opacity: 0;
  }
  50%{
    opacity: 1;
  }
  100%{
    opacity: 0;
  }
`;

export const ContentItemRightDown3 = styled.div`
  position: absolute !important;
  align-items: center;
  justify-content: center;
  animation-fill-mode: backwards;

  img {
    height: 170px;
    width: 170px;
    animation: ${appearFromRightDown3} 3s infinite;
    animation-delay: 6.3s;
    animation-fill-mode: backwards;
  }
`;

export const Text = styled.div`
  font-family: Arial, Helvetica, sans-serif;
  font-size: 20px;
  margin-top: 240px;

  animation: ${appearFromRightDown3} 3s infinite;
  animation-delay: 6.3s;
`;

// import styled, { keyframes } from 'styled-components';

// export const Container = styled.div`
//   z-index: 1;
//   position: absolute;
//   flex: 1;
//   justify-content: 'center';
//   align-items: 'center';
//   max-width: auto;
//   position: absolute;
// `;

// export const Content = styled.div`
//   background-color: #000000;
//   top: 0%;
//   left: 0%;
//   right: 0%;
//   bottom: 0%;
//   height: 100%;
//   width: 100%;
//   max-width: auto;
//   margin: 0 auto;
//   display: flex;
//   position: fixed !important;
//   align-items: center;
//   justify-content: center;
//   opacity: 0.6;
// `;

// export const ContentItens = styled.div`
//   z-index: 1;
//   position: absolute;
//   flex: 1;
//   justify-content: 'center';
//   align-items: 'center';
//   align-self: 'center';
//   justify-self: 'center';
//   top: 0%;
//   left: 0%;
//   right: 0%;
//   bottom: 0%;
//   height: 100%;
//   width: 100%;
//   max-width: auto;
//   margin: 0 auto;
//   display: flex;
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
// `;

// const appearFromCentro = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     /* height:0px; */
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     /* height:170px; */
//   }
// `;

// export const ContentItemCentro = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromCentro} 667ms;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromLeftDown = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `;

// export const ContentItemLeftDown = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromLeftDown} 667ms;
//     animation-delay: 667ms;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromRightTop = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `;

// export const ContentItemRightTop = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromRightTop} 667ms;
//     animation-delay: 667ms;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromLeftTop = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `;

// export const ContentItemLeftTop = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromLeftTop} 667ms;
//     animation-delay: 1333ms;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromRightDown = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `;

// export const ContentItemRightDown = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromRightDown} 667ms;
//     animation-delay: 1333ms;
//     animation-fill-mode: backwards;
//   }
// `;

// // ////////////////////////////////////////////////////////////

// const appearFromCentro1 = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     /* height:0px; */
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     /* height:170px; */
//   }
// `;

// export const ContentItemCentro1 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromCentro1} 667ms;
//     animation-delay: 2.1s;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromLeftDown1 = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `;

// export const ContentItemLeftDown1 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromLeftDown1} 667ms;
//     animation-delay: 2767ms;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromRightTop1 = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `;

// export const ContentItemRightTop1 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromRightTop1} 667ms;
//     animation-delay: 2767ms;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromLeftTop1 = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `;

// export const ContentItemLeftTop1 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromLeftTop1} 667ms;
//     animation-delay: 3434ms;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromRightDown1 = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `;

// export const ContentItemRightDown1 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromRightDown1} 667ms;
//     animation-delay: 3434ms;
//     animation-fill-mode: backwards;
//   }
// `;
// // ////////////////////////////////////////////////////////////

// const appearFromCentro2 = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     /* height:0px; */
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     /* height:170px; */
//   }
// `;

// export const ContentItemCentro2 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromCentro2} 667ms;
//     animation-delay: 4.3s;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromLeftDown2 = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `;

// export const ContentItemLeftDown2 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromLeftDown2} 667ms;
//     animation-delay: 4967ms;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromRightTop2 = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `;

// export const ContentItemRightTop2 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromRightTop2} 667ms;
//     animation-delay: 4967ms;
//     animation-fill-mode: backwards;
//   }
// `;

// /** const appearFromLeftTop2 = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `; */

// export const ContentItemLeftTop2 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromLeftTop1} 667ms;
//     animation-delay: 5634ms;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromRightDown2 = keyframes`
//   from {
//     opacity: 0;
//     width:0px;
//     height:0px;
//   }
//   to{
//     opacity: 1;
//     width: 170px;
//     height:170px;
//   }
// `;

// export const ContentItemRightDown2 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromRightDown2} 667ms;
//     animation-delay: 5634ms;
//     animation-fill-mode: backwards;
//   }
// `;

// // ////////////////////////////////////////////////////////////

// const appearFromCentro3 = keyframes`
//   0% {
//     opacity: 0;
//   }
//   50%{
//     opacity: 1;
//   }
//   100%{
//     opacity: 0;
//   }
// `;

// export const ContentItemCentro3 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromCentro3} 3s infinite;
//     animation-delay: 6.3s;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromLeftDown3 = keyframes`
//   0% {
//     opacity: 0;
//   }
//   50%{
//     opacity: 1;
//   }
//   100%{
//     opacity: 0;
//   }
// `;

// export const ContentItemLeftDown3 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromLeftDown3} 3s infinite;
//     animation-delay: 6.3s;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromRightTop3 = keyframes`
//   0% {
//     opacity: 0;
//   }
//   50%{
//     opacity: 1;
//   }
//   100%{
//     opacity: 0;
//   }
// `;

// export const ContentItemRightTop3 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromRightTop3} 3s infinite;
//     animation-delay: 6.3s;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromLeftTop3 = keyframes`
//   0% {
//     opacity: 0;
//   }
//   50%{
//     opacity: 1;
//   }
//   100%{
//     opacity: 0;
//   }
// `;

// export const ContentItemLeftTop3 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromLeftTop3} 3s infinite;
//     animation-delay: 6.3s;
//     animation-fill-mode: backwards;
//   }
// `;

// const appearFromRightDown3 = keyframes`
//   0% {
//     opacity: 0;
//   }
//   50%{
//     opacity: 1;
//   }
//   100%{
//     opacity: 0;
//   }
// `;

// export const ContentItemRightDown3 = styled.div`
//   position: absolute !important;
//   align-items: center;
//   justify-content: center;
//   animation-fill-mode: backwards;

//   img {
//     height: 170px;
//     width: 170px;
//     animation: ${appearFromRightDown3} 3s infinite;
//     animation-delay: 6.3s;
//     animation-fill-mode: backwards;
//   }
// `;

// export const Text = styled.div`
//   font-family: Arial, Helvetica, sans-serif;
//   font-size: 20px;
//   margin-top: 240px;

//   animation: ${appearFromRightDown3} 3s infinite;
//   animation-delay: 6.3s;
// `;
