import React from 'react';
import { useHistory } from 'react-router-dom';
import { GrUpdate } from 'react-icons/gr';
import { ImFilesEmpty } from 'react-icons/im';
import { AiFillCloseCircle } from 'react-icons/ai';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';

import {
  ContainerModal,
  Container,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
  ContainerLine,
  ButtonClose,
} from './styles';

interface ModalQrCode {
  dados: string[];
  onClose: () => void;
}

export const ModalResponseCsv: React.FC<ModalQrCode> = ({
  dados,
  onClose = () => {
    return '';
  },
}) => {
  const history = useHistory();

  // const handleOutSideClick = useCallback(
  //   (e) => {
  //     if (e.target.id === 'modal') {
  //       onClose();
  //     }
  //   },
  //   [onClose],
  // );

  return (
    <>
      <div>
        <ContainerModal>
          <Container>
            <ContainerHeaderDados>
              <h3>Registros inseridos:</h3>
              {dados.length ? (
                <table id="tablePreCadastroFuncionariosCsvLogs">
                  <tbody>
                    {dados.map((message) => (
                      <ContainerLine
                        key={message}
                        firstWord={message.split(' ')[0]}
                      >
                        <td>{message}</td>
                      </ContainerLine>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div>
                  <ImFilesEmpty size={65} color="#fff" />
                </div>
              )}
            </ContainerHeaderDados>
            <ContainerButtonAtualizar>
              <button type="button" id="btnPrint" onClick={() => history.go(0)}>
                <GrUpdate /> Atualizar
              </button>

              {!!dados.length && (
                <ReactHTMLTableToExcel
                  id="export-excel"
                  className="btn"
                  table="tablePreCadastroFuncionariosCsvLogs"
                  filename={`logs-preCadastro-funcionarios-${moment().format(
                    'DD-MM-YYYY',
                  )}`}
                  sheet="PreCadastroFuncionariosCsvLogs"
                  buttonText="Exportar Logs"
                />
              )}
            </ContainerButtonAtualizar>

            {!dados.length && (
              <ButtonClose onClick={() => onClose()}>
                <AiFillCloseCircle size={28} />
              </ButtonClose>
            )}
          </Container>
        </ContainerModal>
      </div>
    </>
  );
};
