/* eslint-disable no-nested-ternary */
import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { RiLockPasswordLine } from 'react-icons/ri';
// @ts-ignore
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import Swal from 'sweetalert2';
import { AiFillEdit, AiOutlineSave } from 'react-icons/ai';
import { ImBlocked } from 'react-icons/im';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import ModalConexao from '../../../components/ModalConexao';
import api from '../../../services/api';
import DropDownPagination from '../../../components/DropDownPagination';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import { Loading } from '../../../components/Loading';
import { useToast } from '../../../hooks/toast';
import { maskCpf, maskTel } from '../../../components/Input/mask';
import Input from '../../../components/Input';
import avatarImg from '../../../assets/IconAvatar.svg';
import { urlAccessBucketAvatar } from '../../../utils/listUrlAccessAws';
import { useAuth } from '../../../hooks/auth';

import {
  Container,
  ContainerSearch,
  ContainerCadastro,
  ContainerPagination,
  ContainerTable,
  ContainerSemResultados,
  SearchCustom,
  ButtonEditEmail,
  ButtonResetPassword,
} from './styles';

interface ResponseGet {
  AVATAR: string;
  Ativo: number;
  CPF: string;
  DataCadastro: string;
  DataNascimento: string;
  EMAIL: string;
  ID: number;
  NOME: string;
  Password: string;
  Telefone: string;
  param: string;
  privarDados: boolean;
  token: string;
}

interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

const pageAccess = {
  ID: 3,
  NOME: 'LISTAGEM-USUARIOS',
};

const headers = [
  { name: 'Avatar', field: 'AVATAR', sortable: false },
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'CPF', field: 'CPF', sortable: true },
  { name: 'Email', field: 'EMAIL', sortable: true },
  { name: 'Telefone', field: 'Telefone', sortable: true },
  { name: 'Nascimento', field: 'DataNascimento', sortable: true },
  { name: 'Cadastro', field: 'DataCadastro', sortable: true },
  { name: 'Privar dados', field: 'privarDados', sortable: true },
  { name: 'Status', field: 'Ativo', sortable: true },
];

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

const ListagemUsuarios: React.FC = () => {
  const { addToast } = useToast();
  const { modulos } = useAuth();
  const history = useHistory();
  const trRef = useRef<any>(null);
  const formRef = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<ResponseGet[]>([]);

  const [searchCpf, setSearchCpf] = useState('');
  const [searchNome, setSearchNome] = useState('');
  const [searchEmail, setSearchEmail] = useState('');
  const [searchCadastro, setSearchCadastro] = useState('');

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });

  const [trTableSelected, setTrTableSelected] = useState<ResponseGet>();
  const [editEmail, setEditEmail] = useState(false);
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });

  const CustomTooltipEditEmail = withStyles((theme) => ({
    arrow: {
      color: !editEmail ? '#ff9900' : '#ff3333',
    },
    tooltip: {
      backgroundColor: !editEmail ? '#ff9900' : '#ff3333',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);
  const CustomTooltipResetPassword = withStyles((theme) => ({
    arrow: {
      color: '#8080ff',
    },
    tooltip: {
      backgroundColor: '#8080ff',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  const getInitResponses = useCallback(() => {
    setLoading(true);
    api
      .get(`users/findAll`)
      .then((data) => {
        setResponse(data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        if (navigator.onLine) {
          addToast({
            type: 'error',
            title: 'Erro',
            description: 'Erro ao carregar dados, por favor atualize a página!',
          });
        }
      });
  }, [addToast]);

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);
    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title: 'Você não tem permissão para acessar essa página!',
      });
    } else {
      getInitResponses();
    }
  }, [getInitResponses, modulos, history]);

  const especialCharMask = useCallback((senha: string): string => {
    let novaSenha = senha;
    novaSenha = novaSenha.replace(/[áàãâä]/iu, 'a');
    novaSenha = novaSenha.replace(/[éèêë]/iu, 'e');
    novaSenha = novaSenha.replace(/[íìîï]/iu, 'i');
    novaSenha = novaSenha.replace(/[óòõôö]/iu, 'o');
    novaSenha = novaSenha.replace(/[úùûü]/iu, 'u');
    novaSenha = novaSenha.replace(/[ç]/iu, 'c');
    novaSenha = novaSenha.replace(/[^a-z0-9]/i, '_');
    novaSenha = novaSenha.replace(/_+/, '_'); //
    return novaSenha;
  }, []);

  const resetPassword = useCallback(
    (data: ResponseGet): string => {
      let reset = data.CPF.substring(8, 11);
      reset = reset.concat(especialCharMask(data.NOME.substring(0, 1)));
      reset = reset.concat(especialCharMask(data.NOME.substring(1, 2)));
      reset = reset.concat(especialCharMask(data.NOME.substring(2, 3)));
      return reset.toLowerCase();
    },
    [especialCharMask],
  );

  const handleRestorePassword = useCallback(
    async (data: ResponseGet) => {
      try {
        setLoading(true);
        const resetPass = resetPassword(data);
        await api
          .post('password/reset', {
            token: data.token,
            password: resetPass,
            password_confirmation: resetPass,
          })
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Senha resetada',
              description: 'A senha foi resetada com sucesso!',
            });
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro',
              description:
                'Ocorreu uma falha ao resetar a senha. Tente novamente',
            });
          });
      } catch (err) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Ocorreu uma falha ao resetar a senha. Tente novamente',
        });
      }
    },
    [addToast, resetPassword],
  );

  const handleEditEmail = useCallback(
    async ({ emailEdit }) => {
      try {
        setLoading(true);
        if (!emailEdit || !emailEdit.includes('@')) {
          setLoading(false);
          addToast({
            type: 'error',
            title: 'E-mail incorreto',
            description: 'Digite um e-mail válido!',
          });
        } else {
          await api
            .patch('password/emailUpdate', {
              user_id: trTableSelected?.ID,
              email: emailEdit.trim(),
            })
            .then(() => {
              setLoading(false);
              addToast({
                type: 'success',
                title: 'E-mail atualizado',
                description: 'E-mail atualizado com sucesso!',
              });

              setTimeout(() => {
                history.go(0);
              }, 1500);
            })
            .catch((e) => {
              setLoading(false);
              addToast({
                type: 'error',
                title: 'Erro',
                description: `${e.response.data.errors}`,
              });
            });
        }
      } catch (err) {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Falha ao atualizar email.',
        });
      }
    },
    [addToast, history, trTableSelected],
  );

  const handleConfirmResetPassword = useCallback(
    (data: ResponseGet) => {
      Swal.fire({
        title: `Tem certeza que deseja resetar a senha do(a) usuário(a) "${
          data.NOME ? data.NOME.trim() : 'SEM NOME'
        }"?`,
        text:
          'A nova senha será composta pelos 3 últimos números do CPF e as 3 primeiras letras do nome (minúsculas e sem acentos).',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleRestorePassword(data);
        }
      });
    },
    [handleRestorePassword],
  );

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response.length > 0 ? response : [];

    if (searchCpf) {
      let CPF = searchCpf.replace(/\./g, '');
      CPF = CPF.replace(/-/g, '');

      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.CPF && res.CPF.toString().includes(CPF.toString()),
      );
    }
    if (searchNome) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.NOME && res.NOME.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }
    if (searchEmail) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.EMAIL &&
          res.EMAIL.toLowerCase().includes(searchEmail.toLowerCase()),
      );
    }
    if (searchCadastro) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.DataCadastro &&
          moment(res.DataCadastro)
            .format('DD/MM/YYYY')
            .toLowerCase()
            .includes(searchCadastro.toLowerCase()),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (a[sorting.field] !== null && b[sorting.field] !== null) {
          if (typeof a[sorting.field] === 'object') {
            return (
              reversed *
              a[sorting.field]
                .join(', ')
                .localeCompare(b[sorting.field].join(', '))
            );
          }
          if (typeof a[sorting.field] === 'string') {
            return reversed * a[sorting.field].localeCompare(b[sorting.field]);
          }
          return (
            reversed *
            a[sorting.field]
              .toString()
              .localeCompare(b[sorting.field].toString())
          );
        }
        return a;
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    searchCpf,
    searchNome,
    searchEmail,
    searchCadastro,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchCpf, searchNome, searchEmail, searchCadastro]);

  return (
    <>
      <ModalConexao />
      <Container>
        <ContainerSearch>
          <SearchCustom
            onSearch={(value: string) => {
              setSearchNome(value);
            }}
            nomePlaceHolder="Buscar nome"
          />
          <SearchCustom
            onSearch={(value: string) => {
              setSearchCpf(value);
            }}
            nomePlaceHolder="Buscar CPF"
          />
          <SearchCustom
            onSearch={(value: string) => {
              setSearchEmail(value);
            }}
            nomePlaceHolder="Buscar e-mail"
          />
          <SearchCustom
            onSearch={(value: string) => {
              setSearchCadastro(value);
            }}
            typeProps="date"
            nomePlaceHolder="Buscar cadastro"
          />
        </ContainerSearch>

        <ContainerCadastro>
          {!responseData.length && (
            <ContainerSemResultados>
              <h2>Não existem usuários cadastrados ainda.</h2>
            </ContainerSemResultados>
          )}

          {!!responseData.length && (
            <div className="divTable">
              <ContainerTable>
                <table id="tableListagemUsuarios">
                  <HeaderTable
                    headers={headers}
                    onSorting={(field: string, order: string) =>
                      setSorting({ field, order })
                    }
                  />
                  <tbody>
                    {responseData.map((res) => (
                      <>
                        <tr
                          key={res.ID}
                          ref={
                            trTableSelected && trTableSelected.ID === res.ID
                              ? trRef
                              : null
                          }
                          onClick={() =>
                            trTableSelected === res
                              ? [
                                  setTrTableSelected(undefined),
                                  setEditEmail(false),
                                ]
                              : setTrTableSelected(res)
                          }
                          style={{
                            background:
                              trTableSelected && trTableSelected.ID === res.ID
                                ? '#737373'
                                : '',
                          }}
                        >
                          <td>
                            <img
                              src={
                                res.AVATAR
                                  ? `${urlAccessBucketAvatar}${res.AVATAR}`
                                  : `${avatarImg}`
                              }
                              alt="Avatar"
                            />
                          </td>
                          <td>{res.NOME ? res.NOME : '-'}</td>
                          <td>{res.CPF ? maskCpf(res.CPF) : '-'}</td>
                          <td>{res.EMAIL ? res.EMAIL : '-'}</td>
                          <td>{res.Telefone ? maskTel(res.Telefone) : '-'}</td>
                          <td>
                            {res.DataNascimento
                              ? moment(res.DataNascimento).format('DD/MM/YYYY')
                              : '-'}
                          </td>
                          <td>
                            {res.DataCadastro
                              ? moment(res.DataCadastro).format('DD/MM/YYYY')
                              : '-'}
                          </td>
                          <td>{res.privarDados ? 'Sim' : 'Não'}</td>
                          <td style={{ padding: 5 }}>
                            <div
                              style={{
                                background: `${
                                  !res.Ativo
                                    ? 'rgb(255, 51, 51)'
                                    : 'rgb(128, 128, 255)'
                                }`,
                                width: '100%',
                                height: '100%',
                                borderRadius: 4,
                              }}
                            >
                              {!!res.Ativo && 'Ativo'}
                              {!res.Ativo && 'Inativo'}
                            </div>
                          </td>
                        </tr>

                        {editEmail && res.ID === trTableSelected?.ID && (
                          <tr
                            className="trEditEmail"
                            style={{
                              cursor: 'default',
                              background: '#494554',
                            }}
                          >
                            <td />
                            <td />
                            <td />
                            <td>
                              <Form ref={formRef} onSubmit={handleEditEmail}>
                                <Input
                                  name="emailEdit"
                                  icon={AiFillEdit}
                                  widthProps="auto"
                                  placeholder="Novo e-mail"
                                />
                                <button type="submit">
                                  <AiOutlineSave size={22} />
                                </button>
                              </Form>
                            </td>
                            <td />
                            <td />
                            <td />
                            <td />
                            <td />
                          </tr>
                        )}
                      </>
                    ))}
                  </tbody>
                </table>

                <table
                  id="tableListagemUsuariosTable"
                  style={{ display: 'none' }}
                >
                  <HeaderTable
                    headers={headers}
                    onSorting={(field: string, order: string) =>
                      setSorting({ field, order })
                    }
                  />
                  <tbody>
                    {responseData.map((res) => (
                      <tr key={res.ID}>
                        <td>
                          <a
                            href={
                              res.AVATAR
                                ? `https://app-colaborador-avatar.s3.amazonaws.com/${res.AVATAR}`
                                : '-'
                            }
                          >
                            Foto
                          </a>
                        </td>
                        <td>{res.NOME ? res.NOME : '-'}</td>
                        <td>{res.CPF ? maskCpf(res.CPF) : '-'}</td>
                        <td>{res.EMAIL ? res.EMAIL : '-'}</td>
                        <td>{res.Telefone ? maskTel(res.Telefone) : '-'}</td>
                        <td>
                          {res.DataNascimento
                            ? moment(res.DataNascimento).format('DD/MM/YYYY')
                            : '-'}
                        </td>
                        <td>
                          {res.DataCadastro
                            ? moment(res.DataCadastro).format('DD/MM/YYYY')
                            : '-'}
                        </td>
                        <td>{res.privarDados ? 'Sim' : 'Não'}</td>
                        <td>
                          {res.Ativo && res.Ativo === 1 ? 'Ativo' : 'Inativo'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ContainerTable>

              <ContainerPagination>
                {responseData.length > 0 && (
                  <div id="divPaginacao">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: number) => setCurrentPage(page)}
                    />
                    <DropDownPagination
                      onChangeItems={(value: string) => {
                        setCurrentPage(1);
                        if (value === 'Todos') {
                          setITEMS_PER_PAGE(1);
                        } else {
                          setITEMS_PER_PAGE(Number(value));
                        }
                      }}
                      objetoEnum={dropDown}
                      minWidth={30}
                    />

                    <div id="divBtnTable">
                      {accessUser.ESCRITA && (
                        <>
                          <CustomTooltipEditEmail
                            title={
                              !trTableSelected
                                ? 'Selecione um usuário primeiro'
                                : !editEmail
                                ? 'Editar e-mail'
                                : 'Fechar edição'
                            }
                            arrow
                            TransitionComponent={Zoom}
                          >
                            <span>
                              <ButtonEditEmail
                                disabled={!trTableSelected}
                                style={{
                                  cursor: !trTableSelected ? 'not-allowed' : '',
                                  background: !trTableSelected
                                    ? '#ffb84d'
                                    : editEmail
                                    ? '#ff3333'
                                    : '',
                                  filter: !trTableSelected
                                    ? 'brightness(1)'
                                    : '',
                                }}
                                onClick={() => {
                                  trTableSelected && setEditEmail(!editEmail);
                                  trTableSelected &&
                                    trTableSelected &&
                                    trRef.current &&
                                    trRef.current.scrollIntoView({
                                      block: 'center',
                                      behavior: 'smooth',
                                    });
                                }}
                              >
                                {editEmail ? (
                                  <ImBlocked size={26} />
                                ) : (
                                  <AiFillEdit size={26} />
                                )}
                              </ButtonEditEmail>
                            </span>
                          </CustomTooltipEditEmail>
                          <CustomTooltipResetPassword
                            title={
                              !trTableSelected
                                ? 'Selecione um usuário primeiro'
                                : 'Resetar senha'
                            }
                            arrow
                            TransitionComponent={Zoom}
                          >
                            <span>
                              <ButtonResetPassword
                                disabled={!trTableSelected}
                                style={{
                                  cursor: !trTableSelected ? 'not-allowed' : '',
                                  background: !trTableSelected ? '#b3b3ff' : '',
                                  filter: !trTableSelected
                                    ? 'brightness(1)'
                                    : '',
                                }}
                                onClick={() =>
                                  trTableSelected &&
                                  handleConfirmResetPassword(trTableSelected)
                                }
                              >
                                <RiLockPasswordLine size={26} />
                              </ButtonResetPassword>
                            </span>
                          </CustomTooltipResetPassword>
                        </>
                      )}

                      <ReactHTMLTableToExcel
                        id="export-excel"
                        className="btn"
                        table="tableListagemUsuariosTable"
                        filename={`listagem-usuarios-${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        sheet="tablexls"
                        buttonText="Exportar Excel"
                      />
                    </div>
                  </div>
                )}
              </ContainerPagination>
            </div>
          )}
        </ContainerCadastro>
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default ListagemUsuarios;
