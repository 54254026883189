import React, { createContext, useCallback, useState, useContext } from 'react';
import api from '../services/api';

interface UserAcesso {
  ID: number;
  Nome: string;
  CPF: string;
  EMAIL: string;
  SetorID: number;
  Setor: string;
  NivelACesso: number;
  idUsuario: number;
  Nome_NivelAcesso: string;
}

export interface NivelAcessoModulos {
  ID_MODULO: number;
  NOME_MODULO: string;
  LEITURA: boolean;
  ESCRITA: boolean;
}

interface User {
  ativo: number;
  avatar_url: string;
  cpf: string;
  dataNascimento: string;
  email: string;
  id: string;
  nome: string;
  telefone: string;
}

interface AuthState {
  token: string;
  user: User;
  userAcesso: UserAcesso[];
  modulos: NivelAcessoModulos[];
}

interface SignInCredentials {
  email: string;
  password: string;
}

interface AuthContextData {
  user: User;
  userAcesso: UserAcesso[];
  modulos: NivelAcessoModulos[];
  signIn(credentials: SignInCredentials): Promise<boolean>;
  signOut(): void;
  updateUser(user: User, modulos: NivelAcessoModulos[]): void;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@Colaborador:token');
    const user = localStorage.getItem('@Colaborador:user');
    const userAcesso = localStorage.getItem('@Colaborador:userAcesso');
    const modulos = localStorage.getItem('@Colaborador:modulos');

    if (token && user && userAcesso && modulos) {
      api.defaults.headers.authorization = `Bearer ${token}`;
      return {
        token,
        user: JSON.parse(user),
        userAcesso: JSON.parse(userAcesso),
        modulos: JSON.parse(modulos),
      };
    }

    return {} as AuthState;
  });

  const signOut = useCallback(() => {
    localStorage.removeItem('@Colaborador:token');
    localStorage.removeItem('@Colaborador:user');
    localStorage.removeItem('@Colaborador:userAcesso');
    localStorage.removeItem('@Colaborador:modulos');
    localStorage.removeItem('@Colaborador:modulosAcesso');

    setData({} as AuthState);
  }, []);

  const signIn = useCallback(
    async ({ email, password }): Promise<boolean> => {
      const response = await api.post('sessions', {
        email,
        password,
      });

      const { token, user, userAcesso, modulos } = response.data;
      localStorage.setItem('@Colaborador:token', token);
      localStorage.setItem('@Colaborador:user', JSON.stringify(user));
      localStorage.setItem(
        '@Colaborador:userAcesso',
        JSON.stringify(userAcesso),
      );
      localStorage.setItem('@Colaborador:modulos', JSON.stringify(modulos));

      api.defaults.headers.authorization = `Bearer ${token}`;

      // teste de nivel de acesso
      if (
        response.data.userAcesso[0].SetorID === 1 &&
        response.data.userAcesso[0].NivelACesso === 1
      ) {
        signOut();
        return false;
      }

      setData({ token, user, userAcesso, modulos });

      return true;
    },
    [signOut],
  );

  const updateUser = useCallback(
    async (user: User, modulos: NivelAcessoModulos[]) => {
      localStorage.setItem('@Colaborador:user', JSON.stringify(user));
      localStorage.setItem('@Colaborador:modulos', JSON.stringify(modulos));
      setData({
        token: data.token,
        user,
        userAcesso: data.userAcesso,
        modulos,
      } as AuthState);
    },
    [data.token, data.userAcesso],
  );

  return (
    <AuthContext.Provider
      value={{
        user: data.user,
        userAcesso: data.userAcesso,
        modulos: data.modulos,
        signIn,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
