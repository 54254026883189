import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { AiFillCloseCircle } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { BsBuilding, BsInfoSquare } from 'react-icons/bs';
import Input from '../Input';
import { maskCnpj } from '../Input/mask';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  SignUpFormData,
  ResponseGet,
  ResponseGetFilial,
} from '../../pages/Cadastros/PreCadastroFuncionario';
import InputDate from '../DatePicker';
import { Loading } from '../Loading';
import { SelectCustom } from '../SelectCustom';
import {
  findFilialCnpj,
  findFilialFantasyName,
  findFilialName,
} from '../../interfaces/functionsFilterFind';

import {
  ContainerModal,
  Container,
  ButtonClose,
  Content,
  Title,
  ButtonSubmit,
  ContainerForm,
} from './styles';

interface ModalProps {
  data: ResponseGet;
  filiais: ResponseGetFilial[];
  onClose(e: boolean): void;
}

export const EditRegistrationColabModal: React.FC<ModalProps> = ({
  data,
  filiais,
  onClose = (e: boolean): boolean => {
    return e;
  },
}: ModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const { addToast } = useToast();
  const [editNome, setEditNome] = useState(data?.NOME);
  const [editMatricula, setEditMatricula] = useState(data?.MATRICULA);
  const [editFilial, setEditFilial] = useState(data?.FILIAL);
  const [editCnpj, setEditCnpj] = useState(data?.CNPJ);
  const [editNomeEmpresa, setEditNomeEmpresa] = useState(data?.NOME_EMPRESA);
  const [editNomeFantasiaEmpresa, setEditNomeFantasiaEmpresa] = useState(
    data?.NOME_FANTASIA_EMPRESA,
  );
  const [editDataAdmissao, setEditDataAdmissao] = useState(data?.DATA_ADMISSAO);
  const [editDataNascimento, setEditDataNascimento] = useState(
    data?.DATA_NASCIMENTO,
  );
  const [editFuncao, setEditFuncao] = useState(data?.FUNCAO);
  const [editSituacao, setEditSituacao] = useState(data?.SITUACAO);
  const [editDataAdmissaoValid, setEditDataAdmissaoValid] = useState(false);
  const [editDataNascimentoValid, setEditDataNascimentoValid] = useState(false);
  const [filiaisTemp, setFiliaisTemp] = useState<ResponseGetFilial[]>([]);

  useEffect(() => {
    const findDataFilial = filiais.find(
      (filial) => filial.CODIGO_FILIAL === editFilial,
    );

    findDataFilial === undefined
      ? setFiliaisTemp([
          ...filiais,
          {
            ATIVO: false,
            BAIRRO: 'INATIVO',
            CEP: 'INATIVO',
            CIDADE: 'INATIVO',
            CODIGO_FILIAL: editFilial,
            ENDERECO: 'INATIVO',
            ESTADO: 'INATIVO',
            ID: -1,
            NUMERO: 'INATIVO',
            NOME_EMPRESA: 'INATIVO',
            NOME_FANTASIA_EMPRESA: 'INATIVO',
            CNPJ: 'INATIVO',
          },
        ])
      : setFiliaisTemp([...filiais]);
  }, [editFilial, filiais]);

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose(true);
      }
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    async (dataTemp: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});
        let validEditDateAdmissao = true;
        let validEditDateNascimento = true;

        if (editDataAdmissao === '' || editDataAdmissao === undefined) {
          setEditDataAdmissaoValid(true);
        } else {
          setEditDataAdmissaoValid(false);
          validEditDateAdmissao = false;
        }

        if (editDataNascimento === '' || editDataNascimento === undefined) {
          setEditDataNascimentoValid(true);
        } else {
          setEditDataNascimentoValid(false);
          validEditDateNascimento = false;
        }

        const schema = Yup.object().shape({
          situacao: Yup.string().required('Situação é obrigatória!'),
          filial: Yup.string().required('Filial é obrigatória!'),
          nome: Yup.string().required('Nome é obrigatório!'),
          matricula: Yup.string().required('Matrícula é obrigatória!'),
          nomeEmpresa: Yup.string().required('Nome da empresa é obrigatório!'),
          funcao: Yup.string().required('Função é obrigatória!'),
        });

        await schema.validate(dataTemp, {
          abortEarly: false,
        });

        if (!validEditDateAdmissao && !validEditDateNascimento) {
          setShowLoading(true);
          api
            .put(
              `/cadastroIndividual/updatePreCadastroIndividual?cpf=${data.CPF}`,
              {
                situacao: editSituacao,
                data_admissao: editDataAdmissao,
                data_nascimento: editDataNascimento,
                filial: editFilial,
                nome: editNome,
                matricula: editMatricula,
                nome_empresa: findFilialName(filiaisTemp, editFilial),
                cnpj: findFilialCnpj(filiaisTemp, editFilial),
                nome_fantasia_empresa: findFilialFantasyName(
                  filiaisTemp,
                  editFilial,
                ),
                funcao: editFuncao,
              },
            )
            .then(() => {
              setShowLoading(false);
              addToast({
                type: 'success',
                title: 'Funcionário editado!',
                description:
                  'O funcionário foi editado com sucesso e já pode ser visualizado na listagem.',
              });

              setTimeout(() => {
                history.go(0);
              }, 1500);
            })
            .catch(() => {
              setShowLoading(false);
              addToast({
                type: 'error',
                title: 'Erro na edição',
                description:
                  'Ocorreu um erro ao editar o funcionário, tente novamente.',
              });
            });
        } else {
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description: 'Existem campos obrigatórios em branco.',
          });
        }
      } catch (err) {
        setShowLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description:
              'Existem campos obrigatórios não preenchidos, favor verificar.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description:
              'Ocorreu um erro ao editar o funcionário, tente novamente.',
          });
        }
      }
    },
    [
      addToast,
      data.CPF,
      editDataAdmissao,
      editDataNascimento,
      editFilial,
      editFuncao,
      editMatricula,
      editNome,
      editSituacao,
      filiaisTemp,
      history,
    ],
  );

  return (
    <ContainerModal id="modal" onClick={handleOutSideClick}>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Title>Editar funcionário</Title>
          <ContainerForm>
            <Content>
              <SelectCustom
                name="situacao"
                widthProps="49%"
                icon={BsInfoSquare}
                defaultValue="Situação"
                value={editSituacao}
                onValue={(value) => setEditSituacao(value)}
                optionsData={['ATIVO', 'AFASTADO', 'FÉRIAS', 'DEMITIDO']}
              />
              <SelectCustom
                name="filial"
                widthProps="49%"
                icon={BsBuilding}
                defaultValue="Filial"
                value={editFilial}
                onValue={(value) => setEditFilial(value)}
                optionsData={filiaisTemp.map((filial) => {
                  return filial.CODIGO_FILIAL;
                })}
              />
            </Content>
            <Content>
              <Input
                name="nomeEmpresa"
                icon={BsBuilding}
                placeholder="Nome da empresa"
                widthProps="49%"
                placeholderProps="Nome da empresa"
                editProps
                tooltip={false}
                value={findFilialName(filiaisTemp, editFilial)}
                disabled
              />
              <Input
                name="nome"
                icon={FiUser}
                placeholder="Nome"
                widthProps="49%"
                placeholderProps="Nome"
                editProps
                tooltip={false}
                value={editNome}
                onValue={(value) => setEditNome(value)}
              />
            </Content>

            <Content>
              <Input
                name="funcao"
                icon={FiUser}
                placeholder="Função"
                widthProps="49%"
                placeholderProps="Função"
                editProps
                tooltip={false}
                value={editFuncao}
                onValue={(value) => setEditFuncao(value)}
              />
              <Input
                name="matricula"
                icon={FiUser}
                mask="registration"
                placeholder="Matrícula"
                widthProps="49%"
                placeholderProps="Matrícula"
                editProps
                tooltip={false}
                value={editMatricula}
                onValue={(value) => setEditMatricula(value)}
              />
            </Content>
            <Content>
              <InputDate
                nameProps="dataAdmissao"
                placeholderProps="Data de admissão"
                widthProps="49%"
                editProps
                validProps={editDataAdmissaoValid}
                defaultValueProps={editDataAdmissao}
                dateFormatReturn={(e: string) => setEditDataAdmissao(e)}
              />
              <InputDate
                nameProps="dataNascimento"
                placeholderProps="Data de nascimento"
                widthProps="49%"
                editProps
                validProps={editDataNascimentoValid}
                defaultValueProps={editDataNascimento}
                dateFormatReturn={(e: string) => setEditDataNascimento(e)}
              />
            </Content>
          </ContainerForm>
          <ButtonSubmit type="submit">Salvar</ButtonSubmit>
        </Form>
        <ButtonClose onClick={() => onClose(true)}>
          <AiFillCloseCircle size={28} />
        </ButtonClose>
      </Container>

      {showLoading && <Loading />}
    </ContainerModal>
  );
};
