import {
  ResponseGetFilial,
  ResponseGet,
} from '../pages/Cadastros/PreCadastroFuncionario';

export const findFilialName = (
  dataFiliais: ResponseGetFilial[],
  codFilial: string,
): string => {
  let nameFilial = '';

  dataFiliais.forEach((filial) => {
    if (filial.CODIGO_FILIAL === codFilial) nameFilial = filial.NOME_EMPRESA;
  });

  return nameFilial;
};

export const findFilialFantasyName = (
  dataFiliais: ResponseGetFilial[],
  codFilial: string,
): string => {
  let fantasyNameFilial = '';

  dataFiliais.forEach((filial) => {
    if (filial.CODIGO_FILIAL === codFilial)
      fantasyNameFilial = filial.NOME_FANTASIA_EMPRESA;
  });

  return fantasyNameFilial;
};

export const findFilialCnpj = (
  dataFiliais: ResponseGetFilial[],
  codFilial: string,
): string => {
  let cnpjFilial = '';

  dataFiliais.forEach((filial) => {
    if (filial.CODIGO_FILIAL === codFilial) cnpjFilial = filial.CNPJ;
  });

  return cnpjFilial;
};

export const findFuncName = (
  dataFuncionarios: ResponseGet[],
  cpfFuncionario: string,
): string => {
  let CPF = cpfFuncionario.replace(/\./g, '');
  CPF = CPF.replace(/-/g, '');

  const findFuncionario = dataFuncionarios.find(
    (funcionario) => funcionario.CPF === CPF,
  );

  return findFuncionario ? findFuncionario.NOME : '';
};

export const findFuncMatricula = (
  dataFuncionarios: ResponseGet[],
  cpfFuncionario: string,
): string => {
  let CPF = cpfFuncionario.replace(/\./g, '');
  CPF = CPF.replace(/-/g, '');

  const findFuncionario = dataFuncionarios.find(
    (funcionario) => funcionario.CPF === CPF,
  );

  return findFuncionario ? findFuncionario.MATRICULA : '';
};

export const findFuncFuncao = (
  dataFuncionarios: ResponseGet[],
  cpfFuncionario: string,
): string => {
  let CPF = cpfFuncionario.replace(/\./g, '');
  CPF = CPF.replace(/-/g, '');

  const findFuncionario = dataFuncionarios.find(
    (funcionario) => funcionario.CPF === CPF,
  );

  return findFuncionario ? findFuncionario.FUNCAO : '';
};

export const findFuncDataAdmissao = (
  dataFuncionarios: ResponseGet[],
  cpfFuncionario: string,
): string => {
  let CPF = cpfFuncionario.replace(/\./g, '');
  CPF = CPF.replace(/-/g, '');

  const findFuncionario = dataFuncionarios.find(
    (funcionario) => funcionario.CPF === CPF,
  );

  return findFuncionario ? findFuncionario.DATA_ADMISSAO : '';
};

export const findFuncSituacao = (
  dataFuncionarios: ResponseGet[],
  cpfFuncionario: string,
): string => {
  let CPF = cpfFuncionario.replace(/\./g, '');
  CPF = CPF.replace(/-/g, '');

  const findFuncionario = dataFuncionarios.find(
    (funcionario) => funcionario.CPF === CPF,
  );

  return findFuncionario ? findFuncionario.SITUACAO : '';
};
