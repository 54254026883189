import styled, { css } from 'styled-components';
import { shade } from 'polished';

interface DropContainerProps {
  isDragActive?: boolean;
  isDragReject?: boolean;
}

interface UploadMessageProps {
  paddingProps: string;
  type?: string;
}

const dragActive = css`
  border-color: #78e5d5;
`;

const dragReject = css`
  border-color: #e57878;
`;

export const DropContainer = styled.div<DropContainerProps>`
  color: #000;
  border: 2px dashed #ddd;
  border-radius: 10px;
  cursor: pointer;
  background: ${shade(0.2, '#4a4554')};
  transition: 0.2s ease;

  &:hover {
    background: ${shade(0.3, '#4a4554')};
  }

  ${(props) => props.isDragActive && dragActive};
  ${(props) => props.isDragReject && dragReject};
`;

const messageColors: any = {
  default: '#ddd',
  error: '#e57878',
  success: '#78e5d5',
};

export const UploadMessage = styled.p<UploadMessageProps>`
  display: flex;
  color: ${(props) => messageColors[props.type || 'default']};
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.paddingProps} 0;
  font-size: 18px;
  margin: 0;
`;
