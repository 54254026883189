import styled from 'styled-components';
import Search from '../../../../components/Search';

export const SearchCustom = styled(Search)`
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  margin-right: 10px !important;
`;

export const ContainerSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  background: #3e3946;
  padding: 20px;
  border-radius: 0.5rem;

  div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
    padding: 0px !important;
  }
  div {
    border-top-left-radius: 7px !important;
    border-bottom-left-radius: 7px !important;

    input {
      width: 210px !important;
      margin-right: 0 !important;
    }
  }
`;
