import React, { useState, useCallback } from 'react';
import {
  RiCheckboxMultipleLine,
  RiCheckboxMultipleBlankLine,
} from 'react-icons/ri';
import { AiOutlineArrowDown, AiOutlineArrowUp } from 'react-icons/ai';
import { ContainerTh } from './styles';

interface HeaderInterface {
  name: string;
  field: string;
  sortable?: boolean;
  id?: number;
}

interface DadosInterface {
  headers: HeaderInterface[];
  onSorting?(field: string, order: string): void;
  checkboxList?(data: boolean): void;
}

const HeaderTable: React.FC<DadosInterface> = ({
  headers,
  onSorting = (field: string, order: string): object => {
    return { field, order };
  },
  checkboxList = (data: boolean): boolean => {
    return data;
  },
}) => {
  const [sortingField, setSortingField] = useState('');
  const [sortingOrder, setSortingOrder] = useState('asc');

  const onSortingChange = useCallback(
    (field: string) => {
      const order =
        field === sortingField && sortingOrder === 'asc' ? 'desc' : 'asc';

      setSortingField(field);
      setSortingOrder(order);
      onSorting(field, order);
    },
    [onSorting, sortingField, sortingOrder],
  );

  return (
    <thead>
      <tr>
        {headers.map((head: HeaderInterface, index: number, sortable) => (
          <ContainerTh
            key={head.field + index.toString()}
            onClick={() =>
              sortable[index].sortable ? onSortingChange(head.field) : null
            }
          >
            {head.name}
            {head.name === '' && head.field === 'checkbox' && (
              <>
                <div className="divBtn">
                  <button
                    type="button"
                    className="btnBusca"
                    onClick={() => checkboxList(true)}
                  >
                    <RiCheckboxMultipleLine size={22} />
                  </button>
                  <button
                    type="button"
                    className="btnBusca2"
                    onClick={() => checkboxList(false)}
                  >
                    <RiCheckboxMultipleBlankLine size={22} />
                  </button>
                </div>
              </>
            )}

            {sortingField && sortingField === head.field && (
              <button type="button">
                {sortingOrder === 'asc' ? (
                  <AiOutlineArrowDown size={20} />
                ) : (
                  <AiOutlineArrowUp size={20} />
                )}
              </button>
            )}
          </ContainerTh>
        ))}
      </tr>
    </thead>
  );
};

export default HeaderTable;
