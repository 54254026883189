import styled from 'styled-components';

interface ContainerProps {
  texto?: boolean;
  cor?: boolean;
  corTexto?: string;
}

export const Container = styled.div`
  align-items: center;
  justify-content: center;
  padding: 20px;
  overflow-x: auto;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: auto;
`;

export const ContainerSearchInf = styled.div`
  display: flex;
`;

export const Aba = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px;
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    background-color: ${(props) => (props.cor ? ' #f1f1f1' : '#a6a6a6')};
    background-color: ${(props) => props.corTexto};
    color: #111111;
    border-width: 0;
    border: 1px solid #a6a6a6;
    padding: 10px;
    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const ContainerTable = styled.div`
  align-items: center;
  max-height: 50vh;
  overflow-x: auto;
  table {
    width: 100%;
    padding: 10px, 40px;
    margin: 0px auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 30px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      white-space: nowrap;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      white-space: nowrap;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerTableMeses = styled.div`
  align-items: center;
  max-height: 45vh;
  margin: 10px 20px 0 20px;
  overflow: auto;
  table {
    width: 100%;
    margin: 0px auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      padding: 5px 0px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      white-space: nowrap;
      & + th {
        padding-left: 10px;
      }
    }
  }
  tbody {
    td {
      margin: 10px;
      padding: 10px;
      text-align: center;
      font-size: 16px;
      white-space: nowrap;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 40px;
        }
      }
      a {
        text-decoration: none;
        size: 25px;
        color: #ffffff;
      }
      textarea {
        border-radius: 5px;
        padding: 10px 10px;
        font-size: 16px;
        font-family: Arial;
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerTableEstatica = styled.div`
  align-items: center;
  max-height: 60vh;
  margin: 0 20px;
  overflow-x: auto;
  table {
    width: 100%;
    padding: 10px, 10px;
    margin: 0px auto;
    margin-top: 1px;
    border-collapse: collapse;
    border: 1px solid #006600;
    th {
      border-bottom: 1px solid #006600;
      padding: 5px 15px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: left;
      font-size: 14px;
    }
  }
`;

export const ContainerSemDados = styled.div`
  padding: 20px;
  margin: 0 20px;
  background-color: #3e3946;
  overflow-x: auto;

  h3 {
    text-align: center;
    margin: 0;
  }
`;

export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #439133;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 15px 0 15px 0;
  height: auto;

  #divPaginacao {
    display: flex;
    justify-content: center;
    padding: 0 15px;
    width: 100%;
  }
`;
