import React, { useCallback, useRef, useState } from 'react';
import {
  FiArrowLeft,
  FiMail,
  FiUser,
  FiLock,
  FiFileMinus,
  FiCalendar,
  FiPhone,
} from 'react-icons/fi';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { Link, useHistory } from 'react-router-dom';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import getValidationErrors from '../../../utils/getValidationErrors';
import logoImg from '../../../assets/LogoGreenDot.png';
import Input from '../../../components/Input';
import Button from '../../../components/Button';
import ModalConexao from '../../../components/ModalConexao';
import AlertEmail from '../../../components/AlertEmail';

import { Container, Content, AnimationContainer } from './styles';

interface SignUpFormData {
  nome: string;
  email: string;
  cpf: string;
  telefone: string;
  password: string;
  dataNascimento: string;
}

const SignUp: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { addToast } = useToast();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome obrigatório !'),
          email: Yup.string()
            .required('E-mail obrigatório !')
            .email('Digite um email válido !'),
          cpf: Yup.string().required('CPF obrigatório !'),
          dataNascimento: Yup.string().required(
            'Data de Nascimento obrigatória !',
          ),
          telefone: Yup.string().required('Telefone obrigatório !'),
          password: Yup.string().min(6, 'No mínimo 6 dígitos !'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        const { nome } = data;
        const { cpf } = data;
        const { telefone } = data;
        const { dataNascimento } = data;
        const { password } = data;

        await api
          .post('/users', {
            nome: nome.trim(),
            email: data.email.trim(),
            password: password.trim(),
            cpf,
            telefone,
            dataNascimento,
          })
          .then(() => {
            setTimeout(function (): void {// eslint-disable-line
              setLoading(false);
            }, 2000);

            addToast({
              type: 'success',
              title: 'Cadastro realizado!',
              description: 'Você já pode fazer seu login.',
            });
            // console.log('Sucess!');
            history.push('/');
          })
          .catch(() => {
            setTimeout(function (): void {// eslint-disable-line
              setLoading(false);
            }, 2000);

            addToast({
              type: 'error',
              title: 'Erro ao cadastrar!',
              description: '.',
            });
          });
      } catch (err) {
        // console.log(err);
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }

        addToast({
          type: 'error',
          title: 'Erro no Cadastro',
          description:
            'Ocorreu um erro ao fazer o Cadastro, Por favor verifique os campos.',
        });
      } finally {
        // setLoading(false);
      }
    },
    [addToast, history],
  );

  return (
    <>
      <ModalConexao />
      <Container>
        <Content>
          <AnimationContainer>
            <img src={logoImg} alt="Logo Servnac" />

            <Form ref={formRef} onSubmit={handleSubmit}>
              <h1>Cadastro</h1>

              <Input name="nome" icon={FiUser} placeholder="Nome Completo" />
              <Input
                name="email"
                icon={FiMail}
                placeholder="E-mail"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <AlertEmail email={email} />
              <Input
                name="cpf"
                icon={FiFileMinus}
                placeholder="CPF"
                mask="cpf"
              />
              <Input
                name="dataNascimento"
                icon={FiCalendar}
                placeholder="Data de Nascimento"
                mask="dateOfBirth"
              />
              <Input
                name="telefone"
                icon={FiPhone}
                placeholder="Telefone"
                mask="phone"
              />
              <Input
                name="password"
                icon={FiLock}
                tipo="password"
                placeholder="Senha"
              />

              <Button type="submit">Cadastrar</Button>
            </Form>

            <Link to="/">
              <FiArrowLeft />
              Voltar para Login
            </Link>
          </AnimationContainer>
        </Content>
        {loading && <Loading />}
      </Container>
    </>
  );
};

export default SignUp;
