// URLS DE ACESSO ÚNICAS POR CLIENTE:

export const urlAccessBucketAvatar =
  'https://app-colaborador-avatar.s3.amazonaws.com/';
export const urlAccessBucketPreCadastroFuncionario =
  'https://app-colaborador-aquivo-importacao-modelo.s3.amazonaws.com/importacao-precadastro-funcionario.csv';
export const urlAccessBucketContracheque =
  'https://app-colaborador-aquivo-importacao-modelo.s3.amazonaws.com/importacao-contra-cheque.csv';
export const urlAccessBucketFerias =
  'https://app-colaborador-aquivo-importacao-modelo.s3.amazonaws.com/importacao-ferias.csv';
export const urlAccessBucketBeneficios =
  'https://app-colaborador-aquivo-importacao-modelo.s3.amazonaws.com/importacao-beneficios.csv';
export const urlAccessBucketContrachequeDecimoTerceiro =
  'https://app-colaborador-aquivo-importacao-modelo.s3.amazonaws.com/importacao-contra-cheque-decimo.csv';
export const urlAccessBucketEscala =
  'https://app-colaborador-aquivo-importacao-modelo.s3.amazonaws.com/importacao-escala.csv';
