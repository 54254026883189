import styled from 'styled-components';
import Search from '../../../../components/Search';

export const Container = styled.div``;

export const SearchCustom = styled(Search)`
  width: 200px !important;
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  margin-right: 10px !important;
`;

export const ContainerSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-top: 15px;
  background: #3e3946;
  padding: 20px;
  border-radius: 0.5rem;

  div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
    padding: 0px !important;
  }

  div {
    border-top-left-radius: 7px !important;
    border-bottom-left-radius: 7px !important;
  }
`;

export const ContainerTable = styled.div`
  width: auto;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 42vh;
  overflow-x: auto;
  border-radius: 0.25rem 0.25rem 0 0;
  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 10px 10px;
      background-color: #006600;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      z-index: 1;
      top: 0;
      white-space: nowrap;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      white-space: nowrap;

      label.ant-checkbox-wrapper {
        position: relative !important;
        z-index: 0 !important;
      }

      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      cursor: pointer;
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerAgendamento = styled.div`
  margin-top: 40px;
  background: #3e3946;
  padding: 20px;
  border-radius: 0.5rem;

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    section {
      width: 100%;
      margin-bottom: 10px;
      display: flex;

      div {
        & + div {
          margin-left: 10px;
        }
      }
    }
  }
`;
