import styled from 'styled-components';

interface ContainerProps {
  texto?: boolean;
  cor?: boolean;
  corTexto?: string;
}

export const Container = styled.div`
  height: auto;
  flex: 1;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  margin: 0px auto;
  margin-top: 10px;
`;

export const ContainerSearch = styled.div`
  display: flex;
  margin: 0px auto;
  justify-content: space-evenly;
  input {
    width: 200px;
    border-top-right-radius: 5px;
  }
  div {
    border-top-left-radius: 5px;
  }
`;

export const ContainerSearchTodos = styled.div`
  width: 100%;
  display: flex;

  input {
    width: 150px;
    border-radius: 0 5px 5px 0;
    padding: 0px 5px;
  }

  > div {
    margin-left: -20px;
  }

  div {
    border-radius: 5px 0 0 5px;
  }
`;

export const ContainerSearchInf = styled.div`
  max-width: 90%;
  min-width: 90%;
  display: flex;
  margin: 0px auto;
  justify-content: space-evenly;
  margin-top: 2px;
  input {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 5px;
    margin-right: 0px;
    width: 200px;
  }
  div {
    border-bottom-left-radius: 5px;
    border-top-left-radius: 0px;
  }
`;

export const ContainerFilter = styled.div`
  padding: 20px;
  background-color: #3e3946;
  overflow-x: auto;
  h3 {
    text-align: center;
  }
`;

export const ContainerSupFilter = styled.div`
  display: flex;
  width: 100%;
  margin: 0px auto;
  justify-content: center;
  p {
    font-size: 20px;
    font-family: Arial;
    margin: 10px;
  }
  select {
    background-color: #f1f1f1;
    color: #439133;
    border-radius: 10px;
    margin: 0px;
    border: none;
    justify-content: center;
    width: 220px;
    padding: 5px 10px;
  }
  input {
    background-color: #f1f1f1;
    color: #439133;
    border-radius: 10px;
    border: none;
    padding: 10px;
    width: 110px;
    height: 40px;
    text-align: center;
    width: 120px;
    margin-left: 10px;
  }
`;

export const ContainerInfFilter = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  p {
    font-size: 20px;
    font-family: Arial;
    margin: 10px;
  }
  input {
    height: 20px;
    width: 20px;
  }
  label {
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    width: 100px;
    padding: 10px 5px;
  }
  button {
    height: 40px;
    padding: 10px;
    margin-top: 10px;
    margin-right: 5px;
    background-color: #439133;
    color: #ffffff;
    border-radius: 5px;
    border: none;
  }
`;

export const ContainerFiltro = styled.div`
  padding: 20px;
  margin: 10px 0;
  background-color: #3e3946;
  border-radius: 0.25rem;
`;

export const Aba = styled.div`
  width: 100%;
  display: flex;
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;
  button {
    height: 40px;
    background-color: ${(props) => (props.cor ? ' #f1f1f1' : '#a6a6a6')};
    background-color: ${(props) => props.corTexto};
    color: #111111;
    border-width: 0px;
    min-width: 80px;
    padding: 0 10px;
    border: 1px solid #a6a6a6;
    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const ContainerTable = styled.div`
  align-items: center;
  max-height: 60vh;
  overflow-x: auto;
  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 15px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      white-space: nowrap;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      white-space: nowrap;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerPagination = styled.div<ContainerProps>`
  display: flex;
  padding: 20px;
  justify-content: start;
  align-items: center;
  button {
    background-color: #006600;
    margin-left: 20px;
    border: none;
    height: 40px;
    width: 100px;
    padding: 0px 20px;
    border-radius: 5px;
    color: #ffffff;
    line-height: 20px;
  }
  .buttonPdf {
    background-color: #006600;
    margin-left: 20px;
    border: none;
    height: 40px;
    width: 40px;
    padding: 5px;
    border-radius: 5px;
    color: #ffffff;
    svg {
      height: 30px;
      width: 20px;
    }
  }
`;
