import styled from 'styled-components';

export const Container = styled.div`
  input {
    color: #fff;

    &::placeholder {
      color: #665360;
    }
  }
  span {
    color: #fff;
    background: #232129 !important;
    margin-right: 5px;

    svg {
      width: 20px;
      height: 20px;
    }
  }
`;
