import React, { useState, useMemo } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { FiEye } from 'react-icons/fi';
import { GoFilePdf } from 'react-icons/go';
import Search from '../../../../components/Search';
import HeaderTable from '../../../../components/HeaderTable';
import { maskCpf } from '../../../../components/Input/mask';
import DropDown from '../../../../components/DropDown';
import Pagination from '../../../../components/Pagination';
import 'react-datepicker/dist/react-datepicker.css';
import { ResponseGet } from '../Interface/index';
import { exportPdfPontoDetalhado } from '../ExportPdf/tablePontoDetalhado';
import PontoTodosDetalhado from '../TablesExportExcel/ponto';
import {
  Container,
  Aba,
  ContainerAba,
  ContainerFiltro,
  ContainerFilter,
  ContainerSearchTodos,
  ContainerTable,
  ContainerPagination,
} from './styles';

interface DadosTable {
  dados: ResponseGet[];
  dadosCompletos: ResponseGet[];
  empresa: string;
  dataInicial: string;
  dataFinal: string;
  valor: number;
  onChange: () => void;
  onVisualize: (dados: ResponseGet) => void;
}

const TabelaExibicao: React.FC<DadosTable> = ({
  dados,
  dadosCompletos,
  empresa,
  dataInicial,
  dataFinal,
  valor,
  onChange,
  onVisualize,
}) => {
  // const [searchCliente, setSearchCliente] = useState('');
  // const [searchContrato, setSearchContrato] = useState('');
  // const [searchPosto, setSearchPosto] = useState('');
  const [searchNome, setSearchNome] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [totalTabela, setTotalTabela] = useState(0);

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState<number>(20);

  const dropDown = [
    { valor: '20', id: 20 },
    { valor: '30', id: 30 },
    { valor: '40', id: 40 },
    { valor: '50', id: 50 },
    { valor: 'todos', id: 1 },
  ];

  const abas = [
    { name: 'Ponto', field: 'ponto', id: 1 },
    { name: 'Hora Extra', field: 'he', id: 2 },
    { name: 'Falta', field: 'falta', id: 3 },
    { name: 'Atraso', field: 'atraso', id: 4 },
    // { name: 'Suspensão', field: 'suspensao' },
    // { name: 'Férias', field: 'ferias', id: 6 },
    // { name: 'Licença', field: 'licenca' },
    // { name: 'Afastamento', field: 'afasta', id: 8 },
    { name: 'Local Divergente', field: 'ponto', id: 9 },
    { name: 'Hora Divergente', field: 'horaDivergente', id: 11 },
    { name: 'Abono', field: 'abono', id: 12 },
  ];

  const headersPonto = [
    { name: '', field: 'visualizar' },
    // { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    // { name: 'Contrato', field: 'contrato' },
    // { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
  ];

  const headersHoraExtra = [
    { name: '', field: 'visualizar' },
    // { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    // { name: 'Contrato', field: 'contrato' },
    // { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
    // { name: 'Data', field: 'data' },
    // { name: 'Horas à trabalhar', field: 'horaATrabalhar' },
    // { name: 'Horas Trabalhadas', field: 'horaTrabalhadas' },
  ];

  const headersFalta = [
    { name: '', field: 'visualizar' },
    // { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    // { name: 'Contrato', field: 'contrato' },
    // { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
  ];

  const headersAtraso = [
    { name: '', field: 'visualizar' },
    // { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    // { name: 'Contrato', field: 'contrato' },
    // { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
    // { name: 'Data', field: 'data' },
    // { name: 'Horas à Trabalhar', field: 'horaATrabalhar' },
    // { name: 'Horas Trabalhadas', field: 'horaTrabalhada' },
  ];

  // const headersFerias = [
  //   { name: '', field: 'visualizar' },
  //   { name: 'Cliente', field: 'cliente' },
  //   { name: 'Nome', field: 'nome' },
  //   { name: 'CPF', field: 'cpf' },
  //   { name: 'Contrato', field: 'contrato' },
  //   { name: 'Posto', field: 'posto' },
  //   { name: 'Função', field: 'funcao' },
  //   { name: 'Data Inicial', field: 'dataInicial' },
  //   { name: 'Data Final', field: 'dataFinal' },
  //   { name: 'Data Volta', field: 'dataVolta' },
  // ];

  // const headersAfastamento = [
  //   { name: '', field: 'visualizar' },
  //   { name: 'Cliente', field: 'cliente' },
  //   { name: 'Nome', field: 'nome' },
  //   { name: 'CPF', field: 'cpf' },
  //   { name: 'Contrato', field: 'contrato' },
  //   { name: 'Posto', field: 'posto' },
  //   { name: 'Função', field: 'funcao' },
  //   { name: 'Data Inicial', field: 'dataInicial' },
  //   { name: 'Data Final', field: 'dataFinal' },
  //   { name: 'Data Volta', field: 'dataVolta' },
  //   { name: 'Motivo', field: 'motivo' },
  // ];

  const headersPontoDivergente = [
    { name: '', field: 'visualizar' },
    // { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    // { name: 'Contrato', field: 'contrato' },
    // { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
    /* { name: 'Data', field: 'data' },
    { name: 'Latitude Posto', field: 'latitudePosto' },
    { name: 'Longitude Posto', field: 'longitudePosto' },
    { name: 'Latitude Real', field: 'latitudeReal' },
    { name: 'Longitude Real', field: 'longitudeReal' },
    { name: 'Distância', field: 'distancia' }, */
  ];
  const headersHoraDivergente = [
    { name: '', field: 'visualizar' },
    // { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    // { name: 'Contrato', field: 'contrato' },
    // { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
    // { name: 'Data', field: 'data' },
    // { name: 'Hora Escala', field: 'horaEscala' },
    // { name: 'Hora Ponto', field: 'horaPonto' },
  ];

  const headersAbono = [
    { name: '', field: 'visualizar' },
    // { name: 'Cliente', field: 'cliente' },
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    // { name: 'Contrato', field: 'contrato' },
    // { name: 'Posto', field: 'posto' },
    { name: 'Função', field: 'funcao' },
  ];

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = dados;
    switch (valor) {
      case 1: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => !res.SEM_PONTO,
        );
        break;
      }
      case 2: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.EXTRA,
        );
        break;
      }
      case 3: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.SEM_PONTO,
        );
        break;
      }
      case 4: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.ATRASO,
        );
        break;
      }
      // case 6: {
      //   computedResponses = computedResponses.filter(
      //     (res: ResponseGet) => res.FERIAS,
      //   );
      //   break;
      // }
      // case 8: {
      //   computedResponses = computedResponses.filter(
      //     (res: ResponseGet) => res.AFASTAMENTO,
      //   );
      //   break;
      // }
      // case 9: {
      //   computedResponses = computedResponses.filter(
      //     (res: ResponseGet) => res.PONTO_DIVERGENTE,
      //   );
      //   break;
      // }
      case 11: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.HORA_DIVERGENTE,
        );
        break;
      }
      case 12: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.ABONO || res.FALTA_ABONADA,
        );
        break;
      }
      case 13: {
        computedResponses = computedResponses.filter(
          (res: ResponseGet) => res.SEM_PONTO,
        );
        break;
      }
      default: {
        break;
      }
    }

    // if (searchCliente) {
    //   computedResponses = computedResponses.filter((res: ResponseGet) =>
    //     res.NOME_CLIENTE.toLowerCase().includes(searchCliente.toLowerCase()),
    //   );
    // }
    // if (searchContrato) {
    //   computedResponses = computedResponses.filter((res: ResponseGet) =>
    //     res.CONTRATO.toLowerCase().includes(searchContrato.toLowerCase()),
    //   );
    // }
    if (searchNome) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CPFSEMFORMATO.toLowerCase().includes(CPF.toLowerCase()),
      );
    }
    // if (searchPosto) {
    //   computedResponses = computedResponses.filter((res: ResponseGet) =>
    //     res.POSTO.toLowerCase().includes(searchPosto.toLowerCase()),
    //   );
    // }
    setTotalTabela(computedResponses.length);
    // console.log(computedResponses);
    setTotalItems(computedResponses.length);

    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    currentPage,
    dados,
    searchCPF,
    // searchCliente,
    // searchContrato,
    searchNome,
    // searchPosto,
    valor,
  ]);

  return (
    <>
      <ContainerFiltro>
        <Aba>
          <ContainerAba className="aba" cor={false}>
            <button
              type="button"
              onClick={() => {
                onChange();
              }}
            >
              Voltar
            </button>
          </ContainerAba>
          {abas.map(
            (head) =>
              head.id === valor && (
                <ContainerAba key={head.field} className="aba" cor>
                  <button type="button">{head.name}</button>
                </ContainerAba>
              ),
          )}
        </Aba>
        <Container>
          <ContainerSearchTodos>
            {/* <Search
              onSearch={(value: string) => {
                setSearchCliente(value);
              }}
              nomePlaceHolder="Buscar Cliente"
            /> */}
            <Search
              onSearch={(value: string) => {
                setSearchNome(value);
              }}
              nomePlaceHolder="Buscar Nome"
            />
            <Search
              onSearch={(value: string) => {
                setSearchCPF(value);
              }}
              nomePlaceHolder="Buscar CPF"
            />
            {/* <Search
              onSearch={(value: string) => {
                setSearchContrato(value);
              }}
              nomePlaceHolder="Buscar Contrato"
            /> */}
            {/* <Search
              onSearch={(value: string) => {
                setSearchPosto(value);
              }}
              nomePlaceHolder="Buscar Posto"
            /> */}
          </ContainerSearchTodos>
        </Container>
      </ContainerFiltro>
      {responseData.length <= 0 && (
        <ContainerFilter>
          <h3>Não existe dados para essa filtragem.</h3>
        </ContainerFilter>
      )}

      <ContainerTable>
        {valor === 1 && responseData.length > 0 && (
          <>
            <div style={{ display: 'none' }}>
              <PontoTodosDetalhado
                dados={responseData}
                dadosCompletos={dadosCompletos}
              />
            </div>
            <table id="pontoEscala">
              <HeaderTable headers={headersPonto} />
              <tbody>
                {responseData.map((res: ResponseGet, index) => (
                  <tr key={res.CPFSEMFORMATO + index.toString()}>
                    <td>
                      <button
                        type="button"
                        onClick={() => {
                          onVisualize(res);
                        }}
                      >
                        <FiEye />
                      </button>
                    </td>
                    {/* <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td> */}
                    <td style={{ minWidth: 250 }}>{res.NOME}</td>
                    <td style={{ minWidth: 120 }}>
                      {maskCpf(res.CPFSEMFORMATO)}
                    </td>
                    {/* <td>{res.CONTRATO}</td> */}
                    {/* <td style={{ minWidth: 150 }}>{res.POSTO}</td> */}
                    <td>{res.FUNCAO}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </>
        )}
        {valor === 2 && responseData.length > 0 && (
          <table id="horaExtra">
            <HeaderTable headers={headersHoraExtra} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  {/* <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td> */}
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  {/* <td>{res.CONTRATO}</td> */}
                  {/* <td style={{ minWidth: 150 }}>{res.POSTO}</td> */}
                  <td>{res.FUNCAO}</td>
                  {/* <td style={{ minWidth: 100 }}>
                    {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
                  </td>
                  <td>{res.HORAS_A_TRABALHAR}</td>
                    <td>{res.HORAS_TRABALHADAS_MENOS_HORA_PAUSA}</td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {valor === 3 && responseData.length > 0 && (
          <table id="fal">
            <HeaderTable headers={headersFalta} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  {/* <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td> */}
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  {/* <td>{res.CONTRATO}</td> */}
                  {/* <td style={{ minWidth: 150 }}>{res.POSTO}</td> */}
                  <td>{res.FUNCAO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {valor === 4 && responseData.length > 0 && (
          <table id="atraso">
            <HeaderTable headers={headersAtraso} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  {/* <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td> */}
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  {/* <td>{res.CONTRATO}</td> */}
                  {/* <td style={{ minWidth: 150 }}>{res.POSTO}</td> */}
                  <td>{res.FUNCAO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {/* {valor === 6 && responseData.length > 0 && (
          <table id="ferias">
            <HeaderTable headers={headersFerias} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  <td>{res.CONTRATO}</td>
                  <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                  <td>{res.FUNCAO}</td>
                  <td>
                    {res.DATA_INICIAL_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(
                          res.DATA_INICIAL_FERIAS_AFASTAMENTO,
                        )
                      : '-'}
                  </td>
                  <td style={{ minWidth: 80 }}>
                    {res.DATA_FINAL_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(res.DATA_FINAL_FERIAS_AFASTAMENTO)
                      : '-'}
                  </td>
                  <td style={{ minWidth: 80 }}>
                    {res.DATA_VOLTA_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(res.DATA_VOLTA_FERIAS_AFASTAMENTO)
                      : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )} */}
        {/* {valor === 8 && responseData.length > 0 && (
          <table id="afastamento">
            <HeaderTable headers={headersAfastamento} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td>
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  <td>{res.CONTRATO}</td>
                  <td style={{ minWidth: 150 }}>{res.POSTO}</td>
                  <td>{res.FUNCAO}</td>
                  <td style={{ minWidth: 100 }}>
                    {res.DATA_INICIAL_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(
                          res.DATA_INICIAL_FERIAS_AFASTAMENTO,
                        )
                      : '-'}
                  </td>
                  <td style={{ minWidth: 100 }}>
                    {res.DATA_FINAL_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(res.DATA_FINAL_FERIAS_AFASTAMENTO)
                      : '-'}
                  </td>
                  <td style={{ minWidth: 100 }}>
                    {res.DATA_VOLTA_FERIAS_AFASTAMENTO
                      ? formatarDataAnoMesDia(res.DATA_VOLTA_FERIAS_AFASTAMENTO)
                      : '-'}
                  </td>
                  <td style={{ minWidth: 200 }}>{res.MOTIVO_AFASTAMENTO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )} */}
        {valor === 9 && responseData.length > 0 && (
          <table id="pontoDivergente">
            <HeaderTable headers={headersPontoDivergente} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  {/* <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td> */}
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  {/* <td>{res.CONTRATO}</td> */}
                  {/* <td style={{ minWidth: 150 }}>{res.POSTO}</td> */}
                  <td>{res.FUNCAO}</td>
                  {/* <td style={{ minWidth: 100 }}>
                    {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
                  </td>
                  <td>{res.LOCAL_LATITUDE}</td>
                  <td>{res.LOCAL_LONGITUDE}</td>
                  <td style={{ minWidth: 150 }}>
                    <div style={{ textAlign: 'left' }}>
                      <p>
                        Entrada:{' '}
                        {res.PONTO_LATITUDE_ENTRADA
                          ? res.PONTO_LATITUDE_ENTRADA
                          : '-'}
                      </p>
                      <p>
                        Pausa:{' '}
                        {res.PONTO_LATITUDE_PAUSA
                          ? res.PONTO_LATITUDE_PAUSA
                          : '-'}
                      </p>
                      <p>
                        Volta:{' '}
                        {res.PONTO_LATITUDE_VOLTA
                          ? res.PONTO_LATITUDE_VOLTA
                          : '-'}
                      </p>
                      <p>
                        Saída:{' '}
                        {res.PONTO_LATITUDE_SAIDA
                          ? res.PONTO_LATITUDE_SAIDA
                          : '-'}
                      </p>
                    </div>
                  </td>
                  <td style={{ minWidth: 150 }}>
                    <div style={{ textAlign: 'left' }}>
                      <p>
                        Entrada:{' '}
                        {res.PONTO_LONGITUDE_ENTRADA
                          ? res.PONTO_LONGITUDE_ENTRADA
                          : '-'}
                      </p>
                      <p>
                        Pausa:{' '}
                        {res.PONTO_LONGITUDE_PAUSA
                          ? res.PONTO_LONGITUDE_PAUSA
                          : '-'}
                      </p>
                      <p>
                        Volta:{' '}
                        {res.PONTO_LONGITUDE_VOLTA
                          ? res.PONTO_LONGITUDE_VOLTA
                          : '-'}
                      </p>
                      <p>
                        Saída:{' '}
                        {res.PONTO_LONGITUDE_SAIDA
                          ? res.PONTO_LONGITUDE_SAIDA
                          : '-'}
                      </p>
                    </div>
                  </td>
                  <td style={{ minWidth: 150 }}>
                    <div style={{ textAlign: 'left' }}>
                      <p>
                        Entrada:{' '}
                        {res.PONTO_DIFERENCA_METROS_ENTRADA
                          ? `${res.PONTO_DIFERENCA_METROS_ENTRADA}m`
                          : '-'}
                      </p>
                      <p>
                        Pausa:{' '}
                        {res.PONTO_DIFERENCA_METROS_PAUSA
                          ? `${res.PONTO_DIFERENCA_METROS_PAUSA}m`
                          : '-'}
                      </p>
                      <p>
                        Volta:{' '}
                        {res.PONTO_DIFERENCA_METROS_VOLTA
                          ? `${res.PONTO_DIFERENCA_METROS_VOLTA}m`
                          : '-'}
                      </p>
                      <p>
                        Saída:{' '}
                        {res.PONTO_DIFERENCA_METROS_SAIDA
                          ? `${res.PONTO_DIFERENCA_METROS_SAIDA}m`
                          : '-'}
                      </p>
                    </div>
                        </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {valor === 11 && responseData.length > 0 && (
          <table id="horaDivergente">
            <HeaderTable headers={headersHoraDivergente} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  {/* <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td> */}
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  {/* <td>{res.CONTRATO}</td> */}
                  {/* <td style={{ minWidth: 150 }}>{res.POSTO}</td> */}
                  <td>{res.FUNCAO}</td>
                  {/* <td style={{ minWidth: 100 }}>
                    {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
                  </td>
                  <td style={{ minWidth: 150 }}>
                    <div style={{ textAlign: 'left' }}>
                      <p>Entrada: {res.HORA_INICIAL}</p>
                      <p>Saída: {res.HORA_FINAL}</p>
                    </div>
                  </td>
                  <td style={{ minWidth: 150 }}>
                    <div style={{ textAlign: 'left' }}>
                      <p>
                        Entrada:{' '}
                        {res.PONTO_HORA_ENTRADA ? res.PONTO_HORA_ENTRADA : '-'}
                      </p>
                      <p>
                        Pausa:{' '}
                        {res.PONTO_HORA_PAUSA ? res.PONTO_HORA_PAUSA : '-'}
                      </p>
                      <p>
                        Volta:{' '}
                        {res.PONTO_HORA_VOLTA ? res.PONTO_HORA_VOLTA : '-'}
                      </p>
                      <p>
                        Saída:{' '}
                        {res.PONTO_HORA_SAIDA ? res.PONTO_HORA_SAIDA : '-'}
                      </p>
                    </div>
                    </td> */}
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {valor === 12 && responseData.length > 0 && (
          <table id="abono">
            <HeaderTable headers={headersAbono} />
            <tbody>
              {responseData.map((res: ResponseGet, index) => (
                <tr key={res.CPFSEMFORMATO + index.toString()}>
                  <td>
                    <button
                      type="button"
                      onClick={() => {
                        onVisualize(res);
                      }}
                    >
                      <FiEye />
                    </button>
                  </td>
                  {/* <td style={{ minWidth: 250 }}>{res.NOME_CLIENTE}</td> */}
                  <td style={{ minWidth: 250 }}>{res.NOME}</td>
                  <td style={{ minWidth: 120 }}>
                    {maskCpf(res.CPFSEMFORMATO)}
                  </td>
                  {/* <td>{res.CONTRATO}</td> */}
                  {/* <td style={{ minWidth: 150 }}>{res.POSTO}</td> */}
                  <td>{res.FUNCAO}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </ContainerTable>
      {responseData.length > 0 && (
        <ContainerPagination>
          <Pagination
            total={totalItems}
            itemsPerPage={ITEMS_PER_PAGE}
            currentPage={currentPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
          <DropDown
            onChangeItems={(value: string) => {
              setCurrentPage(1);
              if (value === 'todos') {
                setITEMS_PER_PAGE(1);
              } else {
                setITEMS_PER_PAGE(Number(value));
              }
            }}
            objetoEnum={dropDown}
            minWidth={30}
          />
          {valor === 1 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="PontoTodosExcel"
              filename={`gerenciamento_ponto_todos_${dataInicial}_a_${dataFinal}`}
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 1 && (
            <button
              className="buttonPdf"
              type="button"
              onClick={() => {
                exportPdfPontoDetalhado(
                  responseData,
                  dadosCompletos,
                  empresa,
                  dataInicial,
                  dataFinal,
                );
              }}
            >
              <GoFilePdf />
            </button>
          )}
          {valor === 2 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="horaExtra"
              filename="gerenciamento_ponto_hora_extra"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 3 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="fal"
              filename="gerenciamento_ponto_faltas"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 4 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="atraso"
              filename="gerenciamento_ponto_atraso"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {/* {valor === 6 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="ferias"
              filename="gerenciamento_ponto_ferias"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )} */}
          {valor === 8 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              // table="afastamento"
              // filename="gerenciamento_ponto_afastamento"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 9 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="pontoDivergente"
              filename="gerenciamento_ponto_Ponto_divergente"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 11 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="horaDivergente"
              filename="gerenciamento_ponto_Hora_divergente"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          {valor === 12 && (
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="abono"
              filename="gerenciamento_ponto_abono"
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          )}
          <div style={{ marginLeft: 'auto' }}>
            <p>Pessoas: {totalTabela}</p>
          </div>
        </ContainerPagination>
      )}
    </>
  );
};
export default TabelaExibicao;
