import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { FiLock, FiEdit2 } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import Search from '../../../components/Search';
import HeaderTable from '../../../components/HeaderTable';
import api from '../../../services/api';
import { maskCpf } from '../../../components/Input/mask';
import ModalConexao from '../../../components/ModalConexao';
import { Loading } from '../../../components/Loading';
import Button from '../../../components/Button';

import {
  Container,
  ContainerSearch,
  ContainerTable,
  ContainerAviso,
  ContainerEditEmail,
  AnimationContainer,
  ContentSubBody,
  ContentSubLabel,
} from './styles';

interface PersonGet {
  NOME: string;
  CPF: string;
  EMAIL: string;
  Telefone: string;
  token: string;
  ID: number;
}

const RestorePassword: React.FC = () => {
  const history = useHistory();
  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [response, setResponse] = useState<PersonGet[]>([]);
  const [loading, setLoading] = useState(false);
  const [nomeUser, setNomeUser] = useState<PersonGet>();
  const [visualizarEditEmail, setVisualizarEditEmail] = useState(false);
  const [emailEdit, setEmailEdit] = useState('');
  const [user_id, setUserId] = useState<number>();
  const [refresh, setRefresh] = useState(false);

  const headers = [
    { name: 'Nome', field: 'name' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Email', field: 'email' },
    { name: 'Editar Email', field: 'editarEmail' },
    { name: 'Telefone', field: 'telefone' },
    { name: 'Resetar Senha', field: 'resetPassword' },
  ];

  useEffect(() => {
    // let semAcesso = true;
    // let access;
    // userAcesso.forEach((u) => {
    //   access = setores.filter(
    //     (setor) => setor.id === u.SetorID && u.NivelACesso >= setor.NivelAcesso,
    //   );
    //   if (access.length > 0) {
    //     semAcesso = false;
    //   }
    // });
    // if (semAcesso) {
    //   history.push('/dashboard');
    //   Swal.fire({
    //     icon: 'info',
    //     title:
    //       'Seu nível de acesso não tem permissão para acessar essa página!',
    //     showClass: {
    //       popup: 'animate__animated animate__fadeInDown',
    //     },
    //     hideClass: {
    //       popup: 'animate__animated animate__fadeOutUp',
    //     },
    //   });
    // } else {
    setLoading(true);
    api
      .get(`/users/findAll`)
      .then((data) => {
        setResponse(data.data);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        if (navigator.onLine) {
          Swal.fire({
            icon: 'info',
            title: 'Erro ao carregar dados, por favor atualize a página!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        }
      });
    // }
  }, [history, refresh]);

  function especialCharMask(senha: string): string {
    let novaSenha = senha;
    novaSenha = novaSenha.replace(/[áàãâä]/iu, 'a');
    novaSenha = novaSenha.replace(/[éèêë]/iu, 'e');
    novaSenha = novaSenha.replace(/[íìîï]/iu, 'i');
    novaSenha = novaSenha.replace(/[óòõôö]/iu, 'o');
    novaSenha = novaSenha.replace(/[úùûü]/iu, 'u');
    novaSenha = novaSenha.replace(/[ç]/iu, 'c');
    novaSenha = novaSenha.replace(/[^a-z0-9]/i, '_');
    novaSenha = novaSenha.replace(/_+/, '_'); //
    return novaSenha;
  }

  function resetPassword(data: PersonGet): string {
    let reset = data.CPF.substring(8, 11);
    reset = reset.concat(especialCharMask(data.NOME.substring(0, 1)));
    reset = reset.concat(especialCharMask(data.NOME.substring(1, 2)));
    reset = reset.concat(especialCharMask(data.NOME.substring(2, 3)));
    return reset.toLowerCase();
  }

  const handleRestorePassword = useCallback(async (data: PersonGet) => {
    try {
      setLoading(true);
      const resetPass = resetPassword(data);
      await api
        .post('password/reset', {
          token: data.token,
          password: resetPass,
          password_confirmation: resetPass,
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Senha Recuperada com Sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Falha ao recuperar Senha.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Falha ao Recuperar Senha.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleEditEmail = useCallback(
    async (email: string) => {
      try {
        setLoading(true);
        if (!email || !email.includes('@')) {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Digite um E-mail válido!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        } else {
          await api
            .patch('password/emailUpdate', {
              user_id,
              email: email.trim(),
            })
            .then(() => {
              setLoading(false);
              Swal.fire({
                icon: 'success',
                title: 'E-mail atualizado com Sucesso!',
                showConfirmButton: false,
                timer: 1500,
              });
              setVisualizarEditEmail(false);
              setRefresh(!refresh);
            })
            .catch((e) => {
              setLoading(false);
              Swal.fire({
                icon: 'error',
                title: `${e.response.data.errors}`,
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            });
        }
      } catch (err) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Falha ao atualizar email.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [user_id, refresh],
  );

  const responseData = useMemo(() => {
    let computedResponses: PersonGet[] = [];

    if (search || searchCPF) {
      computedResponses = response; // recebe os dados da variavel que recebeu os dados da api.
    }

    if (search) {
      computedResponses = computedResponses.filter((res: PersonGet) =>
        res.NOME.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: PersonGet) =>
        res.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }

    return computedResponses;
  }, [response, search, searchCPF]);

  const handleCertezaDelete = useCallback(
    (data: PersonGet) => {
      Swal.fire({
        title: 'Tem certeza que deseja resetar?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleRestorePassword(data);
        }
      });
    },
    [handleRestorePassword],
  );

  return (
    <Container>
      <ModalConexao />
      {/* <Header /> */}
      <ContainerSearch>
        <Search
          onSearch={(value: string) => {
            setSearch(value);
          }}
          nomePlaceHolder="Buscar Nome"
        />
        <Search
          onSearch={(value: string) => {
            setSearchCPF(value);
          }}
          nomePlaceHolder="Buscar CPF"
        />
      </ContainerSearch>
      <ContainerTable>
        <table>
          <HeaderTable headers={headers} />
          <tbody>
            {responseData.map((res: PersonGet) => (
              <tr key={res.CPF}>
                <td style={{ minWidth: 250 }}>{res.NOME}</td>
                <td style={{ minWidth: 120 }}>{maskCpf(res.CPF)}</td>
                <td style={{ minWidth: 200 }}>{res.EMAIL}</td>
                <td
                  style={{
                    width: 30,
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setVisualizarEditEmail(true);
                      setUserId(res.ID);
                      setNomeUser(res);
                    }}
                  >
                    <FiEdit2 />
                  </button>
                </td>
                <td>{res.Telefone}</td>
                <td
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 5,
                  }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      handleCertezaDelete(res);
                    }}
                  >
                    <FiLock />
                  </button>
                </td>
              </tr>
              // ))}
            ))}
          </tbody>
        </table>
      </ContainerTable>
      {visualizarEditEmail && (
        <ContainerTable>
          <ContainerEditEmail>
            <p
              style={{
                fontFamily: 'Arial',
                padding: 12,
                height: 40,
                minWidth: 150,
                maxWidth: 450,
                color: '#ffffff',
              }}
            >
              {nomeUser?.NOME}
            </p>
            <AnimationContainer>
              <ContentSubBody style={{ height: 50 }}>
                <ContentSubLabel
                  style={{
                    borderTopLeftRadius: 15,
                    borderBottomLeftRadius: 15,
                    paddingLeft: 15,
                  }}
                />
                <ContentSubLabel style={{ marginLeft: 0, marginRight: 0 }}>
                  Email Atual
                </ContentSubLabel>
                <p
                  style={{
                    fontFamily: 'Arial',
                    alignSelf: 'center',
                    justifySelf: 'center',
                    padding: 8,
                    margin: 0,
                    height: 40,
                    minWidth: 150,
                    maxWidth: 450,
                    backgroundColor: '#ffffff',
                    color: '#111111',
                  }}
                >
                  {nomeUser?.EMAIL}
                </p>
                <ContentSubLabel style={{ marginLeft: 0, marginRight: 0 }}>
                  Email Novo
                </ContentSubLabel>
                <input
                  type="text"
                  name="email"
                  placeholder="Email"
                  onChange={(e) => setEmailEdit(e.target.value)}
                />
                <ContentSubLabel
                  style={{
                    marginLeft: 0,
                    borderTopRightRadius: 15,
                    borderBottomRightRadius: 15,
                    paddingLeft: 15,
                  }}
                />
                <Button
                  type="button"
                  style={{
                    maxWidth: 150,
                    maxHeight: 40,
                    marginTop: 0,
                    marginLeft: 'auto',
                    marginRight: 3,
                  }}
                  onClick={() => handleEditEmail(emailEdit)}
                >
                  Atualizar
                </Button>
                <Button
                  type="button"
                  style={{
                    maxWidth: 150,
                    maxHeight: 40,
                    marginTop: 0,
                    marginLeft: 0,
                    backgroundColor: '#b30000',
                  }}
                  onClick={() => setVisualizarEditEmail(false)}
                >
                  Cancelar Edição
                </Button>
              </ContentSubBody>
            </AnimationContainer>
          </ContainerEditEmail>
        </ContainerTable>
      )}
      <ContainerAviso>
        <h3>
          A nova senha será composta dos 3 últimos números do CPF e as 3
          primeiras letras do nome. (Minúsculas e sem Acentos)
        </h3>
      </ContainerAviso>
      {loading && <Loading />}
    </Container>
  );
};

export default RestorePassword;
