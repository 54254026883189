import React from 'react';
import ModalConexao from '../../../components/ModalConexao';
import { Container } from './styles';
import logoImg from '../../../assets/LogoGreenDot.png';

const Dashboard: React.FC = () => {
  return (
    <>
      <ModalConexao />
      <Container>
        <header>
          <img src={logoImg} alt="Logo Servnac" />
        </header>
      </Container>
    </>
  );
};

export default Dashboard;
