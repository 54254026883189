import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  z-index: 3;
  right: 0;
  top: 50px;
  padding: 0 30px 30px 30px;
  overflow: hidden;
`;
