import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Arial;
  select {
    font-size: 16px;
    height: 100%;
    background-color: #008f00;
    color: #ffffff;
    font-weight: bold;
    margin-left: 40px;
    border-color: #008f00;
    cursor: pointer;
    &:hover {
      background: ${shade(0.1, '#008f00')};
    }
  }
`;
