import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { AiFillCloseCircle } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { BsBuilding } from 'react-icons/bs';
import Input from '../Input';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  SignUpFormData,
  ResponseGet,
  ResponseGetFilial,
} from '../../pages/Cadastros/Escala';
import { Loading } from '../Loading';
import { SelectCustom } from '../SelectCustom';
import { findFilialName } from '../../interfaces/functionsFilterFind';

import {
  ContainerModal,
  Container,
  ButtonClose,
  Content,
  Title,
  ButtonSubmit,
  ContainerForm,
} from './styles';

interface ModalProps {
  data: ResponseGet;
  filiais: ResponseGetFilial[];
  onClose(e: boolean): void;
}

export const EditEscalaModal: React.FC<ModalProps> = ({
  data,
  filiais,
  onClose = (e: boolean): boolean => {
    return e;
  },
}: ModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const { addToast } = useToast();
  const [editNome, setEditNome] = useState(data?.NOME);
  const [editFilial, setEditFilial] = useState(data?.FILIAL);
  const [editIntraJornada, setEditIntraJornada] = useState(data?.INTRA_JORNADA);
  const [editE12X36, setEditE12X36] = useState(data?.EH_12X36);
  const [editConsiderarFds, setEditConsiderarFds] = useState(
    data?.CONSIDERAR_FINS_DE_SEMANA,
  );
  const [
    editConsiderarFeriadoDiaUtil,
    setEditConsiderarFeriadoDiaUtil,
  ] = useState(data?.FERIADO_DIA_UTIL);
  const [editEscalaNoturna, setEditEscalaNoturna] = useState(data?.VIRA_NOITE);
  const [editHorarioInicio, setEditHorarioInicio] = useState(
    data?.HORA_INICIAL,
  );
  const [editHorarioPausa, setEditHorarioPausa] = useState(data?.HORA_PAUSA);
  const [editHorarioVolta, setEditHorarioVolta] = useState(data?.HORA_VOLTA);
  const [editHorarioSaida, setEditHorarioSaida] = useState(data?.HORA_SAIDA);
  const [filiaisTemp, setFiliaisTemp] = useState<ResponseGetFilial[]>([]);

  useEffect(() => {
    const findDataFilial = filiais.find(
      (filial) => filial.CODIGO_FILIAL === editFilial,
    );

    findDataFilial === undefined
      ? setFiliaisTemp([
          ...filiais,
          {
            ATIVO: false,
            BAIRRO: 'INATIVO',
            CEP: 'INATIVO',
            CIDADE: 'INATIVO',
            CODIGO_FILIAL: editFilial,
            ENDERECO: 'INATIVO',
            ESTADO: 'INATIVO',
            ID: -1,
            NUMERO: 'INATIVO',
            NOME_EMPRESA: 'INATIVO',
            NOME_FANTASIA_EMPRESA: 'INATIVO',
            CNPJ: 'INATIVO',
          },
        ])
      : setFiliaisTemp([...filiais]);
  }, [editFilial, filiais]);

  useEffect(() => {
    if (!editIntraJornada) {
      setEditHorarioPausa('');
      setEditHorarioVolta('');
    }
  }, [editIntraJornada]);

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose(true);
      }
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    async (dataTemp: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});
        let schema: any;

        schema = Yup.object().shape({
          nome: Yup.string().required('Nome é obrigatório!'),
          filial: Yup.string().required('Filial é obrigatória!'),
          horarioInicio: Yup.string().required(
            'Horário de início é obrigatório!',
          ),
          horarioSaida: Yup.string().required(
            'Horário da saída é obrigatório!',
          ),
          intraJornada: Yup.string().required(
            'Confirmação se tem intra jornada é obrigatória!',
          ),
          eh12X36: Yup.string().required(
            'Confirmação se é 12X36 é obrigatória!',
          ),
          considerarFinsDeSemana: Yup.string().required(
            'Confirmação se deve considerar fins de semana é obrigatória!',
          ),
          considerarFeriadoDiaUtil: Yup.string().required(
            'Confirmação se deve considerar dia útil é obrigatória!',
          ),
          escalaNoturna: Yup.string().required(
            'Confirmação se tem escala noturna é obrigatória!',
          ),
        });

        if (dataTemp.intraJornada === 'Sim') {
          schema = Yup.object().shape({
            nome: Yup.string().required('Nome é obrigatório!'),
            filial: Yup.string().required('Filial é obrigatória!'),
            horarioInicio: Yup.string().required(
              'Horário de início é obrigatório!',
            ),
            horarioPausa: Yup.string().required(
              'Horário da pausa é obrigatório!',
            ),
            horarioVolta: Yup.string().required(
              'Horário da volta é obrigatório!',
            ),
            horarioSaida: Yup.string().required(
              'Horário da saída é obrigatório!',
            ),
            intraJornada: Yup.string().required(
              'Confirmação se tem intra jornada é obrigatória!',
            ),
            eh12X36: Yup.string().required(
              'Confirmação se é 12X36 é obrigatória!',
            ),
            considerarFinsDeSemana: Yup.string().required(
              'Confirmação se deve considerar fins de semana é obrigatória!',
            ),
            considerarFeriadoDiaUtil: Yup.string().required(
              'Confirmação se deve considerar dia útil é obrigatória!',
            ),
            escalaNoturna: Yup.string().required(
              'Confirmação se tem escala noturna é obrigatória!',
            ),
          });
        }

        if (
          dataTemp.intraJornada === 'Sim' &&
          dataTemp.horarioInicio !== '' &&
          dataTemp.horarioSaida !== '' &&
          dataTemp.horarioPausa !== '' &&
          dataTemp.horarioVolta !== ''
        ) {
          schema = Yup.object().shape({
            horarioInicio: Yup.string().required(
              'Horário de início é obrigatório!',
            ),
            horarioPausa: Yup.string().required(
              'Horário da pausa é obrigatório!',
            ),
            horarioVolta: Yup.string().required(
              'Horário da volta é obrigatório!',
            ),
            horarioSaida: Yup.string().required(
              'Horário da saída é obrigatório!',
            ),
          });
        } else if (
          dataTemp.intraJornada === 'Não' &&
          dataTemp.horarioInicio !== '' &&
          dataTemp.horarioSaida !== ''
        ) {
          schema = Yup.object().shape({
            horarioInicio: Yup.string().required(
              'Horário de início é obrigatório!',
            ),
            horarioSaida: Yup.string().required(
              'Horário da saída é obrigatório!',
            ),
          });
        }

        await schema.validate(dataTemp, {
          abortEarly: false,
        });

        setShowLoading(true);
        api
          .put(`/escala/updateEscala`, {
            ativo: true,
            id: data.ID,
            nome: dataTemp.nome,
            feriado_dia_util: editConsiderarFeriadoDiaUtil,
            eh_12x36: editE12X36,
            considerar_fins_de_semana: editConsiderarFds,
            intra_jornada: editIntraJornada,
            hora_inicial: dataTemp.horarioInicio,
            hora_pausa: dataTemp.horarioPausa,
            hora_volta: dataTemp.horarioVolta,
            hora_saida: dataTemp.horarioSaida,
            filial: dataTemp.filial,
            vira_noite: editEscalaNoturna,
          })
          .then(() => {
            setShowLoading(false);
            addToast({
              type: 'success',
              title: 'Escala editada!',
              description:
                'A escala foi editada com sucesso e já pode ser visualizada na listagem.',
            });

            setTimeout(() => {
              history.go(0);
            }, 1500);
          })
          .catch((error) => {
            setShowLoading(false);

            if (
              error.response.data.errors ===
              'There are one or more active schedules for the reported scale'
            ) {
              addToast({
                type: 'error',
                title: 'Erro na edição',
                description:
                  'Já existe um agendamento vinculado à escala de final de semana.',
              });
            } else {
              addToast({
                type: 'error',
                title: 'Erro na edição',
                description:
                  'Ocorreu um erro ao editar a escala, tente novamente.',
              });
            }
          });
      } catch (err) {
        setShowLoading(false);

        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description:
              'Existem campos obrigatórios não preenchidos, favor verificar.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description: 'Ocorreu um erro ao editar a escala, tente novamente.',
          });
        }
      }
    },
    [
      addToast,
      data,
      editConsiderarFds,
      editConsiderarFeriadoDiaUtil,
      editE12X36,
      editEscalaNoturna,
      editIntraJornada,
      history,
    ],
  );

  return (
    <ContainerModal id="modal" onClick={handleOutSideClick}>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Title>Editar escala</Title>
          <ContainerForm>
            <Content>
              <Input
                name="nome"
                icon={FiUser}
                placeholder="Nome"
                widthProps="49%"
                placeholderProps="Nome"
                editProps
                tooltip={false}
                value={editNome}
                onValue={(value) => setEditNome(value)}
              />
              <SelectCustom
                name="filial"
                widthProps="49%"
                icon={BsBuilding}
                defaultValue="Filial"
                value={editFilial}
                onValue={(value) => setEditFilial(value)}
                optionsData={filiaisTemp.map((filial) => {
                  return filial.CODIGO_FILIAL;
                })}
              />
            </Content>

            <Content>
              <Input
                name="nomeEmpresa"
                icon={BsBuilding}
                placeholder="Nome da empresa"
                widthProps="49%"
                placeholderProps="Nome da empresa"
                editProps
                tooltip={false}
                value={findFilialName(filiaisTemp, editFilial)}
                disabled
              />
              <SelectCustom
                name="intraJornada"
                widthProps="49%"
                icon={BsBuilding}
                defaultValue="Intra Jornada"
                value={editIntraJornada ? 'Sim' : 'Não'}
                onValue={(value) => setEditIntraJornada(value === 'Sim')}
                optionsData={['Sim', 'Não']}
              />
            </Content>

            <Content>
              <SelectCustom
                name="eh12X36"
                widthProps="49%"
                icon={BsBuilding}
                defaultValue="É 12X36"
                value={editE12X36 ? 'Sim' : 'Não'}
                onValue={(value) => setEditE12X36(value === 'Sim')}
                optionsData={['Sim', 'Não']}
              />
              <SelectCustom
                name="considerarFinsDeSemana"
                widthProps="49%"
                icon={BsBuilding}
                defaultValue="Considerar fins de semana"
                value={editConsiderarFds ? 'Sim' : 'Não'}
                onValue={(value) => setEditConsiderarFds(value === 'Sim')}
                optionsData={['Sim', 'Não']}
              />
            </Content>

            <Content>
              <SelectCustom
                name="considerarFeriadoDiaUtil"
                widthProps="49%"
                icon={BsBuilding}
                defaultValue="Considerar feriado como dia útil"
                value={editConsiderarFeriadoDiaUtil ? 'Sim' : 'Não'}
                onValue={(value) =>
                  setEditConsiderarFeriadoDiaUtil(value === 'Sim')}
                optionsData={['Sim', 'Não']}
              />
              <SelectCustom
                name="escalaNoturna"
                widthProps="49%"
                icon={BsBuilding}
                defaultValue="Escala noturna"
                value={editEscalaNoturna ? 'Sim' : 'Não'}
                onValue={(value) => setEditEscalaNoturna(value === 'Sim')}
                optionsData={['Sim', 'Não']}
              />
            </Content>

            <Content>
              <div
                style={{
                  width: '95%',
                  marginRight: '2%',
                  backgroundColor: '#3d3946',
                  padding: '10px',
                  borderRadius: '10px',
                }}
              >
                <p>Horário de início</p>
                <Input
                  type="time"
                  name="horarioInicio"
                  icon={FiUser}
                  placeholder="Horário de início"
                  widthProps="100%"
                  placeholderProps="Horário de início"
                  editProps
                  tooltip={false}
                  value={editHorarioInicio}
                  onValue={(value) => setEditHorarioInicio(value)}
                />
              </div>

              <div
                style={{
                  width: '95%',
                  backgroundColor: '#3d3946',
                  padding: '10px',
                  borderRadius: '10px',
                }}
              >
                <p>Horário da pausa</p>
                <Input
                  type="time"
                  name="horarioPausa"
                  icon={FiUser}
                  placeholder="Horário da pausa"
                  widthProps="100%"
                  placeholderProps="Horário da pausa"
                  editProps
                  tooltip={false}
                  value={editHorarioPausa}
                  onValue={(value) => setEditHorarioPausa(value)}
                  disabled={!editIntraJornada}
                  style={{
                    background: !editIntraJornada ? '#18171c' : '',
                  }}
                />
              </div>
            </Content>

            <Content>
              <div
                style={{
                  width: '95%',
                  marginRight: '2%',
                  backgroundColor: '#3d3946',
                  padding: '10px',
                  borderRadius: '10px',
                }}
              >
                <p>Horário da volta</p>
                <Input
                  type="time"
                  name="horarioVolta"
                  icon={FiUser}
                  placeholder="Horário da volta"
                  widthProps="100%"
                  placeholderProps="Horário da pausa"
                  editProps
                  tooltip={false}
                  value={editHorarioVolta}
                  onValue={(value) => setEditHorarioVolta(value)}
                  disabled={!editIntraJornada}
                  style={{
                    background: !editIntraJornada ? '#18171c' : '',
                  }}
                />
              </div>

              <div
                style={{
                  width: '95%',
                  backgroundColor: '#3d3946',
                  padding: '10px',
                  borderRadius: '10px',
                }}
              >
                <p>Horário da saída</p>
                <Input
                  type="time"
                  name="horarioSaida"
                  icon={FiUser}
                  placeholder="Horário da saída"
                  widthProps="100%"
                  placeholderProps="Horário da saída"
                  editProps
                  tooltip={false}
                  value={editHorarioSaida}
                  onValue={(value) => setEditHorarioSaida(value)}
                />
              </div>
            </Content>
          </ContainerForm>
          <ButtonSubmit type="submit">Salvar</ButtonSubmit>
        </Form>
        <ButtonClose onClick={() => onClose(true)}>
          <AiFillCloseCircle size={28} />
        </ButtonClose>
      </Container>

      {showLoading && <Loading />}
    </ContainerModal>
  );
};
