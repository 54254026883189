import styled, { keyframes } from 'styled-components';
import { darken } from 'polished';

interface ContentProps {
  isActive: boolean;
}

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div<ContentProps>`
  background: #6e677e;
  border-radius: 7px;
  padding: 10px;
  overflow-y: auto;

  transition: filter 0.5s;

  filter: ${(props) => (!props.isActive ? 'blur(3px)' : '')};

  header {
    border-bottom: 4px solid #f2f2f2;
    border-radius: 5px;
    padding-bottom: 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin: 0;
    }

    article {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        margin: 0;
        border: none;
        background: #00b300;
        border-radius: 2px;
        box-shadow: 1px 1px 1px 0px black;

        display: flex;
        align-items: center;
        justify-content: center;

        transition: background 0.2s;

        svg {
          width: 24px;
          height: 24px;
        }

        &:hover {
          background: ${darken(0.1, '#00b300')};
        }
      }

      span {
        margin-left: 10px;
        button {
          background: #eba417;
          transition: background 0.2s;

          &:hover {
            background: ${darken(0.1, '#eba417')};
          }
        }
      }
    }
  }

  div {
    height: 100vh;
    max-height: calc(100vh - 390px);
    padding-right: 2px;
    margin-top: 20px;
    overflow-y: auto;
    overflow-x: hidden;

    button {
      margin: 0;
      width: 100%;
      background: #565062;
      padding: 10px;
      border-radius: 7px;
      border: 2px solid #565062;
      box-shadow: 1px 1px 1px 0px black;
      cursor: pointer;

      animation: ${appearFromRight} 1s;

      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: space-between;

      transition: 0.2s;

      &:hover {
        background: ${darken(0.1, '#565062')};
        border: 2px solid #888198;
      }

      & + button {
        margin-top: 5px;
      }
    }

    button:last-child {
      margin-bottom: 1px;
    }

    article {
      width: 100%;
      height: 100%;
      background: #565062;
      border-radius: 7px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;
