import React from 'react';

import { AuthProvider } from './auth';
import { ToastProvider } from './toast';
import { ModulesProvider } from './availableModules';

const AppProvider: React.FC = ({ children }) => (
  <ModulesProvider>
    <AuthProvider>
      <ToastProvider>{children}</ToastProvider>
    </AuthProvider>
  </ModulesProvider>
);

export default AppProvider;
