import React, { FormEvent, useState } from 'react';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';
import api from '../../services/api';
import { Loading } from '../Loading';
import { useToast } from '../../hooks/toast';

import { Container } from './styles';

Modal.setAppElement('#root');

interface ModalProps {
  isOpen: boolean;
  titleProps: string;
  onDataReturn: (e: ResponsePost) => void;
  onRequestClose: () => void;
}

interface ModulosResponseGet {
  idModulos: number[];
  nomeModulos: string[];
  leitura: boolean[];
  escrita: boolean[];
}

export interface ResponsePost {
  AVATAR?: string;
  Ativo?: number;
  CPF?: string;
  DataCadastro?: string;
  DataNascimento?: string;
  EMAIL?: string;
  ID: number;
  ID_NIVEL_ACESSO?: number;
  ID_SETOR?: number;
  NOME: string;
  NOME_NIVEL_ACESSO?: string;
  NOME_SETOR?: string;
  Telefone?: string;
  modulos?: ModulosResponseGet;
  param?: string;
  privarDados?: boolean;
  token?: string;
}

export function ModalRegisterSectorFunction({
  isOpen,
  titleProps,
  onDataReturn = (e: ResponsePost) => {
    return e;
  },
  onRequestClose,
}: ModalProps): JSX.Element {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState('');

  async function handleCreateNewSectorFunction(
    event: FormEvent,
  ): Promise<void> {
    event.preventDefault();

    setLoading(true);

    const endPoint =
      titleProps === 'setor'
        ? '/setor/createSetor'
        : '/nivelAcesso/createNivelAcessoFuncao';
    await api
      .post(`${endPoint}`, {
        nome: name,
      })
      .then((res) => {
        setLoading(false);
        onDataReturn(res.data);

        addToast({
          title: 'Sucesso!',
          description: `O ${titleProps} foi cadastrado com sucesso.`,
          type: 'success',
        });

        onRequestClose();
      })
      .catch((err) => {
        setLoading(false);

        if (err.response.data.errors) {
          addToast({
            title: `Erro ao cadastrar ${titleProps}!`,
            description: `${err.response.data.errors}.`,
            type: 'error',
          });
        } else {
          addToast({
            title: `Erro ao cadastrar ${titleProps}!`,
            description: 'Ocorreu um erro, por favor tente novamente.',
            type: 'error',
          });
        }
      });

    setName('');
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <AiOutlineClose size={22} />
      </button>

      <Container onSubmit={handleCreateNewSectorFunction}>
        <h2>Cadastrar {titleProps}</h2>

        <input
          placeholder="Nome"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        <button type="submit">Cadastrar</button>
      </Container>

      {loading && <Loading />}
    </Modal>
  );
}
