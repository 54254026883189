import styled, { keyframes } from 'styled-components';
import Search from '../../../components/Search';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  height: auto;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  margin: 0px auto;
  margin-top: 30px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  animation: ${appearFromRight} 1s;

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background-color: transparent;
  }
`;

export const Aba = styled.div`
  width: 100%;
  display: flex;
`;

interface ContainerProps {
  cor: boolean;
}

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;

  button {
    height: 40px;
    background-color: ${(props) => (props.cor ? ' #f1f1f1' : '#a6a6a6')};
    color: #111111;
    border-width: 0px;
    min-width: 200px;
    border: 1px solid #a6a6a6;
    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const SearchCustom = styled(Search)`
  width: 200px !important;
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
  margin-right: 10px !important;
`;

export const ContainerSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
  background: #3e3946;
  padding: 20px;
  border-radius: 0.5rem;

  div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
    padding: 0px !important;
  }

  div {
    border-top-left-radius: 7px !important;
    border-bottom-left-radius: 7px !important;

    input {
      width: 200px !important;
      margin-right: 0 !important;
    }
  }
`;
