import React, { useState, useEffect, useMemo } from 'react';
import Pagination from 'react-bootstrap/Pagination';

import { ContainerItem } from './styles';

interface Paginate {
  total: number;
  itemsPerPage: number;
  currentPage: number;
  onPageChange: (arg: number) => void;
}

const PaginationComponent: React.FC<Paginate> = ({
  total = 0,
  itemsPerPage = 10,
  currentPage = 1,
  onPageChange,
}: Paginate) => {
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    if (total > 0 && itemsPerPage > 0) {
      if (itemsPerPage === 1) {
        setTotalPages(Math.ceil(total / total));
      } else {
        setTotalPages(Math.ceil(total / itemsPerPage));
      }
    }
  }, [total, itemsPerPage]);

  const paginationItems = useMemo(() => {
    const pages = [];

    for (let i = 1; i <= totalPages; i += 1) {
      if (!(currentPage > i + 3 || currentPage < i - 3)) {
        pages.push(
          <Pagination.Item
            key={i}
            active={i === currentPage}
            onClick={() => onPageChange(i)}
          >
            {i}
          </Pagination.Item>,
        );
      }
    }

    return pages;
  }, [totalPages, currentPage, onPageChange]);

  if (totalPages === 0) return null;

  return (
    <>
      <ContainerItem>
        <Pagination>{paginationItems}</Pagination>
      </ContainerItem>
    </>
  );
};

export default PaginationComponent;
