import React, { useRef, useCallback, useState } from 'react';
import { FormHandles } from '@unform/core';
import { AiFillCloseCircle, AiOutlineFieldNumber } from 'react-icons/ai';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { BsBuilding } from 'react-icons/bs';
import Input from '../Input';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  SignUpFormData,
  ResponseGet,
  UF,
} from '../../pages/Cadastros/CadastroFilial';
import { Loading } from '../Loading';
import { SelectCustom } from '../SelectCustom';

import {
  ContainerModal,
  Container,
  ButtonClose,
  Content,
  Title,
  ButtonSubmit,
  ContainerForm,
} from './styles';

interface ModalProps {
  data: ResponseGet;
  onClose(e: boolean): void;
}

export const EditFilialModal: React.FC<ModalProps> = ({
  data,
  onClose = (e: boolean): boolean => {
    return e;
  },
}: ModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const { addToast } = useToast();
  const [editNome, setEditNome] = useState(data?.NOME_EMPRESA);
  const [editNomeFantasia, setEditNomeFantasia] = useState(
    data?.NOME_FANTASIA_EMPRESA,
  );
  const [editCep, setEditCep] = useState(data?.CEP);
  const [editEndereco, setEditEndereco] = useState(data?.ENDERECO);
  const [editNumero, setEditNumero] = useState(data?.NUMERO);
  const [editBairro, setEditBairro] = useState(data?.BAIRRO);
  const [editCidade, setEditCidade] = useState(data?.CIDADE);
  const [editEstado, setEditEstado] = useState(data?.ESTADO);

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose(true);
      }
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    async (dataTemp: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome é obrigatório!'),
          nomeFantasia: Yup.string().required('Nome fantasia é obrigatório!'),
          endereco: Yup.string().required('Endereço é obrigatório!'),
          numero: Yup.string().required('Número é obrigatório!'),
          bairro: Yup.string().required('Bairro é obrigatório!'),
          cidade: Yup.string().required('Cidade é obrigatória!'),
          estado: Yup.string().required('Estado é obrigatório!'),
          cep: Yup.string().required('CEP é obrigatório!'),
        });

        await schema.validate(dataTemp, {
          abortEarly: false,
        });

        setShowLoading(true);
        api
          .put(`/filiais/updateFiliais`, {
            CODIGO_FILIAL: data.CODIGO_FILIAL,
            CNPJ: data.CNPJ,
            NOME_EMPRESA: editNome,
            NOME_FANTASIA_EMPRESA: editNomeFantasia,
            ENDERECO: editEndereco,
            NUMERO: editNumero,
            BAIRRO: editBairro,
            CIDADE: editCidade,
            ESTADO: editEstado,
            CEP: editCep,
          })
          .then(() => {
            setShowLoading(false);
            addToast({
              type: 'success',
              title: 'Filial editada!',
              description:
                'A filial foi editada com sucesso e já pode ser visualizada na listagem.',
            });

            setTimeout(() => {
              history.go(0);
            }, 1500);
          })
          .catch(() => {
            setShowLoading(false);
            addToast({
              type: 'error',
              title: 'Erro na edição',
              description:
                'Ocorreu um erro ao editar a filial, tente novamente.',
            });
          });
      } catch (err) {
        setShowLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description:
              'Existem campos obrigatórios não preenchidos, favor verificar.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description: 'Ocorreu um erro ao editar a filial, tente novamente.',
          });
        }
      }
    },
    [
      addToast,
      data.CNPJ,
      data.CODIGO_FILIAL,
      editBairro,
      editCep,
      editCidade,
      editEndereco,
      editEstado,
      editNome,
      editNomeFantasia,
      editNumero,
      history,
    ],
  );

  return (
    <ContainerModal id="modal" onClick={handleOutSideClick}>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Title>Editar filial</Title>
          <ContainerForm>
            <Content>
              <Input
                name="nome"
                icon={BsBuilding}
                widthProps="49%"
                placeholder="Nome"
                placeholderProps="Nome"
                editProps
                tooltip={false}
                value={editNome}
                onValue={(value) => setEditNome(value)}
              />
              <Input
                name="nomeFantasia"
                widthProps="49%"
                icon={BsBuilding}
                placeholder="Nome fantasia"
                placeholderProps="Nome fantasia"
                editProps
                tooltip={false}
                value={editNomeFantasia}
                onValue={(value) => setEditNomeFantasia(value)}
              />
            </Content>
            <Content>
              <Input
                name="endereco"
                icon={HiOutlineLocationMarker}
                widthProps="49%"
                placeholder="Endereço"
                placeholderProps="Endereço"
                editProps
                tooltip={false}
                value={editEndereco}
                onValue={(value) => setEditEndereco(value)}
              />
              <Input
                name="numero"
                type="number"
                widthProps="49%"
                icon={AiOutlineFieldNumber}
                placeholder="Número"
                placeholderProps="Número"
                editProps
                tooltip={false}
                value={editNumero}
                onValue={(value) => setEditNumero(value)}
              />
            </Content>
            <Content>
              <Input
                name="bairro"
                icon={HiOutlineLocationMarker}
                widthProps="49%"
                placeholder="Bairro"
                placeholderProps="Bairro"
                editProps
                tooltip={false}
                value={editBairro}
                onValue={(value) => setEditBairro(value)}
              />
              <Input
                name="cidade"
                icon={HiOutlineLocationMarker}
                widthProps="49%"
                placeholder="Cidade"
                placeholderProps="Cidade"
                editProps
                tooltip={false}
                value={editCidade}
                onValue={(value) => setEditCidade(value)}
              />
            </Content>
            <Content>
              <SelectCustom
                name="estado"
                icon={HiOutlineLocationMarker}
                widthProps="49%"
                defaultValue="Estado"
                optionsData={UF}
                value={editEstado}
                onValue={(value) => setEditEstado(value)}
              />
              <Input
                name="cep"
                icon={HiOutlineLocationMarker}
                placeholder="CEP"
                mask="cep"
                placeholderProps="CEP"
                editProps
                widthProps="49%"
                tooltip={false}
                value={editCep}
                onValue={(value) => setEditCep(value)}
              />
            </Content>
          </ContainerForm>
          <ButtonSubmit type="submit">Salvar</ButtonSubmit>
        </Form>
        <ButtonClose onClick={() => onClose(true)}>
          <AiFillCloseCircle size={28} />
        </ButtonClose>
      </Container>

      {showLoading && <Loading />}
    </ContainerModal>
  );
};
