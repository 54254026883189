import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import Search from '../../../components/Search';

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  height: auto;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  margin: 0px auto;
  margin-top: 30px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  animation: ${appearFromRight} 1s;

  &::-webkit-scrollbar {
    height: 9px;
    width: 9px;
    background-color: transparent;
  }
`;

export const SearchCustom = styled(Search)`
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
`;

export const ContainerSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 15px;
  background: #3e3946;
  padding: 20px;
  border-radius: 0.5rem;

  div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
    padding: 0px !important;
  }
  div {
    border-top-left-radius: 7px !important;
    border-bottom-left-radius: 7px !important;

    input {
      width: 200px !important;
      margin-right: 0 !important;
    }
  }
`;

export const ContainerCadastro = styled.div`
  overflow-x: hidden;

  .divTable {
    animation: ${appearFromRight} 1s;
  }
`;

export const ContainerTable = styled.div`
  width: auto;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 50vh;
  overflow-x: auto;
  border-radius: 0.25rem 0.25rem 0 0;
  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 10px 10px;
      background-color: #006600;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      z-index: 1;
      top: 0;
      white-space: nowrap;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      white-space: nowrap;

      label.ant-checkbox-wrapper {
        position: relative !important;
        z-index: 0 !important;
      }

      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }

      img {
        width: 64px;
        height: 64px;
        border-radius: 10px;
        background: #575062;
        border: 2px solid #439133;
      }

      form {
        display: flex;
        align-items: center;
        justify-content: center;
        animation: ${appearFromRight} 1s;

        div {
          border-radius: 10px 0 0 10px !important;
          padding: 10px !important;
        }

        button {
          background: #00b300;
          height: 48.8px;
          border-radius: 0 10px 10px 0;
          padding: 0 8px;
          transition: filter 0.2s;
          display: flex;
          align-items: center;
          justify-content: center;

          &:hover {
            filter: brightness(0.9);
          }
        }
      }
    }
    tr {
      cursor: pointer;
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #006600;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 15px 0 15px 0;
  height: auto;
  #divPaginacao {
    display: flex;
    justify-content: center;
    padding: 0 15px;
    width: 100%;
  }
  #divBtnTable {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      height: 100%;
      margin-left: 40px;

      & + span {
        margin: 0 40px;
      }
    }
  }
  .btn {
    border-radius: 0.25rem;
    width: 140px;
    height: 100%;
    background-color: #008f00;
    color: #fff;
    border: none;
    padding: 0 10px;
    font-weight: bold;
    transition: 0.2s;
    &:hover {
      background: ${shade(0.1, '#008f00')};
    }
  }
`;

export const ContainerSemResultados = styled.div`
  animation: ${appearFromRight} 1s;
  width: auto;
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
  background-color: #28262e;
  h2 {
    margin: 0;
    text-align: center;
  }
`;

export const ButtonEditEmail = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  border-radius: 0.25rem;
  background-color: #ff9900;
  color: #ffffff;
  border: none;
  padding: 0 10px;
  font-weight: bold;

  transition: 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;

export const ButtonResetPassword = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  border-radius: 0.25rem;
  background-color: #8080ff;
  color: #ffffff;
  border: none;
  padding: 0 10px;
  font-weight: bold;

  transition: 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
