import React, {
  SelectHTMLAttributes,
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import { useField } from '@unform/core';
import { IconBaseProps } from 'react-icons';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';

import { Container } from './styles';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  name: string;
  defaultValue: string;
  optionsData?: string[];
  widthProps?: string;
  returnType?: string;
  optionsDataAll?: any;
  icon?: React.ComponentType<IconBaseProps>;
  onValue?: (arg: string) => void;
}

export const SelectCustom: React.FC<SelectProps> = ({
  name,
  defaultValue,
  optionsData,
  widthProps = '100%',
  returnType,
  optionsDataAll,
  icon: Icon,
  onValue,
  ...rest
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isFilled, setIsFilled] = useState(false);
  const [valueChange, setValueChange] = useState('');
  const selectRef = useRef<HTMLSelectElement>(null);
  const { fieldName, error, registerField } = useField(name);
  const CustomTooltipEdit = withStyles((theme) => ({
    arrow: {
      color: '#3e3946',
    },
    tooltip: {
      backgroundColor: '#3e3946',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
    // Se for vazio será false se estiver preenchido será true.
    setIsFilled(!!selectRef.current?.value);
  }, []);

  const handleSelectChange = useCallback(
    (e) => {
      if (onValue) {
        onValue(e.target.value);
      }
      setValueChange(e.target.value);
    },
    [onValue],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  const handleSelectAny = useCallback((escala: any) => {
    let responseEscalasAux = '';
    if (escala.INTRA_JORNADA) {
      responseEscalasAux = `${escala.NOME} | Horários: ${
        escala.HORA_INICIAL
      } - ${escala.HORA_PAUSA} - ${escala.HORA_VOLTA} - ${
        escala.HORA_SAIDA
      } | Fim de semana: ${escala.CONSIDERAR_FINS_DE_SEMANA ? 'Sim' : 'Não'}`;
    } else {
      responseEscalasAux = `${escala.NOME} | Horários: ${
        escala.HORA_INICIAL
      } - ${escala.HORA_SAIDA} | Fim de semana: ${
        escala.CONSIDERAR_FINS_DE_SEMANA ? 'Sim' : 'Não'
      }`;
    }

    return responseEscalasAux;
  }, []);

  return (
    <Container
      isErrored={!!error}
      isFilled={isFilled}
      isFocused={isFocused}
      widthProps={widthProps}
    >
      {Icon && <Icon size={20} />}

      {!!returnType && returnType === 'id' ? (
        <select
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={selectRef}
          value={valueChange || ''}
          onChange={(eForChange) => {
            handleSelectChange(eForChange);
          }}
          {...rest}
        >
          {optionsDataAll.map((option: any) => (
            <option key={option.ID} value={option.ID}>
              {handleSelectAny(option)}
            </option>
          ))}
        </select>
      ) : (
        <select
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          ref={selectRef}
          value={valueChange || ''}
          onChange={(eForChange) => {
            handleSelectChange(eForChange);
          }}
          {...rest}
        >
          {!!optionsData &&
            optionsData.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
        </select>
      )}

      <CustomTooltipEdit title={defaultValue} arrow TransitionComponent={Zoom}>
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <AiOutlineQuestionCircle size="20" />
        </span>
      </CustomTooltipEdit>
    </Container>
  );
};
