import styled from 'styled-components';
import { shade } from 'polished';

interface ContainerProps {
  heightProps: string;
  filesProps?: boolean;
}

interface PreviewProps {
  src?: string;
}

export const Container = styled.ul<ContainerProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: ${(props) => (props.filesProps ? 'center' : 'flex-start')};
  margin-top: 20px;
  margin-bottom: 0;
  height: ${(props) => props.heightProps};
  overflow: auto;
  border-radius: 10px;
  padding: 10px;
  background: #6f677e;

  li {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-radius: 10px;
    background: ${shade(0.08, '#fff')};
    color: #444;

    div.divIcons {
      display: flex;
      align-items: center;

      a {
        display: flex;
      }
    }

    & + li {
      margin-top: 15px;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;

    strong {
      color: #000;
      padding-right: 5px;
    }

    span {
      font-size: 12px;
      color: #999;

      button {
        border: 0;
        background: transparent;
        color: #e57878;
        margin-left: 10px;
        cursor: pointer;
      }
    }
  }
`;

export const Preview = styled.div<PreviewProps>`
  margin-right: 10px;

  svg {
    margin-top: 5px;
  }
`;
