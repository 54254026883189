import styled, { keyframes } from 'styled-components';

const appearFromFront = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to{
    opacity: 0.2;
    transform: scale(1);
  }
`;

export const Container = styled.div`
  height: calc(100vh - 55px);
  display: flex;
  align-items: center;
  justify-content: center;

  header {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      max-width: 80%;
      opacity: 0.2;
      transition: opacity 0.3s;
      animation: ${appearFromFront} 1s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;
