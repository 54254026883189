import styled from 'styled-components';

interface ContainerProps {
  cor?: string;
}

export const Container = styled.div`
  flex: 1;
  max-width: auto;
  align-items: center;
  max-height: 100vh;
`;

export const ContainerOptions = styled.div`
  display: flex;
  width: 350px;
  justify-content: space-between;
  margin: 0px auto;
  margin-top: 40px;
  button {
    background-color: transparent;
    color: #fff;
    border: 0;
    &:hover {
      color: #b3b3b3;
    }
  }
  svg {
    height: 30px;
    width: 30px;
  }
  p {
    svg {
      align-self: center;
      margin-left: 3px;
      height: 15px;
      width: 15px;
    }
  }
`;
export const ContainerButtonCor = styled.div<ContainerProps>`
  display: flex;
  button {
    background-color: transparent;
    color: ${(props) => props.cor};
    border: 0;
    margin-left: 20px;
  }
`;

export const IconLista = styled.div<ContainerProps>`
  color: ${(props) => props.cor};
`;

export const ContainerTable = styled.div`
  width: 400px;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  max-height: 60vh;
  overflow-x: auto;
  table {
    width: 100%;
    margin: 10px auto;
    margin-top: 0px;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 30px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 16px;
      p {
        text-align: center;
        margin-bottom: 0px;
        width: 300px;
      }
      button {
        background-color: transparent;
        color: #ffffff;
        border: 0;
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerFormulario = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  input {
    width: 250px;
    height: 40px;
    border: none;
    border-radius: 5px;
    padding: 2px;
  }
  .buttonOptions {
    margin-left: 15px;
    background-color: #006600;
    color: #ffffff;
    border: none;
    height: 40px;
    width: 40px;
    padding: 5px;
    border-radius: 10px;
    &:hover {
      background-color: #008000;
    }
    svg {
      height: 30px;
      width: 20px;
    }
  }
`;

export const ContainerTableAdd = styled.div`
  width: 400px;
  align-items: center;
  justify-content: center;
  margin: 10px auto;
  table {
    width: 100%;
    margin: 10px auto;
    margin-top: 0px;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 30px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      height: 50px;
      justify-content: center;
      text-align: center;
      align-items: center;
    }
  }
`;
