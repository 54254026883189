export const maskCpf = (value: any) => {// eslint-disable-line
  return (
    value
      .replace(/\D/g, '')
      // substitui qualquer caracter que nao seja numero por nada
      .replace(/(\d{3})(\d)/, '$1.$2')
      // captura 2 grupos de numero o primeiro de 3 e o segundo de 1, apos capturar
      // o primeiro grupo ele adiciona um ponto antes do segundo grupo de numero
      .replace(/(\d{3})(\d)/, '$1.$2')
      .replace(/(\d{3})(\d{1,2})/, '$1-$2')
      .replace(/(-\d{2})\d+?$/, '$1')
  );
  // captura 2 numeros seguidos de um traço e não deixa ser digitado mais nada
};

export const maskMonthYear = (value: any) => {// eslint-disable-line
  // eslint-disable-line
  value = value.substr(0, 7);// eslint-disable-line
  return value.replace(/\D/g, '').replace(/(\d{2})(\d)/, '$1/$2');
};

export const maskDateOfBirth = (value: any) => {// eslint-disable-line
  value = value.substr(0, 10);// eslint-disable-line
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2');
};
export function isDate(date: any) {// eslint-disable-line
  const day = date.substr(0, 2);
  const math = date.substr(3, 2);
  const yaer = date.substr(6, 4);
  let check = true;

  day < 1 && (check = false);
  day > 31 && (check = false);

  math < 1 && (check = false);
  math > 12 && (check = false);

  yaer < 1940 && (check = false);

  return check;
  // var cargo = salario <= 1000 ? 'junior' : salario <= 5000 ? 'senior' : 'diretor';
}

export const maskTel = (tel: any) => {// eslint-disable-line
  tel = tel.substr(0, 16);// eslint-disable-line

  tel = tel // eslint-disable-line
    .replace(/\D/g, '')
    .replace(/^(\d)/, '($1')
    .replace(/(.{3})(\d)/, '$1)$2')
    .replace(/(.{3})(\d)/, '$1 $2')
    .replace(/(.{6})(\d)/, '$1 $2');
  if (tel.length == 12) {// eslint-disable-line
    tel = tel.replace(/(.{1})$/, '-$1');// eslint-disable-line
  } else if (tel.length == 13) {// eslint-disable-line
    tel = tel.replace(/(.{2})$/, '-$1');// eslint-disable-line
  } else if (tel.length == 14) {// eslint-disable-line
    tel = tel.replace(/(.{3})$/, '-$1');// eslint-disable-line
  } else if (tel.length == 15) {// eslint-disable-line
    tel = tel.replace(/(.{4})$/, '-$1');// eslint-disable-line
  }
  return tel;
};
export const maskCnpj = (cnpj: any) => {// eslint-disable-line
  const x = cnpj
    .replace(/\D/g, '')
    .match(/(\d{0,2})(\d{0,3})(\d{0,3})(\d{0,4})(\d{0,2})/);
  const cnpjTemp = !x[2]
    ? x[1]
    : `${x[1]}.${x[2]}.${x[3]}/${x[4]}${x[5] ? `-${x[5]}` : ''}`;

  return cnpjTemp;
};

export const maskCep = (cep: any) => {// eslint-disable-line
  cep = cep// eslint-disable-line
    .replace(/\D/g, '')
    .replace(/^(\d{2})(\d)/, '$1.$2')
    .replace(/\.(\d{3})(\d)/, '.$1-$2');

  return cep;
};

export const maskRegistration = (registration: string): string => {
  const registrationTemp = registration.replace(/\D/g, '');

  return registrationTemp;
};

export function isCpf(c: any) {// eslint-disable-line
  if ((c = c.replace(/[^\d]/g, '')).length != 11) return false;// eslint-disable-line

  if (c == '00000000000') return false;// eslint-disable-line

  let r;
  let s = 0;
  let i = 1;
  for (i = 1; i <= 9; i++) s += parseInt(c[i - 1]) * (11 - i);// eslint-disable-line

  r = (s * 10) % 11;

  if (r == 10 || r == 11) r = 0;// eslint-disable-line

  if (r != parseInt(c[9])) return false;// eslint-disable-line

  s = 0;

  for (i = 1; i <= 10; i++) s += parseInt(c[i - 1]) * (12 - i);// eslint-disable-line

  r = (s * 10) % 11;

  if (r == 10 || r == 11) r = 0;// eslint-disable-line

  if (r != parseInt(c[10])) return false;// eslint-disable-line

  return true;
}

export function moedaReal(num: any) {// eslint-disable-line
  let valor = num;

  valor += '';
  valor = Number(valor.replace(/[\D]+/g, ''));
  valor += '';
  valor = valor.replace(/([0-9]{2})$/g, ',$1');

  if (valor.length > 6) {
    valor = valor.replace(/([0-9]{3}),([0-9]{2}$)/g, '.$1,$2');
  }
  if (valor === 'NaN') {
    return '';
  }
  return valor;
}

export const Criptografar = (data: string): string => {
  let cpfCripto = '';

  data.split('').forEach((obj: string) => {
    switch (obj) {
      case '0':
        cpfCripto += 'Z9A';
        break;
      case '1':
        cpfCripto += 'Y8B';
        break;
      case '2':
        cpfCripto += 'X7C';
        break;
      case '3':
        cpfCripto += 'W6D';
        break;
      case '4':
        cpfCripto += 'V5E';
        break;
      case '5':
        cpfCripto += 'U4F';
        break;
      case '6':
        cpfCripto += 'T3G';
        break;
      case '7':
        cpfCripto += 'S2H';
        break;
      case '8':
        cpfCripto += 'R1I';
        break;
      case '9':
        cpfCripto += 'Q0J';
        break;
      default:
        console.log('Chegou no fim do switch case');
    }
  });
  return cpfCripto;
};

export const Descriptografar = (data: string): string => {
  let cpfDescript = '';
  const dataTemp =
    data !== null && data !== undefined ? data.match(/.{1,3}/g) : [];

  dataTemp !== null &&
    dataTemp.forEach((obj: string) => {
      switch (obj) {
        case 'Z9A':
          cpfDescript += '0';
          break;
        case 'Y8B':
          cpfDescript += '1';
          break;
        case 'X7C':
          cpfDescript += '2';
          break;
        case 'W6D':
          cpfDescript += '3';
          break;
        case 'V5E':
          cpfDescript += '4';
          break;
        case 'U4F':
          cpfDescript += '5';
          break;
        case 'T3G':
          cpfDescript += '6';
          break;
        case 'S2H':
          cpfDescript += '7';
          break;
        case 'R1I':
          cpfDescript += '8';
          break;
        case 'Q0J':
          cpfDescript += '9';
          break;
        default:
          console.log('Chegou no fim do switch case');
      }
    });

  return cpfDescript;
};
