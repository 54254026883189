/* eslint-disable no-nested-ternary */
import { DatePicker, Space } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { MdDateRange } from 'react-icons/md';
import { FiAlertCircle } from 'react-icons/fi';
import 'moment/locale/pt-br';
import locale from 'antd/es/date-picker/locale/pt_BR';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';

import { Container } from './styles';

interface DatePickerInterface {
  pickerProps?: 'week' | 'month' | 'quarter' | 'year';
  defaultValueProps?: string;
  placeholderProps: string;
  nameProps: string;
  validProps?: boolean;
  widthProps?: string;
  editProps?: boolean;
  dateFormatReturn(e: string): void;
}

const InputDate: React.FC<DatePickerInterface> = ({
  pickerProps,
  defaultValueProps,
  placeholderProps,
  nameProps,
  validProps,
  editProps = false,
  widthProps = '100%',
  dateFormatReturn = (e: string): string => {
    return e;
  },
}) => {
  const [dateValue, setDateValue] = useState(null);
  const [activeBorder, setActiveBorder] = useState(false);
  const [emptyField, setEmptyField] = useState(validProps);
  const CustomTooltipEdit = withStyles((theme) => ({
    arrow: {
      color: '#3e3946',
    },
    tooltip: {
      backgroundColor: '#3e3946',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  useEffect(() => {
    setEmptyField(validProps);
  }, [validProps]);

  const handleChangeDate = useCallback(
    (date, dateFormat) => {
      setDateValue(date);
      dateFormatReturn(dateFormat);
    },
    [dateFormatReturn],
  );

  return editProps ? (
    <CustomTooltipEdit
      title={placeholderProps}
      arrow
      TransitionComponent={Zoom}
    >
      <Container style={{ width: widthProps }}>
        <Space direction="vertical" style={{ width: '100%' }}>
          {pickerProps ? (
            <DatePicker
              name={nameProps}
              onChange={(e) => handleChangeDate(e, e?.format('YYYYMM'))}
              defaultValue={
                defaultValueProps ? moment(defaultValueProps) : undefined
              }
              picker={pickerProps}
              placeholder={placeholderProps}
              locale={locale}
              format={pickerProps === 'week' ? 'DD/MM/YYYY' : 'MM/YYYY'}
              size="large"
              bordered
              onFocus={() => setActiveBorder(true)}
              onBlur={() => setActiveBorder(false)}
              suffixIcon={
                emptyField ? (
                  <FiAlertCircle color="#c53030" />
                ) : (
                  <MdDateRange
                    color={
                      activeBorder || !!dateValue
                        ? '#439133'
                        : emptyField
                        ? '#c53030'
                        : '#665360'
                    }
                  />
                )
              }
              style={{
                width: '100%',
                height: 60,
                borderRadius: 10,
                background: '#232129',
                border: activeBorder
                  ? '2px solid #439133'
                  : emptyField
                  ? '2px solid #c53030'
                  : '2px solid #232129',
                boxShadow: 'none',
              }}
            />
          ) : (
            <DatePicker
              name={nameProps}
              onChange={(e) => handleChangeDate(e, e?.format('YYYY-MM-DD'))}
              defaultValue={
                defaultValueProps ? moment(defaultValueProps) : undefined
              }
              format="DD/MM/YYYY"
              placeholder={placeholderProps}
              locale={locale}
              size="large"
              onFocus={() => setActiveBorder(true)}
              onBlur={() => setActiveBorder(false)}
              suffixIcon={
                emptyField ? (
                  <FiAlertCircle color="#c53030" />
                ) : (
                  <MdDateRange
                    color={
                      activeBorder || !!dateValue
                        ? '#439133'
                        : emptyField
                        ? '#c53030'
                        : '#665360'
                    }
                  />
                )
              }
              style={{
                width: '100%',
                height: 60,
                borderRadius: 10,
                background: '#232129',
                border: activeBorder
                  ? '2px solid #439133'
                  : emptyField
                  ? '2px solid #c53030'
                  : '2px solid #232129',
                boxShadow: 'none',
              }}
            />
          )}
        </Space>
      </Container>
    </CustomTooltipEdit>
  ) : (
    <Container style={{ width: widthProps }}>
      <Space direction="vertical" style={{ width: '100%' }}>
        {pickerProps ? (
          <DatePicker
            name={nameProps}
            onChange={(e) => handleChangeDate(e, e?.format('YYYYMM'))}
            defaultValue={
              defaultValueProps ? moment(defaultValueProps) : undefined
            }
            picker={pickerProps}
            placeholder={placeholderProps}
            locale={locale}
            format={pickerProps === 'week' ? 'DD/MM/YYYY' : 'MM/YYYY'}
            size="large"
            bordered
            onFocus={() => setActiveBorder(true)}
            onBlur={() => setActiveBorder(false)}
            suffixIcon={
              emptyField ? (
                <FiAlertCircle color="#c53030" />
              ) : (
                <MdDateRange
                  color={
                    activeBorder || !!dateValue
                      ? '#439133'
                      : emptyField
                      ? '#c53030'
                      : '#665360'
                  }
                />
              )
            }
            style={{
              width: '100%',
              height: 60,
              borderRadius: 10,
              background: '#232129',
              border: activeBorder
                ? '2px solid #439133'
                : emptyField
                ? '2px solid #c53030'
                : '2px solid #232129',
              boxShadow: 'none',
            }}
          />
        ) : (
          <DatePicker
            name={nameProps}
            onChange={(e) => handleChangeDate(e, e?.format('YYYY-MM-DD'))}
            defaultValue={
              defaultValueProps ? moment(defaultValueProps) : undefined
            }
            format="DD/MM/YYYY"
            placeholder={placeholderProps}
            locale={locale}
            size="large"
            onFocus={() => setActiveBorder(true)}
            onBlur={() => setActiveBorder(false)}
            suffixIcon={
              emptyField ? (
                <FiAlertCircle color="#c53030" />
              ) : (
                <MdDateRange
                  color={
                    activeBorder || !!dateValue
                      ? '#439133'
                      : emptyField
                      ? '#c53030'
                      : '#665360'
                  }
                />
              )
            }
            style={{
              width: '100%',
              height: 60,
              borderRadius: 10,
              background: '#232129',
              border: activeBorder
                ? '2px solid #439133'
                : emptyField
                ? '2px solid #c53030'
                : '2px solid #232129',
              boxShadow: 'none',
            }}
          />
        )}
      </Space>
    </Container>
  );
};

export default InputDate;
