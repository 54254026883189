import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromFront = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0.7);
  }
  to{
    opacity: 1;
    transform: scale(1);
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 584px;
  max-width: 90%;
  max-height: 75%;
  padding: 25px 25px;
  background-color: #4a4554;
  border-radius: 5px;
  animation: ${appearFromFront} 300ms;

  form {
    width: 100%;
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 10px;
`;

export const ContainerForm = styled.div`
  width: 100%;
  height: 345px;
  overflow: auto;
  padding: 0 5px 0 0;
  margin: 25px 0;

  ${Content} {
    &:first-child {
      padding-top: 0;
    }
  }

  @media screen and (max-height: 800px) {
    height: 300px;
  }
  @media screen and (max-height: 700px) {
    height: 270px;
  }
  @media screen and (max-height: 600px) {
    height: 230px;
  }
  @media screen and (max-height: 550px) {
    height: 220px;
  }
  @media screen and (max-height: 530px) {
    height: 200px;
  }
  @media screen and (max-height: 500px) {
    height: 140px;
  }
  @media screen and (max-height: 450px) {
    height: 130px;
  }
  @media screen and (max-height: 400px) {
    height: 100px;
  }
`;

export const Title = styled.h2`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 5px 0;
  margin-bottom: 0;
  color: #f2f2f2;
  border-bottom: 4px solid #f2f2f2;
  font-size: 1.6rem;
`;

export const ButtonClose = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  top: -14px;
  right: -14px;
  transition: 0.2s;
  svg {
    background: #f2f2f2;
    border-radius: 15px;
    color: red;
    &:hover {
      color: ${shade(0.2, 'red')};
    }
  }
`;

export const ButtonSubmit = styled.button`
  width: 100%;
  //margin-top: 20px;
  height: 60px;
  border: none;
  border-radius: 5px;
  background: green;
  color: #ffffff;
  font-size: 1.2rem;
  transition: 0.2s;
  &:hover {
    filter: brightness(0.9);
  }
`;

export const ContainerLoading = styled.div`
  position: absolute;
  z-index: 2;
  left: 80px;
`;
