import styled from 'styled-components';
import { shade } from 'polished';

export const ContainerTh = styled.th`
  //max-width: 300px;
  font-family: 'Arial', sans-serif;
  font-size: 16px;
  text-align: center;

  div.divBtn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    button.btnBusca {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 7px 0 0 7px;
      border: 1px solid ${shade(0.3, '#006600')};
      background: ${shade(0.1, '#006600')};
      width: 100px;
      height: 30px;
      transition: background 0.2s;

      &:hover {
        background: ${shade(0.3, '#006600')};
      }
    }

    button.btnBusca2 {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0 7px 7px 0;
      border: 1px solid ${shade(0.3, '#006600')};
      background: ${shade(0.1, '#006600')};
      width: 100px;
      height: 30px;
      transition: background 0.2s;

      &:hover {
        background: ${shade(0.3, '#006600')};
      }
    }
  }

  button {
    background-color: transparent;
    border: none;
    color: #fff;
    margin: 0;

    /* display: flex;
    align-items: center;
    justify-content: center; */

    /* svg {
      margin-left: 5px;
      margin-bottom: -4px;
    } */
  }
`;
