import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import Search from '../../../components/Search';

interface ContainerProps {
  cor: boolean;
}

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  height: auto;
  flex: 1;
  width: 100%;
  align-items: center;
  justify-content: center;
  overflow-x: hidden;
  margin: 0px auto;
  margin-top: 30px;
  padding-bottom: 25px;
  padding-left: 30px;
  padding-right: 30px;
  animation: ${appearFromRight} 1s;
`;

export const Aba = styled.div`
  width: 100%;
  display: flex;
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;
  button {
    height: 40px;
    background-color: ${(props) => (props.cor ? ' #f1f1f1' : '#a6a6a6')};
    color: #111111;
    border-width: 0px;
    min-width: 180px;
    border: 1px solid #a6a6a6;
    &:hover {
      filter: brightness(1.1);
    }
  }
`;

export const ContainerCadastro = styled.div<ContainerProps>`
  width: 100%;
  margin: 0px auto;
  border-radius: 10px;
  .divTable {
    border-radius: 10px;
    animation: ${appearFromRight} 1s;
  }
`;

export const ContentForm = styled.div.attrs(() => ({
  className: 'divContentForm',
}))`
  display: flex;
  align-items: center;
  justify-content: space-between;

  & + .divContentForm {
    margin-top: 10px;
    margin-bottom: 10px;
  }
`;

export const AnimationContainer = styled.div`
  width: 100%;
  margin: 20px 0;
  border-radius: 10px;
  animation: ${appearFromRight} 1s;
  background: #4a4554;
  header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 10px 25px;
    width: 100%;
    height: 55px;
    border-radius: 10px 10px 0 0;
    background: #25222a;
  }
  h1 {
    margin: 0;
    font-size: 22px;
    font-family: Roboto, sans-serif;
  }
  form {
    flex: 1;
    margin-bottom: 5px;
    padding: 25px 25px;
    width: 100%;
    a {
      color: #f4ede8;
      display: block;
      margin-top: 14px;
      text-decoration: none;
      transition: color 0.2s;
      &:hover {
        color: ${shade(0.2, '#f4ede8')};
      }
    }
  }
`;

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 25px !important;

  button {
    & + button {
      background: #e67300;
      margin-left: 10px;
      line-height: 16px;

      svg {
        margin-right: 7px;
        margin-bottom: 5px;
      }

      &:hover {
        background-color: ${shade(0.2, '#e67300')} !important;
      }
    }
  }

  .divButtonCancelar {
    width: 100%;
    background-color: rgb(102, 0, 0);

    &:hover {
      background-color: ${shade(0.2, 'rgb(102, 0, 0)')};
    }
  }

  button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 14px;
    font-size: 18px;
    background-color: #439133;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    transition: 0.2s;

    &:hover {
      background-color: ${shade(0.2, '#439133')};
    }
  }
`;

export const ContainerTable = styled.div`
  width: auto;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 35vh;
  overflow-x: auto;
  border-radius: 0.25rem 0.25rem 0 0;
  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 10px 10px;
      background-color: #006600;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      z-index: 1;
      top: 0;
      white-space: nowrap;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      white-space: nowrap;

      label.ant-checkbox-wrapper {
        position: relative !important;
        z-index: 0 !important;
      }

      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      cursor: pointer;
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerPagination = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #006600;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 15px 0 15px 0;
  height: auto;
  #divPaginacao {
    display: flex;
    justify-content: center;
    padding: 0 15px;
    width: 100%;
  }
  #divBtnTable {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      height: 100%;
      margin-left: 40px;

      & + span {
        margin: 0 40px;
      }
    }
  }
  .btn {
    border-radius: 0.25rem;
    width: 140px;
    height: 100%;
    background-color: #008f00;
    color: #fff;
    border: none;
    padding: 0 10px;
    font-weight: bold;
    transition: 0.2s;
    &:hover {
      background: ${shade(0.1, '#008f00')};
    }
  }
`;

export const SearchCustom = styled(Search)`
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
`;

export const ContainerSearch = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
  background: #3e3946;
  padding: 20px;
  border-radius: 0.5rem;

  div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
    padding: 0px !important;
  }
  div {
    border-top-left-radius: 7px !important;
    border-bottom-left-radius: 7px !important;

    input {
      width: 200px !important;
      margin-right: 0 !important;
    }
  }
`;

export const ContainerSemResultados = styled.div`
  animation: ${appearFromRight} 1s;
  width: auto;
  margin-top: 20px;
  border-radius: 10px;
  padding: 10px;
  background-color: #28262e;
  h2 {
    margin: 0;
    text-align: center;
  }
`;

export const ButtonEdit = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 100%;
  border-radius: 0.25rem;
  background-color: #ff9900;
  color: #ffffff;
  border: none;
  padding: 0 10px;
  font-weight: bold;

  transition: 0.2s;

  &:hover {
    filter: brightness(0.9);
  }
`;
