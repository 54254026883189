import React, { SelectHTMLAttributes, useCallback, useState } from 'react';
import { Container, ContainerSelect } from './styles';

interface DropDownInterface {
  ID: number;
  NOME: string;
}

interface DadosInterface extends SelectHTMLAttributes<HTMLSelectElement> {
  objetoEnum: DropDownInterface[];
  onChangeItems: (arg: string) => void;
  minWidth?: number;
  label: string;
}

const DropDownAccessLevel: React.FC<DadosInterface> = ({
  onChangeItems,
  objetoEnum,
  minWidth,
  label,
}: DadosInterface) => {
  const [value, setValue] = useState('');

  const onChangeItem = useCallback(
    (valor: string) => {
      setValue(`${valor}`);
      onChangeItems(valor);
    },
    [onChangeItems],
  );

  return (
    <Container>
      <p>{label}</p>
      <ContainerSelect>
        <select
          style={{ minWidth }}
          value={value}
          onChange={(e) => {
            onChangeItem(e.target.value);
          }}
        >
          {objetoEnum.map((drop: DropDownInterface) => (
            <option key={drop.ID} value={drop.NOME}>
              {drop.NOME}
            </option>
          ))}
        </select>
      </ContainerSelect>
    </Container>
  );
};

export default DropDownAccessLevel;
