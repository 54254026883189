import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';
import signInBackGroundImg from '../../../assets/1631557411487.png';

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  /* Tem 100% da parte visível da tela que o usuário consegue ver */
  height: 100vh;
  /**Faz os items de dentro ficar todos um do lado do outro. */
  display: flex;
  /* Faz os items de dentro ter o mesmo tamanho, no caso, o tamanho de
  100vh */
  align-items: stretch;
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 700px;
  overflow: auto;
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  animation: ${appearFromLeft} 1s;

  img {
    /* margin-right: 30px; */
    height: 90px;
  }

  form {
    margin: 55px 0 35px 0;
    width: 340px;
    text-align: center;
  }

  > a {
    color: #ffffff;
    display: block;
    text-decoration: none;
    transition: color 0.2s;

    svg {
      margin-right: 16px;
    }

    &:hover {
      color: ${shade(0.2, '#f1f1f1')};
    }
  }
  /* Ele estiliza apenas o 'a' que estar dentro do content. Assim, ele não
  sobrescreve nenhuma estilização de quaisquer outro 'a' que esteja em outro
  componente. */
`;

export const Background = styled.div`
  flex: 1;
  background: url(${signInBackGroundImg}) no-repeat center;
  background-size: cover;
`;

export const ContainerAlert = styled.div`
  background: #232129;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  height: 40px;

  border: 2px solid #232129;

  display: flex;
  align-items: center;
  color: #cc7a00;
  border-color: #cc7a00;

  svg {
    margin-right: 16px;
  }
  p {
    font-size: 14px;
  }
`;
