import jsPDF from 'jspdf';
import { ResponseGet } from '../Interface/index';
import { maskCpf } from '../../../../components/Input/mask';

export const exportPdfGeral = (
  dados: ResponseGet[],
  nomeEmpresa: string,
  dataInicial: string,
  dataFinal: string,
): void => {
  // const filial = nomeEmpresa.split('- ');

  const doc = new jsPDF('landscape'); // eslint-disable-line
  doc.setFontSize(16);
  doc.setTextColor(0, 0, 0);
  doc.setFontType('bold');
  doc.text(`Registro de Ponto`, 147, 10, 'center');
  doc.setFontSize(12);
  doc.text(`Período: ${dataInicial} à ${dataFinal}`, 147, 15, 'center');
  // tamanho 207 / 294 tela a4
  let posY: number;
  let quebraLinha;
  let psY = 0;
  let contaLinha = 0;
  doc.setFontSize(10);
  posY = 20;
  posY += 5;
  // doc.text('Empresa', 10, posY, 'left');
  doc.text('Nome', 10, posY, 'left'); // 35
  doc.text('CPF', 90, posY, 'left'); // 80
  // doc.text('Contrato', 107, posY, 'left'); // 105
  // doc.text('Posto', 135, posY, 'left'); // 130
  doc.text('Ponto', 120, posY, 'left'); // 153
  // quebraLinha = doc.splitTextToSize(`Hora Extra`, 10); // 164
  doc.text(`Hora Extra`, 140, posY, 'left');
  doc.text('Falta', 180, posY, 'left'); // 175
  doc.text('Atraso', 200, posY, 'left'); // 186
  // doc.text('Férias', 196, posY, 'left'); // 194
  // quebraLinha = doc.splitTextToSize(`Afasta-mento`, 14); // 210
  // doc.text(quebraLinha, 208, posY - 2, { align: 'left', maxWidth: 13 });
  // quebraLinha = doc.splitTextToSize(`Local Divergente`, 20); // 226
  // doc.text(`Local Divergente`, 200, posY, 'left');
  // quebraLinha = doc.splitTextToSize(`Hora Divergente`, 20); // 250
  doc.text(`Hora Divergente`, 225, posY, 'left');
  doc.text('Abono', 271, posY, 'left'); // 271

  posY += 10;
  doc.setFontSize(8);
  contaLinha = 0;
  doc.setFontType('normal');
  dados.forEach((dado, index) => {
    psY = posY;
    // quebraLinha = doc.splitTextToSize(`${dado.NOME_CLIENTE}`, 40);
    // doc.text(quebraLinha, 10, psY, { align: 'left', maxWidth: 40 });

    // Descomentar

    // contaLinha = quebraLinha.length;
    // quebraLinha = doc.splitTextToSize(`${dado.NOME}`, 40);
    // doc.text(quebraLinha, 35, psY, { maxWidth: 40 });
    // if (quebraLinha.length > contaLinha) {
    //   contaLinha = quebraLinha.length;
    // }

    // doc.text(`${maskCpf(dado.CPFSEMFORMATO)}`, 80, posY, { maxWidth: 20 });
    // quebraLinha = doc.splitTextToSize(`${dado.CONTRATO}`, 20);
    // doc.text(quebraLinha, 105, psY, { maxWidth: 20 });
    // if (quebraLinha.length > contaLinha) {
    //   contaLinha = quebraLinha.length;
    // }
    // quebraLinha = doc.splitTextToSize(`${dado.POSTO}`, 20);
    // doc.text(quebraLinha, 130, psY, { maxWidth: 20 });
    // if (quebraLinha.length > contaLinha) {
    //   contaLinha = quebraLinha.length;
    // }
    doc.text(`${dado.NOME}`, 10, posY, 'left');
    doc.text(`${maskCpf(dado.CPFSEMFORMATO)}`, 90, posY, 'left');
    doc.text(`${dado.COM_PONTO ? 'Sim' : 'Não'}`, 120, posY, { maxWidth: 8 });
    doc.text(`${dado.EXTRA ? 'Sim' : 'Não'}`, 140, posY, { maxWidth: 8 });
    doc.text(`${dado.SEM_PONTO ? 'Sim' : 'Não'}`, 180, posY, { maxWidth: 8 });
    doc.text(`${dado.ATRASO ? 'Sim' : 'Não'}`, 200, posY, { maxWidth: 8 });
    // doc.text(`${dado.FERIAS ? 'Sim' : 'Não'}`, 199, posY, { maxWidth: 8 });
    // doc.text(`${dado.AFASTAMENTO ? 'Sim' : 'Não'}`, 210, posY, { maxWidth: 8 });
    // doc.text(`${dado.PONTO_DIVERGENTE ? 'Sim' : 'Não'}`, 200, posY);
    doc.text(`${dado.HORA_DIVERGENTE ? 'Sim' : 'Não'}`, 225, posY);
    doc.text(`${dado.ABONO ? 'Sim' : 'Não'}`, 271, posY);
    doc.setLineWidth(0.5);
    posY = 5 * contaLinha + posY;
    if (posY < 185 && index + 1 !== dados.length) {
      doc.line(7, posY - 4, 287, posY - 4);
    }
    if (posY >= 185) {
      if (index + 1 !== dados.length) {
        doc.rect(7, 19, 280, 9);
        doc.rect(7, 19, 280, posY - 5 * contaLinha - 4);
        doc.addPage();
        doc.setFontSize(16);
        doc.setTextColor(0, 0, 0);
        doc.setFontType('bold');
        doc.text(`Registro de Ponto`, 147, 10, 'center');
        doc.setFontSize(12);
        doc.text(`Período: ${dataInicial} à ${dataFinal}`, 147, 15, 'center');
        doc.setFontSize(10);
        // doc.text('Empresa', 10, 25, 'left');
        doc.text('Nome', 45, 25, 'left'); // 35
        doc.text('CPF', 85, 25, 'left'); // 80
        // doc.text('Contrato', 107, 25, 'left'); // 105
        // doc.text('Posto', 135, 25, 'left'); // 130
        doc.text('Ponto', 150, 25, 'left'); // 153
        quebraLinha = doc.splitTextToSize(`Hora Extra`, 10); // 164
        doc.text(quebraLinha, 163, 25 - 2, { align: 'left', maxWidth: 10 });
        doc.text('Falta', 173, 25, 'left'); // 175
        doc.text('Atraso', 183, 25, 'left'); // 186
        // doc.text('Férias', 196, 25, 'left'); // 194
        // quebraLinha = doc.splitTextToSize(`Afasta-mento`, 14); // 210
        // doc.text(quebraLinha, 208, 25 - 2, { align: 'left', maxWidth: 13 });
        quebraLinha = doc.splitTextToSize(`Local Divergente`, 20); // 226
        doc.text(quebraLinha, 224, 25 - 2, { align: 'left', maxWidth: 20 });
        quebraLinha = doc.splitTextToSize(`Hora Divergente`, 20); // 250
        doc.text(quebraLinha, 248, 25 - 2, { align: 'left', maxWidth: 20 });
        doc.text('Abono', 271, 25, 'left'); // 271
        posY = 35;
        doc.setFontType('normal');
        doc.setFontSize(8);
      }
      contaLinha = 0;
    }
  });
  doc.rect(7, 19, 280, 9);
  doc.rect(7, 19, 280, psY - 4);
  doc.setFontSize(16);
  doc.setTextColor(0, 0, 0);
  doc.setFontType('bold');
  doc.text(`Registro de Ponto`, 147, 10, 'center');
  doc.setFontSize(12);
  doc.text(`Período: ${dataInicial} à ${dataFinal}`, 147, 15, 'center');

  doc.save(`Registro_de_ponto_${dataInicial}_${dataFinal}.pdf`);
};
