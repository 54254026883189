// import React from 'react';
// import { Switch, Redirect } from 'react-router-dom';

// import Route from './Route';

// // INICIO
// import SignIn from '../pages/Inicio/SignIn';
// import SignUp from '../pages/Inicio/Signup';
// import ForgotPassword from '../pages/Inicio/ForgotPassword';
// import Dashboard from '../pages/Inicio/Dashboard';

// // PERFIL
// import Profile from '../pages/Perfil/Profile';

// //
// import ResetPassword from '../pages/Usuarios/ResetPassword';
// import RestorePassword from '../pages/Inicio/RestorePassword';

// // PONTO ELETRONICO
// import VisualizarPonto from '../pages/PontoEletronico/VisualizarPonto';
// import ViewPonto from '../pages/PontoEletronico/VFiltroPonto';

// // USUARIOS
// import ListagemUsuarios from '../pages/Usuarios/ListagemUsuarios';
// import ListagemUsuariosQrCode from '../pages/Usuarios/ListagemUsuariosQrCode';

// // CADASTROS
// import PreCadastroFuncionario from '../pages/Cadastros/PreCadastroFuncionario';
// import CadastroFilial from '../pages/Cadastros/CadastroFilial';
// import CadastroAbono from '../pages/Cadastros/CadastroAbono';
// import { Escala } from '../pages/Cadastros/Escala';

// // NIVEL DE ACESSO
// import UserAccess from '../pages/NivelDeAcesso/UserAccess';
// import UserAccessFilter from '../pages/NivelDeAcesso/UserAccessFilter';

// const Routes: React.FC = () => {
//   return (
//     <Switch>
//       <Route path="/" exact component={SignIn} />
//       <Route path="/signup" component={SignUp} />
//       <Route path="/forgot-password" component={ForgotPassword} />
//       <Route path="/reset-password/" component={ResetPassword} />
//       <Route path="/profile" component={Profile} isPrivate />
//       <Route path="/reset-password" component={ResetPassword} isPrivate />
//       <Route path="/dashboard" component={Dashboard} isPrivate />
//       <Route
//         path="/user-access-filter"
//         component={UserAccessFilter}
//         isPrivate
//       />
//       <Route path="/user-access" component={UserAccess} isPrivate />
//       <Route path="/restore-password" component={RestorePassword} isPrivate />
//       <Route
//         path="/pre-cadastro-funcionario"
//         component={PreCadastroFuncionario}
//         isPrivate
//       />
//       <Route path="/listagem-usuarios" component={ListagemUsuarios} isPrivate />
//       <Route path="/cadastro-filial" component={CadastroFilial} isPrivate />
//       <Route
//         path="/list-users-qrcode"
//         component={ListagemUsuariosQrCode}
//         isPrivate
//       />
//       <Route path="/view-ponto" component={ViewPonto} isPrivate />
//       <Route path="/visualizar-ponto" component={VisualizarPonto} isPrivate />
//       <Route path="/cadastro-abono" component={CadastroAbono} isPrivate />
//       <Route path="/escala" component={Escala} isPrivate />

//       <Redirect to="/" />
//     </Switch>
//   );
// };

// export default Routes;

import React, { useMemo } from 'react';
import { Switch, Redirect } from 'react-router-dom';
import { availableModules, ModulesInterface } from '../hooks/availableModules';

import Route from './Route';

import SignIn from '../pages/Inicio/SignIn';
import SignUp from '../pages/Inicio/Signup';
import Dashboard from '../pages/Inicio/Dashboard';
import ForgotPassword from '../pages/Inicio/ForgotPassword';
import Profile from '../pages/Perfil/Profile';
import ResetPassword from '../pages/Usuarios/ResetPassword';
// import AutoDeclaracaoDeSaude from '../pages/AutoDeclaracaoDeSaude';
// import AutoDeclaracaoDeSaudeAgradecimento from '../pages/AutoDeclaracaoDeSaudeAgradecimento';
// import AutoDeclaracaoAnalise from '../pages/AutoDeclaracaoAnalise';
import UserAccessFilter from '../pages/NivelDeAcesso/UserAccessFilter';
import RestorePassword from '../pages/Inicio/RestorePassword';
import UploadPost from '../pages/Cadastros/UploadPost';
import CadastroAdmissao from '../pages/Cadastros/CadastroAdmissao';
import ValidacaoDocumentosAdmissao from '../pages/Cadastros/ValidacaoDocumentoDeAdmissao';
import VisualizarPonto from '../pages/PontoEletronico/VisualizarPonto';
import ViewPonto from '../pages/PontoEletronico/VFiltroPonto';
import CadastroAbono from '../pages/Cadastros/CadastroAbono';
import ValidacaoDocumentosFuncionarios from '../pages/Cadastros/ValidacaoDocumentosDeFuncionarios';
import Ausencia from '../pages/Cadastros/Ausencia';
// import TermoConsentimento from '../pages/Termo';
import SolicitacaoVaga from '../pages/Cadastros/ValidacaoSolicitacaoVaga';
import ListagemUsuariosQrCode from '../pages/Usuarios/ListagemUsuariosQrCode';
// import DeclaracaoVacinacao from '../pages/DeclaracaoVacinacao';
import CadastroCargo from '../pages/Cadastros/CadastroCargo';
import AtestadosMedicos from '../pages/Cadastros/AtestadosMedicos';
import PreCadastroFuncionario from '../pages/Cadastros/PreCadastroFuncionario';
import ListagemUsuarios from '../pages/Usuarios/ListagemUsuarios';
import CadastroContraCheque from '../pages/Cadastros/CadastroContraCheque';
import CadastroFerias from '../pages/Cadastros/CadastroFerias';
import CadastroBeneficios from '../pages/Cadastros/CadastroBeneficios';
import CadastroDecimoTerceiro from '../pages/Cadastros/CadastroDecimoTerceiro';
import CadastroFilial from '../pages/Cadastros/CadastroFilial';
import UserAccess from '../pages/NivelDeAcesso/UserAccess';
import { Escala } from '../pages/Cadastros/Escala';
import { Agendamento } from '../pages/Cadastros/Agendamento';

const Routes: React.FC = () => {
  const { modules } = availableModules();

  // FALTA-PROGRAMADA
  const clientPlanScheduledAbsence = modules.length
    ? modules.find((data) => data.ID === 10)
    : false;

  // PRE-CADASTRO-ADMISSAO
  const clientPlanAdmissionPreRegistration = modules.length
    ? modules.find((data) => data.ID === 11)
    : false;

  // ATUALIZACAO-DOCUMENTOS
  const clientPlanUpdatingDocuments = modules.length
    ? modules.find((data) => data.ID === 12)
    : false;

  // ENVIO-ATESTADOS
  const clientPlanSendingCertificates = modules.length
    ? modules.find((data) => data.ID === 13)
    : false;

  // PONTO-ELETRONICO
  const clientPlanElectronicPoint = modules.length
    ? modules.find((data) => data.ID === 14)
    : false;

  return (
    <Switch>
      <Route path="/" exact component={SignIn} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      <Route path="/reset-password/" component={ResetPassword} />
      <Route path="/profile" component={Profile} isPrivate />
      <Route path="/reset-password" component={ResetPassword} isPrivate />
      <Route path="/dashboard" component={Dashboard} isPrivate />
      <Route
        path="/user-access-filter"
        component={UserAccessFilter}
        isPrivate
      />
      <Route path="/user-access" component={UserAccess} isPrivate />
      <Route path="/restore-password" component={RestorePassword} isPrivate />
      <Route path="/upload-post" component={UploadPost} isPrivate />
      <Route
        path="/pre-cadastro-funcionario"
        component={PreCadastroFuncionario}
        isPrivate
      />
      <Route path="/listagem-usuarios" component={ListagemUsuarios} isPrivate />
      <Route
        path="/cadastro-contra-cheque"
        component={CadastroContraCheque}
        isPrivate
      />
      <Route path="/cadastro-ferias" component={CadastroFerias} isPrivate />
      <Route
        path="/cadastro-beneficios"
        component={CadastroBeneficios}
        isPrivate
      />
      <Route
        path="/cadastro-decimo-terceiro"
        component={CadastroDecimoTerceiro}
        isPrivate
      />
      <Route path="/cadastro-filial" component={CadastroFilial} isPrivate />
      <Route
        path="/list-users-qrcode"
        component={ListagemUsuariosQrCode}
        isPrivate
      />

      {clientPlanScheduledAbsence && (
        <Route path="/visualizar-ausencia" component={Ausencia} isPrivate />
      )}

      {clientPlanAdmissionPreRegistration && (
        <Route
          path="/cadastro-admissao"
          component={CadastroAdmissao}
          isPrivate
        />
      )}

      {clientPlanAdmissionPreRegistration && (
        <Route path="/cadastro-cargo" component={CadastroCargo} isPrivate />
      )}

      {clientPlanAdmissionPreRegistration && (
        <Route
          path="/validacao-documentos-admissao"
          component={ValidacaoDocumentosAdmissao}
          isPrivate
        />
      )}

      {clientPlanAdmissionPreRegistration && (
        <Route path="/solicitacao-vaga" component={SolicitacaoVaga} isPrivate />
      )}

      {clientPlanUpdatingDocuments && (
        <Route
          path="/validacao-documentos-funcionarios"
          component={ValidacaoDocumentosFuncionarios}
          isPrivate
        />
      )}

      {clientPlanSendingCertificates && (
        <Route
          path="/visualizar-atestados-medicos"
          component={AtestadosMedicos}
          isPrivate
        />
      )}

      {clientPlanElectronicPoint && (
        <Route path="/escala" component={Escala} isPrivate />
      )}

      {clientPlanElectronicPoint && (
        <Route path="/agendamento" component={Agendamento} isPrivate />
      )}

      {clientPlanElectronicPoint && (
        <Route path="/view-ponto" component={ViewPonto} isPrivate />
      )}

      {clientPlanElectronicPoint && (
        <Route path="/visualizar-ponto" component={VisualizarPonto} isPrivate />
      )}

      {clientPlanElectronicPoint && (
        <Route path="/cadastro-abono" component={CadastroAbono} isPrivate />
      )}

      {/* PÁGINAS FORA DO ESCOPO DE PLANOS: */}
      {/* <Route
        path="/auto-declaracao-de-saude-agradecimento"
        component={AutoDeclaracaoDeSaudeAgradecimento}
        isPrivate
      /> */}
      {/* <Route
        path="/auto-declaracao-analise"
        component={AutoDeclaracaoAnalise}
        isPrivate
      /> */}
      {/* <Route
        path="/auto-declaracao-de-saude"
        component={AutoDeclaracaoDeSaude}
        isPrivate
      /> */}
      {/* <Route path="/termo-de-consentimento" component={TermoConsentimento} /> */}
      {/* <Route
        path="/declaracao-vacinacao"
        component={DeclaracaoVacinacao}
        isPrivate
      /> */}

      <Redirect to="/" />
    </Switch>
  );
};

export default Routes;
