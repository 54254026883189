import React, { useCallback, useState } from 'react';
import { FiWifiOff } from 'react-icons/fi';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useHistory } from 'react-router-dom';
import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
} from './styles';

interface ModalConexao {
  onClose?(): void;
}

const Modal: React.FC<ModalConexao> = ({
  onClose = () => {
    ('');
  },
}) => {
  const [conexao, setConexao] = useState(navigator.onLine);
  const history = useHistory();
  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        setConexao(true);
        onClose();
      }
    },
    [onClose],
  );

  return (
    <>
      <div>
        {!conexao && (
          <ContainerModal id="modal" onClick={(e) => handleOutSideClick(e)}>
            <Container>
              <ContainerButton>
                <button
                  type="button"
                  onClick={() => {
                    setConexao(true);
                    onClose();
                  }}
                >
                  <AiFillCloseCircle
                    style={{
                      color: '#b30000',
                      height: 30,
                      width: 30,
                      paddingTop: 5,
                      paddingRight: 5,
                    }}
                  />
                </button>
              </ContainerButton>
              <ContainerHeader>
                <FiWifiOff />
              </ContainerHeader>
              <ContainerHeaderDados>
                <p className="titulo">Sem Conexão</p>
              </ContainerHeaderDados>
              <ContainerHeaderDados>
                <p>Por favor, se conecte para ter acesso ao sistema.</p>
              </ContainerHeaderDados>
              <ContainerButtonAtualizar>
                <button type="button" onClick={() => history.go(0)}>
                  Recarregar
                </button>
              </ContainerButtonAtualizar>
            </Container>
          </ContainerModal>
        )}
      </div>
    </>
  );
};

export default Modal;
