import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { BsBuilding } from 'react-icons/bs';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import Input from '../Input';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  SignUpFormData,
  ResponseGet,
} from '../../pages/Cadastros/CadastroBeneficios';
import { Loading } from '../Loading';
import { ResponseGetFilial } from '../../pages/Cadastros/PreCadastroFuncionario';
import { SelectCustom } from '../SelectCustom';
import {
  findFilialCnpj,
  findFilialName,
} from '../../interfaces/functionsFilterFind';

import {
  ContainerModal,
  Container,
  ButtonClose,
  Content,
  Title,
  ButtonSubmit,
  ContainerForm,
} from './styles';

interface ModalProps {
  data: ResponseGet;
  filiais: ResponseGetFilial[];
  onClose(e: boolean): void;
}

export const EditBeneficiosModal: React.FC<ModalProps> = ({
  data,
  filiais,
  onClose = (e: boolean): boolean => {
    return e;
  },
}: ModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const { addToast } = useToast();
  // const [editNome, setEditNome] = useState(data?.NOME);
  // const [editMatricula, setEditMatricula] = useState(data?.MATRICULA);
  // const [editFuncao, setEditFuncao] = useState(data?.FUNCAO);
  const [editEmpresa, setEditEmpresa] = useState(data?.EMPRESA);
  const [editNomeEmpresa, setEditNomeEmpresa] = useState(data?.NOME_EMPRESA);
  // const [editCnpj, setEditCnpj] = useState(data?.CNPJ);
  // const [editDataAdmissao, setEditDataAdmissao] = useState(data?.DT_ADMISSAO);
  // const [editSituacao, setEditSituacao] = useState(data?.SITUACAO);
  const [editCodigoVale, setEditCodigoVale] = useState(
    data?.CODIGO_VALE.toString(),
  );
  const [editBeneficio, setEditBeneficio] = useState(data?.BENEFICIO);
  const [editValorBeneficio, setEditValorBeneficio] = useState(
    data?.VALOR_BENEFICIO.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    }),
  );
  // const [editDataAdmissaoValid, setEditDataAdmissaoValid] = useState(false);
  const [filiaisTemp, setFiliaisTemp] = useState<ResponseGetFilial[]>([]);

  useEffect(() => {
    const findDataFilial = filiais.find(
      (filial) => filial.CODIGO_FILIAL === editEmpresa,
    );

    findDataFilial === undefined
      ? setFiliaisTemp([
          ...filiais,
          {
            ATIVO: false,
            BAIRRO: 'INATIVO',
            CEP: 'INATIVO',
            CIDADE: 'INATIVO',
            CODIGO_FILIAL: editEmpresa,
            ENDERECO: 'INATIVO',
            ESTADO: 'INATIVO',
            ID: -1,
            NUMERO: 'INATIVO',
            NOME_EMPRESA: 'INATIVO',
            NOME_FANTASIA_EMPRESA: 'INATIVO',
            CNPJ: 'INATIVO',
          },
        ])
      : setFiliaisTemp([...filiais]);
  }, [editEmpresa, filiais]);

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose(true);
      }
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    async (dataTemp: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          empresa: Yup.string().required('Código da empresa é obrigatório!'),
          nomeEmpresa: Yup.string().required('Nome da empresa é obrigatório!'),
          codigoVale: Yup.string().required('Código do vale é obrigatório!'),
          beneficio: Yup.string().required('Benefício é obrigatório!'),
          valorBeneficio: Yup.string().required(
            'Valor do benefício é obrigatório!',
          ),
        });

        await schema.validate(dataTemp, {
          abortEarly: false,
        });

        setShowLoading(true);
        api
          .put(
            `/cadastroIndividual/updateBeneficios?periodo=${data.PERIODO}&cpf=${data.CPFSEMFORMATO}&codigoVale=${data.CODIGO_VALE}`,
            {
              EMPRESA: editEmpresa,
              NOME_EMPRESA: editNomeEmpresa,
              NOME: data.NOME,
              CNPJ: findFilialCnpj(filiaisTemp, editEmpresa),
              MATRICULA: data.MATRICULA,
              DT_ADMISSAO: data.DT_ADMISSAO,
              SITUACAO: data.SITUACAO,
              FUNCAO: data.FUNCAO,
              NOVO_CODIGO_VALE: Number(editCodigoVale),
              BENEFICIO: editBeneficio,
              VALOR_BENEFICIO: editValorBeneficio
                .replace('.', '')
                .replace(',', '.'),
            },
          )
          .then(() => {
            setShowLoading(false);
            addToast({
              type: 'success',
              title: 'Benefício editado!',
              description:
                'O benefício foi editado com sucesso e já pode ser visualizado na listagem.',
            });

            setTimeout(() => {
              history.go(0);
            }, 1500);
          })
          .catch(() => {
            setShowLoading(false);
            addToast({
              type: 'error',
              title: 'Erro na edição',
              description:
                'Ocorreu um erro ao editar o benefício, tente novamente.',
            });
          });
      } catch (err) {
        setShowLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro na edição',
            description:
              'Existem campos obrigatórios não preenchidos, favor verificar.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description:
              'Ocorreu um erro ao editar o benefício, tente novamente.',
          });
        }
      }
    },
    [
      addToast,
      data.CODIGO_VALE,
      data.CPFSEMFORMATO,
      data.DT_ADMISSAO,
      data.FUNCAO,
      data.MATRICULA,
      data.NOME,
      data.PERIODO,
      data.SITUACAO,
      editBeneficio,
      editCodigoVale,
      editEmpresa,
      editNomeEmpresa,
      editValorBeneficio,
      filiaisTemp,
      history,
    ],
  );

  return (
    <ContainerModal id="modal" onClick={handleOutSideClick}>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Title>Editar benefício</Title>
          <ContainerForm>
            <Content>
              <SelectCustom
                name="empresa"
                widthProps="49%"
                icon={BsBuilding}
                defaultValue="Código da empresa"
                value={editEmpresa}
                onValue={(value) => {
                  setEditEmpresa(value);
                  setEditNomeEmpresa(findFilialName(filiaisTemp, value));
                }}
                optionsData={filiaisTemp.map((filial) => {
                  return filial.CODIGO_FILIAL;
                })}
              />
              <Input
                name="nomeEmpresa"
                icon={BsBuilding}
                widthProps="49%"
                placeholder="Nome da empresa"
                placeholderProps="Nome da empresa"
                editProps
                tooltip={false}
                value={editNomeEmpresa}
                disabled
              />
            </Content>
            <Content>
              <Input
                name="codigoVale"
                icon={IoDocumentTextOutline}
                widthProps="49%"
                placeholder="Código do vale"
                placeholderProps="Código do vale"
                editProps
                type="number"
                tooltip={false}
                value={editCodigoVale}
                onValue={(value) => setEditCodigoVale(value)}
              />
              <SelectCustom
                name="beneficio"
                widthProps="49%"
                icon={IoDocumentTextOutline}
                defaultValue="Benefício"
                value={editBeneficio}
                onValue={(value) => setEditBeneficio(value)}
                optionsData={[
                  'VALE TRANSPORTE',
                  'VALE ALIMENTAÇÃO',
                  'CESTA BÁSICA',
                  'PLANO DE SAÚDE',
                ]}
              />
            </Content>
            <Content>
              <Input
                name="valorBeneficio"
                icon={FaRegMoneyBillAlt}
                placeholder="Valor do benefício"
                placeholderProps="Valor do benefício"
                mask="coin"
                editProps
                tooltip={false}
                value={editValorBeneficio}
                onValue={(value) => setEditValorBeneficio(value)}
              />
            </Content>
          </ContainerForm>
          <ButtonSubmit type="submit">Salvar</ButtonSubmit>
        </Form>
        <ButtonClose onClick={() => onClose(true)}>
          <AiFillCloseCircle size={28} />
        </ButtonClose>
      </Container>

      {showLoading && <Loading />}
    </ContainerModal>
  );
};
