import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  position: fixed;
  width: 100%;
  z-index: 2;
  overflow: hidden !important;
`;

export const Profile = styled.div`
  margin-bottom: 10px;
  padding: 0 0 15px 0;
  border-bottom: 1px solid #f1f1f1;

  a {
    text-decoration: none;

    img {
      width: 64px;
      height: 64px;
      border-radius: 50%;
      margin-bottom: 10px;
      border: 2px solid;
      border-color: #00cc00;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    justify-content: center;
    align-items: center;

    span {
      color: #fff;
      font-size: 16px;
    }

    strong {
      color: ${shade(0.2, '#fff')};
      font-size: 12px;
    }
  }
`;

export const ContainerButtonDrawer = styled.div`
  display: flex;
  height: 55px;
  background-color: #28262e;
  align-items: center;

  a {
    img {
      margin-left: 20px;
      height: 37px;
    }
  }

  button {
    height: 55px;
    margin-top: 3px;

    svg {
      color: #fff;
    }
  }
`;

export const ButtonSignOut = styled.div`
  margin-left: auto;
  margin-right: 20px;
  background-color: #660000;
  border-radius: 15px;
  border: 0px;
  padding-top: 4px;
  padding-left: 7px;
  padding-right: 7px;
  padding-bottom: 3px;
  transition: 0.2s;

  svg {
    color: #ffffff;
    margin-left: 0;
    margin-bottom: 0;
  }

  &:hover {
    background-color: ${shade(0.2, '#660000')};
    cursor: pointer;
  }
`;

export const ButtonSignOutf = styled.div`
  background-color: #009900;
  border-radius: 5px;
  border: 0px;
  margin-top: 25px;
  margin-bottom: 25px;
  padding: 5px;
  justify-content: center;
  align-content: center;
  justify-self: center;
  align-self: center;
  transition: transform 0.2s;
  cursor: pointer;
  display: flex;
  flex-direction: 'row';
  width: 80%;
  height: 40px;

  transition: background 0.2s;

  div {
    display: flex;
    flex-direction: 'row';
    margin-top: 5px;

    p {
      margin-left: 5px;
    }
  }

  &:hover {
    background: ${shade(0.2, '#009900')};
  }
`;
