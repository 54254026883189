import { ResponseGetUser } from '../index';

export function FilterUser(dataUsers: ResponseGetUser[], id: number): string {
  const userFind = dataUsers.find((user) => user.ID === id);

  return userFind ? userFind.NOME : '-';
}

export function FilterNameUser(
  dataUsers: ResponseGetUser[],
  name: string,
): number {
  const userFind = dataUsers.find((user) => user.NOME === name);

  return userFind ? userFind.ID : -1;
}
