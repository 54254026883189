/* eslint-disable no-nested-ternary */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import Swal from 'sweetalert2';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import DatePicker from 'react-datepicker';
import { FiCheckCircle, FiFileText, FiSave, FiXCircle } from 'react-icons/fi';
import { BsQuestionCircleFill } from 'react-icons/bs';
import { RiFileExcel2Line } from 'react-icons/ri';

import { useAuth } from '../../../hooks/auth';
import ModalConexao from '../../../components/ModalConexao';
import HeaderTable from '../../../components/HeaderTable';
import Search from '../../../components/Search';
import { Loading } from '../../../components/Loading';
import Tooltip from '../../../components/Tooltip';
import DropDown from '../../../components/DropDown';
import TableAtestados from './TableAtestados';
import { maskCpf } from '../../../components/Input/mask';
import {
  formatarData,
  formatarDataAnoMesDia,
  formatarMaxData,
} from '../../../components/Formulas/formulas';
import { AusenciaAtestado } from './interface/atestado';
import { headers } from './interface/headers';
import { FaltaApontamento } from './interface/faltaApontamento';
import api from '../../../services/api';

import {
  Container,
  ContainerTable,
  ButtonBorderIcon,
  ContainerSupSearch,
  ContainerSearch,
  ContainerExport,
  ButtonBorder,
  ContainerVisualizarVazio,
  ButtonStyle,
  SearchFilter,
  SearchContentRight,
} from './styles';

interface ResponseGetFilial {
  valor: string;
  id: number;
  codigo: string;
}

interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

const pageAccess = {
  ID: 13,
  NOME: 'ENVIO-ATESTADOS',
};

// const dropDownFilial = [
//   { valor: 'Nenhum', id: 0, codigo: '000000' },
//   { valor: '01 - Servnac Segurança', id: 1, codigo: '010101' },
//   { valor: '02 - Sol. Corporativas', id: 2, codigo: '020101' },
//   { valor: '03 - Sol. Empresarias', id: 3, codigo: '030101' },
//   { valor: '04 - Facilities', id: 4, codigo: '040101' },
//   { valor: '05 - TOK Segurança', id: 5, codigo: '050101' },
//   { valor: '06 - OAK SVNK Tecnologia', id: 6, codigo: '999999' },
// ];

const vencidos = 'Data final do atestado é anterior a data de hoje.';
const naoVencidos =
  'Data final do atestado é Igual ou Posterior a data de Hoje.';
const todosAlert = 'Todas as requisicões de atestado. Vencidos ou não.';

const AtestadosMedicos: React.FC = () => {
  const { user, modulos } = useAuth();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [responseData, setResponseData] = useState<AusenciaAtestado[]>([]);
  const [responseDataCopia, setResponseDataCopia] = useState<
    AusenciaAtestado[]
  >([]);

  // variaveis dos filtros
  const [filial, setFilial] = useState('');
  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [vencido, setVencido] = useState(false);
  const [todos, setTodos] = useState(true);
  const [ciente, setCiente] = useState(false);
  const [hasFichaMedica, setHasFichaMedica] = useState(true);

  const [dadoIcon, setDadoIcon] = useState(false);
  const [IdAusencia, setIdAusencia] = useState<number>(-1);
  const [maxDate, setMaxDate] = useState('');
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();
  const [dataInicialFilter, setDataInicialFilter] = useState('');
  const [dataFinalFilter, setDataFinalFilter] = useState('');
  const [dropDownFilial, setDropDownFilial] = useState<ResponseGetFilial[]>([]);
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });

  useMemo(() => {
    responseData.forEach((data: AusenciaAtestado) => {
      if (data.ID === IdAusencia) {
        // eslint-disable-next-line no-param-reassign
        data.APROVADO = dadoIcon;
      }
      return data;
    });
  }, [IdAusencia, dadoIcon, responseData]);

  const transfomaNomeToCodigo = useCallback(
    (value: string): string => {
      const data = dropDownFilial.filter((r) => r.valor === value);
      if (data.length > 0) {
        return data[0].codigo;
      }
      return '';
    },
    [dropDownFilial],
  );

  const listClienteAtestado = useMemo(() => {
    let computedResponses: AusenciaAtestado[] = [];
    computedResponses = responseData;
    const dateHoje = new Date();
    const codigoEmpresa = transfomaNomeToCodigo(filial);
    if (codigoEmpresa !== '000000' && codigoEmpresa !== '') {
      computedResponses = computedResponses.filter(
        (res: AusenciaAtestado) =>
          res.FILIAL !== null && res.FILIAL.includes(codigoEmpresa),
      );
    }
    if (dataInicialFilter && dataFinalFilter) {
      const dateInit = new Date(dataInicialFilter).getTime();
      const dateFim = new Date(dataFinalFilter).getTime();
      computedResponses = computedResponses.filter(
        (item: AusenciaAtestado) =>
          new Date(item.DATA_INICIO).getTime() <= dateFim &&
          new Date(item.DATA_FINAL).getTime() >= dateInit,
      );
    }

    if (ciente) {
      computedResponses = computedResponses.filter(
        (item) => item.APROVADO === true || item.APROVADO === false,
      );
    }
    if (!ciente) {
      computedResponses = computedResponses.filter(
        (item) => item.APROVADO === null,
      );
    }

    if (hasFichaMedica) {
      computedResponses = computedResponses.filter(
        (item) => !!item.FICHA_MEDICA,
      );
    }
    if (!hasFichaMedica) {
      computedResponses = computedResponses.filter(
        (item) => item.FICHA_MEDICA === null || item.FICHA_MEDICA === '',
      );
    }

    if (vencido) {
      computedResponses = computedResponses.filter((item) => {
        if (
          dateHoje.getFullYear() > new Date(item.DATA_FINAL).getFullYear() ||
          dateHoje.getMonth() > new Date(item.DATA_FINAL).getMonth() ||
          (dateHoje.getFullYear() === new Date(item.DATA_FINAL).getFullYear() &&
            dateHoje.getMonth() === new Date(item.DATA_FINAL).getMonth() &&
            dateHoje.getDate() > new Date(item.DATA_FINAL).getDate() + 1)
        ) {
          return item;
        }
        return null;
      });
    }
    if (!(vencido || todos)) {
      computedResponses = computedResponses.filter((item) => {
        if (
          dateHoje.getFullYear() <= new Date(item.DATA_FINAL).getFullYear() &&
          dateHoje.getMonth() <= new Date(item.DATA_FINAL).getMonth() &&
          dateHoje.getDate() <= new Date(item.DATA_FINAL).getDate() + 1
        ) {
          return item;
        }
        return null;
      });
    }
    if (search) {
      computedResponses = computedResponses.filter((item: AusenciaAtestado) =>
        item.NOME_FUNC.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((item: AusenciaAtestado) =>
        item.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }

    return computedResponses;
  }, [
    ciente,
    dataFinalFilter,
    dataInicialFilter,
    filial,
    hasFichaMedica,
    responseData,
    search,
    searchCPF,
    todos,
    transfomaNomeToCodigo,
    vencido,
  ]);

  // const handlePreencherMotivo = useCallback(
  //   (dado: string, dadosCadatrais: AusenciaAtestado) => {
  //     // eslint-disable-next-line no-param-reassign
  //     dadosCadatrais.MOTIVO_APROVACAO = dado;
  //   },
  //   [],
  // );

  function handlePreencherESalvarDados(): void {
    const arrayIdAtestados: number[] = [];
    const arrayAtestadosCiente: boolean[] = [];
    const arrayMotivo: string[] = [];
    let motivoVazio = false;
    let notPossuiFichaMedica = false;
    responseData.forEach((data: AusenciaAtestado) => {
      responseDataCopia.forEach((dados) => {
        if (
          data.APROVADO !== null &&
          (data.APROVADO !== dados.APROVADO ||
            data.MOTIVO_APROVACAO !== dados.MOTIVO_APROVACAO) &&
          data.ID === dados.ID &&
          data.CPF === dados.CPF
        ) {
          if (data.APROVADO !== null) {
            arrayIdAtestados.push(data.ID);
            arrayAtestadosCiente.push(data.APROVADO);
            if (
              (data.MOTIVO_APROVACAO === '' ||
                data.MOTIVO_APROVACAO === null) &&
              data.APROVADO !== true
            ) {
              motivoVazio = true;
            } else {
              arrayMotivo.push(data.MOTIVO_APROVACAO || '');
            }
            if (!data.FICHA_MEDICA && data.APROVADO === true) {
              notPossuiFichaMedica = true;
            }
          }
        }
      });
    });
    if (motivoVazio) {
      Swal.fire({
        icon: 'error',
        title: 'Preencha o Motivo!',
        showConfirmButton: false,
        timer: 1500,
      });
      motivoVazio = false;
      return;
    }
    if (arrayAtestadosCiente.length <= 0) {
      Swal.fire({
        icon: 'info',
        title: 'Sem dados a salvar',
        showConfirmButton: false,
        timer: 1500,
      });
      motivoVazio = false;
      return;
    }

    if (notPossuiFichaMedica) {
      Swal.fire({
        icon: 'info',
        title: 'Um dos atestados aceitos não possui ficha de atestado',
        showConfirmButton: false,
        timer: 1500,
      });
      return;
    }

    const dataRequest = {
      ID_ATESTADO: arrayIdAtestados,
      APROVADO: arrayAtestadosCiente,
      USER_NOME_APROVADOR: user.nome,
      USER_ID_APROVADOR: user.id,
      MOTIVO_APROVACAO: arrayMotivo,
      ABONO: arrayAtestadosCiente,
    };

    setLoading(true);

    api
      .post('/programacaoAusencia/aprovaAtestados', dataRequest)
      .then(() => {
        setLoading(false);
        Swal.fire({
          icon: 'success',
          title: 'Registro salvo com Sucesso!',
          showConfirmButton: false,
          timer: 1500,
        });
        history.go(0);
      })
      .catch(() => {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro, ao salvar Registro.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      });
  }

  function viewTableFaltaApont(faltas: FaltaApontamento[]): JSX.Element[] {
    return faltas.map((item, index) => (
      <div key={index.toString()}>
        {formatarDataAnoMesDia(item.DATA_MANUTENCAO)} - {item.DESC_MOTIVO_FALTA}
      </div>
    ));
  }

  const loadingData = useCallback(async () => {
    setLoading(true);
    await api
      .get(`/programacaoAusencia/showAtestados`)
      .then(async (response) => {
        const resp: AusenciaAtestado[] = response.data.Atestados;
        const idAtestado = resp.map((item) => item.ID);
        await api
          .post('/programacaoAusencia/checaAtestadoApontamento', {
            idAtestado,
          })
          .then((responseApont) => {
            const respApont = responseApont.data.atestadosApontamentos;
            const atestados = resp.map((item, index) => {
              return {
                ...item,
                FaltaApontamento: respApont[index],
              };
            });
            setResponseData(atestados);
            setResponseDataCopia(JSON.parse(JSON.stringify(atestados)));
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((e) => {
        console.log(e);
      });
    setLoading(false);
  }, []);

  const getFiliais = useCallback(() => {
    setLoading(true);

    api
      .get('filiais/showFiliais')
      .then((res) => {
        const dropDownFilialAux: ResponseGetFilial[] = [];

        dropDownFilialAux.push({
          valor: 'Nenhum',
          id: 0,
          codigo: '000000',
        });

        res.data.forEach((filialTemp: any) => {
          dropDownFilialAux.push({
            valor: `${filialTemp.CODIGO_FILIAL}`,
            id: filialTemp.ID,
            codigo: `${filialTemp.CODIGO_FILIAL}`,
          });
        });

        setDropDownFilial(dropDownFilialAux);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);

    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title: 'Você não tem permissão para acessar essa página!',
      });
    } else {
      loadingData();
      getFiliais();
    }
  }, [getFiliais, history, loadingData, modulos]);

  return (
    <>
      <ModalConexao />
      <Container>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <div>
            <ContainerSupSearch>
              <DropDown
                onChangeItems={(value: string) => {
                  setFilial(value);
                }}
                objetoEnum={dropDownFilial}
              />
              <div className="searchDate">
                <DatePicker
                  locale="pt-BR"
                  selected={dataInicial}
                  onChange={(date) => {
                    setDataInicialFilter(formatarData(date?.toLocaleString()));
                    setDataInicial(date);
                    setMaxDate(formatarMaxData(date?.toLocaleString()));
                  }}
                  selectsStart
                  startDate={dataInicial}
                  endDate={dataFinal}
                  dateFormat="P"
                  placeholderText="Data Inicial"
                />
              </div>
              <div className="searchDateFim">
                <DatePicker
                  locale="pt-BR"
                  selected={dataFinal}
                  onChange={(date) => {
                    setDataFinalFilter(formatarData(date?.toLocaleString()));
                    setDataFinal(date);
                  }}
                  selectsEnd
                  startDate={dataInicial}
                  endDate={dataFinal}
                  minDate={dataInicial}
                  maxDate={new Date(maxDate)}
                  dateFormat="P"
                  placeholderText="Data Final"
                />
              </div>
            </ContainerSupSearch>
            <ContainerSearch>
              <div className="searchNome">
                <Search
                  onSearch={(value: string) => {
                    setSearch(value);
                  }}
                  nomePlaceHolder="Buscar Nome"
                />
              </div>
              <div className="searchCpf">
                <Search
                  onSearch={(value: string) => {
                    setSearchCPF(value);
                  }}
                  nomePlaceHolder="Buscar CPF"
                />
              </div>
            </ContainerSearch>
          </div>
          <SearchContentRight>
            <SearchFilter style={{ marginBottom: '2px' }}>
              <div className="searchUp" style={{ marginRight: '2px' }}>
                <ButtonStyle
                  style={{ borderTopLeftRadius: 7 }}
                  cor={ciente}
                  onClick={() => setCiente(true)}
                >
                  Ciente
                </ButtonStyle>
                <ButtonStyle
                  style={{ borderTopRightRadius: 7 }}
                  cor={!ciente}
                  onClick={() => setCiente(false)}
                >
                  Pendente
                </ButtonStyle>
              </div>
              <div className="searchUp" style={{ marginLeft: '2px' }}>
                <ButtonStyle
                  style={{ borderTopLeftRadius: 7 }}
                  cor={hasFichaMedica}
                  onClick={() => setHasFichaMedica(true)}
                >
                  Possui ficha
                </ButtonStyle>
                <ButtonStyle
                  style={{ borderTopRightRadius: 7 }}
                  cor={!hasFichaMedica}
                  onClick={() => setHasFichaMedica(false)}
                >
                  Não possui ficha
                </ButtonStyle>
              </div>
            </SearchFilter>
            <SearchFilter>
              <ButtonStyle
                cor={vencido}
                type="button"
                onClick={() => {
                  setVencido(true);
                  setTodos(false);
                }}
              >
                <div style={{ display: 'flex', paddingTop: '7px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      title={`${vencidos}`}
                      className="Branco"
                      tamanho={260}
                      marginTopComponent={-17}
                      marginRightComponent={5}
                    >
                      <BsQuestionCircleFill
                        className="iconVencidos"
                        size={17}
                        style={{ marginLeft: -8 }}
                      />
                    </Tooltip>
                    <p
                      style={{
                        width: 75,
                        paddingTop: 7,
                      }}
                    >
                      Vencido
                    </p>
                  </div>
                </div>
              </ButtonStyle>
              <ButtonStyle
                cor={!(vencido || todos)}
                type="button"
                onClick={() => {
                  setVencido(false);
                  setTodos(false);
                }}
              >
                <div style={{ display: 'flex', paddingTop: '7px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      title={`${naoVencidos}`}
                      className="Branco"
                      tamanho={260}
                      marginTopComponent={-17}
                      marginRightComponent={5}
                    >
                      <BsQuestionCircleFill
                        className="iconVencidos"
                        size={17}
                        style={{ marginLeft: -8 }}
                      />
                    </Tooltip>
                    <p
                      style={{
                        width: 110,
                        paddingTop: 7,
                      }}
                    >
                      Não Vencido
                    </p>
                  </div>
                </div>
              </ButtonStyle>
              <ButtonStyle
                cor={todos}
                type="button"
                onClick={() => {
                  setTodos(true);
                  setVencido(false);
                }}
              >
                <div style={{ display: 'flex', paddingTop: '7px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                    }}
                  >
                    <Tooltip
                      title={`${todosAlert}`}
                      className="Branco"
                      tamanho={260}
                      marginTopComponent={-17}
                      marginRightComponent={5}
                    >
                      <BsQuestionCircleFill
                        className="iconVencidos"
                        size={17}
                        style={{ marginLeft: -8 }}
                      />
                    </Tooltip>
                    <p
                      style={{
                        width: 75,
                        paddingTop: 7,
                      }}
                    >
                      Todos
                    </p>
                  </div>
                </div>
              </ButtonStyle>
            </SearchFilter>
          </SearchContentRight>
        </div>
        <ContainerTable>
          <div style={{ display: 'none' }}>
            <TableAtestados dados={responseData} />
          </div>
          <table id="clientes">
            <HeaderTable headers={headers} />
            <tbody>
              {listClienteAtestado.map((item) => (
                <tr key={`${item.CPF}-${item.ID}`}>
                  <td style={{ minWidth: 200 }}>{item.NOME_FUNC}</td>
                  <td style={{ minWidth: 120 }}>{maskCpf(item.CPF)}</td>
                  <td style={{ minWidth: 60 }}>{item.FILIAL}</td>
                  <td style={{ minWidth: 60 }}>{item.MATRICULA}</td>
                  <td style={{ minWidth: 100 }}>
                    {item.FICHA_MEDICA ? item.FICHA_MEDICA : '-'}
                  </td>
                  <td>{item.FICHA_MEDICA ? 'SIM' : 'NÃO'}</td>
                  <td>{item.afastRAIS}</td>
                  <td>{item.codAusencia}</td>
                  <td>{item.ID}</td>
                  <td style={{ minWidth: 250 }}>
                    {item.FaltaApontamento.length !== 0
                      ? viewTableFaltaApont(item.FaltaApontamento)
                      : '-'}
                  </td>
                  <td>{item.ABONO ? 'SIM' : 'NÃO'}</td>
                  <td style={{ minWidth: 200 }}>{item.NOME_MEDICO}</td>
                  <td style={{ minWidth: 100 }}>{item.CRM}</td>
                  <td style={{ minWidth: 100 }}>{item.CIDGrup}</td>
                  <td style={{ minWidth: 100 }}>{item.CID}</td>
                  <td style={{ minWidth: 120 }}>{item.LOCAL_ATESTADO}</td>
                  <td style={{ minWidth: 100 }}>
                    {formatarDataAnoMesDia(item.DATA_INICIO)}
                  </td>
                  <td style={{ minWidth: 100 }}>
                    {formatarDataAnoMesDia(item.DATA_FINAL)}
                  </td>
                  <td>{item.QUANTIDADE_DIAS}</td>
                  <td style={{ minWidth: 60 }}>{item.VEZES_ALTERADO}</td>
                  <td style={{ width: 30 }}>
                    {item.IMAGEM_ORIGINAL_NAME === null ? (
                      '-'
                    ) : (
                      <a
                        href={item.IMAGEM_ORIGINAL_NAME}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FiFileText />
                      </a>
                    )}
                  </td>
                  <td>{item.ENVIADO_PROTHEUS ? 'SIM' : 'NÃO'}</td>
                  <td style={{ minWidth: 100 }}>
                    {item.ENVIADO_PROTHEUS === true ? (
                      'NÃO É MAIS POSSIVEL AVALIAR'
                    ) : accessUser.ESCRITA ? (
                      <>
                        <button
                          type="button"
                          onClick={() => {
                            setDadoIcon(false);
                            setIdAusencia(item.ID);
                          }}
                        >
                          {(item.APROVADO === null ||
                            item.APROVADO === true) && (
                            <FiXCircle
                              style={{
                                color: '#737373',
                                marginTop: -1,
                                marginLeft: -1,
                              }}
                              size={25}
                            />
                          )}
                          {!(
                            item.APROVADO === null || item.APROVADO === true
                          ) && (
                            <ButtonBorderIcon
                              style={{ backgroundColor: '#b30000' }}
                            >
                              <FiXCircle
                                style={{ color: '#f2f2f2' }}
                                size={25}
                              />
                            </ButtonBorderIcon>
                          )}
                        </button>
                        <button
                          type="button"
                          onClick={() => {
                            setDadoIcon(true);
                            setIdAusencia(item.ID);
                          }}
                        >
                          {item.APROVADO && (
                            <ButtonBorderIcon
                              style={{ backgroundColor: '#336600' }}
                            >
                              <FiCheckCircle
                                style={{ color: '#f2f2f2' }}
                                size={25}
                              />
                            </ButtonBorderIcon>
                          )}
                          {!item.APROVADO && (
                            <FiCheckCircle
                              style={{
                                color: '#737373',
                                marginTop: -1,
                                marginLeft: -1,
                              }}
                              size={25}
                            />
                          )}
                        </button>
                      </>
                    ) : (
                      <>
                        <button
                          type="button"
                          disabled
                          style={{ cursor: 'auto' }}
                        >
                          {(item.APROVADO === null ||
                            item.APROVADO === true) && (
                            <FiXCircle
                              style={{
                                color: '#737373',
                                marginTop: -1,
                                marginLeft: -1,
                              }}
                              size={25}
                            />
                          )}
                          {!(
                            item.APROVADO === null || item.APROVADO === true
                          ) && (
                            <ButtonBorderIcon
                              style={{ backgroundColor: '#b30000' }}
                            >
                              <FiXCircle
                                style={{ color: '#f2f2f2' }}
                                size={25}
                              />
                            </ButtonBorderIcon>
                          )}
                        </button>
                        <button
                          type="button"
                          disabled
                          style={{ cursor: 'auto' }}
                        >
                          {item.APROVADO && (
                            <ButtonBorderIcon
                              style={{ backgroundColor: '#336600' }}
                            >
                              <FiCheckCircle
                                style={{ color: '#f2f2f2' }}
                                size={25}
                              />
                            </ButtonBorderIcon>
                          )}
                          {!item.APROVADO && (
                            <FiCheckCircle
                              style={{
                                color: '#737373',
                                marginTop: -1,
                                marginLeft: -1,
                              }}
                              size={25}
                            />
                          )}
                        </button>
                      </>
                    )}
                  </td>
                  <td style={{ minWidth: 100 }}>
                    <textarea
                      rows={3}
                      maxLength={255}
                      style={{ resize: 'none', minWidth: 100, color: '#fff' }}
                      value={item.MOTIVO_APROVACAO}
                      disabled
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </ContainerTable>
      </Container>
      {!(responseData.length > 0) && (
        <ContainerVisualizarVazio>
          {ciente ? (
            <h3>Não existe registro de ausência ciente.</h3>
          ) : (
            <h3>Não existe registro de ausência pendente.</h3>
          )}
        </ContainerVisualizarVazio>
      )}
      {responseData.length > 0 && (
        <div
          style={{
            display: 'flex',
            width: '90%',
            margin: '0px auto',
            marginTop: 20,
          }}
        >
          <ContainerExport>
            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="AtestadosExcel"
              filename="Atestados"
              sheet="tablexls"
              buttonText={<RiFileExcel2Line />}
            />
          </ContainerExport>
          {accessUser.ESCRITA && (
            <ButtonBorder>
              <button
                type="button"
                onClick={() => {
                  handlePreencherESalvarDados();
                }}
              >
                <FiSave />
                Salvar
              </button>
            </ButtonBorder>
          )}
        </div>
      )}
      {loading && <Loading />}
    </>
  );
};

export default AtestadosMedicos;
