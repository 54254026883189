import styled from 'styled-components';

interface ContainerProps {
  cor?: boolean;
}

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: 'center';
  align-items: 'center';
`;

export const Container = styled.div`
  position: relative;
  width: 90%;
  height: 80%;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  margin: auto auto;
  p {
    margin-top: 20px;
    font-size: 16px;
    text-align: center;
  }
`;

export const ContainerButton = styled.div`
  width: auto;
  display: flex;
  button {
    border: transparent;
    background-color: transparent;
    padding: 2px;
    margin-left: auto;
  }
`;

export const ContainerHeaderDados = styled.div`
  display: flex;
  margin: 0px auto;
  justify-content: center;
  p {
    margin-top: 10px;
    font-size: 20px;
    word-wrap: break-word;
    padding: 0px 20px 0px 20px;
    text-align: center;
    font-weight: bold;
    font-family: Arial;
  }
`;

export const ContainerDados = styled.div`
  width: 100%;
  /* height: 70%;
  margin: 0px auto 5% auto; */
  justify-content: center;
  align-self: center;
  display: flex;
`;

export const ContainerButtonSalvar = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 70%;
  right: 10%;
  button {
    padding: 10px;
    background-color: #439133;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    min-width: 200px;
  }
`;

export const ContainerButtonCancelar = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  bottom: -20px;
  left: 10%;
  right: 70%;
  button {
    padding: 10px;
    background-color: #660000;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    min-width: 200px;
  }
`;

export const ContainerTable = styled.div`
  width: 90%;
  align-items: center;
  max-height: 60vh;
  overflow-x: auto;
  table {
    width: 100%;
    background-color: #323232;
    padding: 10px, 40px;
    margin: 0px auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 15px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      button {
        background-color: transparent;
        color: #111111;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
      span {
        margin: auto 0px;
        text-align: center;
        height: 25px;
      }
      .buttonAbonar {
        background-color: #439133;
        border-radius: 5px;
        padding: 8px;
        border: 1px;
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #bfbfbf;
      }
      &:nth-child(odd) {
        background-color: #a6a6a6;
      }
      &:hover {
        background-color: #cccccc;
      }
    }
    a {
      size: 25px;
      color: #111111;
    }
    .aComLink {
      text-decoration: normal;
    }
  }
  select {
    background-color: #f1f1f1;
    color: #439133;
    height: 25px;
    border-radius: 10px;
    border: none;
    justify-content: center;
    padding: 0px 10px;
  }
`;

export const ContainerFormulario = styled.div<ContainerProps>`
  button {
    border: none;
    svg {
      color: ${(props) => (props.cor ? '#006600' : '#111111')};
    }
  }
`;

export const ContainerInformation = styled.div`
  width: 90%;
  height: 40%;
  margin: 0px auto;
  margin-top: 15px;
  padding: 20px;
  background-color: #bfbfbf;
  overflow-x: auto;
  text-align: center;
  p {
    font-size: 20px;
    text-align: center;
  }
`;
