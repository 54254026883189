/* eslint-disable no-nested-ternary */
import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import {
  HiOutlineDocument,
  HiOutlineDocumentRemove,
  HiOutlineDocumentAdd,
} from 'react-icons/hi';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import HeaderTable from '../../../../components/HeaderTable';
import { ResponseGet, PageAccessUser } from '../../Escala';
import { ResponseGetEscalaAgendamento, ResponseGetUser } from '../index';
import { FilterEscala } from '../FunctionsFilter/filterEscala';
import { FilterUser } from '../FunctionsFilter/filterUser';
import Pagination from '../../../../components/Pagination';
import DropDownPagination from '../../../../components/DropDownPagination';
import { maskCpf } from '../../../../components/Input/mask';

import { ButtonEdit, ContainerPagination, ContainerTable } from './styles';

const dropDown = [
  { valor: '20', id: 20 },
  { valor: '40', id: 40 },
  { valor: '80', id: 80 },
  { valor: '160', id: 160 },
  { valor: 'Todos', id: 1 },
];

const headers = [
  { name: 'Nome', field: 'Id_User', sortable: true },
  { name: 'CPF', field: 'CPF', sortable: true },
  { name: 'Filial', field: 'FILIAL', sortable: true },
  { name: 'Escala', field: 'Id_Dom_Escala', sortable: true },
  {
    name: 'Data inicial',
    field: 'AGENDA_DATA_INICIAL',
    sortable: true,
  },
  { name: 'Data final', field: 'AGENDA_DATA_FINAL', sortable: true },
  {
    name: 'Competência inicial',
    field: 'AGENDA_COMPETENCIA_INICIAL',
    sortable: true,
  },
  {
    name: 'Competência final',
    field: 'AGENDA_COMPETENCIA_FINAL',
    sortable: true,
  },
  { name: 'Status cadastral', field: 'ATIVO', sortable: true },
];

interface TableProps {
  responseDataProp: ResponseGetEscalaAgendamento[];
  responseUsersProp: ResponseGetUser[];
  responseEscalaProp: ResponseGet[];
  totalItemsProp: number;
  ITEMS_PER_PAGE_Prop: number;
  currentPageProp: number;
  accessUserProp: PageAccessUser;
  handleConfirmChangeStatusUserProp: () => void;
  setCurrentPageProp: (e: number) => void;
  setITEMS_PER_PAGE_Prop: (e: number) => void;
  setSortingProp: (field: string, order: string) => void;
  trTableSelectedProp: (e: ResponseGetEscalaAgendamento | undefined) => void;
}

export const Tables: React.FC<TableProps> = ({
  responseDataProp,
  responseUsersProp,
  responseEscalaProp,
  totalItemsProp,
  ITEMS_PER_PAGE_Prop,
  currentPageProp,
  accessUserProp,
  handleConfirmChangeStatusUserProp,
  setCurrentPageProp = (e: number) => {
    return e;
  },
  setITEMS_PER_PAGE_Prop = (e: number) => {
    return e;
  },
  setSortingProp = (field: string, order: string) => {
    return { field, order };
  },
  trTableSelectedProp = (e: ResponseGetEscalaAgendamento | undefined) => {
    return e;
  },
}) => {
  const [trTableSelected, setTrTableSelected] = useState<
    ResponseGetEscalaAgendamento
  >();

  useEffect(() => {
    trTableSelectedProp(trTableSelected);
  }, [trTableSelected, trTableSelectedProp]);

  const CustomTooltipUpdateStatus = withStyles((theme) => ({
    arrow: {
      color: !trTableSelected
        ? '#009900'
        : trTableSelected.ATIVO
        ? '#ff3333'
        : '#8080ff',
    },
    tooltip: {
      backgroundColor: !trTableSelected
        ? '#009900'
        : trTableSelected.ATIVO
        ? '#ff3333'
        : '#8080ff',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  return (
    <div className="divTable">
      <ContainerTable>
        <table>
          <HeaderTable
            headers={headers}
            onSorting={(field: string, order: string) =>
              setSortingProp(field, order)}
          />
          <tbody>
            {responseDataProp.map((res) => (
              <tr
                key={res.ID}
                onClick={() =>
                  trTableSelected === res
                    ? setTrTableSelected(undefined)
                    : setTrTableSelected(res)}
                style={{
                  background:
                    trTableSelected && trTableSelected.ID === res.ID
                      ? '#737373'
                      : '',
                }}
              >
                <td>{res.nome_user ? res.nome_user : '-'}</td>
                <td>{res.CPF ? maskCpf(res.CPF) : '-'}</td>
                <td>{res.FILIAL ? res.FILIAL : '-'}</td>
                <td>
                  {res.Id_Dom_Escala
                    ? FilterEscala(responseEscalaProp, res.Id_Dom_Escala)
                    : '-'}
                </td>
                <td>
                  {res.AGENDA_DATA_INICIAL
                    ? moment(res.AGENDA_DATA_INICIAL).format('DD/MM/YYYY')
                    : '-'}
                </td>
                <td>
                  {res.AGENDA_DATA_FINAL
                    ? moment(res.AGENDA_DATA_FINAL).format('DD/MM/YYYY')
                    : '-'}
                </td>
                <td>
                  {res.AGENDA_COMPETENCIA_INICIAL
                    ? moment(res.AGENDA_COMPETENCIA_INICIAL).format('MM/YYYY')
                    : '-'}
                </td>
                <td>
                  {res.AGENDA_COMPETENCIA_FINAL
                    ? moment(res.AGENDA_COMPETENCIA_FINAL).format('MM/YYYY')
                    : '-'}
                </td>
                <td style={{ padding: 5 }}>
                  <div
                    style={{
                      background: `${
                        !res.ATIVO ? 'rgb(255, 51, 51)' : 'rgb(128, 128, 255)'
                      }`,
                      width: '100%',
                      height: '100%',
                      borderRadius: 4,
                    }}
                  >
                    {!!res.ATIVO && 'Ativo'}
                    {!res.ATIVO && 'Inativo'}
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        <table id="tableListagemAgendas" style={{ display: 'none' }}>
          <HeaderTable
            headers={headers}
            onSorting={(field: string, order: string) =>
              setSortingProp(field, order)}
          />
          <tbody>
            {responseDataProp.map((res) => (
              <tr key={res.ID}>
                <td>
                  {res.Id_User
                    ? FilterUser(responseUsersProp, res.Id_User)
                    : '-'}
                </td>
                <td>{res.CPF ? res.CPF : '-'}</td>
                <td>{res.FILIAL ? res.FILIAL : '-'}</td>
                <td>
                  {res.Id_Dom_Escala
                    ? FilterEscala(responseEscalaProp, res.Id_Dom_Escala)
                    : '-'}
                </td>
                <td>
                  {res.AGENDA_DATA_INICIAL
                    ? moment(res.AGENDA_DATA_INICIAL).format('DD/MM/YYYY')
                    : '-'}
                </td>
                <td>
                  {res.AGENDA_DATA_FINAL
                    ? moment(res.AGENDA_DATA_FINAL).format('DD/MM/YYYY')
                    : '-'}
                </td>
                <td>
                  {res.AGENDA_COMPETENCIA_INICIAL
                    ? moment(res.AGENDA_COMPETENCIA_INICIAL).format('MM/YYYY')
                    : '-'}
                </td>
                <td>
                  {res.AGENDA_COMPETENCIA_FINAL
                    ? moment(res.AGENDA_COMPETENCIA_FINAL).format('MM/YYYY')
                    : '-'}
                </td>
                <td>
                  {!!res.ATIVO && 'Ativo'}
                  {!res.ATIVO && 'Inativo'}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTable>

      <ContainerPagination>
        {responseDataProp.length > 0 && (
          <div id="divPaginacao">
            <Pagination
              total={totalItemsProp}
              itemsPerPage={ITEMS_PER_PAGE_Prop}
              currentPage={currentPageProp}
              onPageChange={(page: number) => {
                setCurrentPageProp(page);
              }}
            />
            <DropDownPagination
              onChangeItems={(value: string) => {
                setCurrentPageProp(1);
                if (value === 'Todos') {
                  setITEMS_PER_PAGE_Prop(1);
                } else {
                  setITEMS_PER_PAGE_Prop(Number(value));
                }
              }}
              objetoEnum={dropDown}
              minWidth={30}
            />

            <div id="divBtnTable">
              {accessUserProp.ESCRITA && (
                <>
                  {/* <CustomTooltipEdit
                     title={
                       !trTableSelected
                         ? 'Selecione uma escala primeiro'
                         : 'Editar'
                     }
                     arrow
                     TransitionComponent={Zoom}
                   >
                     <span>
                       <ButtonEdit
                         disabled={!trTableSelected}
                         style={{
                           cursor: !trTableSelected
                             ? 'not-allowed'
                             : '',
                           background: !trTableSelected
                             ? '#009900'
                             : '',
                           filter: !trTableSelected
                             ? 'brightness(1)'
                             : '',
                         }}
                         onClick={handleAlertEdit}
                       >
                         <AiFillEdit size={26} />
                       </ButtonEdit>
                     </span>
                   </CustomTooltipEdit> */}

                  <CustomTooltipUpdateStatus
                    title={
                      !trTableSelected
                        ? 'Selecione uma agenda primeiro'
                        : trTableSelected.ATIVO
                        ? 'Inativar agenda'
                        : 'Ativar agenda'
                    }
                    arrow
                    TransitionComponent={Zoom}
                  >
                    <span>
                      <ButtonEdit
                        disabled={!trTableSelected}
                        style={{
                          cursor: !trTableSelected ? 'not-allowed' : '',
                          background: !trTableSelected
                            ? '#009900'
                            : trTableSelected.ATIVO
                            ? '#ff3333'
                            : '#8080ff',
                          filter: !trTableSelected ? 'brightness(1)' : '',
                        }}
                        onClick={() =>
                          trTableSelected && handleConfirmChangeStatusUserProp()}
                      >
                        {!trTableSelected ? (
                          <HiOutlineDocument size={26} />
                        ) : trTableSelected.ATIVO ? (
                          <HiOutlineDocumentRemove size={26} />
                        ) : (
                          <HiOutlineDocumentAdd size={26} />
                        )}
                      </ButtonEdit>
                    </span>
                  </CustomTooltipUpdateStatus>
                </>
              )}

              <ReactHTMLTableToExcel
                id="export-excel"
                className="btn"
                table="tableListagemAgendas"
                filename={`listagem-agendas-funcionarios-${moment().format(
                  'DD-MM-YYYY',
                )}`}
                sheet="tablexls"
                buttonText="Exportar Excel"
              />
            </div>
          </div>
        )}
      </ContainerPagination>
    </div>
  );
};
