import axios from 'axios';

const api = axios.create({
  baseURL: 'https://server.api-connect-rh.org/', // producao
  // baseURL: 'http://157.230.232.220', // igor
});

export const apiSesmt = axios.create({
  baseURL: 'https://api.appfluigservnac.com/', // producao
  // baseURL: 'https://e5671d929967.ngrok.io/', // igor
});

export default api;
