/* eslint-disable no-nested-ternary */
import React, {
  useRef,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import { FiUser, FiUserCheck, FiUserX } from 'react-icons/fi';
import {
  AiOutlineUserAdd,
  AiOutlineDownload,
  AiFillEdit,
} from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { BsBuilding } from 'react-icons/bs';
import { FaFileCsv } from 'react-icons/fa';
import ModalConexao from '../../../components/ModalConexao';
import api from '../../../services/api';
import FileList from '../../../components/FileList';
import Upload from '../../../components/Upload';
import DropDownPagination from '../../../components/DropDownPagination';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import Input from '../../../components/Input';
import { Loading } from '../../../components/Loading';
import getValidationErrors from '../../../utils/getValidationErrors';
import { maskCpf, maskCnpj } from '../../../components/Input/mask';
import InputDate from '../../../components/DatePicker';
import { useToast } from '../../../hooks/toast';
import { EditRegistrationColabModal } from '../../../components/EditRegistrationColabModal';
import { SelectCustom } from '../../../components/SelectCustom';
import { ModalResponseCsv } from '../../../components/ModalResponseCsv';
import { urlAccessBucketPreCadastroFuncionario } from '../../../utils/listUrlAccessAws';
import { useAuth } from '../../../hooks/auth';
import {
  findFilialName,
  findFilialFantasyName,
  findFilialCnpj,
} from '../../../interfaces/functionsFilterFind';

import {
  Container,
  Content,
  Aba,
  ContainerAba,
  ContainerSearch,
  ContainerCadastro,
  ContainerPagination,
  ContainerTable,
  AnimationContainer,
  ContainerButton,
  ContainerSemResultados,
  SearchCustom,
  ContentForm,
  ButtonEdit,
  ConainerCadastroOption,
  RadioBox,
} from './styles';

interface Uploaded {
  file: File | null;
  id: string;
  name: string;
  readableSize: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  type: string;
}

export interface SignUpFormData {
  situacao: string;
  dataAdmissao: string;
  dataNascimento: string;
  filial: string;
  nome: string;
  matricula: string;
  cpf: string;
  nomeEmpresa: string;
  cnpj: string;
  nomeFantasiaEmpresa: string;
  funcao: string;
}

export interface ResponseGet {
  CNPJ: string;
  CPF: string;
  DATA_ADMISSAO: string;
  DATA_NASCIMENTO: string;
  FILIAL: string;
  ID: number;
  MATRICULA: string;
  NOME: string;
  NOME_EMPRESA: string;
  NOME_FANTASIA_EMPRESA: string;
  SITUACAO: string;
  FUNCAO: string;
  ATIVO: boolean;
}

export interface ResponseGetFilial {
  ATIVO: boolean;
  BAIRRO: string;
  CEP: string;
  CIDADE: string;
  CODIGO_FILIAL: string;
  ENDERECO: string;
  ESTADO: string;
  ID: number;
  NUMERO: string;
  NOME_EMPRESA: string;
  NOME_FANTASIA_EMPRESA: string;
  CNPJ: string;
}

interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

const pageAccess = {
  ID: 2,
  NOME: 'PRE-CADASTRO-FUNCIONARIOS',
};

const headers = [
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'Matrícula', field: 'MATRICULA', sortable: true },
  { name: 'CPF', field: 'CPF', sortable: true },
  { name: 'Filial', field: 'FILIAL', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  { name: 'Empresa', field: 'NOME_EMPRESA', sortable: true },
  {
    name: 'Nome fantasia',
    field: 'NOME_FANTASIA_EMPRESA',
    sortable: true,
  },
  { name: 'Função', field: 'FUNCAO', sortable: true },
  { name: 'Admissão', field: 'DATA_ADMISSAO', sortable: true },
  { name: 'Nascimento', field: 'DATA_NASCIMENTO', sortable: true },
  { name: 'Situação', field: 'SITUACAO', sortable: true },
  { name: 'Status cadastral', field: 'ATIVO', sortable: true },
];

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

const PreCadastroFuncionario: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { modulos } = useAuth();
  const formRefIndividual = useRef<FormHandles>(null);
  const formRefEmLote = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [responseFiliais, setResponseFiliais] = useState<ResponseGetFilial[]>(
    [],
  );
  const [selectFilial, setSelectFilial] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState<Uploaded[]>([]);
  const [cadastrar, setCadastrar] = useState(true);
  const [searchCpf, setSearchCpf] = useState('');
  const [searchNome, setSearchNome] = useState('');
  const [searchMatricula, setSearchMatricula] = useState('');
  const [searchFilial, setSearchFilial] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const urlToDownload = urlAccessBucketPreCadastroFuncionario;
  const [download, setDownload] = useState('');
  const [count, setCount] = useState(0);
  const [dataAdmissao, setDataAdmissao] = useState('');
  const [dataNascimento, setDataNascimento] = useState('');
  const [dataAdmissaoValid, setDataAdmissaoValid] = useState(false);
  const [dataNascimentoValid, setDataNascimentoValid] = useState(false);
  const [verificaResponse, setVerificaResponse] = useState(false);
  const [verificaResponseCsv, setVerificaResponseCsv] = useState<number[]>([]);
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [trTableSelected, setTrTableSelected] = useState<ResponseGet>();
  const [showModalResponse, setShowModalResponse] = useState(false);
  const [dataCsvModal, setDataCsvModal] = useState<string[]>([]);
  const [registerType, setRegisterType] = useState('individual');
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });

  const CustomTooltipUpdateStatus = withStyles((theme) => ({
    arrow: {
      color: !trTableSelected
        ? '#009900'
        : trTableSelected.ATIVO
        ? '#ff3333'
        : '#8080ff',
    },
    tooltip: {
      backgroundColor: !trTableSelected
        ? '#009900'
        : trTableSelected.ATIVO
        ? '#ff3333'
        : '#8080ff',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);
  const CustomTooltipEdit = withStyles((theme) => ({
    arrow: {
      color: !trTableSelected ? '#009900' : '#ff9900',
    },
    tooltip: {
      backgroundColor: !trTableSelected ? '#009900' : '#ff9900',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  const getInitResponses = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const listaPreCadastro = await api.get(
        `cadastroIndividual/listarPreCadastro`,
      );
      const listaFiliaisTemp = await api.get(`filiais/showFiliais`);
      const listaFiliais: ResponseGetFilial[] = listaFiliaisTemp.data.filter(
        (filial: ResponseGetFilial) => filial.ATIVO,
      );

      setResponse(listaPreCadastro.data);
      setResponseFiliais(listaFiliais);
      !!listaFiliais.length && setSelectFilial(listaFiliais[0].CODIGO_FILIAL);
      setVerificaResponse(true);
    } catch (error) {
      setLoading(false);
      if (navigator.onLine) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página!',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);
    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title: 'Você não tem permissão para acessar essa página!',
      });
    } else {
      getInitResponses();
    }
  }, [getInitResponses, modulos, history]);

  const updateFile = useCallback((id, data) => {
    setUploadedFiles((estadoAnterior) =>
      estadoAnterior.map((file) =>
        id === file.id ? { ...file, ...data } : file,
      ),
    );
  }, []);

  const processUpload = useCallback(
    (uploadedFile: Uploaded) => {
      const data = new FormData();
      let contThen = 0;

      if (uploadedFile.file) {
        data.append('csvPreCadastro', uploadedFile.file);
      }

      api
        .post('/csvImportacao/createPreCadastroCSV', data, {
          onUploadProgress: (e) => {
            const progress = Math.round((e.loaded * 100) / e.total);

            updateFile(uploadedFile.id, {
              progress,
            });
          },
        })
        .then((res) => {
          updateFile(uploadedFile.id, {
            uploaded: true,
          });
          !!res.data.Message.length &&
            setDataCsvModal(dataCsvModal.concat(res.data.Message));
          contThen += 1;
        })
        .catch((err) => {
          updateFile(uploadedFile.id, {
            error: true,
          });

          if (
            err.response.data.errors.includes(
              'Error: column_mismatched. JSON Line number:',
            )
          ) {
            Swal.fire({
              icon: 'error',
              title: 'Arquivo incorreto!',
              text: `A linha ${
                Number(err.response.data.errors.split(' ').pop()) + 2
              } do arquivo .CSV está incorreta. Favor verificar.`,
            });
          }
        })
        .finally(() => {
          verificaResponseCsv.push(0);

          if (
            uploadedFiles.length === verificaResponseCsv.length &&
            contThen > 0
          ) {
            setShowModalResponse(true);
          } else if (uploadedFiles.length === verificaResponseCsv.length) {
            setVerificaResponseCsv([]);
          }
        });
    },
    [dataCsvModal, updateFile, uploadedFiles.length, verificaResponseCsv],
  );

  const handleUpload = useCallback(
    (files) => {
      const uploadedFilesHandleUpload: Uploaded[] = files.map((file: File) => ({
        file,
        id: uuid(),
        name: file.name,
        readableSize: filesize(file.size),
        progress: 0,
        uploaded: false,
        error: false,
        type: '.csv, application/vnd.ms-excel, text/csv',
      }));

      setUploadedFiles(uploadedFiles.concat(uploadedFilesHandleUpload));
    },
    [uploadedFiles],
  );

  const clickHandleUpload = useCallback(() => {
    if (!uploadedFiles.length) {
      Swal.fire({
        icon: 'error',
        title: 'Erro ao pré-cadastrar lista de funcionários!',
        text:
          'É necessário inserir um arquivo .CSV válido. Favor baixar o arquivo modelo pré-formatado para o preenchimento correto.',
      });
    } else {
      uploadedFiles.forEach(processUpload);
    }
  }, [processUpload, uploadedFiles]);

  const handleDelete = useCallback(
    async (id) => {
      uploadedFiles.splice(
        uploadedFiles.findIndex((element) => element.id === id),
        1,
      );
      setUploadedFiles([...uploadedFiles]);
    },
    [uploadedFiles],
  );

  /*----------------------------------------------------------------------------------------------------------------------------*/

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRefIndividual.current?.setErrors({});
        let validDateAdmissao = true;
        let validDateNascimento = true;

        if (dataAdmissao === '' || dataAdmissao === undefined) {
          setDataAdmissaoValid(true);
        } else {
          setDataAdmissaoValid(false);
          validDateAdmissao = false;
        }

        if (dataNascimento === '' || dataNascimento === undefined) {
          setDataNascimentoValid(true);
        } else {
          setDataNascimentoValid(false);
          validDateNascimento = false;
        }

        const schema = Yup.object().shape({
          filial: Yup.string().required('Filial é obrigatória!'),
          nome: Yup.string().required('Nome é obrigatório!'),
          matricula: Yup.string().required('Matrícula é obrigatória!'),
          cpf: Yup.string().required('CPF é obrigatório!'),
          nomeEmpresa: Yup.string().required('Nome da empresa é obrigatório!'),
          funcao: Yup.string().required('Função é obrigatória!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let CPF = data.cpf.replace(/\./g, '');
        CPF = CPF.replace(/-/g, '');

        if (!validDateAdmissao && !validDateNascimento) {
          setLoading(true);
          await api
            .post('cadastroIndividual/preCadastroIndividual', {
              cpf: CPF,
              situacao: 'ATIVO',
              data_admissao: dataAdmissao,
              data_nascimento: dataNascimento,
              filial: data.filial,
              nome: data.nome,
              matricula: data.matricula,
              nome_empresa: data.nomeEmpresa,
              cnpj: findFilialCnpj(responseFiliais, selectFilial),
              nome_fantasia_empresa: findFilialFantasyName(
                responseFiliais,
                selectFilial,
              ),
              funcao: data.funcao,
            })
            .then(() => {
              setLoading(false);
              addToast({
                type: 'success',
                title: 'Cadastro efetuado',
                description: 'Funcionário pré-cadastrado com sucesso!',
              });
              setTimeout(() => {
                history.go(0);
              }, 1000);
            })
            .catch(() => {
              setLoading(false);
              addToast({
                type: 'error',
                title: 'Erro no cadastro',
                description: 'Erro ao pré-cadastrar funcionário!',
              });
            });
        } else {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Existem campos obrigatórios em branco.',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRefIndividual.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Existem campos obrigatórios em branco.',
        });
        setLoading(false);
      }
    },
    [
      dataAdmissao,
      dataNascimento,
      responseFiliais,
      selectFilial,
      addToast,
      history,
    ],
  );

  const handleChangeStatusUser = useCallback(async () => {
    setLoading(true);
    await api
      .put(
        `/cadastroIndividual/updatePreCadastroIndividual?cpf=${trTableSelected?.CPF}`,
        {
          ativo: !trTableSelected?.ATIVO,
        },
      )
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Funcionário editado',
          description: 'Funcionário editado com sucesso!',
        });
        setTimeout(() => {
          history.go(0);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro na edição',
          description: 'Erro ao editar funcionário!',
        });
      });
  }, [trTableSelected, addToast, history]);

  const handleConfirmChangeStatusUser = useCallback(
    (data: ResponseGet) => {
      Swal.fire({
        title: `Tem certeza que deseja alterar o status do(a) funcionário(a) "${
          data.NOME ? data.NOME.trim() : 'SEM NOME'
        }"?`,
        text:
          'Ao alterar o status do(a) funcionário(a) no pré-cadastro, automaticamente essa mudança também será refletida na listagem de usuários.',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleChangeStatusUser();
        }
      });
    },
    [handleChangeStatusUser],
  );

  const handleAlertMaster = useCallback(() => {
    if (verificaResponse && !!responseFiliais.length) {
      setCadastrar(false);
      setDownload('');
      setSearchCpf('');
      setDataAdmissao('');
      setDataNascimento('');
      setDataAdmissaoValid(false);
      setDataNascimentoValid(false);
    } else if (verificaResponse && !responseFiliais.length) {
      addToast({
        type: 'info',
        title: 'Lista de filiais vazia!',
        description:
          'Favor realizar o cadastro de pelo menos uma empresa/filial previamente.',
      });
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar dados, por favor atualize a página!',
      });
    }
  }, [addToast, responseFiliais.length, verificaResponse]);

  const handleAlertEdit = useCallback(() => {
    if (verificaResponse && !responseFiliais.length) {
      addToast({
        type: 'info',
        title: 'Lista de filiais vazia!',
        description:
          'Favor realizar o cadastro de pelo menos uma empresa/filial previamente.',
      });
    } else {
      setShowModalEdit(true);
    }
  }, [addToast, responseFiliais.length, verificaResponse]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response.length ? response : [];

    if (searchCpf) {
      let CPF = searchCpf.replace(/\./g, '');
      CPF = CPF.replace(/-/g, '');

      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.CPF && res.CPF.toString().includes(CPF.toString()),
      );
    }
    if (searchNome) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.NOME && res.NOME.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }
    if (searchMatricula) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.MATRICULA &&
          res.MATRICULA.toString().includes(searchMatricula.toString()),
      );
    }
    if (searchFilial) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.FILIAL && res.FILIAL.toString().includes(searchFilial.toString()),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (a[sorting.field] !== null && b[sorting.field] !== null) {
          if (typeof a[sorting.field] === 'object') {
            return (
              reversed *
              a[sorting.field]
                .join(', ')
                .localeCompare(b[sorting.field].join(', '))
            );
          }
          if (typeof a[sorting.field] === 'string') {
            return reversed * a[sorting.field].localeCompare(b[sorting.field]);
          }
          return (
            reversed *
            a[sorting.field]
              .toString()
              .localeCompare(b[sorting.field].toString())
          );
        }
        return a;
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }
    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    currentPage,
    response,
    searchCpf,
    searchFilial,
    searchMatricula,
    searchNome,
    sorting.field,
    sorting.order,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchNome, searchCpf, searchMatricula, searchFilial]);

  return (
    <>
      <ModalConexao />
      <Container>
        {accessUser.ESCRITA && (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button
                style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
                type="button"
                onClick={() => setCadastrar(true)}
              >
                Listar funcionários
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={!cadastrar}>
              <button
                style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7 }}
                type="button"
                onClick={() => handleAlertMaster()}
              >
                Cadastrar funcionários
              </button>
            </ContainerAba>
          </Aba>
        )}

        {cadastrar && !!response.length && (
          <ContainerSearch>
            <SearchCustom
              onSearch={(value: string) => {
                setSearchNome(value);
              }}
              nomePlaceHolder="Buscar nome"
            />
            <SearchCustom
              onSearch={(value: string) => {
                setSearchCpf(value);
              }}
              nomePlaceHolder="Buscar CPF"
              typeProps="cpf"
            />
            <SearchCustom
              onSearch={(value: string) => {
                setSearchMatricula(value);
              }}
              nomePlaceHolder="Buscar matrícula"
            />
            <SearchCustom
              onSearch={(value: string) => {
                setSearchFilial(value);
              }}
              nomePlaceHolder="Buscar filial"
            />
          </ContainerSearch>
        )}

        <ContainerCadastro cor={!cadastrar}>
          {!cadastrar && (
            <ConainerCadastroOption>
              <RadioBox
                type="button"
                onClick={() => setRegisterType('individual')}
                isActive={registerType === 'individual'}
                activeColor="green"
              >
                <AiOutlineUserAdd size={28} />
                Cadastro individual
              </RadioBox>
              <RadioBox
                type="button"
                onClick={() => setRegisterType('lote')}
                isActive={registerType === 'lote'}
                activeColor="red"
              >
                <FaFileCsv size={28} color="#fff" />
                Cadastro em lote
              </RadioBox>
            </ConainerCadastroOption>
          )}

          {!cadastrar &&
            (registerType === 'individual' ? (
              <div className="divUnicAnimationContainer">
                <AnimationContainer className="divAnimationContainer">
                  <header>
                    <AiOutlineUserAdd size={24} />
                  </header>

                  <Form ref={formRefIndividual} onSubmit={handleSubmit}>
                    <ContentForm>
                      <SelectCustom
                        name="filial"
                        widthProps="49%"
                        icon={BsBuilding}
                        defaultValue="Filial"
                        optionsData={responseFiliais.map((filial) => {
                          return filial.CODIGO_FILIAL;
                        })}
                        onValue={(value) => setSelectFilial(value)}
                      />
                      <Input
                        name="nomeEmpresa"
                        icon={BsBuilding}
                        widthProps="49%"
                        placeholder="Nome da empresa"
                        value={findFilialName(responseFiliais, selectFilial)}
                        disabled
                      />
                    </ContentForm>

                    <ContentForm>
                      <Input
                        name="nome"
                        icon={FiUser}
                        widthProps="49%"
                        placeholder="Nome"
                      />
                      <Input
                        name="matricula"
                        icon={FiUser}
                        widthProps="49%"
                        mask="registration"
                        placeholder="Matrícula"
                      />
                    </ContentForm>

                    <ContentForm>
                      <Input
                        name="cpf"
                        icon={FiUser}
                        widthProps="49%"
                        placeholder="CPF"
                        mask="cpf"
                      />
                      <Input
                        name="funcao"
                        icon={FiUser}
                        widthProps="49%"
                        placeholder="Função"
                      />
                    </ContentForm>

                    <ContentForm>
                      <InputDate
                        nameProps="dataAdmissao"
                        placeholderProps="Data de admissão"
                        validProps={dataAdmissaoValid}
                        widthProps="49%"
                        dateFormatReturn={(e: string) => setDataAdmissao(e)}
                      />
                      <InputDate
                        nameProps="dataNascimento"
                        placeholderProps="Data de nascimento"
                        validProps={dataNascimentoValid}
                        widthProps="49%"
                        dateFormatReturn={(e: string) => setDataNascimento(e)}
                      />
                    </ContentForm>

                    <ContainerButton>
                      <button type="submit" id="btnCadastroIndividual">
                        Cadastrar
                      </button>
                    </ContainerButton>
                  </Form>
                </AnimationContainer>
              </div>
            ) : (
              <div className="divUnicAnimationContainer">
                <AnimationContainer className="divAnimationContainer">
                  <header>
                    <FaFileCsv size={24} color="#fff" />
                  </header>

                  <Form ref={formRefEmLote} onSubmit={clickHandleUpload}>
                    <Content>
                      <Upload
                        onUpload={(e: any) => handleUpload(e)}
                        paddingProps="70px"
                      />
                      <FileList
                        files={uploadedFiles}
                        heightProps="152px"
                        onDelete={(e: string) => handleDelete(e)}
                      />
                    </Content>
                    <ContainerButton>
                      <button type="submit">Cadastrar</button>
                      <button
                        type="button"
                        onClick={() => {
                          setDownload(urlToDownload);
                          setCount((old) => old + 1);
                        }}
                      >
                        <AiOutlineDownload size={24} />
                        Baixar Modelo
                      </button>
                      {download && (
                        <iframe
                          title="downloadModeloCsv"
                          src={`${download}?c=${count}`}
                          hidden
                        />
                      )}
                    </ContainerButton>
                  </Form>
                </AnimationContainer>
              </div>
            ))}

          {cadastrar && !responseData.length && (
            <ContainerSemResultados>
              <h2>Não existem funcionários pré-cadastrados ainda.</h2>
            </ContainerSemResultados>
          )}

          {cadastrar && !!responseData.length && (
            <div className="divTable">
              <ContainerTable>
                <table>
                  <HeaderTable
                    headers={headers}
                    onSorting={(field: string, order: string) =>
                      setSorting({ field, order })}
                  />
                  <tbody>
                    {responseData.map((res) => (
                      <tr
                        key={res.ID}
                        onClick={() =>
                          trTableSelected === res
                            ? setTrTableSelected(undefined)
                            : setTrTableSelected(res)
                        }
                        style={{
                          background:
                            trTableSelected && trTableSelected.ID === res.ID
                              ? '#737373'
                              : '',
                        }}
                      >
                        <td>{res.NOME ? res.NOME : '-'}</td>
                        <td>{res.MATRICULA ? res.MATRICULA : '-'}</td>
                        <td>{res.CPF ? maskCpf(res.CPF) : '-'}</td>
                        <td>{res.FILIAL ? res.FILIAL : '-'}</td>
                        <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                        <td>{res.NOME_EMPRESA ? res.NOME_EMPRESA : '-'}</td>
                        <td>
                          {res.NOME_FANTASIA_EMPRESA
                            ? res.NOME_FANTASIA_EMPRESA
                            : '-'}
                        </td>
                        <td>{res.FUNCAO ? res.FUNCAO : '-'}</td>
                        <td>
                          {res.DATA_ADMISSAO
                            ? moment(res.DATA_ADMISSAO).format('DD/MM/YYYY')
                            : '-'}
                        </td>
                        <td>
                          {res.DATA_NASCIMENTO
                            ? moment(res.DATA_NASCIMENTO).format('DD/MM/YYYY')
                            : '-'}
                        </td>
                        <td>{res.SITUACAO ? res.SITUACAO : '-'}</td>
                        <td style={{ padding: 5 }}>
                          <div
                            style={{
                              background: `${
                                !res.ATIVO
                                  ? 'rgb(255, 51, 51)'
                                  : 'rgb(128, 128, 255)'
                              }`,
                              width: '100%',
                              height: '100%',
                              borderRadius: 4,
                            }}
                          >
                            {!!res.ATIVO && 'Ativo'}
                            {!res.ATIVO && 'Inativo'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table
                  id="tableListagemPreCadastroFuncionarios"
                  style={{ display: 'none' }}
                >
                  <HeaderTable
                    headers={headers}
                    onSorting={(field: string, order: string) =>
                      setSorting({ field, order })}
                  />
                  <tbody>
                    {responseData.map((res) => (
                      <tr key={res.ID}>
                        <td>{res.NOME ? res.NOME : '-'}</td>
                        <td>{res.MATRICULA ? res.MATRICULA : '-'}</td>
                        <td>{res.CPF ? maskCpf(res.CPF) : '-'}</td>
                        <td>{res.FILIAL ? res.FILIAL : '-'}</td>
                        <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                        <td>{res.NOME_EMPRESA ? res.NOME_EMPRESA : '-'}</td>
                        <td>
                          {res.NOME_FANTASIA_EMPRESA
                            ? res.NOME_FANTASIA_EMPRESA
                            : '-'}
                        </td>
                        <td>
                          {res.DATA_ADMISSAO
                            ? moment(res.DATA_ADMISSAO).format('DD/MM/YYYY')
                            : '-'}
                        </td>
                        <td>
                          {res.DATA_NASCIMENTO
                            ? moment(res.DATA_NASCIMENTO).format('DD/MM/YYYY')
                            : '-'}
                        </td>
                        <td>{res.SITUACAO ? res.SITUACAO : '-'}</td>
                        <td>
                          {!!res.ATIVO && 'Ativo'}
                          {!res.ATIVO && 'Inativo'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ContainerTable>

              <ContainerPagination>
                {responseData.length > 0 && (
                  <div id="divPaginacao">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: number) => {
                        setCurrentPage(page);
                      }}
                    />
                    <DropDownPagination
                      onChangeItems={(value: string) => {
                        setCurrentPage(1);
                        if (value === 'Todos') {
                          setITEMS_PER_PAGE(1);
                        } else {
                          setITEMS_PER_PAGE(Number(value));
                        }
                      }}
                      objetoEnum={dropDown}
                      minWidth={30}
                    />

                    <div id="divBtnTable">
                      {accessUser.ESCRITA && (
                        <>
                          <CustomTooltipEdit
                            title={
                              !trTableSelected
                                ? 'Selecione um funcionário primeiro'
                                : 'Editar'
                            }
                            arrow
                            TransitionComponent={Zoom}
                          >
                            <span>
                              <ButtonEdit
                                disabled={!trTableSelected}
                                style={{
                                  cursor: !trTableSelected ? 'not-allowed' : '',
                                  background: !trTableSelected ? '#009900' : '',
                                  filter: !trTableSelected
                                    ? 'brightness(1)'
                                    : '',
                                }}
                                onClick={handleAlertEdit}
                              >
                                <AiFillEdit size={26} />
                              </ButtonEdit>
                            </span>
                          </CustomTooltipEdit>

                          <CustomTooltipUpdateStatus
                            title={
                              !trTableSelected
                                ? 'Selecione um funcionário primeiro'
                                : trTableSelected.ATIVO
                                ? 'Inativar funcionário'
                                : 'Ativar funcionário'
                            }
                            arrow
                            TransitionComponent={Zoom}
                          >
                            <span>
                              <ButtonEdit
                                disabled={!trTableSelected}
                                style={{
                                  cursor: !trTableSelected ? 'not-allowed' : '',
                                  background: !trTableSelected
                                    ? '#009900'
                                    : trTableSelected.ATIVO
                                    ? '#ff3333'
                                    : '#8080ff',
                                  filter: !trTableSelected
                                    ? 'brightness(1)'
                                    : '',
                                }}
                                onClick={() =>
                                  trTableSelected &&
                                  handleConfirmChangeStatusUser(trTableSelected)}
                              >
                                {!trTableSelected ? (
                                  <FiUser size={26} />
                                ) : trTableSelected.ATIVO ? (
                                  <FiUserX size={26} />
                                ) : (
                                  <FiUserCheck size={26} />
                                )}
                              </ButtonEdit>
                            </span>
                          </CustomTooltipUpdateStatus>
                        </>
                      )}

                      <ReactHTMLTableToExcel
                        id="export-excel"
                        className="btn"
                        table="tableListagemPreCadastroFuncionarios"
                        filename={`listagem-preCadastro-funcionarios-${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        sheet="tablexls"
                        buttonText="Exportar Excel"
                      />
                    </div>
                  </div>
                )}
              </ContainerPagination>
            </div>
          )}
        </ContainerCadastro>
      </Container>

      {loading && <Loading />}
      {showModalEdit && trTableSelected && (
        <EditRegistrationColabModal
          data={trTableSelected}
          filiais={responseFiliais}
          onClose={(e) => setShowModalEdit(!e)}
        />
      )}
      {showModalResponse && (
        <ModalResponseCsv
          dados={dataCsvModal}
          onClose={() => setShowModalResponse(false)}
        />
      )}
    </>
  );
};

export default PreCadastroFuncionario;
