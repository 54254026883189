import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  flex: 1;
  max-width: auto;
  align-items: center;
  max-height: 100vh;
`;

export const ContainerSearch = styled.div`
  display: flex;
  margin-left: 5%;
  margin-top: 40px;
`;

export const ContainerTable = styled.div`
  max-width: 90%;
  align-items: center;
  margin: 10px auto;
  margin-top: 0px;
  max-height: 60vh;
  overflow-x: auto;
  table {
    width: 100%;
    margin: 10px auto;
    margin-top: 0px;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 30px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerButton = styled.div`
  margin-left: auto;
  min-width: 150px;
  button {
    margin-right: 3px;
    background-color: red;
    color: #fff;
    border-radius: 10px;
    padding: 10px;
    border: none;
    width: 150px;
    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const ContainerEditEmail = styled.div`
  border-radius: 15px;
  background-color: #3e3c44;
  padding: 20px 15px;
`;

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const AnimationContainer = styled.div`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  animation: ${appearFromLeft} 1s;

  form {
    margin: 0px auto;
    width: 100%;
    text-align: center;
    align-items: center;
    padding: 10px;
  }
`;

export const ContainerForm = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
  overflow-x: auto;
`;

export const ContentSubBody = styled.main`
  width: 100%;
  height: 70px;
  display: flex;
  border-top-left-radius: 7px;
  border-top-right-radius: 7px;
  align-items: center;
  justify-content: center;
  flex-direction: row;

  input {
    width: 200px;
    height: 40px;
    padding: 3px 5px;
    border: none;
  }
`;

export const ContentSubLabel = styled.label`
  font-family: 'Arial', serif;
  background-color: #00cc44;
  color: #000000;
  height: 40px;
  padding: 8px 8px;
  text-align: center;
  justify-content: center;
`;

export const ContainerAviso = styled.div`
  display: flex;
  width: 1120px;
  background-color: #3e3946;
  margin: 10px auto;
  border-radius: 10px;
  h3 {
    color: #ffffff;
    margin: 0px auto;
    font-size: 22px;
    font-family: Arial;
    padding: 40px;
  }
`;
