import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  font-family: Arial;
  padding: 30;

  select {
    font-size: 16px;
    height: 40px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background-color: #006600;
    color: #ffffff;
    margin-left: 50px;
    cursor: pointer;

    label + & {
      margin-left: 0px;
      border-radius: 0px;
      background-color: #ffffff;
      color: #888888;
    }
  }
`;
