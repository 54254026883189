import styled from 'styled-components';

interface ContainerProps {
  cor: boolean;
}

export const Container = styled.div`
  /**Tem 100% da parte visível da tela que o usuário consegue ver */
  height: auto;
  /**Faz os items de dentro ficar todos um do lado do outro. */
  flex: 1;
  /**Faz os items de dentro ter o mesmo tamanho, no caso, o tamanho de
    100vh */
  align-items: center;
  justify-content: center;
  overflow-x: auto;
  margin-top: 10px;
`;

export const Content = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  width: 75%;
  margin: 0px auto;
`;

export const ContainerInput = styled.div`
  display: flex;

  button {
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    background-color: #439133;
    border: 0px;
    height: 30px;
    margin-left: 20px;
    border-radius: 5px;
    min-width: 150px;
    color: #ffffff;
  }
`;

export const ContainerSearch = styled.div`
  width: 80%;
  display: flex;
  margin: 0px auto;
  margin-top: 15px;
`;

export const ContainerPendente = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 50%;
  button {
    height: 40px;
    background-color: #006600;
    color: #ffffff;
    border-width: 0px;
    border-radius: 7px;
    min-width: 150px;
    margin-top: -10px;
  }
  h3 {
    margin-left: 10px;
    color: #ffffff;
    padding: 0px 10px;
    min-width: 150px;
  }
`;

export const ContainerTable = styled.div`
  width: 90%;
  align-items: center;
  margin: 0px auto;
  padding: 0px;
  margin-bottom: 30px;
  max-height: 60vh;
  overflow-x: auto;
  table {
    width: 100%;
    padding: 10px, 40px;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 5px 30px;
      background-color: #439133;
      color: #fff;
      min-width: 200px;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
      .aceitoFlex {
        display: flex;
        justify-content: center;
        .aceito {
          display: flex;
          justify-content: center;
          align-items: center;

          background-color: transparent;
          color: #fff;
          border: 0;
          & + .aceito {
            margin-left: 40px;
          }
        }
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerValidar = styled.div`
  width: 90%;
  background: #3d3946;
  margin: 0px auto;
  p {
    margin-left: 5%;
    font-size: 20px;
    font-family: Arial;
    padding-top: 20px;
    font-weight: bold;
  }
`;

export const ContainerTableValidar = styled.div`
  align-items: center;
  width: 80%;
  margin: 10px auto;
  margin-top: 30px;
  margin-bottom: 20px;
  padding: 0 10px;
  max-height: 70vh;
  overflow-x: auto;
  overflow-y: auto;
  table {
    margin: 0px auto;
    padding: 0px, 10px;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      padding: 5px 0px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      min-width: 200px;
      top: 0;
      & + th {
        padding-left: 10px;
      }
    }
  }
  tbody {
    td {
      margin: 10px;
      padding: 10px;
      text-align: center;
      font-size: 16px;
      max-width: 200;
      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 40px;
        }
      }
      a {
        text-decoration: none;
        size: 25px;
        color: #ffffff;
      }
      textarea {
        border-radius: 5px;
        padding: 10px 10px;
        font-size: 16px;
        font-family: Arial;
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      background: #312e38;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ButtonBorder = styled.div<ContainerProps>`
  display: flex;
  margin: 5px auto;
  background-color: ${(props) => (props.cor ? ' #439133' : '#660000')};
  border-radius: 15px;
  border: 0px;
  padding: 10px 16px;
  justify-content: center;
  align-self: center;
  margin-bottom: 20px;
  cursor: pointer;
  button {
    background: transparent;
    border: 0px;
    color: #ffffff;
    height: 20px;
    width: 100px;
  }
  svg {
    margin-right: 10px;
    color: #ffffff;
    size: 20px;
  }
`;

export const ButtonBorderIconFalse = styled.div`
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background-color: #b30000;
  svg {
    margin-top: -1px;
    margin-left: -1px;
  }
`;

export const ButtonBorderIconTrue = styled.div`
  height: 23px;
  width: 23px;
  border-radius: 50%;
  background-color: #336600;
  svg {
    margin-top: -1px;
    margin-left: -1px;
  }
`;

export const ButtonBorderIconFalseRespostaDp = styled.div`
  height: 26px;
  width: 28px;
  border-radius: 5px;
  background-color: #b30000;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    /* margin-top: 4px;
    margin-left: 1px; */
  }
`;

export const ButtonBorderIconTrueRespostaDp = styled.div`
  height: 26px;
  width: 28px;
  border-radius: 5px;
  background-color: #336600;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    /* margin-top: 4px;
    margin-left: 1px; */
  }
`;

export const ContainerVisualizarVazio = styled.div`
  width: 70%;
  background-color: #3e3946;
  border-radius: 10px;
  margin: 0px auto;
  h3 {
    margin: 0px auto;
    color: #ffffff;
    font-size: 32px;
    font-family: Arial;
    padding: 40px;
    text-align: center;
  }
  div {
    display: flex;
    justify-content: center;
    align-items: center;
    button {
      background: #439133;
      border: 0px;
      border-radius: 5px;
      height: 40px;
      width: 100px;
      color: #ffffff;
      margin-bottom: 20px;
    }
  }
`;
