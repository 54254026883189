/* eslint-disable no-nested-ternary */
import React, {
  useRef,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import { FiSearch, FiUser } from 'react-icons/fi';
import {
  AiOutlineUserAdd,
  AiOutlineDownload,
  AiFillEdit,
} from 'react-icons/ai';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import { FaFileCsv, FaRegMoneyBillAlt } from 'react-icons/fa';
import { BsBuilding, BsInfoSquare } from 'react-icons/bs';
import { MdDateRange } from 'react-icons/md';
import { IoCalculatorOutline, IoDocumentTextOutline } from 'react-icons/io5';
import {
  HiOutlineDocument,
  HiOutlineDocumentAdd,
  HiOutlineDocumentRemove,
} from 'react-icons/hi';

import ModalConexao from '../../../components/ModalConexao';
import api from '../../../services/api';
import FileList from '../../../components/FileList';
import Upload from '../../../components/Upload';
import DropDownPagination from '../../../components/DropDownPagination';
import Pagination from '../../../components/Pagination';
import HeaderTable from '../../../components/HeaderTable';
import Input from '../../../components/Input';
import { Loading } from '../../../components/Loading';
import getValidationErrors from '../../../utils/getValidationErrors';
import InputDate from '../../../components/DatePicker';
import { maskCpf, maskCnpj } from '../../../components/Input/mask';
import { useToast } from '../../../hooks/toast';
import { EditContrachequeDecimoTerceiroModal } from '../../../components/EditContrachequeDecimoTerceiroModal';
import { ModalResponseCsv } from '../../../components/ModalResponseCsv';
import { SelectCustom } from '../../../components/SelectCustom';
import { ResponseGetFilial } from '../PreCadastroFuncionario';
import { urlAccessBucketContrachequeDecimoTerceiro } from '../../../utils/listUrlAccessAws';
import { useAuth } from '../../../hooks/auth';
import {
  findFilialCnpj,
  findFilialName,
  findFuncName,
  findFuncMatricula,
  findFuncDataAdmissao,
  findFuncFuncao,
} from '../../../interfaces/functionsFilterFind';

import {
  Container,
  Content,
  Aba,
  ContainerAba,
  ContainerSearch,
  ContainerCadastro,
  ContainerPagination,
  ContainerTable,
  AnimationContainer,
  ContainerButton,
  ContainerSemResultados,
  SearchCustom,
  ContentForm,
  ContainerFilterPeriodo,
  ConainerCadastroOption,
  RadioBox,
  ButtonEdit,
} from './styles';

interface Uploaded {
  file: File | null;
  id: string;
  name: string;
  readableSize: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  type: string;
}

export interface SignUpFormData {
  empresa: string;
  nomeEmpresa: string;
  cnpj: string;
  nome: string;
  matricula: string;
  cpf: string;
  funcao: string;
  salarioBase: number;
  referencia: number;
  salarioLiquido: number;
  somaDescontosTotais: number;
  somaProventosTotais: number;
  verbas: string;
  descontosProventosPorVerbas: number;
  tipoCodigoVerba: string;
  centroCusto: string;
  codigoVerba: string;
  parcela: string;
}

export interface ResponseGet {
  ATIVO: boolean;
  CNPJ: string;
  COD_VERBA: string;
  CPF: string;
  CPFSEMFORMATO: string;
  DATA_ADMISSAO: string;
  DESCONTOS_PROVENTOS_POR_VERBAS: number;
  DESC_CENTRO_CUSTO: string;
  FUNCAO: string;
  ID: number;
  NOME_EMPRESA: string;
  PARCELA_DESCONTOS: string;
  PARCELA_PROVENTOS: string;
  PERIODO_FOLHA: string;
  RA_FILIAL: string;
  RA_MAT: string;
  RA_NOME: string;
  REFERENCIA: number;
  SALARIO_BASE: number;
  SALARIO_LIQUIDO: number;
  SOMA_DESCONTOS_TOTAIS: number;
  SOMA_PROVENTOS_TOTAIS: number;
  TIPO_COD_VERBA: string;
  VERBAS: string;
}

export interface ResponseGetFuncionarios {
  CNPJ: string;
  CPF: string;
  DATA_ADMISSAO: string;
  DATA_NASCIMENTO: string;
  FILIAL: string;
  ID: number;
  MATRICULA: string;
  NOME: string;
  NOME_EMPRESA: string;
  NOME_FANTASIA_EMPRESA: string;
  SITUACAO: string;
  FUNCAO: string;
  ATIVO: boolean;
}

interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

const pageAccess = {
  ID: 7,
  NOME: 'CADASTRO-DECIMO-TERCEIRO',
};

const headers = [
  { name: 'CPF', field: 'CPF', sortable: true },
  { name: 'Nome', field: 'RA_NOME', sortable: true },
  { name: 'Matrícula', field: 'RA_MAT', sortable: true },
  { name: 'Função', field: 'FUNCAO', sortable: true },
  { name: 'Empresa', field: 'RA_FILIAL', sortable: true },
  { name: 'Nome da empresa', field: 'NOME_EMPRESA', sortable: true },
  { name: 'CNPJ', field: 'CNPJ', sortable: true },
  { name: 'Admissão', field: 'DATA_ADMISSAO', sortable: true },
  { name: 'Parcela (descontos)', field: 'PARCELA_DESCONTOS', sortable: true },
  { name: 'Parcela (proventos)', field: 'PARCELA_PROVENTOS', sortable: true },
  { name: 'Referência', field: 'REFERENCIA', sortable: true },
  { name: 'Salário base', field: 'SALARIO_BASE', sortable: true },
  { name: 'Salário líquido', field: 'SALARIO_LIQUIDO', sortable: true },
  {
    name: 'Soma dos desc. totais',
    field: 'SOMA_DESCONTOS_TOTAIS',
    sortable: true,
  },
  {
    name: 'Soma dos prov. totais',
    field: 'SOMA_PROVENTOS_TOTAIS',
    sortable: true,
  },
  { name: 'Código da verba', field: 'COD_VERBA', sortable: true },
  { name: 'Tipo de código da verba', field: 'TIPO_COD_VERBA', sortable: true },
  { name: 'Verbas', field: 'VERBAS', sortable: true },
  {
    name: 'Desc. dos prov. por verbas',
    field: 'DESCONTOS_PROVENTOS_POR_VERBAS',
    sortable: true,
  },
  { name: 'Centro de custo', field: 'CENTRO_CUSTO', sortable: true },
  { name: 'Período de folha', field: 'PERIODO_FOLHA', sortable: true },
  { name: 'Status cadastral', field: 'ATIVO', sortable: true },
];

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '40', id: '40' },
  { valor: '80', id: '80' },
  { valor: '160', id: '160' },
  { valor: 'Todos', id: '1' },
];

const CadastroContraCheque: React.FC = () => {
  const history = useHistory();
  const { addToast } = useToast();
  const { modulos } = useAuth();
  const formRefFilter = useRef<FormHandles>(null);
  const formRefIndividual = useRef<FormHandles>(null);
  const formRefEmLote = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [responseFuncionarios, setResponseFuncionarios] = useState<
    ResponseGetFuncionarios[]
  >([]);
  const [responseFiliais, setResponseFiliais] = useState<ResponseGetFilial[]>(
    [],
  );
  const [filialName, setFilialName] = useState('');
  const [funcCpf, setFuncCpf] = useState('');
  const [uploadedFiles, setUploadedFiles] = useState<Uploaded[]>([]);
  const [cadastrar, setCadastrar] = useState(true);
  const [searchCpf, setSearchCpf] = useState('');
  const [searchNome, setSearchNome] = useState('');
  const [searchFuncao, setSearchFuncao] = useState('');
  const [searchEmpresa, setSearchEmpresa] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const urlToDownload = urlAccessBucketContrachequeDecimoTerceiro;
  const [download, setDownload] = useState('');
  const [count, setCount] = useState(0);
  const [verificaResponse, setVerificaResponse] = useState(false);
  const [
    verificaResponseDecimoTerceiro,
    setVerificaResponseDecimoTerceiro,
  ] = useState(false);
  const [verificaResponseCsv, setVerificaResponseCsv] = useState<number[]>([]);
  const [dataCompetencia, setDataCompetencia] = useState('');
  const [dataCompetenciaCsv, setDataCompetenciaCsv] = useState('');
  const [dataCompetenciaValid, setDataCompetenciaValid] = useState(false);
  const [dataCompetenciaCsvValid, setDataCompetenciaCsvValid] = useState(false);
  const [periodoQuery, setPeriodoQuery] = useState('');
  const [showModalResponse, setShowModalResponse] = useState(false);
  const [dataCsvModal, setDataCsvModal] = useState<string[]>([]);
  const [registerType, setRegisterType] = useState('individual');
  const [trTableSelected, setTrTableSelected] = useState<ResponseGet>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });

  const CustomTooltipUpdateStatus = withStyles((theme) => ({
    arrow: {
      color: !trTableSelected
        ? '#009900'
        : trTableSelected.ATIVO
        ? '#ff3333'
        : '#8080ff',
    },
    tooltip: {
      backgroundColor: !trTableSelected
        ? '#009900'
        : trTableSelected.ATIVO
        ? '#ff3333'
        : '#8080ff',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);
  const CustomTooltipEdit = withStyles((theme) => ({
    arrow: {
      color: !trTableSelected ? '#009900' : '#ff9900',
    },
    tooltip: {
      backgroundColor: !trTableSelected ? '#009900' : '#ff9900',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  const getInitResponses = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const listaPreCadastro = await api.get(
        `cadastroIndividual/listarPreCadastro`,
      );
      const listaFiliaisTemp = await api.get(`filiais/showFiliais`);
      const listaFiliais = listaFiliaisTemp.data.filter(
        (filial: ResponseGetFilial) => filial.ATIVO,
      );

      setResponseFuncionarios(listaPreCadastro.data);
      setResponseFiliais(listaFiliais);
      setVerificaResponse(true);
    } catch (error) {
      setLoading(false);
      if (navigator.onLine) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página!',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);
    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title: 'Você não tem permissão para acessar essa página!',
      });
    } else {
      getInitResponses();
    }
  }, [getInitResponses, modulos, history]);

  const handleSearchPeriodoContraChequeDecimoTerceiro = useCallback(
    ({ parcelaFilter }) => {
      let parcelaFilterTemp: string | number = '';
      switch (parcelaFilter) {
        case '1ª parcela':
          parcelaFilterTemp = 1;
          break;
        case '2ª parcela':
          parcelaFilterTemp = 2;
          break;
        case 'Todas':
          parcelaFilterTemp = '';
          break;
        default:
          console.log('Chegou no final do switch case');
      }

      if (periodoQuery !== '' && periodoQuery !== undefined) {
        setLoading(true);
        api
          .get(
            `cadastroIndividual/listarContraChequeDecimo?periodo=${periodoQuery}&parcela=${parcelaFilterTemp}`,
          )
          .then((data) => {
            setResponse(data.data);
            setVerificaResponseDecimoTerceiro(true);
            setLoading(false);
          })
          .catch(() => {
            setLoading(false);
            if (navigator.onLine) {
              addToast({
                type: 'error',
                title: 'Erro',
                description:
                  'Erro ao carregar dados, por favor atualize a página!',
              });
            }
          });
      } else {
        addToast({
          type: 'info',
          title: 'Campo em branco!',
          description: 'Favor inserir o período para realizar a busca.',
        });
      }
    },
    [addToast, periodoQuery],
  );

  const updateFile = useCallback((id, data) => {
    setUploadedFiles((estadoAnterior) =>
      estadoAnterior.map((file) =>
        id === file.id ? { ...file, ...data } : file,
      ),
    );
  }, []);

  const processUpload = useCallback(
    (uploadedFile: Uploaded) => {
      const data = new FormData();
      let contThen = 0;

      if (uploadedFile.file) {
        data.append('csvDecimoTerceiro', uploadedFile.file);
        data.append('periodo', dataCompetenciaCsv);
      }

      api
        .post('/csvImportacao/decimoTerceiroCSV', data, {
          onUploadProgress: (e) => {
            const progress = Math.round((e.loaded * 100) / e.total);

            updateFile(uploadedFile.id, {
              progress,
            });
          },
        })
        .then((res) => {
          updateFile(uploadedFile.id, {
            uploaded: true,
          });
          !!res.data.Message.length &&
            setDataCsvModal(dataCsvModal.concat(res.data.Message));
          contThen += 1;
        })
        .catch((err) => {
          updateFile(uploadedFile.id, {
            error: true,
          });

          if (
            err.response.data.errors.includes(
              'Error: column_mismatched. JSON Line number:',
            )
          ) {
            Swal.fire({
              icon: 'error',
              title: 'Arquivo incorreto!',
              text: `A linha ${
                Number(err.response.data.errors.split(' ').pop()) + 2
              } do arquivo .CSV está incorreta. Favor verificar.`,
            });
          }
        })
        .finally(() => {
          verificaResponseCsv.push(0);

          if (
            uploadedFiles.length === verificaResponseCsv.length &&
            contThen > 0
          ) {
            setShowModalResponse(true);
          } else if (uploadedFiles.length === verificaResponseCsv.length) {
            setVerificaResponseCsv([]);
          }
        });
    },
    [
      dataCompetenciaCsv,
      dataCsvModal,
      updateFile,
      uploadedFiles.length,
      verificaResponseCsv,
    ],
  );

  const handleUpload = useCallback(
    (files) => {
      const uploadedFilesHandleUpload: Uploaded[] = files.map((file: File) => ({
        file,
        id: uuid(),
        name: file.name,
        readableSize: filesize(file.size),
        progress: 0,
        uploaded: false,
        error: false,
        type: '.csv, application/vnd.ms-excel, text/csv',
      }));

      setUploadedFiles(uploadedFiles.concat(uploadedFilesHandleUpload));
    },
    [uploadedFiles],
  );

  const clickHandleUpload = useCallback(() => {
    let validDateCompetenciaCsv = true;

    if (dataCompetenciaCsv === '') {
      setDataCompetenciaCsvValid(true);
      validDateCompetenciaCsv = true;
    } else {
      setDataCompetenciaCsvValid(false);
      validDateCompetenciaCsv = false;
    }

    if (!validDateCompetenciaCsv) {
      if (!uploadedFiles.length) {
        Swal.fire({
          icon: 'error',
          title:
            'Erro ao pré-cadastrar lista de contracheques (décimo terceiro)!',
          text:
            'É necessário inserir um arquivo .CSV válido. Favor baixar o arquivo modelo pré-formatado para o preenchimento correto.',
        });
      } else {
        uploadedFiles.forEach(processUpload);
      }
    } else {
      addToast({
        type: 'error',
        title: 'Erro no cadastro',
        description: 'Existem campos obrigatórios em branco.',
      });
    }
  }, [addToast, dataCompetenciaCsv, processUpload, uploadedFiles]);

  const handleDelete = useCallback(
    async (id) => {
      uploadedFiles.splice(
        uploadedFiles.findIndex((element) => element.id === id),
        1,
      );
      setUploadedFiles([...uploadedFiles]);
    },
    [uploadedFiles],
  );

  /*-----------------------------------------------------------------------*/

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRefIndividual.current?.setErrors({});

        let validDateCompetencia = true;

        if (dataCompetencia === '') {
          setDataCompetenciaValid(true);
        } else {
          setDataCompetenciaValid(false);
          validDateCompetencia = false;
        }

        const schema = Yup.object().shape({
          empresa: Yup.string().required('Código da empresa é obrigatório!'),
          nomeEmpresa: Yup.string().required('Nome da empresa é obrigatório!'),
          nome: Yup.string().required('Nome é obrigatório!'),
          cpf: Yup.string().required('CPF é obrigatório!'),
          salarioBase: Yup.string().required('Salário base é obrigatório!'),
          referencia: Yup.string().required('Referência é obrigatória!'),
          salarioLiquido: Yup.string().required(
            'Salário líquido é obrigatório!',
          ),
          somaDescontosTotais: Yup.string().required(
            'Soma dos descontos totais é obrigatória!',
          ),
          somaProventosTotais: Yup.string().required(
            'Soma dos proventos totais é obrigatória!',
          ),
          verbas: Yup.string().required('Verbas são obrigatórias!'),
          descontosProventosPorVerbas: Yup.string().required(
            'Descontos dos proventos por verbas são obrigatórios!',
          ),
          tipoCodigoVerba: Yup.string().required(
            'Tipo de código da verba é obrigatório!',
          ),
          centroCusto: Yup.string().required('Centro de custo é obrigatório!'),
          codigoVerba: Yup.string().required('Código da verba é obrigatório!'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        let CPF = data.cpf.replace(/\./g, '');
        CPF = CPF.replace(/-/g, '');

        if (!validDateCompetencia) {
          setLoading(true);
          await api
            .post('cadastroIndividual/cadastroIndividualContraChequeDecimo', {
              EMPRESA: data.empresa,
              NOME_EMPRESA: data.nomeEmpresa,
              CNPJ: findFilialCnpj(responseFiliais, data.cpf),
              NOME: data.nome,
              MATRICULA: findFuncMatricula(responseFuncionarios, data.cpf),
              DATA_ADMISSAO: moment(
                findFuncDataAdmissao(responseFuncionarios, data.cpf),
              ).format('YYYY-MM-DD'),
              CPF: data.cpf,
              FUNCAO: findFuncFuncao(responseFuncionarios, data.cpf),
              SALARIO_BASE: data.salarioBase,
              REFERENCIA: data.referencia,
              SALARIO_LIQUIDO: data.salarioLiquido,
              PERIODO_FOLHA: dataCompetencia,
              SOMA_DESCONTOS_TOTAIS: data.somaDescontosTotais,
              SOMA_PROVENTOS_TOTAIS: data.somaProventosTotais,
              VERBAS: data.verbas,
              DESCONTOS_PROVENTOS_POR_VERBAS: data.descontosProventosPorVerbas,
              TIPO_COD_VERBA: data.tipoCodigoVerba === 'PROVENTO' ? 1 : 2,
              CENTRO_CUSTO: data.centroCusto,
              COD_VERBA: data.codigoVerba,
              PARCELA: data.parcela === '1ª parcela' ? 1 : 2,
              CPFSEMFORMATO: CPF,
            })
            .then(() => {
              setLoading(false);
              addToast({
                type: 'success',
                title: 'Cadastro efetuado',
                description:
                  'Contracheque (décimo terceiro) pré-cadastrado com sucesso!',
              });
              setTimeout(() => {
                history.go(0);
              }, 1000);
            })
            .catch((erro) => {
              setLoading(false);
              if (erro.response.data.message === 'Parcela não permitida') {
                addToast({
                  type: 'error',
                  title: 'Erro no cadastro',
                  description: 'Parcela não permitida!',
                });
              } else {
                addToast({
                  type: 'error',
                  title: 'Erro no cadastro',
                  description:
                    'Erro ao pré-cadastrar contracheque (décimo terceiro)!',
                });
              }
            });
        } else {
          addToast({
            type: 'error',
            title: 'Erro no cadastro',
            description: 'Existem campos obrigatórios em branco.',
          });
        }
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRefIndividual.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Existem campos obrigatórios em branco.',
        });
        setLoading(false);
      }
    },
    [addToast, dataCompetencia, history, responseFiliais, responseFuncionarios],
  );

  const handleChangeStatusContrachequeDecimoTerceiro = useCallback(async () => {
    setLoading(true);

    const parcelaTemp =
      trTableSelected?.PARCELA_DESCONTOS === '2'
        ? trTableSelected?.PARCELA_DESCONTOS
        : trTableSelected?.PARCELA_PROVENTOS;

    await api
      .put(
        `/cadastroIndividual/updateContraChequeDecimo?periodo=${trTableSelected?.PERIODO_FOLHA}&cpf=${trTableSelected?.CPFSEMFORMATO}&parcela=${parcelaTemp}`,
        {
          ATIVO: !trTableSelected?.ATIVO,
        },
      )
      .then(() => {
        setLoading(false);
        addToast({
          type: 'success',
          title: 'Contracheque (13º) editado',
          description: 'Contracheque (13º) editado com sucesso!',
        });
        setTimeout(() => {
          history.go(0);
        }, 1000);
      })
      .catch(() => {
        setLoading(false);
        addToast({
          type: 'error',
          title: 'Erro na edição',
          description: 'Erro ao editar contracheque (13º)!',
        });
      });
  }, [trTableSelected, addToast, history]);

  const handleConfirmChangeStatusContrachequeDecimoTerceiro = useCallback(
    (data: ResponseGet) => {
      Swal.fire({
        title: `Tem certeza que deseja alterar o status do registro de contracheque (13º) do funcionário "${
          data.RA_NOME ? data.RA_NOME.trim() : 'SEM NOME'
        }"?`,
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleChangeStatusContrachequeDecimoTerceiro();
        }
      });
    },
    [handleChangeStatusContrachequeDecimoTerceiro],
  );

  const handleAlertMaster = useCallback(() => {
    if (verificaResponse && !!responseFiliais.length) {
      setCadastrar(false);
      setDownload('');
      setSearchCpf('');
      setDataCompetencia('');
      setDataCompetenciaCsv('');
      setPeriodoQuery('');
      setDataCompetenciaValid(false);
      setDataCompetenciaCsvValid(false);
    } else if (verificaResponse && !responseFiliais.length) {
      addToast({
        type: 'info',
        title: 'Lista de filiais vazia!',
        description:
          'Favor realizar o cadastro de pelo menos uma empresa/filial previamente.',
      });
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar dados, por favor atualize a página!',
      });
    }
  }, [addToast, responseFiliais.length, verificaResponse]);

  const handleAlertEdit = useCallback(() => {
    if (verificaResponse && !responseFiliais.length) {
      addToast({
        type: 'info',
        title: 'Lista de filiais vazia!',
        description:
          'Favor realizar o cadastro de pelo menos uma empresa/filial previamente.',
      });
    } else {
      setShowEditModal(true);
    }
  }, [addToast, responseFiliais.length, verificaResponse]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response.length ? response : [];

    if (searchCpf) {
      let CPF = searchCpf.replace(/\./g, '');
      CPF = CPF.replace(/-/g, '');

      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.CPFSEMFORMATO &&
          res.CPFSEMFORMATO.toString().includes(CPF.toString()),
      );
    }
    if (searchNome) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.RA_NOME &&
          res.RA_NOME.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }
    if (searchFuncao) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.FUNCAO &&
          res.FUNCAO.toLowerCase().includes(searchFuncao.toLowerCase()),
      );
    }
    if (searchEmpresa) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.RA_FILIAL &&
          res.RA_FILIAL.toString()
            .toLowerCase()
            .includes(searchEmpresa.toString().toLowerCase()),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (a[sorting.field] !== null && b[sorting.field] !== null) {
          if (typeof a[sorting.field] === 'object') {
            return (
              reversed *
              a[sorting.field]
                .join(', ')
                .localeCompare(b[sorting.field].join(', '))
            );
          }
          if (typeof a[sorting.field] === 'string') {
            return reversed * a[sorting.field].localeCompare(b[sorting.field]);
          }
          return (
            reversed *
            a[sorting.field]
              .toString()
              .localeCompare(b[sorting.field].toString())
          );
        }
        return a;
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    response,
    searchCpf,
    searchNome,
    searchFuncao,
    searchEmpresa,
    sorting.field,
    sorting.order,
    ITEMS_PER_PAGE,
    currentPage,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchCpf, searchNome, searchFuncao, searchEmpresa]);

  return (
    <>
      <ModalConexao />
      <Container>
        {accessUser.ESCRITA && (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button
                style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
                type="button"
                onClick={() => setCadastrar(true)}
              >
                Listar contracheques (13º salário)
              </button>
            </ContainerAba>
            <ContainerAba className="aba1" cor={!cadastrar}>
              <button
                style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7 }}
                type="button"
                onClick={() => handleAlertMaster()}
              >
                Cadastrar contracheques (13º salário)
              </button>
            </ContainerAba>
          </Aba>
        )}

        {cadastrar && (
          <ContainerFilterPeriodo
            ref={formRefFilter}
            onSubmit={handleSearchPeriodoContraChequeDecimoTerceiro}
          >
            <InputDate
              nameProps="periodoFilter"
              placeholderProps="Buscar período"
              pickerProps="month"
              widthProps="auto"
              dateFormatReturn={(e) => setPeriodoQuery(e)}
            />
            <SelectCustom
              name="parcelaFilter"
              widthProps="auto"
              defaultValue="Parcela"
              icon={BsInfoSquare}
              optionsData={['Todas', '1ª parcela', '2ª parcela']}
            />
            <button
              type="submit"
              onClick={handleSearchPeriodoContraChequeDecimoTerceiro}
            >
              <FiSearch size={24} />
            </button>
          </ContainerFilterPeriodo>
        )}

        {cadastrar && !!response.length && (
          <ContainerSearch>
            <SearchCustom
              onSearch={(value: string) => {
                setSearchCpf(value);
              }}
              nomePlaceHolder="Buscar CPF"
              typeProps="cpf"
            />
            <SearchCustom
              onSearch={(value: string) => {
                setSearchNome(value);
              }}
              nomePlaceHolder="Buscar nome"
            />
            <SearchCustom
              onSearch={(value: string) => {
                setSearchFuncao(value);
              }}
              nomePlaceHolder="Buscar função"
            />
            <SearchCustom
              onSearch={(value: string) => {
                setSearchEmpresa(value);
              }}
              nomePlaceHolder="Buscar empresa"
            />
          </ContainerSearch>
        )}

        <ContainerCadastro cor={!cadastrar}>
          {!cadastrar && (
            <ConainerCadastroOption>
              <RadioBox
                type="button"
                onClick={() => setRegisterType('individual')}
                isActive={registerType === 'individual'}
                activeColor="green"
              >
                <AiOutlineUserAdd size={28} />
                Cadastro individual
              </RadioBox>
              <RadioBox
                type="button"
                onClick={() => setRegisterType('lote')}
                isActive={registerType === 'lote'}
                activeColor="red"
              >
                <FaFileCsv size={28} color="#fff" />
                Cadastro em lote
              </RadioBox>
            </ConainerCadastroOption>
          )}

          {!cadastrar &&
            (registerType === 'individual' ? (
              <div className="divUnicAnimationContainer">
                <AnimationContainer className="divAnimationContainer">
                  <header>
                    <AiOutlineUserAdd size={24} />
                  </header>

                  <Form ref={formRefIndividual} onSubmit={handleSubmit}>
                    <ContentForm>
                      <SelectCustom
                        name="empresa"
                        widthProps="49%"
                        icon={BsBuilding}
                        defaultValue="Código da empresa"
                        optionsData={responseFiliais.map((filial) => {
                          return filial.CODIGO_FILIAL;
                        })}
                        onValue={(value) =>
                          setFilialName(findFilialName(responseFiliais, value))
                        }
                      />
                      <Input
                        name="nomeEmpresa"
                        icon={BsBuilding}
                        widthProps="49%"
                        placeholder="Nome da empresa"
                        value={filialName}
                        disabled
                      />
                    </ContentForm>

                    <ContentForm>
                      <Input
                        name="cpf"
                        icon={FiUser}
                        widthProps="49%"
                        placeholder="CPF do funcionário"
                        mask="cpf"
                        value={funcCpf}
                        onValue={(value) => setFuncCpf(value)}
                      />
                      <Input
                        name="nome"
                        icon={FiUser}
                        widthProps="49%"
                        placeholder="Nome do funcionário"
                        value={findFuncName(responseFuncionarios, funcCpf)}
                        disabled
                      />
                    </ContentForm>

                    <ContentForm>
                      <Input
                        name="salarioBase"
                        icon={FaRegMoneyBillAlt}
                        widthProps="49%"
                        placeholder="Salário base do funcionário"
                        mask="coin"
                      />
                      <Input
                        name="salarioLiquido"
                        icon={FaRegMoneyBillAlt}
                        widthProps="49%"
                        placeholder="Salário líquido do funcionário"
                        mask="coin"
                      />
                    </ContentForm>

                    <ContentForm>
                      <Input
                        name="referencia"
                        icon={MdDateRange}
                        widthProps="49%"
                        placeholder="Referência"
                        type="number"
                      />
                      <Input
                        name="somaDescontosTotais"
                        icon={IoCalculatorOutline}
                        widthProps="49%"
                        placeholder="Soma dos descontos totais"
                        mask="coin"
                      />
                    </ContentForm>

                    <ContentForm>
                      <Input
                        name="somaProventosTotais"
                        icon={IoCalculatorOutline}
                        widthProps="49%"
                        placeholder="Soma dos proventos totais"
                        mask="coin"
                      />
                      <Input
                        name="verbas"
                        icon={IoDocumentTextOutline}
                        widthProps="49%"
                        placeholder="Verbas"
                      />
                    </ContentForm>

                    <ContentForm>
                      <Input
                        name="descontosProventosPorVerbas"
                        icon={IoCalculatorOutline}
                        widthProps="49%"
                        placeholder="Descontos de proventos por verbas"
                        mask="coin"
                      />
                      <Input
                        name="codigoVerba"
                        icon={IoDocumentTextOutline}
                        widthProps="49%"
                        placeholder="Código da verba"
                        maxLength={5}
                      />
                    </ContentForm>

                    <ContentForm>
                      <Input
                        name="centroCusto"
                        icon={IoDocumentTextOutline}
                        widthProps="49%"
                        placeholder="Centro de custo"
                      />
                      <SelectCustom
                        name="tipoCodigoVerba"
                        icon={IoDocumentTextOutline}
                        widthProps="49%"
                        defaultValue="Tipo de código de verba"
                        optionsData={['PROVENTO', 'DESCONTO']}
                      />
                    </ContentForm>

                    <ContentForm>
                      <InputDate
                        nameProps="dataCompetencia"
                        pickerProps="month"
                        placeholderProps="Período da competência"
                        validProps={dataCompetenciaValid}
                        widthProps="49%"
                        dateFormatReturn={(e: string) => setDataCompetencia(e)}
                      />
                      <SelectCustom
                        name="parcela"
                        widthProps="49%"
                        defaultValue="Parcela"
                        icon={BsInfoSquare}
                        optionsData={['1ª parcela', '2ª parcela']}
                      />
                    </ContentForm>

                    <ContainerButton>
                      <button type="submit" id="btnCadastroIndividual">
                        Cadastrar
                      </button>
                    </ContainerButton>
                  </Form>
                </AnimationContainer>
              </div>
            ) : (
              <div className="divUnicAnimationContainer">
                <AnimationContainer className="divAnimationContainer">
                  <header>
                    <FaFileCsv size={24} color="#fff" />
                  </header>

                  <Form ref={formRefEmLote} onSubmit={clickHandleUpload}>
                    <InputDate
                      nameProps="dataCompetenciaCsv"
                      pickerProps="month"
                      placeholderProps="Selecione o período da competência"
                      validProps={dataCompetenciaCsvValid}
                      dateFormatReturn={(e: string) => setDataCompetenciaCsv(e)}
                    />
                    <Content>
                      <Upload
                        onUpload={(e: any) => handleUpload(e)}
                        paddingProps="70px"
                      />
                      <FileList
                        files={uploadedFiles}
                        heightProps="152px"
                        onDelete={(e: string) => handleDelete(e)}
                      />
                    </Content>
                    <ContainerButton>
                      <button type="submit">Cadastrar</button>
                      <button
                        type="button"
                        onClick={() => {
                          setDownload(urlToDownload);
                          setCount((old) => old + 1);
                        }}
                      >
                        <AiOutlineDownload size={24} />
                        Baixar Modelo
                      </button>
                      {download && (
                        <iframe
                          title="downloadModeloCsv"
                          src={`${download}?c=${count}`}
                          hidden
                        />
                      )}
                    </ContainerButton>
                  </Form>
                </AnimationContainer>
              </div>
            ))}

          {cadastrar && !responseData.length && verificaResponseDecimoTerceiro && (
            <ContainerSemResultados>
              <h2>
                Não existem contracheques (décimo terceiro) pré-cadastrados
                ainda dentro do período selecionado.
              </h2>
            </ContainerSemResultados>
          )}

          {cadastrar && !!responseData.length && (
            <div className="divTable">
              <ContainerTable>
                <table>
                  <HeaderTable
                    headers={headers}
                    onSorting={(field: string, order: string) =>
                      setSorting({ field, order })
                    }
                  />
                  <tbody>
                    {responseData.map((res) => (
                      <tr
                        key={res.ID}
                        onClick={() =>
                          trTableSelected === res
                            ? setTrTableSelected(undefined)
                            : setTrTableSelected(res)
                        }
                        style={{
                          background:
                            trTableSelected && trTableSelected.ID === res.ID
                              ? '#737373'
                              : '',
                        }}
                      >
                        <td>{res.CPF ? maskCpf(res.CPF) : '-'}</td>
                        <td>{res.RA_NOME ? res.RA_NOME : '-'}</td>
                        <td>{res.RA_MAT ? res.RA_MAT : '-'}</td>
                        <td>{res.FUNCAO ? res.FUNCAO : '-'}</td>
                        <td>{res.RA_FILIAL ? res.RA_FILIAL : '-'}</td>
                        <td>{res.NOME_EMPRESA ? res.NOME_EMPRESA : '-'}</td>
                        <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                        <td>
                          {res.DATA_ADMISSAO
                            ? moment(res.DATA_ADMISSAO).format('DD/MM/YYYY')
                            : '-'}
                        </td>
                        <td>{res.PARCELA_DESCONTOS ? 'Sim' : 'Não'}</td>
                        <td>{res.PARCELA_PROVENTOS ? 'Sim' : 'Não'}</td>
                        <td>{res.REFERENCIA ? res.REFERENCIA : '0'}</td>
                        <td>
                          {res.SALARIO_BASE
                            ? new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(res.SALARIO_BASE)
                            : '-'}
                        </td>
                        <td>
                          {res.SALARIO_LIQUIDO
                            ? new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(res.SALARIO_LIQUIDO)
                            : '-'}
                          {res.SALARIO_LIQUIDO}
                        </td>
                        <td>
                          {res.SOMA_DESCONTOS_TOTAIS
                            ? new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(res.SOMA_DESCONTOS_TOTAIS)
                            : '-'}
                        </td>
                        <td>
                          {res.SOMA_PROVENTOS_TOTAIS
                            ? new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(res.SOMA_PROVENTOS_TOTAIS)
                            : '-'}
                        </td>
                        <td>{res.COD_VERBA ? res.COD_VERBA : '-'}</td>
                        <td>
                          {res.TIPO_COD_VERBA && res.TIPO_COD_VERBA === '1'
                            ? 'PROVENTO'
                            : res.TIPO_COD_VERBA && res.TIPO_COD_VERBA === '2'
                            ? 'DESCONTO'
                            : '-'}
                        </td>
                        <td>{res.VERBAS ? res.VERBAS : '-'}</td>
                        <td>
                          {res.DESCONTOS_PROVENTOS_POR_VERBAS
                            ? new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(res.DESCONTOS_PROVENTOS_POR_VERBAS)
                            : '-'}
                        </td>
                        <td>
                          {res.DESC_CENTRO_CUSTO ? res.DESC_CENTRO_CUSTO : '-'}
                        </td>
                        <td>
                          {res.PERIODO_FOLHA
                            ? moment(res.PERIODO_FOLHA).format('MM/YYYY')
                            : '-'}
                        </td>
                        <td style={{ padding: 5 }}>
                          <div
                            style={{
                              background: `${
                                !res.ATIVO
                                  ? 'rgb(255, 51, 51)'
                                  : 'rgb(128, 128, 255)'
                              }`,
                              width: '100%',
                              height: '100%',
                              borderRadius: 4,
                            }}
                          >
                            {!!res.ATIVO && 'Ativo'}
                            {!res.ATIVO && 'Inativo'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table
                  id="tableListagemContrachequeDecimoTerceiro"
                  style={{ display: 'none' }}
                >
                  <HeaderTable
                    headers={headers}
                    onSorting={(field: string, order: string) =>
                      setSorting({ field, order })
                    }
                  />
                  <tbody>
                    {responseData.map((res) => (
                      <tr key={res.ID}>
                        <td>{res.CPF ? maskCpf(res.CPF) : '-'}</td>
                        <td>{res.RA_NOME ? res.RA_NOME : '-'}</td>
                        <td>{res.RA_MAT ? res.RA_MAT : '-'}</td>
                        <td>{res.FUNCAO ? res.FUNCAO : '-'}</td>
                        <td>{res.RA_FILIAL ? res.RA_FILIAL : '-'}</td>
                        <td>{res.NOME_EMPRESA ? res.NOME_EMPRESA : '-'}</td>
                        <td>{res.CNPJ ? maskCnpj(res.CNPJ) : '-'}</td>
                        <td>
                          {res.DATA_ADMISSAO
                            ? moment(res.DATA_ADMISSAO).format('DD/MM/YYYY')
                            : '-'}
                        </td>
                        <td>
                          {res.PARCELA_DESCONTOS ? res.PARCELA_DESCONTOS : '-'}
                        </td>
                        <td>
                          {res.PARCELA_PROVENTOS ? res.PARCELA_PROVENTOS : '-'}
                        </td>
                        <td>{res.REFERENCIA ? res.REFERENCIA : '0'}</td>
                        <td>
                          {res.SALARIO_BASE
                            ? new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(res.SALARIO_BASE)
                            : '-'}
                        </td>
                        <td>
                          {res.SALARIO_LIQUIDO
                            ? new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(res.SALARIO_LIQUIDO)
                            : '-'}
                          {res.SALARIO_LIQUIDO}
                        </td>
                        <td>
                          {res.SOMA_DESCONTOS_TOTAIS
                            ? new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(res.SOMA_DESCONTOS_TOTAIS)
                            : '-'}
                        </td>
                        <td>
                          {res.SOMA_PROVENTOS_TOTAIS
                            ? new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(res.SOMA_PROVENTOS_TOTAIS)
                            : '-'}
                        </td>
                        <td>{res.COD_VERBA ? res.COD_VERBA : '-'}</td>
                        <td>
                          {res.TIPO_COD_VERBA && res.TIPO_COD_VERBA === '1'
                            ? 'PROVENTO'
                            : res.TIPO_COD_VERBA && res.TIPO_COD_VERBA === '2'
                            ? 'DESCONTO'
                            : '-'}
                        </td>
                        <td>{res.VERBAS ? res.VERBAS : '-'}</td>
                        <td>
                          {res.DESCONTOS_PROVENTOS_POR_VERBAS
                            ? new Intl.NumberFormat('pt-BR', {
                                style: 'currency',
                                currency: 'BRL',
                              }).format(res.DESCONTOS_PROVENTOS_POR_VERBAS)
                            : '-'}
                        </td>
                        <td>
                          {res.DESC_CENTRO_CUSTO ? res.DESC_CENTRO_CUSTO : '-'}
                        </td>
                        <td>
                          {res.PERIODO_FOLHA
                            ? moment(res.PERIODO_FOLHA).format('MM/YYYY')
                            : '-'}
                        </td>
                        <td>
                          {!!res.ATIVO && 'Ativo'}
                          {!res.ATIVO && 'Inativo'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ContainerTable>

              <ContainerPagination>
                {responseData.length > 0 && (
                  <div id="divPaginacao">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: number) => setCurrentPage(page)}
                    />
                    <DropDownPagination
                      onChangeItems={(value: string) => {
                        setCurrentPage(1);
                        if (value === 'Todos') {
                          setITEMS_PER_PAGE(1);
                        } else {
                          setITEMS_PER_PAGE(Number(value));
                        }
                      }}
                      objetoEnum={dropDown}
                      minWidth={30}
                    />

                    <div id="divBtnTable">
                      {accessUser.ESCRITA && (
                        <>
                          <CustomTooltipEdit
                            title={
                              !trTableSelected
                                ? 'Selecione um registro de contracheque (13º) primeiro'
                                : 'Editar'
                            }
                            arrow
                            TransitionComponent={Zoom}
                          >
                            <span>
                              <ButtonEdit
                                disabled={!trTableSelected}
                                style={{
                                  cursor: !trTableSelected ? 'not-allowed' : '',
                                  background: !trTableSelected ? '#009900' : '',
                                  filter: !trTableSelected
                                    ? 'brightness(1)'
                                    : '',
                                }}
                                onClick={handleAlertEdit}
                              >
                                <AiFillEdit size={26} />
                              </ButtonEdit>
                            </span>
                          </CustomTooltipEdit>

                          <CustomTooltipUpdateStatus
                            title={
                              !trTableSelected
                                ? 'Selecione um registro de contracheque (13º) primeiro'
                                : trTableSelected.ATIVO
                                ? 'Inativar contracheque (13º)'
                                : 'Ativar contracheque (13º)'
                            }
                            arrow
                            TransitionComponent={Zoom}
                          >
                            <span>
                              <ButtonEdit
                                disabled={!trTableSelected}
                                style={{
                                  cursor: !trTableSelected ? 'not-allowed' : '',
                                  background: !trTableSelected
                                    ? '#009900'
                                    : trTableSelected.ATIVO
                                    ? '#ff3333'
                                    : '#8080ff',
                                  filter: !trTableSelected
                                    ? 'brightness(1)'
                                    : '',
                                }}
                                onClick={() =>
                                  trTableSelected &&
                                  handleConfirmChangeStatusContrachequeDecimoTerceiro(
                                    trTableSelected,
                                  )}
                              >
                                {!trTableSelected ? (
                                  <HiOutlineDocument size={26} />
                                ) : trTableSelected.ATIVO ? (
                                  <HiOutlineDocumentRemove size={26} />
                                ) : (
                                  <HiOutlineDocumentAdd size={26} />
                                )}
                              </ButtonEdit>
                            </span>
                          </CustomTooltipUpdateStatus>
                        </>
                      )}

                      <ReactHTMLTableToExcel
                        id="export-excel"
                        className="btn"
                        table="tableListagemContrachequeDecimoTerceiro"
                        filename={`listagem-contracheque-decimoTerceiro-${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        sheet="tablexls"
                        buttonText="Exportar Excel"
                      />
                    </div>
                  </div>
                )}
              </ContainerPagination>
            </div>
          )}
        </ContainerCadastro>
      </Container>

      {loading && <Loading />}
      {showEditModal && trTableSelected && (
        <EditContrachequeDecimoTerceiroModal
          data={trTableSelected}
          filiais={responseFiliais}
          onClose={(e) => setShowEditModal(!e)}
        />
      )}
      {showModalResponse && (
        <ModalResponseCsv
          dados={dataCsvModal}
          onClose={() => setShowModalResponse(false)}
        />
      )}
    </>
  );
};

export default CadastroContraCheque;
