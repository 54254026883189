import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { FiType, FiEdit2, FiTrash } from 'react-icons/fi';
import { HiOutlineDocumentDuplicate } from 'react-icons/hi';
import { ImBlocked } from 'react-icons/im';
import { Loading } from '../../../components/Loading';
import api from '../../../services/api';
import getValidationErrors from '../../../utils/getValidationErrors';
import ModalConexao from '../../../components/ModalConexao';
import Pagination from '../../../components/Pagination';
import Search from '../../../components/Search';
import HeaderTable from '../../../components/HeaderTable';
import Input from '../../../components/Input';
import DropDownPagination from '../../../components/DropDownPagination';
import Checkbox from '../../../components/CheckBox';
import { useAuth } from '../../../hooks/auth';

import {
  Container,
  Aba,
  ContainerAba,
  ContainerSearch,
  ContainerFundoCadastro,
  ContainerCadastro,
  AnimationContainer,
  ContainerFuncoes,
  ContainerButton,
  ContainerTable,
  ContainerPagination,
  ContainerLabel,
} from './styles';

interface SignUpFormData {
  nome: string;
}

interface ResponseGetOffice {
  ID: number;
  NOME: string;
  id_documentacao: number[];
  documentacao: string[];
}

interface ResponseGetDocument {
  cargo: string;
  documentacao: string;
  id_cargo: number;
  id_documentacao: number;
}

interface ResponseGetListDocuments {
  ID: number;
  NOME: string;
}

interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

const headers = [
  { name: 'Nome', field: 'name' },
  { name: 'Documentação', field: 'documents' },
  { name: 'Editar Nome', field: 'editName' },
  { name: 'Editar Documentação', field: 'editDocuments' },
  { name: 'Remover', field: 'remove' },
];

const dropDown = [
  { valor: '20', id: '20' },
  { valor: '30', id: '30' },
  { valor: '40', id: '40' },
  { valor: '50', id: '50' },
  { valor: 'Todos', id: '1' },
];

const pageAccess = {
  ID: 11,
  NOME: 'PRE-CADASTRO-ADMISSAO',
};

const CadastroCargo: React.FC = () => {
  const history = useHistory();
  const { modulos } = useAuth();
  const formRef = useRef<FormHandles>(null);
  const formRefEdit = useRef<FormHandles>(null);
  const formRefDoc = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [responsePositions, setResponsePositions] = useState<
    ResponseGetOffice[]
  >([]);
  const [responseListDocuments, setResponseListDocuments] = useState<
    ResponseGetListDocuments[]
  >([]);
  const [cadastrar, setCadastrar] = useState(true);
  const [editar, setEditar] = useState(false);
  const [cadastrarDoc, setCadastrarDoc] = useState(false);
  const [nomeCargo, setNomeCargo] = useState('');
  const [defaultListDocuments, setDefaultListDocuments] = useState(true);

  const [verificaListDocuments, setVerificaListDocuments] = useState<string[]>(
    [],
  );
  const [
    verificaListDocumentsDelete,
    setVerificaListDocumentsDelete,
  ] = useState<string[]>([]);

  const [cargoEdit, setCargoEdit] = useState<ResponseGetOffice>();
  const [addListDocuments, setAddListDocuments] = useState('#439133');

  const [documento, setDocumento] = useState<number[]>([]);
  const [documentoNome, setDocumentoNome] = useState<string[]>([]);

  const [documentoDelete, setDocumentoDelete] = useState<number[]>([]);
  const [documentoNomeDelete, setDocumentoNomeDelete] = useState<string[]>([]);

  const [documentListFormat, setDocumentListFormat] = useState<
    ResponseGetListDocuments[]
  >([]);

  const [searchCargo, setSearchCargo] = useState('');
  const [searchDocumento, setSearchDocumento] = useState('');

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });

  const getItens = useCallback(async () => {
    setLoading(true);

    try {
      const dataPositions = await api.get('cargos/');
      const dataDocuments = await api.get('cargos/showDocumentacaoCargos');
      const dataListDocuments = await api.get('/documentacao/showDocumentacao');

      const dataPositionsCopy = dataPositions.data.map(
        (data: ResponseGetOffice) => {
          return {
            ...data,
            id_documentacao: [],
            documentacao: [],
          };
        },
      );

      dataPositions.data.forEach((data: ResponseGetOffice, index: number) => {
        dataDocuments.data.forEach((dataTemp: ResponseGetDocument) => {
          if (data.ID === dataTemp.id_cargo) {
            dataPositionsCopy[index].id_documentacao.push(
              dataTemp.id_documentacao,
            );
            dataPositionsCopy[index].documentacao.push(dataTemp.documentacao);
          }
        });
        if (
          dataPositionsCopy[index].documentacao.indexOf(
            'Solicitação de Vaga',
          ) === -1
        ) {
          dataPositionsCopy[index].id_documentacao.push(24);
          dataPositionsCopy[index].documentacao.push('Solicitação de Vaga');
        }
      });

      setResponsePositions(dataPositionsCopy);
      setResponseListDocuments(dataListDocuments.data);

      setLoading(false);
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'info',
        title: 'Listagem de cargos vazia!',
        text:
          'Favor realizar o primeiro cadastro. Caso o erro persista, atualize a página.',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, []);

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);

    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title: 'Você não tem permissão para acessar essa página!',
      });
    } else {
      getItens();
    }
  }, [modulos, history, getItens]);

  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRef.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome do cargo é obrigatório !'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .post('cargos/saveCargo', {
            nome: data.nome,
          })
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Cargo cadastrado com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao cadastrar cargo!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
        }
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao cadastrar cargo!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [history],
  );

  const handleSubmitEdit = useCallback(
    async (data: SignUpFormData) => {
      try {
        setLoading(true);
        formRefEdit.current?.setErrors({});
        const schema = Yup.object().shape({
          nome: Yup.string().required('Nome do cargo é obrigatório !'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await api
          .put(`cargos/updateCargo`, {
            id: cargoEdit?.ID,
            nome: data.nome,
          })
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Cargo editado com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao editar cargo!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          setLoading(false);
          const errors = getValidationErrors(err);

          formRefEdit.current?.setErrors(errors);
        }
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao editar cargo!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [cargoEdit, history],
  );

  const handleSubmitDoc = useCallback(async () => {
    try {
      setLoading(true);

      const documentoTemp: number[] = [];

      documento.forEach((element) => {
        if (element !== 24) documentoTemp.push(element);
      });

      await api
        .post('cargos/insertCargosDocumentos', {
          idCargo: cargoEdit?.ID,
          idDoc: documentoTemp,
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Documento(s) vinculado(s) com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
          setTimeout(() => {
            history.go(0);
            setDocumentListFormat([]);
            setDocumento([]);
            setDocumentoDelete([]);
            setDocumentoNome([]);
            setDocumentoNomeDelete([]);
          }, 1000);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro ao vincular documento(s)!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao vincular documento(s)!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [cargoEdit, documento, history]);

  const handleSubmitDocDelete = useCallback(async () => {
    try {
      setLoading(true);

      const documentoDeleteTemp: number[] = [];

      documentoDelete.forEach((element) => {
        if (element !== 24) documentoDeleteTemp.push(element);
      });

      await api
        .post('cargos/deleteCargoDocumentacao', {
          idCargo: cargoEdit?.ID,
          idDoc: documentoDeleteTemp,
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Documento(s) removidos(s) com sucesso!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
          setTimeout(() => {
            history.go(0);
            setDocumentListFormat([]);
            setDocumento([]);
            setDocumentoDelete([]);
            setDocumentoNome([]);
            setDocumentoNomeDelete([]);
          }, 1000);
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Erro ao remover documento(s)!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'error',
        title: 'Erro ao remover documento(s)!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [cargoEdit, documentoDelete, history]);

  const handleDelete = useCallback(
    async (dataDelete: ResponseGetOffice) => {
      try {
        setLoading(true);

        await api
          .delete(`cargos/deleteCargo/${dataDelete.ID}`)
          .then(() => {
            setLoading(false);
            Swal.fire({
              icon: 'success',
              title: 'Cargo removido com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            setTimeout(() => {
              history.go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);
            Swal.fire({
              icon: 'error',
              title: 'Erro ao remover cargo!',
              text:
                'Favor desvincular todos os documentos antes de remover o cargo!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      } catch (err) {
        setLoading(false);
        Swal.fire({
          icon: 'error',
          title: 'Erro ao remover cargo!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [history],
  );

  const handleChangeDocuments = useCallback(
    (dados: ResponseGetListDocuments, value: boolean) => {
      responseListDocuments.forEach((box) => {
        if (box.ID === dados.ID) {
          if (value) {
            if (documentoNome.indexOf(dados.NOME) === -1) {
              setDocumento(documento.concat(box.ID));
              setDocumentoNome(documentoNome.concat(box.NOME));
            }
          } else {
            documento.splice(documento.indexOf(dados.ID), 1);
            documentoNome.splice(documentoNome.indexOf(dados.NOME), 1);
            setDocumento([...documento]);
            setDocumentoNome([...documentoNome]);
          }
        }
      });
    },
    [documento, documentoNome, responseListDocuments],
  );

  const handleChangeDocumentsDelete = useCallback(
    (dados: ResponseGetListDocuments, value: boolean) => {
      documentListFormat.forEach((box) => {
        if (box.ID === dados.ID) {
          if (value) {
            if (documentoDelete.indexOf(dados.ID) === -1) {
              setDocumentoDelete(documentoDelete.concat(box.ID));
            }
            if (documentoNomeDelete.indexOf(dados.NOME) === -1) {
              setDocumentoNomeDelete(documentoNomeDelete.concat(box.NOME));
            }
          } else {
            setDocumentoDelete(
              documentoDelete.filter((item) => item !== dados.ID),
            );
            setDocumentoNomeDelete(
              documentoNomeDelete.filter((item) => item !== dados.NOME),
            );
          }
        }
      });
    },
    [documentListFormat, documentoDelete, documentoNomeDelete],
  );

  const verificaCheckListDocuments = useCallback(
    (dado: ResponseGetListDocuments): boolean => {
      const data = verificaListDocuments.filter((r: string) => r === dado.NOME);
      if (defaultListDocuments === false) {
        if (data.length > 0) {
          if (documento.indexOf(dado.ID) === -1) {
            documento.push(dado.ID);
          }
          return true;
        }
      }
      return false;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [verificaListDocuments, defaultListDocuments],
  );

  const verificaCheckListDocumentsDelete = useCallback(
    (dado: ResponseGetListDocuments): boolean => {
      const data = verificaListDocumentsDelete.filter(
        (r: string) => r === dado.NOME,
      );
      if (defaultListDocuments === false) {
        if (data.length > 0) {
          if (documentoDelete.indexOf(dado.ID) === -1) {
            documentoDelete.push(dado.ID);
          }
          return true;
        }
      }
      return false;
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [verificaListDocumentsDelete, defaultListDocuments],
  );

  const validCheck = useCallback(
    (item: string): boolean => {
      let computedResponses = false;

      if (documentoNome.indexOf(item) !== -1) {
        computedResponses = true;
      }

      return computedResponses;
    },
    [documentoNome],
  );

  const validCheckDelete = useCallback(
    (itemNome: string, itemId: number): boolean => {
      let computedResponses = false;

      if (
        documentoNomeDelete.indexOf(itemNome) !== -1 &&
        documentoDelete.indexOf(itemId) !== -1
      ) {
        computedResponses = true;
      }

      return computedResponses;
    },
    [documentoDelete, documentoNomeDelete],
  );

  const responseData = useMemo(() => {
    let computedResponses: ResponseGetOffice[] = [];
    computedResponses = responsePositions.length ? responsePositions : [];

    if (searchCargo) {
      computedResponses = computedResponses.filter((res: ResponseGetOffice) =>
        res.NOME.toString().toLowerCase().includes(searchCargo),
      );
    }

    if (searchDocumento) {
      computedResponses = computedResponses.filter((res: ResponseGetOffice) =>
        res.documentacao
          .join(', ')
          .toLowerCase()
          .includes(searchDocumento.toLowerCase()),
      );
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses;
  }, [ITEMS_PER_PAGE, responsePositions, searchCargo, searchDocumento]);

  return (
    <>
      <ModalConexao />
      <Container>
        {accessUser.ESCRITA && (
          <Aba>
            <ContainerAba className="aba" cor={cadastrar}>
              <button
                style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
                type="button"
                onClick={() => {
                  setCadastrar(true);
                  setCadastrarDoc(false);
                }}
              >
                Listar Cargos
              </button>
            </ContainerAba>

            <ContainerAba className="aba1" cor={!cadastrar}>
              <button
                style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7 }}
                type="button"
                onClick={() => {
                  setCadastrar(false);
                  setCadastrarDoc(false);
                  setSearchCargo('');
                  setSearchDocumento('');
                }}
              >
                Cadastrar Cargo
              </button>
            </ContainerAba>
          </Aba>
        )}

        <ContainerSearch>
          {cadastrar && !editar && (
            <div>
              <Search
                className="search1"
                onSearch={(value: string) => {
                  setSearchCargo(value);
                }}
                nomePlaceHolder="Buscar Cargo"
              />
              <Search
                className="search1"
                onSearch={(value: string) => {
                  setSearchDocumento(value);
                }}
                nomePlaceHolder="Buscar Documento"
              />
            </div>
          )}
        </ContainerSearch>

        <ContainerFundoCadastro cor={!cadastrar}>
          {!cadastrar && !cadastrarDoc && (
            <ContainerCadastro>
              <AnimationContainer>
                <h1>Cadastro de Cargo</h1>
                <Form ref={formRef} onSubmit={handleSubmit}>
                  <div>
                    <Input
                      name="nome"
                      icon={FiType}
                      placeholder="Nome do Cargo"
                    />
                  </div>
                  <ContainerButton>
                    <button type="submit">Cadastrar</button>
                  </ContainerButton>
                </Form>
              </AnimationContainer>
            </ContainerCadastro>
          )}

          {!cadastrar && cadastrarDoc && !editar && (
            <ContainerCadastro>
              <AnimationContainer>
                <h1>Documentos Necessários</h1>
                <Form
                  ref={formRefDoc}
                  onSubmit={
                    addListDocuments === '#1a3814'
                      ? handleSubmitDoc
                      : handleSubmitDocDelete
                  }
                >
                  <div>
                    <Input
                      name="nome"
                      icon={FiType}
                      value={nomeCargo}
                      disabled
                    />
                  </div>
                  <div className="divFuncoes">
                    <p>Lista de Documentos:</p>
                  </div>
                  <div className="divAddDelete">
                    <button
                      type="button"
                      onClick={() => {
                        setAddListDocuments('#1a3814');
                        setVerificaListDocumentsDelete(documentoNomeDelete);
                      }}
                    >
                      Adicionar
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        setAddListDocuments('#330000');
                        setVerificaListDocuments(documentoNome);
                      }}
                    >
                      Remover
                    </button>
                  </div>

                  <ContainerFuncoes>
                    <div className="divCheck">
                      {addListDocuments === '#1a3814' &&
                        responseListDocuments.map((item) => (
                          <ContainerLabel
                            key={item.ID}
                            htmlFor={item.NOME}
                            cor={validCheck(item.NOME)}
                            status={addListDocuments}
                          >
                            <Checkbox
                              onChangeCheck={(value: boolean) => {
                                handleChangeDocuments(item, value);
                              }}
                              valorCheck={
                                defaultListDocuments === false &&
                                verificaCheckListDocuments(item)
                              }
                              disabledCheck={item.ID === 24}
                            />

                            {item.NOME}
                          </ContainerLabel>
                        ))}

                      {addListDocuments === '#330000' &&
                        documentListFormat.map((item) => (
                          <ContainerLabel
                            key={item.ID}
                            htmlFor={item.NOME}
                            cor={validCheckDelete(item.NOME, item.ID)}
                            status={addListDocuments}
                          >
                            <Checkbox
                              onChangeCheck={(value: boolean) => {
                                handleChangeDocumentsDelete(item, value);
                              }}
                              valorCheck={
                                defaultListDocuments === false &&
                                verificaCheckListDocumentsDelete(item)
                              }
                              disabledCheck={item.ID === 24}
                            />

                            {item.NOME}
                          </ContainerLabel>
                        ))}
                    </div>
                  </ContainerFuncoes>

                  <ContainerButton>
                    <div>
                      <button
                        type="button"
                        className="divButtonCancelar"
                        onClick={() => {
                          setCadastrar(true);
                          setCadastrarDoc(false);
                          setDocumento([]);
                          setDocumentoDelete([]);
                          setDocumentoNome([]);
                          setDocumentoNomeDelete([]);
                          setDocumentListFormat([]);
                        }}
                      >
                        Cancelar
                      </button>
                      <button type="submit">Salvar</button>
                    </div>
                  </ContainerButton>
                </Form>
              </AnimationContainer>
            </ContainerCadastro>
          )}

          {cadastrar && !cadastrarDoc && !editar && (
            <div className="divTable">
              <ContainerTable>
                <table id="tableListagemCargos">
                  <HeaderTable headers={headers} />
                  <tbody>
                    {!!responseData.length &&
                      responseData.map((res) => (
                        <tr key={res.ID}>
                          <td>{res.NOME}</td>
                          <td>{res.documentacao.join(', ')}</td>
                          <td>
                            {accessUser.ESCRITA && (
                              <button
                                type="button"
                                onClick={() => {
                                  setCadastrarDoc(false);
                                  setEditar(true);
                                  setDefaultListDocuments(false);
                                  setNomeCargo(res.NOME);
                                  setVerificaListDocuments(res.documentacao);
                                  setCargoEdit(res);
                                }}
                              >
                                <FiEdit2 size={18} />
                              </button>
                            )}
                          </td>
                          <td>
                            {accessUser.ESCRITA ? (
                              <button
                                type="button"
                                onClick={() => {
                                  setCadastrar(false);
                                  setCadastrarDoc(true);
                                  setDefaultListDocuments(false);
                                  setNomeCargo(res.NOME);
                                  setVerificaListDocuments(res.documentacao);
                                  setVerificaListDocumentsDelete(
                                    res.documentacao,
                                  );
                                  setCargoEdit(res);
                                  setDocumentoNome([...res.documentacao]);
                                  setDocumentoNomeDelete([...res.documentacao]);
                                  setAddListDocuments('#1a3814');

                                  res.id_documentacao.forEach(
                                    (element, index) => {
                                      documentListFormat.push({
                                        ID: element,
                                        NOME: res.documentacao[index],
                                      });
                                    },
                                  );

                                  setDocumentListFormat(documentListFormat);
                                }}
                              >
                                <HiOutlineDocumentDuplicate size={20} />
                              </button>
                            ) : (
                              <ImBlocked />
                            )}
                          </td>
                          <td>
                            {accessUser.ESCRITA ? (
                              <button
                                type="button"
                                onClick={() => {
                                  Swal.fire({
                                    title: 'Tem certeza que deseja remover?',
                                    icon: 'warning',
                                    showCancelButton: true,
                                    cancelButtonText: 'Não',
                                    confirmButtonColor: '#009900',
                                    cancelButtonColor: '#d33',
                                    confirmButtonText: 'Sim',
                                  }).then((result) => {
                                    if (result.isConfirmed) {
                                      handleDelete(res);
                                    }
                                  });
                                }}
                              >
                                <FiTrash size={18} />
                              </button>
                            ) : (
                              <ImBlocked />
                            )}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </ContainerTable>

              <ContainerPagination>
                {responseData.length > 0 && (
                  <>
                    <div className="divPaginacao">
                      <Pagination
                        total={totalItems}
                        itemsPerPage={ITEMS_PER_PAGE}
                        currentPage={currentPage}
                        onPageChange={(page: number) => setCurrentPage(page)}
                      />

                      <DropDownPagination
                        onChangeItems={(value: string) => {
                          setCurrentPage(1);
                          if (value === 'Todos') {
                            setITEMS_PER_PAGE(1);
                          } else {
                            setITEMS_PER_PAGE(Number(value));
                          }
                        }}
                        objetoEnum={dropDown}
                        minWidth={30}
                      />
                    </div>

                    <ReactHTMLTableToExcel
                      id="export-excel"
                      className="btn"
                      table="tableListagemCargos"
                      filename={`listagem-cargos-${moment().format(
                        'DD-MM-YYYY',
                      )}`}
                      sheet="tablexls"
                      buttonText="Exportar Excel"
                    />
                  </>
                )}
              </ContainerPagination>
            </div>
          )}
        </ContainerFundoCadastro>

        <ContainerFundoCadastro cor={cadastrar}>
          {cadastrar && !cadastrarDoc && editar && (
            <ContainerCadastro>
              <AnimationContainer>
                <h1>Editar Cargo</h1>
                <Form ref={formRefEdit} onSubmit={handleSubmitEdit}>
                  <div>
                    <Input
                      name="nome"
                      icon={FiType}
                      value={nomeCargo}
                      onValue={(e) => setNomeCargo(e)}
                      placeholder="Nome do Cargo"
                    />
                  </div>
                  <ContainerButton>
                    <div>
                      <button
                        type="button"
                        className="divButtonCancelar"
                        onClick={() => {
                          setCadastrar(true);
                          setCadastrarDoc(false);
                          setEditar(false);
                        }}
                      >
                        Cancelar
                      </button>
                      <button type="submit">Salvar</button>
                    </div>
                  </ContainerButton>
                </Form>
              </AnimationContainer>
            </ContainerCadastro>
          )}
        </ContainerFundoCadastro>
      </Container>
      {loading && <Loading />}
    </>
  );
};

export default CadastroCargo;
