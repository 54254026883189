import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { AiFillCloseCircle } from 'react-icons/ai';
import { FiUser } from 'react-icons/fi';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { BsBuilding } from 'react-icons/bs';
import Input from '../Input';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  SignUpFormData,
  ResponseGetFimDeSemana,
  ResponseGetFilial,
  ResponseGet,
} from '../../pages/Cadastros/Escala';
import { Loading } from '../Loading';
import { SelectCustom } from '../SelectCustom';
import { findFilialName } from '../../interfaces/functionsFilterFind';

import {
  ContainerModal,
  Container,
  ButtonClose,
  Content,
  Title,
  ButtonSubmit,
  ContainerForm,
} from './styles';

interface ModalProps {
  data: ResponseGetFimDeSemana;
  escalas: ResponseGet[];
  filiais: ResponseGetFilial[];
  onClose(e: boolean): void;
}

export const EditEscalaFdsModal: React.FC<ModalProps> = ({
  data,
  escalas,
  filiais,
  onClose = (e: boolean): boolean => {
    return e;
  },
}: ModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const { addToast } = useToast();
  const [editNome, setEditNome] = useState(data?.NOME);
  const [editFilial, setEditFilial] = useState(data?.FILIAL);
  const [editHorarioInicio, setEditHorarioInicio] = useState(
    data?.HORA_INICIAL,
  );
  const [editHorarioPausa, setEditHorarioPausa] = useState(data?.HORA_PAUSA);
  const [editHorarioVolta, setEditHorarioVolta] = useState(data?.HORA_VOLTA);
  const [editHorarioSaida, setEditHorarioSaida] = useState(data?.HORA_SAIDA);
  const [filiaisTemp, setFiliaisTemp] = useState<ResponseGetFilial[]>([]);
  const [checkIntraJornada, setCheckIntraJornada] = useState(true);

  useEffect(() => {
    const findDataFilial = filiais.find(
      (filial) => filial.CODIGO_FILIAL === editFilial,
    );

    findDataFilial === undefined
      ? setFiliaisTemp([
          ...filiais,
          {
            ATIVO: false,
            BAIRRO: 'INATIVO',
            CEP: 'INATIVO',
            CIDADE: 'INATIVO',
            CODIGO_FILIAL: editFilial,
            ENDERECO: 'INATIVO',
            ESTADO: 'INATIVO',
            ID: -1,
            NUMERO: 'INATIVO',
            NOME_EMPRESA: 'INATIVO',
            NOME_FANTASIA_EMPRESA: 'INATIVO',
            CNPJ: 'INATIVO',
          },
        ])
      : setFiliaisTemp([...filiais]);

    const findEscala = escalas.find(
      (escalaTemp) => escalaTemp.ID === data.Id_Dom_Escala,
    );

    setCheckIntraJornada(findEscala ? findEscala.INTRA_JORNADA : true);
  }, [data.Id_Dom_Escala, editFilial, escalas, filiais]);

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose(true);
      }
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    async (dataTemp: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});
        let schema: any;

        schema = Yup.object().shape({
          nome: Yup.string().required('Nome é obrigatório!'),
          filial: Yup.string().required('Filial é obrigatória!'),
          horarioInicioFds: Yup.string().required(
            'Horário de início é obrigatório!',
          ),
          horarioSaidaFds: Yup.string().required(
            'Horário da saída é obrigatório!',
          ),
        });

        if (checkIntraJornada) {
          schema = Yup.object().shape({
            nome: Yup.string().required('Nome é obrigatório!'),
            filial: Yup.string().required('Filial é obrigatória!'),
            horarioInicioFds: Yup.string().required(
              'Horário de início é obrigatório!',
            ),
            horarioPausaFds: Yup.string().required(
              'Horário da pausa é obrigatório!',
            ),
            horarioVoltaFds: Yup.string().required(
              'Horário da volta é obrigatório!',
            ),
            horarioSaidaFds: Yup.string().required(
              'Horário da saída é obrigatório!',
            ),
          });
        }

        if (
          checkIntraJornada &&
          dataTemp.horarioInicioFds !== '' &&
          dataTemp.horarioSaidaFds !== '' &&
          dataTemp.horarioPausaFds !== '' &&
          dataTemp.horarioVoltaFds !== ''
        ) {
          schema = Yup.object().shape({
            horarioInicioFds: Yup.string().required(
              'Horário de início é obrigatório!',
            ),
            horarioPausaFds: Yup.string().required(
              'Horário da pausa é obrigatório!',
            ),
            horarioVoltaFds: Yup.string().required(
              'Horário da volta é obrigatório!',
            ),
            horarioSaidaFds: Yup.string().required(
              'Horário da saída é obrigatório!',
            ),
          });
        } else if (
          !checkIntraJornada &&
          dataTemp.horarioInicioFds !== '' &&
          dataTemp.horarioSaidaFds !== ''
        ) {
          schema = Yup.object().shape({
            horarioInicioFds: Yup.string().required(
              'Horário de início é obrigatório!',
            ),
            horarioSaidaFds: Yup.string().required(
              'Horário da saída é obrigatório!',
            ),
          });
        }

        await schema.validate(dataTemp, {
          abortEarly: false,
        });

        setShowLoading(true);
        api
          .put(`/escala/updateEscalaFimDeSemana`, {
            id: data.ID,
            nome: dataTemp.nome,
            hora_inicial: dataTemp.horarioInicioFds,
            hora_pausa: dataTemp.horarioPausaFds,
            hora_volta: dataTemp.horarioVoltaFds,
            hora_saida: dataTemp.horarioSaidaFds,
            filial: dataTemp.filial,
            Id_Dom_Escala: data.Id_Dom_Escala,
            ativo: true,
          })
          .then(() => {
            setShowLoading(false);
            addToast({
              type: 'success',
              title: 'Escala editada!',
              description:
                'A escala foi editada com sucesso e já pode ser visualizada na listagem.',
            });

            setTimeout(() => {
              history.go(0);
            }, 1500);
          })
          .catch(() => {
            setShowLoading(false);
            addToast({
              type: 'error',
              title: 'Erro na edição',
              description:
                'Ocorreu um erro ao editar a escala, tente novamente.',
            });
          });
      } catch (err) {
        setShowLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description:
              'Existem campos obrigatórios não preenchidos, favor verificar.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description: 'Ocorreu um erro ao editar a escala, tente novamente.',
          });
        }
      }
    },
    [addToast, checkIntraJornada, data.ID, data.Id_Dom_Escala, history],
  );

  return (
    <ContainerModal id="modal" onClick={handleOutSideClick}>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Title>Editar escala de fim de semana</Title>
          <ContainerForm>
            <Content>
              <Input
                name="nome"
                icon={FiUser}
                placeholder="Nome"
                widthProps="49%"
                placeholderProps="Nome"
                editProps
                tooltip={false}
                value={editNome}
                onValue={(value) => setEditNome(value)}
              />
              <SelectCustom
                name="filial"
                widthProps="49%"
                icon={BsBuilding}
                defaultValue="Filial"
                value={editFilial}
                onValue={(value) => setEditFilial(value)}
                optionsData={filiaisTemp.map((filial) => {
                  return filial.CODIGO_FILIAL;
                })}
              />
            </Content>

            <Content>
              <Input
                name="nomeEmpresa"
                icon={BsBuilding}
                placeholder="Nome da empresa"
                widthProps="100%"
                placeholderProps="Nome da empresa"
                editProps
                tooltip={false}
                value={findFilialName(filiaisTemp, editFilial)}
                disabled
              />
            </Content>

            <Content>
              <div
                style={{
                  width: checkIntraJornada ? '95%' : '100%',
                  marginRight: checkIntraJornada ? '2%' : '0%',
                  backgroundColor: '#3d3946',
                  padding: '10px',
                  borderRadius: '10px',
                }}
              >
                <p>Horário de início</p>
                <Input
                  type="time"
                  name="horarioInicioFds"
                  icon={FiUser}
                  placeholder="Horário de início"
                  widthProps="100%"
                  placeholderProps="Horário de início"
                  editProps
                  tooltip={false}
                  value={editHorarioInicio}
                  onValue={(value) => setEditHorarioInicio(value)}
                />
              </div>

              {checkIntraJornada && (
                <div
                  style={{
                    width: '95%',
                    backgroundColor: '#3d3946',
                    padding: '10px',
                    borderRadius: '10px',
                  }}
                >
                  <p>Horário da pausa</p>
                  <Input
                    type="time"
                    name="horarioPausaFds"
                    icon={FiUser}
                    placeholder="Horário da pausa"
                    widthProps="100%"
                    placeholderProps="Horário da pausa"
                    editProps
                    tooltip={false}
                    value={editHorarioPausa}
                    onValue={(value) => setEditHorarioPausa(value)}
                  />
                </div>
              )}
            </Content>

            <Content>
              {checkIntraJornada && (
                <div
                  style={{
                    width: '95%',
                    marginRight: '2%',
                    backgroundColor: '#3d3946',
                    padding: '10px',
                    borderRadius: '10px',
                  }}
                >
                  <p>Horário da volta</p>
                  <Input
                    type="time"
                    name="horarioVoltaFds"
                    icon={FiUser}
                    placeholder="Horário da volta"
                    widthProps="100%"
                    placeholderProps="Horário da pausa"
                    editProps
                    tooltip={false}
                    value={editHorarioVolta}
                    onValue={(value) => setEditHorarioVolta(value)}
                  />
                </div>
              )}

              <div
                style={{
                  width: checkIntraJornada ? '95%' : '100%',
                  backgroundColor: '#3d3946',
                  padding: '10px',
                  borderRadius: '10px',
                }}
              >
                <p>Horário da saída</p>
                <Input
                  type="time"
                  name="horarioSaidaFds"
                  icon={FiUser}
                  placeholder="Horário da saída"
                  widthProps="100%"
                  placeholderProps="Horário da saída"
                  editProps
                  tooltip={false}
                  value={editHorarioSaida}
                  onValue={(value) => setEditHorarioSaida(value)}
                />
              </div>
            </Content>
          </ContainerForm>
          <ButtonSubmit type="submit">Salvar</ButtonSubmit>
        </Form>
        <ButtonClose onClick={() => onClose(true)}>
          <AiFillCloseCircle size={28} />
        </ButtonClose>
      </Container>

      {showLoading && <Loading />}
    </ContainerModal>
  );
};
