import React, {
  ReactNode,
  createContext,
  useState,
  useContext,
  useCallback,
} from 'react';
import api from '../services/api';

interface ModulesProviderProps {
  children: ReactNode;
}

export interface ModulesInterface {
  ID: number;
  NOME: string;
}

interface ModulesContextData {
  modules: ModulesInterface[];
  getModulosAcesso: () => void;
}

const ModulesContext = createContext<ModulesContextData>(
  {} as ModulesContextData,
);

export const ModulesProvider: React.FC<ModulesProviderProps> = ({
  children,
}) => {
  const [modulosAcesso, setModulosAcesso] = useState<ModulesInterface[]>(() => {
    const modulos = localStorage.getItem('@Colaborador:modulosAcesso');

    if (modulos) {
      return JSON.parse(modulos);
    }

    return {} as ModulesInterface;
  });

  const getModulosAcesso = useCallback(async (): Promise<void> => {
    const { data } = await api.get('/modulosDisponiveis/modulos');

    localStorage.setItem(
      '@Colaborador:modulosAcesso',
      JSON.stringify(data.modulos),
    );

    setModulosAcesso(data.modulos);
  }, []);

  return (
    <ModulesContext.Provider
      value={{
        modules: modulosAcesso,
        getModulosAcesso,
      }}
    >
      {children}
    </ModulesContext.Provider>
  );
};

export function availableModules(): ModulesContextData {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const context = useContext(ModulesContext);

  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }

  return context;
}
