import React from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { MdCheckCircle, MdError } from 'react-icons/md';
import { GrDocumentCsv } from 'react-icons/gr';
import { ImFilesEmpty } from 'react-icons/im';
import { Container, FileInfo, Preview } from './styles';

interface Uploaded {
  file: File | null;
  id: string;
  name: string;
  readableSize: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  type: string;
}

interface FileListProps {
  heightProps: string;
  files: Uploaded[];
  onDelete?(e: string): void;
}

const FileList: React.FC<FileListProps> = ({
  heightProps,
  onDelete = () => {
    ('');
  },
  files,
}) => {
  return (
    <Container filesProps={!files.length} heightProps={heightProps}>
      {!files.length && <ImFilesEmpty size={72} />}
      {files.map((uploadedFile) => (
        <li key={`${uploadedFile.id}`}>
          <FileInfo>
            <Preview>
              <GrDocumentCsv size={28} />
            </Preview>
            <div>
              <strong>{uploadedFile.name}</strong>
              <span>
                {uploadedFile.readableSize}
                {!uploadedFile.uploaded && (
                  <button
                    type="button"
                    onClick={() => {
                      onDelete(uploadedFile.id);
                    }}
                  >
                    Excluir
                  </button>
                )}
              </span>
            </div>
          </FileInfo>

          <div className="divIcons">
            {!uploadedFile.uploaded && !uploadedFile.error && (
              <CircularProgressbar
                styles={{
                  root: { width: 24 },
                  path: { stroke: '#7159c1' },
                }}
                strokeWidth={10}
                value={uploadedFile.progress}
              />
            )}

            {uploadedFile.uploaded && (
              <MdCheckCircle size={24} color="#78e5d5" />
            )}
            {uploadedFile.error && <MdError size={24} color="#e57878" />}
          </div>
        </li>
      ))}
    </Container>
  );
};

export default FileList;
