// import React, { ReactNode, useMemo } from 'react';
// import { Menu } from 'antd';
// import Swal from 'sweetalert2';

// import * as AiIcons from 'react-icons/ai';
// import * as FiIcons from 'react-icons/fi';
// import * as RiIcons from 'react-icons/ri';
// import * as FaIcons from 'react-icons/fa';

// import { useHistory } from 'react-router-dom';
// import { Container } from './styles';
// import { useAuth } from '../../hooks/auth';

// const MenuLateral: React.FC = () => {
//   const { SubMenu } = Menu;
//   const history = useHistory();
//   const { signOut } = useAuth();

//   function handleClick(e: any): void {
//     if (e.key === '/signout') {
//       Swal.fire({
//         title: 'Tem certeza que deseja sair?',
//         icon: 'warning',
//         showCancelButton: true,
//         cancelButtonText: 'Não',
//         confirmButtonColor: '#3085d6',
//         cancelButtonColor: '#d33',
//         confirmButtonText: 'Sim',
//       }).then((result) => {
//         if (result.isConfirmed) {
//           signOut();
//         }
//       });
//     } else {
//       history.push(e.key);
//     }
//   }

//   const filterPermition = useMemo((): ReactNode => {
//     return (
//       <>
//         <Menu.Item key="/" icon={<AiIcons.AiFillHome />}>
//           Início
//         </Menu.Item>

//         <SubMenu key="sub" icon={<AiIcons.AiOutlineForm />} title="Cadastros">
//           <Menu.Item key="/cadastro-filial" style={{ margin: 0 }}>
//             Filial
//           </Menu.Item>
//           <Menu.Item key="/pre-cadastro-funcionario" style={{ margin: 0 }}>
//             Funcionários
//           </Menu.Item>
//           <Menu.Item key="/cadastro-abono" style={{ margin: 0 }}>
//             Cadastro Abono
//           </Menu.Item>
//           <Menu.Item key="/escala" style={{ margin: 0 }}>
//             Escala
//           </Menu.Item>
//         </SubMenu>

//         <SubMenu
//           key="sub5"
//           icon={<RiIcons.RiAdminFill />}
//           title="Administração"
//         >
//           <Menu.Item key="/user-access" style={{ margin: 0 }}>
//             Nível de Acesso
//           </Menu.Item>
//           <Menu.Item key="/user-access-filter" style={{ margin: 0 }}>
//             Nível de Acesso - Filtros
//           </Menu.Item>
//         </SubMenu>

//         <SubMenu key="sub6" icon={<FiIcons.FiPhoneCall />} title="Call Center">
//           <Menu.Item key="/listagem-usuarios" style={{ margin: 0 }}>
//             Listagem de usuários
//           </Menu.Item>
//         </SubMenu>

//         <SubMenu key="sub9" icon={<FaIcons.FaWarehouse />} title="Estoque">
//           <Menu.Item key="/list-users-qrcode" style={{ margin: 0 }}>
//             Usuários QR Code
//           </Menu.Item>
//         </SubMenu>

//         <SubMenu key="sub10" icon={<FaIcons.FaFingerprint />} title="Ponto">
//           <Menu.Item key="/view-ponto" style={{ margin: 0 }}>
//             Gestão Ponto
//           </Menu.Item>
//           <Menu.Item key="/visualizar-ponto" style={{ margin: 0 }}>
//             Relatório Ponto
//           </Menu.Item>
//         </SubMenu>
//       </>
//     );
//   }, []);

//   return (
//     <>
//       <Container>
//         <Menu
//           onClick={handleClick}
//           style={{
//             background: '#004d00',
//             width: 280,
//           }}
//           mode="inline"
//           theme="dark"
//         >
//           {filterPermition}
//         </Menu>
//       </Container>
//     </>
//   );
// };

// export default MenuLateral;

import React, { ReactNode, useMemo } from 'react';
import { Menu } from 'antd';
import Swal from 'sweetalert2';

import * as AiIcons from 'react-icons/ai';
import * as IoIcons from 'react-icons/io';
import * as HiIcons from 'react-icons/hi';
import * as FiIcons from 'react-icons/fi';
import * as RiIcons from 'react-icons/ri';
import * as FaIcons from 'react-icons/fa';
import * as GiIcons from 'react-icons/gi';

import { useHistory } from 'react-router-dom';
import { Container } from './styles';
import { useAuth } from '../../hooks/auth';
import { availableModules } from '../../hooks/availableModules';

const MenuLateral: React.FC = () => {
  const { SubMenu } = Menu;
  const history = useHistory();
  const { signOut } = useAuth();
  const { modules } = availableModules();

  // FALTA-PROGRAMADA
  const clientPlanScheduledAbsence = modules.find((data) => data.ID === 10);

  // PRE-CADASTRO-ADMISSAO
  const clientPlanAdmissionPreRegistration = modules.find(
    (data) => data.ID === 11,
  );

  // ATUALIZACAO-DOCUMENTOS
  const clientPlanUpdatingDocuments = modules.find((data) => data.ID === 12);

  // ENVIO-ATESTADOS
  const clientPlanSendingCertificates = modules.find((data) => data.ID === 13);

  // PONTO-ELETRONICO
  const clientPlanElectronicPoint = modules.find((data) => data.ID === 14);

  function handleClick(e: any): void {
    if (e.key === '/signout') {
      Swal.fire({
        title: 'Tem certeza que deseja sair?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          signOut();
        }
      });
    } else {
      history.push(e.key);
    }
  }

  const filterPermition = useMemo((): ReactNode => {
    return (
      <>
        <Menu.Item key="/" icon={<AiIcons.AiFillHome />}>
          Início
        </Menu.Item>

        <SubMenu key="sub" icon={<AiIcons.AiOutlineForm />} title="Cadastros">
          <Menu.Item key="/cadastro-filial" style={{ margin: 0 }}>
            Filial
          </Menu.Item>
          <Menu.Item key="/pre-cadastro-funcionario" style={{ margin: 0 }}>
            Funcionários
          </Menu.Item>
          <Menu.Item key="/cadastro-contra-cheque" style={{ margin: 0 }}>
            Contracheque
          </Menu.Item>
          <Menu.Item key="/cadastro-ferias" style={{ margin: 0 }}>
            Férias
          </Menu.Item>
          <Menu.Item key="/cadastro-beneficios" style={{ margin: 0 }}>
            Benefícios
          </Menu.Item>
          <Menu.Item key="/cadastro-decimo-terceiro" style={{ margin: 0 }}>
            13º salário
          </Menu.Item>
          {!!clientPlanElectronicPoint && (
            <>
              <Menu.Item key="/escala" style={{ margin: 0 }}>
                Escala
              </Menu.Item>
              <Menu.Item key="/agendamento" style={{ margin: 0 }}>
                Agendamento
              </Menu.Item>
            </>
          )}
        </SubMenu>

        <SubMenu
          key="sub5"
          icon={<RiIcons.RiAdminFill />}
          title="Administração"
        >
          <Menu.Item key="/user-access" style={{ margin: 0 }}>
            Nível de Acesso
          </Menu.Item>
          <Menu.Item key="/user-access-filter" style={{ margin: 0 }}>
            Nível de Acesso - Filtros
          </Menu.Item>
        </SubMenu>

        <SubMenu key="sub6" icon={<FiIcons.FiPhoneCall />} title="Call Center">
          <Menu.Item key="/listagem-usuarios" style={{ margin: 0 }}>
            Listagem de usuários
          </Menu.Item>
        </SubMenu>

        <SubMenu
          key="sub8"
          icon={<HiIcons.HiOutlineLightBulb />}
          title="Marketing"
        >
          <Menu.Item key="/upload-post" style={{ margin: 0 }}>
            Upload Feed
          </Menu.Item>
        </SubMenu>

        <SubMenu key="sub9" icon={<FaIcons.FaWarehouse />} title="Estoque">
          <Menu.Item key="/list-users-qrcode" style={{ margin: 0 }}>
            Usuários QR Code
          </Menu.Item>
        </SubMenu>

        {!!clientPlanScheduledAbsence && (
          <SubMenu key="sub7" icon={<FaIcons.FaUsersCog />} title="Operacional">
            <Menu.Item key="/visualizar-ausencia" style={{ margin: 0 }}>
              Falta Programada
            </Menu.Item>
          </SubMenu>
        )}

        {!!clientPlanAdmissionPreRegistration && (
          <SubMenu
            key="sub2"
            icon={<IoIcons.IoIosPeople />}
            title="Recursos Humanos"
          >
            <Menu.Item key="/cadastro-admissao" style={{ margin: 0 }}>
              Cadastro Admissão
            </Menu.Item>
            <Menu.Item key="/cadastro-cargo" style={{ margin: 0 }}>
              Cadastro de Cargo
            </Menu.Item>
            <Menu.Item
              key="/validacao-documentos-admissao"
              style={{ margin: 0 }}
            >
              Validar Documentos de Admissão
            </Menu.Item>
          </SubMenu>
        )}

        {(!!clientPlanAdmissionPreRegistration ||
          !!clientPlanUpdatingDocuments) && (
          <SubMenu
            key="sub3"
            icon={<RiIcons.RiFileUserLine />}
            title="Dep. Pessoal"
          >
            {!!clientPlanAdmissionPreRegistration && (
              <Menu.Item
                key="/validacao-documentos-funcionarios"
                style={{ margin: 0 }}
              >
                Validar Documentos de Funcionários
              </Menu.Item>
            )}
            {!!clientPlanUpdatingDocuments && (
              <Menu.Item key="/solicitacao-vaga" style={{ margin: 0 }}>
                Aprovação de Admissão
              </Menu.Item>
            )}
          </SubMenu>
        )}

        {!!clientPlanSendingCertificates && (
          <SubMenu key="sub11" icon={<GiIcons.GiHealthNormal />} title="SESMT">
            <Menu.Item
              key="/visualizar-atestados-medicos"
              style={{ margin: 0 }}
            >
              Atestados Médicos
            </Menu.Item>
          </SubMenu>
        )}

        {!!clientPlanElectronicPoint && (
          <SubMenu key="sub10" icon={<FaIcons.FaFingerprint />} title="Ponto">
            <Menu.Item key="/view-ponto" style={{ margin: 0 }}>
              Gestão Ponto
            </Menu.Item>
            <Menu.Item key="/visualizar-ponto" style={{ margin: 0 }}>
              Relatório Ponto
            </Menu.Item>
            <Menu.Item key="/cadastro-abono" style={{ margin: 0 }}>
              Cadastro Abono
            </Menu.Item>
          </SubMenu>
        )}
      </>
    );
  }, [
    clientPlanAdmissionPreRegistration,
    clientPlanElectronicPoint,
    clientPlanScheduledAbsence,
    clientPlanSendingCertificates,
    clientPlanUpdatingDocuments,
  ]);

  return (
    <>
      <Container>
        <Menu
          onClick={handleClick}
          style={{
            background: '#004d00',
            width: 280,
          }}
          mode="inline"
          theme="dark"
        >
          {filterPermition}
        </Menu>
      </Container>
    </>
  );
};

export default MenuLateral;
