import styled from 'styled-components';
import { darken, lighten } from 'polished';

export const Container = styled.div`
  margin-left: 20px;

  p {
    background: ${lighten(0.1, '#3e3c44')};
    margin: 0;
    padding: 9.2px;
    border: 1px solid #e5e5e5;
    border-right: none;
  }
`;

export const ContainerSelect = styled.div`
  border: 1px solid #e5e5e5;
  transition: background 0.2s;

  &:hover {
    background: ${darken(0.1, '#3e3c44')};
  }

  select {
    font-size: 14px;
    border: none;
    width: auto;
    background: transparent;
    cursor: pointer;
    padding: 10px;

    option {
      background: #3e3c44;
    }
  }
`;
