import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { FiEdit2 } from 'react-icons/fi';
import { ImBlocked } from 'react-icons/im';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import moment from 'moment';
import { useAuth } from '../../../hooks/auth';
import HeaderTable from '../../../components/HeaderTable';
import api from '../../../services/api';
import { maskCpf } from '../../../components/Input/mask';
import ModalConexao from '../../../components/ModalConexao';
import { Loading } from '../../../components/Loading';
import Pagination from '../../../components/Pagination';
import { ResponseGet } from '../UserAccess';
import { ResponsePost } from '../../../components/ModalRegisterSectorFunction';
import { useToast } from '../../../hooks/toast';
import { SearchContainer } from './SearchContainer';
import { RegisterEditContainer } from './RegisterEditContainer';
import DropDownPagination from '../../../components/DropDownPagination';

import { Container, ContainerTable, ContainerPagination } from './styles';

interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

const pageAccess = {
  ID: 9,
  NOME: 'NIVEL-ACESSO-CUSTOMIZAVEL',
};

const dropDown = [
  { valor: '20', id: 20 },
  { valor: '40', id: 40 },
  { valor: '80', id: 80 },
  { valor: '160', id: 160 },
  { valor: 'Todos', id: 1 },
];

const headers = [
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'CPF', field: 'CPF', sortable: true },
  { name: 'E-mail', field: 'EMAIL', sortable: true },
  { name: 'Setor', field: 'NOME_SETOR', sortable: true },
  { name: 'Função', field: 'NOME_NIVEL_ACESSO', sortable: true },
  { name: 'Editar', field: 'editar', sortable: false },
];

const UserAccessFilter: React.FC = () => {
  const { modulos } = useAuth();
  const history = useHistory();
  const { addToast } = useToast();
  const [registerEditRef, setRegisterEditRef] = useState<any>();
  const [search, setSearch] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  const [searchSetor, setSearchSetor] = useState('');
  const [searchFuncao, setSearchFuncao] = useState('');
  const [editUser, setEditUser] = useState(false); // editar cadastro do user
  const setor = 1;
  const nivelAcesso = 1;
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [userEdit, setUserEdit] = useState<ResponseGet>(); // dados do usuario para edit nivel de acesso
  const [loading, setLoading] = useState(false);
  const [responsePostSetor, setResponsePostSetor] = useState<ResponsePost[]>(
    [],
  );
  const [responsePostFuncao, setResponsePostFuncao] = useState<ResponsePost[]>(
    [],
  );

  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [currentPage, setCurrentPage] = useState(1);
  const [totalTabela, setTotalTabela] = useState(0);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState<number>(20);
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });

  const getItens = useCallback(async () => {
    setLoading(true);
    try {
      const dataUsers = await api.get('users/findAll');
      const dataSetor = await api.get('setor/showSetor');
      const dataFuncao = await api.get('nivelAcesso/getNivelAcesso');

      setResponse(dataUsers.data);
      setResponsePostSetor(dataSetor.data);
      setResponsePostFuncao(dataFuncao.data);

      setLoading(false);
    } catch (error) {
      setLoading(false);

      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar dados, por favor atualize a página!',
      });
    }
  }, [addToast]);

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);

    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title: 'Você não tem permissão para acessar essa página!',
      });
    } else {
      getItens();
    }
  }, [getItens, history, modulos]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response;

    if (search) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.NOME && res.NOME.toLowerCase().includes(search.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.CPF && res.CPF.toLowerCase().includes(CPF.toLowerCase()),
      );
    }
    if (searchSetor) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.NOME_SETOR &&
          res.NOME_SETOR.toLowerCase().includes(searchSetor.toLowerCase()),
      );
    }
    if (searchFuncao) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.NOME_NIVEL_ACESSO &&
          res.NOME_NIVEL_ACESSO.toLowerCase().includes(
            searchFuncao.toLowerCase(),
          ),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (a[sorting.field] !== null && b[sorting.field] !== null) {
          if (typeof a[sorting.field] === 'object') {
            return (
              reversed *
              a[sorting.field]
                .join(', ')
                .localeCompare(b[sorting.field].join(', '))
            );
          }
          if (typeof a[sorting.field] === 'string') {
            return reversed * a[sorting.field].localeCompare(b[sorting.field]);
          }
          return (
            reversed *
            a[sorting.field]
              .toString()
              .localeCompare(b[sorting.field].toString())
          );
        }
        return a;
      });
    }

    setTotalTabela(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    currentPage,
    response,
    search,
    searchCPF,
    searchFuncao,
    searchSetor,
    sorting.field,
    sorting.order,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [search, searchCPF, searchFuncao, searchSetor]);

  return (
    <Container>
      <ModalConexao />

      {editUser && userEdit && (
        <RegisterEditContainer
          responsePostSetorProps={responsePostSetor}
          responsePostFuncaoProps={responsePostFuncao}
          setorProps={setor}
          nivelAcessoProps={nivelAcesso}
          userEditProps={userEdit}
          dataUsersProps={response}
          onRefProps={(e) => setRegisterEditRef(e)}
          onDataUsersEdit={(e) => setResponse(e)}
          onClose={(e) => setEditUser(!e)}
        />
      )}

      <SearchContainer
        onSearchName={(e) => setSearch(e)}
        onSearchCpf={(e) => setSearchCPF(e)}
        onSearchSetor={(e) => setSearchSetor(e)}
        onSearchFuncao={(e) => setSearchFuncao(e)}
      />

      <ContainerTable>
        <table id="tableListagemUserAccess">
          <HeaderTable
            headers={headers}
            onSorting={(field: string, order: string) =>
              setSorting({ field, order })
            }
          />
          <tbody>
            {responseData.map((res: ResponseGet) => (
              <tr key={res.CPF + res.ID_SETOR}>
                <td>{res.NOME}</td>
                <td>{maskCpf(res.CPF)}</td>
                <td>{res.EMAIL}</td>
                <td>{res.NOME_SETOR}</td>
                <td>{res.NOME_NIVEL_ACESSO}</td>
                <td
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    padding: 5,
                  }}
                >
                  {accessUser.ESCRITA ? (
                    <button
                      type="button"
                      onClick={() => {
                        setEditUser(true);
                        setUserEdit(res);
                        registerEditRef &&
                          registerEditRef.current &&
                          registerEditRef.current.scrollIntoView({
                            block: 'center',
                            behavior: 'smooth',
                          });
                      }}
                    >
                      <FiEdit2 />
                    </button>
                  ) : (
                    <button type="button">
                      <ImBlocked />
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </ContainerTable>

      <ContainerPagination>
        {responseData.length > 0 && (
          <div id="divPaginacao">
            <Pagination
              total={totalTabela}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page: number) => setCurrentPage(page)}
            />
            <DropDownPagination
              onChangeItems={(value: string) => {
                setCurrentPage(1);
                if (value === 'Todos') {
                  setITEMS_PER_PAGE(1);
                } else {
                  setITEMS_PER_PAGE(Number(value));
                }
              }}
              objetoEnum={dropDown}
              minWidth={30}
            />

            <ReactHTMLTableToExcel
              id="export-excel"
              className="btn"
              table="tableListagemUserAccess"
              filename={`listagem-nivel-acesso-${moment().format(
                'DD-MM-YYYY',
              )}`}
              sheet="tablexls"
              buttonText="Exportar Excel"
            />
          </div>
        )}
      </ContainerPagination>

      {loading && <Loading />}
    </Container>
  );
};

export default UserAccessFilter;
