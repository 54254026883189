import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

const appearFromFront = keyframes`
  from {
    opacity: 0.5;
    transform: scale(0.7);
  }
  to{
    opacity: 1;
    transform: scale(1);
  }
`;

export const ContainerModal = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: 'center';
  align-items: 'center';
`;

export const Container = styled.div`
  animation: ${appearFromFront} 300ms;
  position: relative;
  width: 530px;
  height: 400px;
  max-width: 90%;
  max-height: 400px;
  background-color: #ffffff;
  color: #000000;
  border-radius: 5px;
  margin: auto auto;
  padding: 0 0 40px 0;
`;

export const ContainerHeader = styled.div`
  width: auto;
  display: flex;
  margin-top: 40px;
  canvas {
    margin: 0px auto;
  }
`;

export const ContainerHeaderDados = styled.div`
  height: 100%;

  h3 {
    text-align: center;
    border-radius: 5px 5px 0 0;
    font-size: 22px;
    font-weight: bold;
    padding: 10px;
    margin: 0;
    color: rgb(35, 33, 41);
  }

  table {
    width: 100%;

    tbody {
      height: 310px;
      overflow: auto;
      padding: 0 20px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  div {
    background: #bfbfbf;
    margin: 0 35px;
    height: 310px;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #a6a6a6;
    border-radius: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #cccccc;
  }
`;

export const ContainerButtonAtualizar = styled.div`
  position: absolute;
  bottom: -20px;
  left: 50%;
  right: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    padding: 10px;
    background-color: #25258e;
    color: #ffffff;
    border-radius: 5px;
    border: none;
    min-width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      margin-right: 8px;
      path {
        stroke: #ffffff;
      }
    }
    &:hover {
      background-color: ${shade(0.1, '#25258e')};
    }

    & + button {
      margin-left: 10px;
      background-color: #006600;

      &:hover {
        background-color: ${shade(0.1, '#006600')};
      }
    }
  }
`;

interface LineProps {
  firstWord: string;
}

export const ContainerLine = styled.tr<LineProps>`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px;
  background: ${(props) =>
    props.firstWord === 'Sucesso' ? '#008000' : '#cc0000'};
  font-size: 0.9rem;
  color: #fff;
  border-radius: 0.25rem;
  & + tr {
    margin-top: 4px;
  }
`;

export const ButtonClose = styled.button`
  border: none;
  background: transparent;
  position: absolute;
  top: -14px;
  right: -14px;
  transition: 0.2s;
  svg {
    background: #f2f2f2;
    border-radius: 15px;
    color: red;
    &:hover {
      color: ${shade(0.2, 'red')};
    }
  }
`;
