import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-family: Arial;
  justify-content: center;
  input {
    height: 40px;
    border-radius: 10px;
    width: 40px;
  }
  padding-bottom: 5px;
`;
