/* eslint-disable no-nested-ternary */
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import Swal from 'sweetalert2';
import moment from 'moment';
import api from '../../../services/api';
import ModalConexao from '../../../components/ModalConexao';
import { useToast } from '../../../hooks/toast';
import { useAuth } from '../../../hooks/auth';
import { ResponseGet, PageAccessUser, pageAccess } from '../Escala';
import { Register } from './Register';
import { Tables } from './Tables';
import { Loading } from '../../../components/Loading';

import {
  Container,
  Aba,
  ContainerAba,
  ContainerSearch,
  SearchCustom,
} from './styles';

export interface ResponseGetEscalaAgendamento {
  AGENDA_COMPETENCIA_FINAL: string;
  AGENDA_COMPETENCIA_INICIAL: string;
  AGENDA_DATA_FINAL: string;
  AGENDA_DATA_INICIAL: string;
  ATIVO: boolean;
  ID: number;
  CPF: string;
  FILIAL: string;
  Id_Dom_Escala: number;
  Id_User: number;
  nome_user: string;
}

export interface ResponseGetUser {
  AVATAR: string;
  Ativo: number;
  CPF: string;
  DataCadastro: string;
  DataNascimento: string;
  EMAIL: string;
  ID: number;
  NOME: string;
  Password: string;
  Telefone: string;
  param: string;
  privarDados: boolean;
  token: string;
  filial: string;
}

export const Agendamento: React.FC = () => {
  const { addToast } = useToast();
  const { go, push } = useHistory();
  const { modulos } = useAuth();
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });
  const [cadastrar, setCadastrar] = useState(false);
  const [verificaResponse, setVerificaResponse] = useState(false);
  const [responseEscala, setResponseEscala] = useState<ResponseGet[]>([]);
  const [responseEscalaAgendamento, setResponseEscalaAgendamento] = useState<
    ResponseGetEscalaAgendamento[]
  >([]);
  const [responseUsers, setResponseUsers] = useState<ResponseGetUser[]>([]);
  const [loading, setLoading] = useState(false);
  const [searchNome, setSearchNome] = useState('');
  const [searchCpf, setSearchCpf] = useState('');
  const [searchFilial, setSearchFilial] = useState('');
  const [searchDataInicial, setSearchDataInicial] = useState('');
  const [searchDataFinal, setSearchDataFinal] = useState('');
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [trTableSelected, setTrTableSelected] = useState<
    ResponseGetEscalaAgendamento
  >();

  const getInitResponses = useCallback(async () => {
    try {
      setLoading(true);

      const responseEscalaTemp = await api.get('escala/getEscala');
      const responseEscalaAgendamentoTemp = await api.get(
        'escalaAgendamento/showEscalaAgendamento',
      );
      const responseUsersTemp = await api.get('users/findAll');
      setVerificaResponse(true);

      setResponseEscala(
        responseEscalaTemp.data.filter((escala: ResponseGet) => escala.ATIVO),
      );
      setResponseEscalaAgendamento(responseEscalaAgendamentoTemp.data);
      setResponseUsers(responseUsersTemp.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (navigator.onLine) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página!',
        });
      }
    }
  }, [addToast]);

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);
    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      push('/dashboard');

      addToast({
        type: 'info',
        title: 'Acesso bloqueado',
        description: 'Você não tem permissão para acessar essa página',
      });
    } else {
      getInitResponses();
    }
  }, [addToast, getInitResponses, modulos, push]);

  const handleAlertMaster = useCallback(() => {
    if (verificaResponse && !!responseEscala.length) {
      setCadastrar(true);
      // setDownload('');
      // setSearchNome('');
      // setSearchFilial('');
      // setSearchHorarioInicio('');
      // setSearchHorarioPausa('');
      // setSearchHorarioVolta('');
      // setSearchHorarioSaida('');
      // setIntraJornadaStatus(true);
      // setConsiderarFimDeSemanaStatus(true);
    } else if (verificaResponse && !responseEscala.length) {
      addToast({
        type: 'info',
        title: 'Lista de escalas vazia!',
        description:
          'Favor realizar o cadastro de pelo menos uma escala previamente.',
      });
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar dados, por favor atualize a página!',
      });
    }
  }, [addToast, responseEscala.length, verificaResponse]);

  const handleChangeStatusUser = useCallback(async () => {
    try {
      if (trTableSelected) {
        if (!trTableSelected.ATIVO) {
          const checkAgendamento = responseEscalaAgendamento.find(
            (agendamento) =>
              agendamento.Id_User === trTableSelected.Id_User &&
              agendamento.ATIVO,
          );

          if (checkAgendamento) {
            throw new Error(
              'Já existe um agendamento ativo do mesmo usuário, favor verificar',
            );
          }
        }

        setLoading(true);

        await api
          .put(`escalaAgendamento/updateEscalaAgendamento`, {
            id: trTableSelected.ID,
            // idUser?: number;
            // dataI?: string;
            // dataF?: string;
            // escalaId?: number;
            // cpf?: string;
            // filial?: string;
            ativo: !trTableSelected.ATIVO,
            // competenciaI?: string;
            // competenciaF?: string;
          })
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Agenda editada',
              description: 'Agenda editada com sucesso',
            });
            setTimeout(() => {
              go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro na edição',
              description: 'Erro ao editar agenda',
            });
          });
      }
    } catch (error) {
      setLoading(false);

      if (
        error.message ===
        'Já existe um agendamento ativo do mesmo usuário, favor verificar'
      ) {
        addToast({
          type: 'info',
          title: 'Conflito de dados',
          description: `${error.message}`,
        });
      } else {
        addToast({
          type: 'error',
          title: 'Erro na edição',
          description: 'Erro ao editar agenda',
        });
      }
    }
  }, [trTableSelected, responseEscalaAgendamento, addToast, go]);

  const handleConfirmChangeStatusUser = useCallback(() => {
    Swal.fire({
      title: `Atualização de status`,
      text: 'Tem certeza que deseja alterar o status da agenda selecionada?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
    }).then((result) => {
      if (result.isConfirmed) {
        handleChangeStatusUser();
      }
    });
  }, [handleChangeStatusUser]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGetEscalaAgendamento[] = [];
    computedResponses = responseEscalaAgendamento.length
      ? responseEscalaAgendamento
      : [];

    if (searchNome) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetEscalaAgendamento) =>
          res.nome_user &&
          res.nome_user.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }
    if (searchCpf) {
      let CPF = searchCpf.replace(/\./g, '');
      CPF = CPF.replace(/-/g, '');

      computedResponses = computedResponses.filter(
        (res: ResponseGetEscalaAgendamento) => {
          if (res.CPF) {
            let CPFAux = res.CPF.replace(/\./g, '');
            CPFAux = CPFAux.replace(/-/g, '');

            return CPFAux.toString().includes(CPF.toString());
          }

          return 0;
        },
      );
    }
    if (searchFilial) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetEscalaAgendamento) =>
          res.FILIAL &&
          res.FILIAL.toLowerCase().includes(searchFilial.toLowerCase()),
      );
    }
    if (searchDataInicial) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetEscalaAgendamento) =>
          res.AGENDA_DATA_INICIAL &&
          moment(res.AGENDA_DATA_INICIAL)
            .format('DD/MM/YYYY')
            .toLowerCase()
            .includes(searchDataInicial.toLowerCase()),
      );
    }
    if (searchDataFinal) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetEscalaAgendamento) =>
          res.AGENDA_DATA_FINAL &&
          moment(res.AGENDA_DATA_FINAL)
            .format('DD/MM/YYYY')
            .toLowerCase()
            .includes(searchDataFinal.toLowerCase()),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (a[sorting.field] !== null && b[sorting.field] !== null) {
          if (typeof a[sorting.field] === 'object') {
            return (
              reversed *
              a[sorting.field]
                .join(', ')
                .localeCompare(b[sorting.field].join(', '))
            );
          }
          if (typeof a[sorting.field] === 'string') {
            return reversed * a[sorting.field].localeCompare(b[sorting.field]);
          }
          return (
            reversed *
            a[sorting.field]
              .toString()
              .localeCompare(b[sorting.field].toString())
          );
        }
        return a;
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }
    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    currentPage,
    responseEscalaAgendamento,
    searchCpf,
    searchDataFinal,
    searchDataInicial,
    searchFilial,
    searchNome,
    sorting.field,
    sorting.order,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [searchNome, searchCpf, searchFilial, searchDataInicial, searchDataFinal]);

  return (
    <>
      <ModalConexao />
      <Container>
        {accessUser.ESCRITA && (
          <Aba>
            <ContainerAba cor={!cadastrar}>
              <button
                style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
                type="button"
                onClick={() => setCadastrar(false)}
              >
                Listar agendamentos
              </button>
            </ContainerAba>
            <ContainerAba cor={cadastrar}>
              <button
                style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7 }}
                type="button"
                onClick={() => handleAlertMaster()}
              >
                Cadastrar agendamentos
              </button>
            </ContainerAba>
          </Aba>
        )}

        {!cadastrar &&
          (!!responseEscala.length || !!responseEscalaAgendamento.length) && (
            <ContainerSearch>
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchNome(value);
                }}
                nomePlaceHolder="Buscar nome"
              />
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchCpf(value);
                }}
                nomePlaceHolder="Buscar CPF"
              />
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchFilial(value);
                }}
                nomePlaceHolder="Buscar filial"
              />
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchDataInicial(value);
                }}
                typeProps="date"
                nomePlaceHolder="Buscar data inicial"
              />
              <SearchCustom
                onSearch={(value: string) => {
                  setSearchDataFinal(value);
                }}
                typeProps="date"
                nomePlaceHolder="Buscar data final"
              />
            </ContainerSearch>
          )}

        {cadastrar && !!responseData.length && (
          <Register
            responseUsersProp={responseUsers}
            responseEscalasProp={responseEscala}
            responseEscalaAgendamentoProp={responseEscalaAgendamento}
            onLoading={(e) => setLoading(e)}
            onResponsePost={(e) =>
              setResponseEscalaAgendamento(responseEscalaAgendamento.concat(e))
            }
          />
        )}

        {!cadastrar && !!responseData.length && (
          <Tables
            responseDataProp={responseData}
            responseUsersProp={responseUsers}
            responseEscalaProp={responseEscala}
            totalItemsProp={totalItems}
            ITEMS_PER_PAGE_Prop={ITEMS_PER_PAGE}
            currentPageProp={currentPage}
            accessUserProp={accessUser}
            handleConfirmChangeStatusUserProp={handleConfirmChangeStatusUser}
            setCurrentPageProp={(e) => setCurrentPage(e)}
            setITEMS_PER_PAGE_Prop={(e) => setITEMS_PER_PAGE(e)}
            setSortingProp={(field, order) => setSorting({ field, order })}
            trTableSelectedProp={(e) => setTrTableSelected(e)}
          />
        )}
      </Container>

      {loading && <Loading />}
    </>
  );
};
