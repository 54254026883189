import React, { useState, useCallback, useEffect } from 'react';
import DatePicker from 'react-datepicker';
import Swal from 'sweetalert2';
import { formatarDataAnoMes } from '../../../../components/Formulas/formulas';
import { Loading } from '../../../../components/Loading';
import api from '../../../../services/api';
import Modal from '../ModalPdf/indexModal';
import { ResponseRelatorio } from '../Interface/index';
import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerHeaderDados,
  ContainerDados,
  ContainerButtonCancelar,
  ContainerButtonSalvar,
} from './stylesModal';

interface ModalArquivo {
  onClose(): void;
  onSave?(arg: FileList): void;
  cpf: string;
}

const ModalData: React.FC<ModalArquivo> = ({
  cpf,
  onClose = () => {
    ('');
  },
  onSave = () => {
    ('');
  },
}) => {
  const [dataInicialGet, setDataInicialGet] = useState(
    formatarDataAnoMes(new Date().toLocaleString()),
  );
  const [dataInicial, setDataInicial] = useState();
  const [verModal, setVerModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<ResponseRelatorio[]>([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleGerarRelatorio = useCallback(async () => {
    // try-catch para pegar o arquivo
    try {
      setLoading(true);
      await api
        .get(
          `/pontoEletronico/getEscalaWebCompetenciaFuncionario/${dataInicialGet}/${cpf}`,
        )
        .then((data) => {
          setResponse(data.data);
          // console.log(data.data);
          setVerModal(true);
          setLoading(false);
        });
    } catch (err) {
      setLoading(true);
      Swal.fire({
        icon: 'info',
        title: 'Erro ao carregar dados, tente novamente!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [cpf, dataInicialGet]);

  return (
    <>
      <div>
        <ContainerModal id="modal">
          <Container>
            <ContainerButton>
              <button
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                X
              </button>
            </ContainerButton>
            <ContainerHeaderDados>
              <p>Escolha um período para o relatório.</p>
            </ContainerHeaderDados>
            <ContainerDados>
              <DatePicker
                locale="pt-BR"
                selected={dataInicial || new Date()}
                onChange={(date) => {
                  setDataInicialGet(formatarDataAnoMes(date?.toLocaleString()));
                  setDataInicial(date);
                }}
                selectsStart
                startDate={new Date()}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </ContainerDados>
            <ContainerButtonCancelar>
              <button type="button" onClick={() => onClose()}>
                Cancelar
              </button>
            </ContainerButtonCancelar>
            <ContainerButtonSalvar>
              <button
                type="button"
                onClick={() => {
                  handleGerarRelatorio();
                }}
              >
                Relatório Ponto
              </button>
            </ContainerButtonSalvar>
          </Container>
        </ContainerModal>
      </div>
      {verModal && (
        <Modal dados={response} onClose={() => setVerModal(false)} />
      )}
      {loading && <Loading />}
    </>
  );
};

export default ModalData;
