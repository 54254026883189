import React, { useState } from 'react';
import clsx from 'clsx';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { shade } from 'polished';

interface CheckboxCustomProps {
  labelProps: string;
  colorProps: 'default' | 'primary' | 'secondary';
  idProps: number;
  defaultCheckProps?: boolean;
  checkedProps: (e: boolean) => void;
}

const colors = {
  primary: '#137cbd',
  secondary: '#cc2900',
  default: '#137cbd',
};

export const CheckboxCustom: React.FC<CheckboxCustomProps> = ({
  labelProps,
  colorProps,
  idProps,
  defaultCheckProps = false,
  checkedProps = (e: boolean) => {
    return e;
  },
}) => {
  const [checkedDefault, setCheckedDefault] = useState(defaultCheckProps);

  const useStyles = makeStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    icon: {
      borderRadius: 3,
      width: 18,
      height: 18,
      boxShadow:
        'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
      backgroundColor: '#f5f8fa',
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
      '$root.Mui-focusVisible &': {
        outline: `2px auto ${colors[colorProps]}`,
        outlineOffset: 2,
      },
      'input:hover ~ &': {
        backgroundColor: '#ebf1f5',
      },
      'input:disabled ~ &': {
        boxShadow: 'none',
        background: 'rgba(206,217,224,.5)',
      },
    },
    checkedIcon: {
      backgroundColor: `${colors[colorProps]}`,
      backgroundImage:
        'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
      '&:before': {
        display: 'block',
        width: 18,
        height: 18,
        backgroundImage:
          "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath" +
          " fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 " +
          "1.003 0 00-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0012 5z' fill='%23fff'/%3E%3C/svg%3E\")",
        content: '""',
      },
      'input:hover ~ &': {
        backgroundColor: `${shade(0.1, colors[colorProps])}`,
      },
    },
  });

  const classes = useStyles();

  return (
    <FormControlLabel
      style={{ marginRight: 0 }}
      control={
        // eslint-disable-next-line react/jsx-wrap-multilines
        <Checkbox
          name={`${idProps}-${labelProps}`}
          className={classes.root}
          checked={checkedDefault}
          onChange={(e) => {
            checkedProps(e.target.checked);
            setCheckedDefault(e.target.checked);
          }}
          disableRipple
          color={colorProps}
          checkedIcon={
            <span className={clsx(classes.icon, classes.checkedIcon)} />
          }
          icon={<span className={classes.icon} />}
          inputProps={{ 'aria-label': 'decorative checkbox' }}
        />
      }
      label={labelProps}
    />
  );
};
