import styled from 'styled-components';
import { shade } from 'polished';
import SearchComponent from '../../../components/Search';

interface ContainerProps {
  texto?: boolean;
  cor?: boolean;
  corTexto?: string;
}

export const Container = styled.div`
  padding: 20px;
`;

export const Search = styled(SearchComponent)`
  border-top-right-radius: 7px !important;
  border-bottom-right-radius: 7px !important;
`;

export const ContainerSearchTodos = styled.div`
  width: 100%;
  background: #3e3946;
  padding: 30px;
  border-radius: 0.25rem;
  overflow: auto;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  div:first-child {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0px !important;
    padding: 0px !important;
  }

  div {
    border-top-left-radius: 7px !important;
    border-bottom-left-radius: 7px !important;

    & + div {
      margin-left: 10px;
    }

    input {
      width: 170px !important;
      margin-right: 0 !important;
    }
  }
`;

export const ContainerFilter = styled.div`
  padding: 30px;
  background-color: #3e3946;
  overflow-x: auto;
  border-radius: 0.25rem;

  h3 {
    text-align: center;
    margin: 0;
  }
`;

export const ContainerPesquisaGeral = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    text-align: center;
  }
`;

export const ContainerFilterGeral = styled.div`
  border-radius: 0 0 0.25rem 0.25rem;

  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    text-align: center;
  }
`;

export const ContainerSupFilter = styled.div`
  width: 100%;
  padding: 30px;
  border-radius: 0.25rem 0.25rem 0 0;
  background-color: #3e3946;
  overflow-x: auto;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  p {
    font-size: 1rem;
    font-family: Arial;
    margin: 0;
    margin: 0 10px 0 40px;
  }
  select {
    background-color: #f1f1f1;
    color: #439133;
    border-radius: 0.25rem;
    margin: 0;
    border: none;
  }
  input {
    background-color: #f1f1f1;
    color: #439133;
    border-radius: 0.25rem;
    border: none;
    padding: 10px;
    height: 40px;
  }
  input:first-child {
    margin-right: 10px;
  }
`;

export const ContainerInfFilter = styled.div`
  display: flex;
  margin-left: 20px;
  justify-content: center;
  p {
    font-size: 17px;
    font-family: Arial;
    margin: 10px;
  }
  input {
    height: 20px;
    width: 20px;
  }
  label {
    font-size: 14px;
    text-align: center;
    font-family: Arial;
    width: 100px;
    padding: 10px 5px;
  }
  button {
    height: 40px;
    padding: 0px 10px;
    justify-content: center;
    align-content: center;
    background-color: #439133;
    color: #ffffff;
    border-radius: 0.25rem;
    border: none;
    &:hover {
      background-color: #57bd42;
    }
  }
  svg {
    margin-left: 3px;
  }
`;

export const ContainerInfFilterGeral = styled.div`
  width: 100%;
  background-color: #4b4554;
  padding: 30px;
  border-radius: 0 0 0.25rem 0.25rem;
  overflow-x: auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    font-size: 17px;
    font-family: Arial;
    margin: 0;
  }
  input {
    height: 20px;
    width: 20px;
  }
  section {
    position: relative;
    background: #3e3946;
    height: 80px;
    border-radius: 0.25rem;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    svg {
      position: absolute;
      top: 15px;
      right: 15px;
      width: 15px;
      height: 15px;
    }

    div {
      width: 100px;
      overflow: auto;

      p {
        text-align: center;
        line-height: 15px;
        font-size: 0.8rem;
      }

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  section:first-child {
    border-radius: 0.25rem 0 0 0.25rem;
  }
  section:last-child {
    border-radius: 0 0.25rem 0.25rem 0;
  }
  button {
    height: 40px;
    padding: 0px 10px;
    justify-content: center;
    align-content: center;
    background-color: #439133;
    color: #ffffff;
    border-radius: 0.25rem;
    border: none;
    &:hover {
      background-color: #57bd42;
    }
  }
`;

export const ButtonFilter = styled.div`
  margin-right: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  button {
    height: 40px;
    width: 60px;
    background-color: #439133;
    color: #ffffff;
    border-radius: 0.25rem;
    border: none;

    &:hover {
      background-color: #57bd42;
    }
  }
`;

export const ContainerFiltro = styled.div`
  background-color: #3e3946;
  padding: 15px;
  border-radius: 0.25rem;
  margin: 15px 0;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ContainerAbaDeButtons = styled.div`
  width: 100%;
  height: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
`;

export const Aba = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  background-color: #3e3946;
  padding: 15px;
  border-radius: 0.25rem;

  div.geralAba {
    display: flex;
  }

  .dropbtn {
    background-color: #006600;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  .dropdown {
    position: sticky;
    display: inline-block;

    & + div {
      margin-left: 20px;
    }

    button {
      background-color: #006600;
      border: none;
      height: 40px;
      width: 220px;
      padding-left: 20px;
      padding-right: 10px;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;

      p {
        justify-content: center;
        align-items: center;
      }
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: transparent;
    width: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 1;
    margin-top: -3px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .dropdown-content button {
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    width: 220px;
  }

  .dropdown-content button:hover {
    background-color: ${shade(0.2, '#006600')};
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }
`;

export const AbaDeButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  background-color: #4b4554;
  border-radius: 0.25rem;
  padding: 15px 0;

  div.geralAba {
    display: flex;
  }

  .dropbtn {
    background-color: #006600;
    color: white;
    font-size: 16px;
    border: none;
    cursor: pointer;
  }

  .dropdown {
    position: sticky;
    display: inline-block;

    button {
      background-color: #006600;
      margin-left: 20px;
      border: none;
      height: 100%;
      width: 220px;
      padding-left: 20px;
      padding-right: 10px;
      border-radius: 0.25rem;
      color: #ffffff;
      line-height: 20px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-content: center;
      align-items: center;

      p {
        justify-content: center;
        align-items: center;
      }
    }
  }

  .dropdown-content {
    display: none;
    position: absolute;
    background-color: transparent;
    width: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    z-index: 1;
    margin-top: -3px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .dropdown-content button {
    padding: 8px 12px;
    text-decoration: none;
    display: block;
    width: 220px;
  }

  .dropdown-content button:hover {
    background-color: ${shade(0.2, '#006600')};
  }

  .dropdown:hover .dropdown-content {
    display: block;
  }

  .dropdown:hover .dropbtn {
    background-color: #3e8e41;
  }
`;

export const ContainerAba = styled.div<ContainerProps>`
  display: flex;

  button {
    height: 100%;
    background-color: ${(props) => (props.cor ? ' #3e8e41' : '#006600')};
    background-color: ${(props) => props.corTexto};
    color: ${(props) => (props.cor ? ' #ffffff' : '#cccccc')};
    font-weight: bold;
    border-radius: 0.25rem;
    border-width: 0;
    padding: 0 20px;
    &:hover {
      filter: brightness(1.2);
    }
  }
  button.buttonVoltar {
    height: 100%;
    margin: 0 10px;
    padding: 0 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #645c70;
    }
  }
`;

export const DivAbaDeButtons = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    height: 100%;
    background-color: ${(props) => (props.cor ? ' #3e8e41' : '#006600')};
    background-color: ${(props) => props.corTexto};
    color: ${(props) => (props.cor ? ' #ffffff' : '#cccccc')};
    font-weight: bold;
    border-radius: 0.25rem;
    border-width: 0;
    padding: 0 20px;
    &:hover {
      filter: brightness(1.2);
    }
  }
  button.buttonVoltar {
    height: 100%;
    margin: 0 10px;
    padding: 0 10px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: #645c70;
    }
  }
`;

export const ContainerTable = styled.div`
  max-height: 50vh;
  overflow: auto;

  table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;

    th {
      border-bottom: 1px solid #006600;
      padding: 5px 15px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      white-space: nowrap;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      white-space: nowrap;

      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
      .buttonAbonar {
        background-color: #439133;
        border-radius: 0;
        padding: 8px;
        border: 1px;
      }
    }
    tr {
      border-bottom: 0px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #494554;
      }
      &:hover {
        background-color: #347128;
      }
    }
    a {
      size: 25px;
      color: #ffffff;
    }
    .aComLink {
      text-decoration: normal;
    }
    select {
      background-color: #f1f1f1;
      color: #439133;
      height: 25px;
      border-radius: 0.25rem;
      border: none;
      justify-content: center;
      padding: 0px 10px;
    }
  }
`;

export const ContainerPagination = styled.div<ContainerProps>`
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .divPaginacao {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    margin-left: 20px;
    border: none;
    height: auto;
    width: 100px;
    padding: 3px 20px 3px;
    border-radius: 0.25rem;
    color: #ffffff;
    line-height: 20px;
    background-color: #006600;
    &:hover {
      background-color: #57bd42;
    }
  }

  .buttonPdf {
    background-color: #006600;
    border: none;
    height: 40px;
    width: 100px;
    padding: 5px;
    border-radius: 0.25rem;
    color: #ffffff;
    svg {
      height: 25px;
      width: 25px;
    }
    &:hover {
      background-color: #57bd42;
    }
  }
`;

export const DivButtonExcel = styled.div`
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContainerTableEscala = styled.div`
  align-items: center;
  max-height: 60vh;
  overflow-x: auto;
  table {
    width: 100%;
    padding: 10px 10px;
    margin: 20px auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    th {
      border-bottom: 1px solid #006600;
      padding: 5px 15px;
      background-color: #439133;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: Left;
      font-size: 14px;
    }
  }
`;

export const TdIcons = styled.td`
  justify-content: center;
  align-content: center;
`;

export const ContainerButton = styled.div`
  width: 100%;
  display: flex;
  margin: 0px auto;
  margin-bottom: 50px;
  justify-content: flex-end;
  .buttonAbonar {
    background-color: #439133;
    color: #ffffff;
    padding: 8px;
    border: 1px;
  }
`;

export const ContainerFormulario = styled.div<ContainerProps>`
  button {
    border: none;
    svg {
      height: 30px;
      width: 20px;
      color: ${(props) => (props.cor ? '#008800' : '#ffffff')};
    }
  }
`;

export const ContainerSpace = styled.div`
  height: 50px;
`;
