import React, { useState, useRef } from 'react';
import { FiSearch } from 'react-icons/fi';
import {
  maskCpf,
  maskDateOfBirth,
  maskCep,
  maskCnpj,
  maskMonthYear,
  maskRegistration,
  maskTel,
  moedaReal,
} from '../Input/mask';

import { Container, ContainerLupa } from './styles';

interface SearchInterface {
  className?: string;
  nomePlaceHolder: string;
  typeProps?:
    | 'text'
    | 'cpf'
    | 'cnpj'
    | 'date'
    | 'cep'
    | 'coin'
    | 'monthYear'
    | 'tel';
  onSearch: (arg: string) => void;
}

const Search: React.FC<SearchInterface> = ({
  onSearch,
  className,
  nomePlaceHolder,
  typeProps = 'text',
  ...rest
}: SearchInterface) => {
  const [search, setSearch] = useState('');

  const InputRef = useRef<HTMLInputElement>(null);

  const onInputChange = (value: string): void => {
    switch (typeProps) {
      case 'cpf':
        setSearch(maskCpf(value));
        onSearch(maskCpf(value));
        break;
      case 'cnpj':
        setSearch(maskCnpj(value));
        onSearch(maskCnpj(value));
        break;
      case 'date':
        setSearch(maskDateOfBirth(value));
        onSearch(maskDateOfBirth(value));
        break;
      case 'cep':
        setSearch(maskCep(value));
        onSearch(maskCep(value));
        break;
      case 'coin':
        setSearch(moedaReal(value));
        onSearch(moedaReal(value));
        break;
      case 'monthYear':
        setSearch(maskMonthYear(value));
        onSearch(maskMonthYear(value));
        break;
      case 'tel':
        setSearch(maskTel(value));
        onSearch(maskTel(value));
        break;
      default:
        setSearch(value);
        onSearch(value);
        break;
    }
  };

  return (
    <Container>
      <ContainerLupa
        classNameProps={className || 'search1'}
        onClick={() => InputRef.current?.focus()}
      >
        <FiSearch color="#000000" size={22} />
      </ContainerLupa>
      <input
        ref={InputRef}
        type="text"
        className={className}
        placeholder={nomePlaceHolder}
        value={search}
        onChange={(e) => onInputChange(e.target.value)}
        {...rest}
      />
    </Container>
  );
};

export default Search;
