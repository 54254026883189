import React, { useCallback } from 'react';
import { BsQuestionCircleFill } from 'react-icons/bs';
import {
  ContainerModal,
  Container,
  ContainerButton,
  ContainerHeader,
  ContainerHeaderDados,
  ContainerButtonAtualizar,
} from './styles';

interface ModalConexao {
  onClose?(): void;
  title: string;
  content?: string;
}

const ModalInformacao: React.FC<ModalConexao> = ({
  title,
  content,
  onClose = () => {
    ('');
  },
}) => {
  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose();
      }
    },
    [onClose],
  );

  return (
    <>
      <div>
        <ContainerModal id="modal" onClick={(e) => handleOutSideClick(e)}>
          <Container>
            <ContainerButton>
              <button
                type="button"
                onClick={() => {
                  onClose();
                }}
              >
                X
              </button>
            </ContainerButton>
            <ContainerHeader>
              <BsQuestionCircleFill />
            </ContainerHeader>
            <ContainerHeaderDados>
              <p className="titulo">{title}</p>
            </ContainerHeaderDados>
            <ContainerHeaderDados>
              <p>{content}</p>
            </ContainerHeaderDados>
            <ContainerButtonAtualizar>
              <button type="button" onClick={() => onClose()}>
                Ok
              </button>
            </ContainerButtonAtualizar>
          </Container>
        </ContainerModal>
      </div>
    </>
  );
};

export default ModalInformacao;
