import styled from 'styled-components';
import { darken, lighten } from 'polished';

interface ContainerProps {
  isActive: boolean;
  isSelected: 'registerBox' | 'editBox';
}

export const Container = styled.div<ContainerProps>`
  background: #888198;
  border-radius: 7px;
  padding: 10px;
  overflow-y: auto;

  transition: filter 0.5s;

  filter: ${(props) => (!props.isActive ? 'blur(3px)' : '')};

  header {
    border-bottom: 4px solid #f2f2f2;
    border-radius: 5px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    h2 {
      margin: 0;
      padding-bottom: 5px;
    }

    article {
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        margin: 0;
        border: none;
        box-shadow: 1px 1px 1px 0px black;
        background: ${(props) =>
          props.isSelected === 'registerBox'
            ? lighten(0.1, '#00b300')
            : '#00b300'};
        border-radius: 2px;
        filter: ${(props) =>
          props.isSelected === 'registerBox'
            ? 'brightness(1)'
            : 'brightness(0.5)'};

        display: flex;
        align-items: center;
        justify-content: center;

        transition: background 0.2s;

        svg {
          width: 24px;
          height: 24px;
        }

        &:hover {
          background: ${darken(0.1, '#00b300')};
        }

        & + button {
          margin-left: 10px;
          background: ${(props) =>
            props.isSelected === 'editBox'
              ? lighten(0.1, '#eba417')
              : '#eba417'};
          filter: ${(props) =>
            props.isSelected === 'editBox'
              ? 'brightness(1)'
              : 'brightness(0.5)'};

          transition: background 0.2s;

          &:hover {
            background: ${darken(0.1, '#eba417')};
          }
        }
      }
    }
  }
`;

export const ContainerDiv = styled.div`
  height: 100%;
  max-height: calc(100vh - 390px);
  padding-right: 2px;
  margin-top: 20px;
  border-radius: 7px;
  overflow-y: auto;

  article.listEmpty {
    width: 100%;
    height: 100%;
    background: #565062;
    border-radius: 7px;

    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const SectionModule = styled.section`
  margin: 0;
  width: 100%;
  background: #565062;
  padding: 10px;
  border-radius: 7px;
  border: 2px solid #565062;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;

  transition: 0.2s;

  &:hover {
    background: ${darken(0.1, '#565062')};
    border: 2px solid #000000;
  }

  & + section {
    margin-top: 5px;
  }

  article {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      margin: 0;
      border: none;
      padding: 5px;
      border-radius: 7px;
      background: ${lighten(0.1, '#565062')};
      border: 2px solid ${lighten(0.2, '#565062')};
      box-shadow: 1px 1px 1px 0px black;

      transition: 0.2s;

      &:hover {
        background: #565062;
        border: 2px solid ${lighten(0.3, '#565062')};
      }

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

interface ContainerAsideProps {
  isOpen: boolean;
}

export const ContainerAside = styled.aside<ContainerAsideProps>`
  width: 100%;
  padding: 0 10px;
  margin-top: 20px;

  display: ${(props) => (props.isOpen ? 'flex' : 'none')};
  align-items: center;
  justify-content: space-between;

  svg {
    width: 20px;
    height: 20px;
  }

  div {
    padding: 10px;
    margin-left: 10px;
    background: #6e677e;
    border-radius: 7px;

    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
  }
`;
