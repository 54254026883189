import React from 'react';
import HeaderTable from '../../../../components/HeaderTable';
import { formatarDataAnoMesDia } from '../../../../components/Formulas/formulas';
import { ContainerTable } from '../styles';
import { maskCpf } from '../../../../components/Input/mask';

interface AusenciaCiente {
  CIENTE_OP: boolean;
  CPF: string;
  DATA_ATUALIZACAO: string;
  DATA_CIENTE_OP: string;
  DATA_PROGRAMADA: string;
  DESC_MOTIVO: string;
  DESC_MOTIVO_OP: string;
  ID: number;
  Id_MotivosAusencia: number;
  Id_UsersAppColaborador: number;
  NOME: string;
  NOME_USER_CIENTE_OP: string;
  LINK: string;
  FILIAL: string;
}

interface DadosTable {
  dados: AusenciaCiente[];
}

const TableAusencia: React.FC<DadosTable> = ({ dados }) => {
  const headers = [
    { name: 'Nome', field: 'nome' },
    { name: 'CPF', field: 'cpf' },
    { name: 'Data Ausência', field: 'data' },
    { name: 'Motivo Ausência', field: 'motAusencia' },
    { name: 'Descrição do Motivo', field: 'descricao' },
    { name: 'Código Motivo', field: 'codigo' },
    { name: 'Arquivo', field: 'arquivo' },
    { name: 'Validar', field: 'validar' },
    { name: 'Motivo', field: 'motivo' },
  ];

  const motivoAusencia = [
    { name: 'Atestado', id: 1 },
    { name: 'Consulta', id: 2 },
    { name: 'Indisposição', id: 3 },
    { name: 'Desistência', id: 4 },
    { name: 'Licenças', id: 6 },
    { name: 'Outros', id: 5 },
    { name: 'Falta', id: 7 },
  ];

  function motivoAusenciaNumberToString(id: number): string {
    const data = motivoAusencia.filter((dado) => id === dado.id);
    return data[0].name;
  }

  return (
    <ContainerTable>
      <table id="AusenciaExcel">
        <HeaderTable headers={headers} />
        <tbody>
          {dados.map((res: AusenciaCiente) => (
            <tr key={res.CPF + res.ID}>
              <td style={{ minWidth: 200 }}>{res.NOME}</td>
              <td style={{ minWidth: 120 }}>{maskCpf(res.CPF)}</td>
              <td>{formatarDataAnoMesDia(res.DATA_PROGRAMADA)}</td>
              <td>{motivoAusenciaNumberToString(res.Id_MotivosAusencia)}</td>
              <td style={{ minWidth: 350 }}>
                {res.DESC_MOTIVO === '' ? '-' : res.DESC_MOTIVO}
              </td>
              <td>{res.ID}</td>
              <td style={{ width: 30 }}>
                {res.LINK === null ? '-' : res.LINK}
              </td>
              <td style={{ minWidth: 90 }}>
                {(res.CIENTE_OP === null || res.CIENTE_OP === true) && (
                  <p>Ainda não foi visualizado</p>
                )}
                {!(res.CIENTE_OP === null || res.CIENTE_OP === true) && (
                  <p>Não foi aceito</p>
                )}
                {res.CIENTE_OP && <p>Foi aceito</p>}
              </td>
              <td style={{ minWidth: 100 }}>{res.DESC_MOTIVO_OP}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </ContainerTable>
  );
};

export default TableAusencia;
