import React, { FormEvent, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { AiOutlineClose } from 'react-icons/ai';
import api from '../../services/api';
import { Loading } from '../Loading';
import { useToast } from '../../hooks/toast';

import { Container } from './styles';

Modal.setAppElement('#root');

interface ModalProps {
  isOpen: boolean;
  titleProps: string;
  data: ResponsePost;
  onDataReturn: (e: ResponsePost) => void;
  onRequestClose: () => void;
}

export interface ResponsePost {
  // Setor e Função
  NOME: string;
  ID: number;

  // Função
  NIVEL?: number;
}

export function ModalEditSectorFunction({
  isOpen,
  titleProps,
  data,
  onDataReturn = (e: ResponsePost) => {
    return e;
  },
  onRequestClose,
}: ModalProps): JSX.Element {
  const { addToast } = useToast();
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState(data.NOME);

  useEffect(() => {
    setName(data.NOME);
  }, [data.NOME]);

  async function handleCreateNewSectorFunction(
    event: FormEvent,
  ): Promise<void> {
    event.preventDefault();

    const endPoint =
      titleProps === 'setor'
        ? `/setor/updateSetor?id=${data.ID}`
        : `/nivelAcesso/updateNivelAcessoFuncao?id=${data.ID}`;

    if (name.trim() !== '') {
      setLoading(true);

      await api
        .put(`${endPoint}`, {
          nome: name,
        })
        .then((res) => {
          setLoading(false);
          onDataReturn(res.data);

          addToast({
            title: 'Sucesso!',
            description: `O ${titleProps} foi editado com sucesso.`,
            type: 'success',
          });

          onRequestClose();
        })
        .catch((err) => {
          setLoading(false);

          if (err.response.data.errors) {
            addToast({
              title: `Erro ao editar ${titleProps}!`,
              description: `${err.response.data.errors}.`,
              type: 'error',
            });
          } else {
            addToast({
              title: `Erro ao editar ${titleProps}!`,
              description: 'Ocorreu um erro, por favor tente novamente.',
              type: 'error',
            });
          }
        });
    } else {
      addToast({
        title: `Campo em branco!`,
        description: 'Campo vazio, favor preencher.',
        type: 'info',
      });
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="react-modal-overlay"
      className="react-modal-content"
    >
      <button
        type="button"
        onClick={onRequestClose}
        className="react-modal-close"
      >
        <AiOutlineClose size={22} />
      </button>

      <Container onSubmit={handleCreateNewSectorFunction}>
        <h2>Editar {titleProps}</h2>

        <input
          placeholder="Nome"
          value={name}
          onChange={(event) => setName(event.target.value)}
        />

        <button type="submit">Salvar</button>
      </Container>

      {loading && <Loading />}
    </Modal>
  );
}
