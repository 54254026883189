import React, { useCallback, useState } from 'react';
import { Drawer, Button } from 'antd';
import * as MdIcons from 'react-icons/md';
import { Link } from 'react-router-dom';
import { FiPower } from 'react-icons/fi';
import Swal from 'sweetalert2';
import {
  Container,
  Profile,
  ContainerButtonDrawer,
  ButtonSignOut,
  ButtonSignOutf,
} from './styles';
import MenuLateral from '../Menu';
import avatar from '../../assets/IconAvatar.svg';
import { useAuth } from '../../hooks/auth';
import logoMenu from '../../assets/LogoGreenDot.png';
import 'antd/dist/antd.css';

const DrawerApp: React.FC = () => {
  const { signOut } = useAuth();
  const [visible, setVisible] = useState(false);
  const { user } = useAuth();

  const showDrawer = (): void => {
    setVisible(true);
  };

  const onClose = (): void => {
    setVisible(false);
  };

  const handleConfirmSignOut = useCallback(() => {
    Swal.fire({
      title: 'Tem certeza que deseja sair?',
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: 'Não',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Sim',
    }).then((result) => {
      if (result.isConfirmed) {
        signOut();
      }
    });
  }, [signOut]);

  return (
    <>
      <Container>
        <ContainerButtonDrawer>
          <Button type="text" onClick={showDrawer}>
            <MdIcons.MdMenu size={40} />
          </Button>
          <Link to="/dashboard">
            <img src={logoMenu} alt="Logo Servnac" />
          </Link>
          <ButtonSignOut onClick={handleConfirmSignOut}>
            <FiPower color="#ffffff" style={{ height: 25, width: 25 }} />
          </ButtonSignOut>
        </ContainerButtonDrawer>

        <Drawer
          width="auto"
          bodyStyle={{
            padding: '20px 15px 0px 15px',
            background: '#004d00',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
          }}
          placement="left"
          closable={false}
          onClose={onClose}
          visible={visible}
        >
          <Profile>
            <Link to="/profile">
              <div>
                <img
                  src={user && user.avatar_url ? user.avatar_url : avatar}
                  alt="Foto Avatar"
                  style={{
                    backgroundColor: '#f2f2f2',
                  }}
                />
              </div>
            </Link>
            <Link to="/profile">
              <div>
                <span>{user ? user.nome : ''}</span>
                <strong>{user ? user.email : ''}</strong>
              </div>
            </Link>
          </Profile>
          <MenuLateral />
          <ButtonSignOutf onClick={handleConfirmSignOut}>
            <div>
              <FiPower color="#ffffff" style={{ height: 20, width: 20 }} />
              <p>Sair</p>
            </div>
          </ButtonSignOutf>
        </Drawer>
      </Container>
      <div style={{ height: 50 }} />
    </>
  );
};

export default DrawerApp;
