import React, { useRef, useCallback, useState, useEffect } from 'react';
import { FormHandles } from '@unform/core';
import { AiFillCloseCircle } from 'react-icons/ai';
import { Form } from '@unform/web';
import * as Yup from 'yup';
import { useHistory } from 'react-router-dom';
import { BsBuilding } from 'react-icons/bs';
import { IoCalculatorOutline, IoDocumentTextOutline } from 'react-icons/io5';
import { FaRegMoneyBillAlt } from 'react-icons/fa';
import { MdDateRange } from 'react-icons/md';
import Input from '../Input';
import api from '../../services/api';
import { useToast } from '../../hooks/toast';
import getValidationErrors from '../../utils/getValidationErrors';
import {
  SignUpFormData,
  ResponseGet,
} from '../../pages/Cadastros/CadastroDecimoTerceiro';
import { Loading } from '../Loading';
import { SelectCustom } from '../SelectCustom';
import { ResponseGetFilial } from '../../pages/Cadastros/PreCadastroFuncionario';
import { findFilialName } from '../../interfaces/functionsFilterFind';

import {
  ContainerModal,
  Container,
  ButtonClose,
  Content,
  Title,
  ButtonSubmit,
  ContainerForm,
} from './styles';

interface ModalProps {
  data: ResponseGet;
  filiais: ResponseGetFilial[];
  onClose(e: boolean): void;
}

export const EditContrachequeDecimoTerceiroModal: React.FC<ModalProps> = ({
  data,
  filiais,
  onClose = (e: boolean): boolean => {
    return e;
  },
}: ModalProps) => {
  const formRef = useRef<FormHandles>(null);
  const history = useHistory();
  const [showLoading, setShowLoading] = useState(false);
  const { addToast } = useToast();
  const [editEmpresa, setEditEmpresa] = useState(data?.RA_FILIAL);
  const [editNomeEmpresa, setEditNomeEmpresa] = useState(data?.NOME_EMPRESA);
  const [editSalarioBase, setEditSalarioBase] = useState(
    data?.SALARIO_BASE.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
  );
  const [editReferencia, setEditReferencia] = useState(
    data?.REFERENCIA.toString(),
  );
  const [editSalarioLiquido, setEditSalarioLiquido] = useState(
    data?.SALARIO_LIQUIDO.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
  );
  const [editSomaDescontosTotais, setEditSomaDescontosTotais] = useState(
    data?.SOMA_DESCONTOS_TOTAIS.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    }),
  );
  const [editSomaProventosTotais, setEditSomaProventosTotais] = useState(
    data?.SOMA_PROVENTOS_TOTAIS.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    }),
  );
  const [editTipoCodVerba, setEditTipoCodVerba] = useState(
    data?.TIPO_COD_VERBA,
  );
  const [editCentroCusto, setEditCentroCusto] = useState(
    data?.DESC_CENTRO_CUSTO,
  );
  const [
    editDescontosProventosPorVerbas,
    setEditDescontosProventosPorVerbas,
  ] = useState(
    data?.DESCONTOS_PROVENTOS_POR_VERBAS.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
    }),
  );
  const [filiaisTemp, setFiliaisTemp] = useState<ResponseGetFilial[]>([]);

  useEffect(() => {
    const findDataFilial = filiais.find(
      (filial) => filial.CODIGO_FILIAL === editEmpresa,
    );

    findDataFilial === undefined
      ? setFiliaisTemp([
          ...filiais,
          {
            ATIVO: false,
            BAIRRO: 'INATIVO',
            CEP: 'INATIVO',
            CIDADE: 'INATIVO',
            CODIGO_FILIAL: editEmpresa,
            ENDERECO: 'INATIVO',
            ESTADO: 'INATIVO',
            ID: -1,
            NUMERO: 'INATIVO',
            NOME_EMPRESA: 'INATIVO',
            NOME_FANTASIA_EMPRESA: 'INATIVO',
            CNPJ: 'INATIVO',
          },
        ])
      : setFiliaisTemp([...filiais]);
  }, [editEmpresa, filiais]);

  const handleOutSideClick = useCallback(
    (e) => {
      if (e.target.id === 'modal') {
        onClose(true);
      }
    },
    [onClose],
  );

  const handleSubmit = useCallback(
    async (dataTemp: SignUpFormData) => {
      try {
        formRef.current?.setErrors({});

        const schema = Yup.object().shape({
          empresa: Yup.string().required('Empresa é obrigatória!'),
          nomeEmpresa: Yup.string().required('Nome da empresa é obrigatória!'),
          salarioBase: Yup.string().required('Salário base é obrigatório!'),
          referencia: Yup.string().required('Referência é obrigatória!'),
          salarioLiquido: Yup.string().required(
            'Salário líquido é obrigatório!',
          ),
          somaDescontosTotais: Yup.string().required(
            'Soma dos descontos totais é obrigatória!',
          ),
          somaProventosTotais: Yup.string().required(
            'Soma dos proventos totais é obrigatória!',
          ),
          descontosProventosPorVerbas: Yup.string().required(
            'Descontos dos proventos por verbas são obrigatórios!',
          ),
          tipoCodVerba: Yup.string().required(
            'Tipo de código de verba é obrigatório!',
          ),
          centroCusto: Yup.string().required('Centro de custo é obrigatório!'),
        });

        await schema.validate(dataTemp, {
          abortEarly: false,
        });

        setShowLoading(true);
        api
          .put(
            `/1cadastroIndividual/updateContraChequeDecimo?periodo=${
              data.PERIODO_FOLHA
            }&cpf=${data.CPFSEMFORMATO}&parcela=${
              data.PARCELA_DESCONTOS === '2'
                ? data.PARCELA_DESCONTOS
                : data.PARCELA_PROVENTOS
            }`,
            {
              EMPRESA: editEmpresa,
              NOME_EMPRESA: editNomeEmpresa,
              CNPJ: data.CNPJ,
              NOME: data.RA_NOME,
              MATRICULA: data.RA_MAT,
              DATA_ADMISSAO: data.DATA_ADMISSAO,
              FUNCAO: data.FUNCAO,
              SALARIO_BASE: editSalarioBase.replace('.', '').replace(',', '.'),
              REFERENCIA: editReferencia,
              SALARIO_LIQUIDO: editSalarioLiquido
                .replace('.', '')
                .replace(',', '.'),
              SOMA_DESCONTOS_TOTAIS: editSomaDescontosTotais
                .replace('.', '')
                .replace(',', '.'),
              SOMA_PROVENTOS_TOTAIS: editSomaProventosTotais
                .replace('.', '')
                .replace(',', '.'),
              DESCONTOS_PROVENTOS_POR_VERBAS: editDescontosProventosPorVerbas
                .replace('.', '')
                .replace(',', '.'),
              TIPO_COD_VERBA: editTipoCodVerba === 'PROVENTO' ? 1 : 2,
              CENTRO_CUSTO: editCentroCusto,
              PARCELA:
                data.PARCELA_DESCONTOS === '2'
                  ? data.PARCELA_DESCONTOS
                  : data.PARCELA_PROVENTOS,
            },
          )
          .then(() => {
            setShowLoading(false);
            addToast({
              type: 'success',
              title: 'Contracheque (décimo terceiro) editado!',
              description:
                'O contracheque (décimo terceiro) foi editado com sucesso e já pode ser visualizado na listagem.',
            });

            setTimeout(() => {
              history.go(0);
            }, 1500);
          })
          .catch(() => {
            setShowLoading(false);
            addToast({
              type: 'error',
              title: 'Erro na edição',
              description:
                'Ocorreu um erro ao editar o contracheque (décimo terceiro), tente novamente.',
            });
          });
      } catch (err) {
        setShowLoading(false);
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRef.current?.setErrors(errors);

          addToast({
            type: 'error',
            title: 'Erro na edição',
            description:
              'Existem campos obrigatórios não preenchidos, favor verificar.',
          });
        } else {
          addToast({
            type: 'error',
            title: 'Erro na edição',
            description:
              'Ocorreu um erro ao editar o contracheque (décimo terceiro), tente novamente.',
          });
        }
      }
    },
    [
      addToast,
      data.CNPJ,
      data.CPFSEMFORMATO,
      data.DATA_ADMISSAO,
      data.FUNCAO,
      data.PARCELA_DESCONTOS,
      data.PARCELA_PROVENTOS,
      data.PERIODO_FOLHA,
      data.RA_MAT,
      data.RA_NOME,
      editCentroCusto,
      editDescontosProventosPorVerbas,
      editEmpresa,
      editNomeEmpresa,
      editReferencia,
      editSalarioBase,
      editSalarioLiquido,
      editSomaDescontosTotais,
      editSomaProventosTotais,
      editTipoCodVerba,
      history,
    ],
  );

  return (
    <ContainerModal id="modal" onClick={handleOutSideClick}>
      <Container>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <Title>Editar contracheque (décimo terceiro)</Title>
          <ContainerForm>
            <Content>
              <SelectCustom
                name="empresa"
                widthProps="49%"
                icon={BsBuilding}
                defaultValue="Código da empresa"
                value={editEmpresa}
                onValue={(value) => {
                  setEditEmpresa(value);
                  setEditNomeEmpresa(findFilialName(filiaisTemp, value));
                }}
                optionsData={filiaisTemp.map((filial) => {
                  return filial.CODIGO_FILIAL;
                })}
              />
              <Input
                name="nomeEmpresa"
                icon={BsBuilding}
                widthProps="49%"
                placeholder="Nome da empresa"
                placeholderProps="Nome da empresa"
                editProps
                tooltip={false}
                value={editNomeEmpresa}
                disabled
              />
            </Content>
            <Content>
              <Input
                name="salarioBase"
                icon={FaRegMoneyBillAlt}
                widthProps="49%"
                placeholder="Salário base"
                placeholderProps="Salário base"
                editProps
                mask="coin"
                tooltip={false}
                value={editSalarioBase}
                onValue={(value) => setEditSalarioBase(value)}
              />
              <Input
                name="salarioLiquido"
                icon={FaRegMoneyBillAlt}
                widthProps="49%"
                placeholder="Salário líquido"
                placeholderProps="Salário líquido"
                editProps
                mask="coin"
                tooltip={false}
                value={editSalarioLiquido}
                onValue={(value) => setEditSalarioLiquido(value)}
              />
            </Content>
            <Content>
              <Input
                name="centroCusto"
                icon={IoDocumentTextOutline}
                widthProps="49%"
                placeholder="Desc. do centro de custo"
                placeholderProps="Desc. do centro de custo"
                editProps
                tooltip={false}
                value={editCentroCusto}
                onValue={(value) => setEditCentroCusto(value)}
              />
              <Input
                name="referencia"
                icon={MdDateRange}
                widthProps="49%"
                placeholder="Referência de dias"
                placeholderProps="Referência de dias"
                editProps
                type="number"
                tooltip={false}
                value={editReferencia}
                onValue={(value) => setEditReferencia(value)}
              />
            </Content>
            <Content>
              <Input
                name="somaProventosTotais"
                icon={IoCalculatorOutline}
                widthProps="49%"
                placeholder="Soma de proventos totais"
                placeholderProps="Soma de proventos totais"
                editProps
                mask="coin"
                tooltip={false}
                value={editSomaProventosTotais}
                onValue={(value) => setEditSomaProventosTotais(value)}
              />
              <Input
                name="somaDescontosTotais"
                icon={IoCalculatorOutline}
                widthProps="49%"
                placeholder="Soma de desc. totais"
                placeholderProps="Soma de desc. totais"
                editProps
                mask="coin"
                tooltip={false}
                value={editSomaDescontosTotais}
                onValue={(value) => setEditSomaDescontosTotais(value)}
              />
            </Content>
            <Content>
              <Input
                name="descontosProventosPorVerbas"
                icon={IoCalculatorOutline}
                widthProps="49%"
                placeholder="Desc. de proventos por verbas"
                placeholderProps="Desc. de proventos por verbas"
                editProps
                mask="coin"
                tooltip={false}
                value={editDescontosProventosPorVerbas}
                onValue={(value) => setEditDescontosProventosPorVerbas(value)}
              />
              <SelectCustom
                name="tipoCodVerba"
                icon={IoDocumentTextOutline}
                widthProps="49%"
                defaultValue="Tipo de cód. de verba"
                value={
                  editTipoCodVerba === '1' || editTipoCodVerba === 'PROVENTO'
                    ? 'PROVENTO'
                    : 'DESCONTO'
                }
                onValue={(value) => setEditTipoCodVerba(value)}
                optionsData={['PROVENTO', 'DESCONTO']}
              />
            </Content>
          </ContainerForm>
          <ButtonSubmit type="submit">Salvar</ButtonSubmit>
        </Form>
        <ButtonClose onClick={() => onClose(true)}>
          <AiFillCloseCircle size={28} />
        </ButtonClose>
      </Container>

      {showLoading && <Loading />}
    </ContainerModal>
  );
};
