import styled from 'styled-components';
import { shade } from 'polished';

export const Container = styled.div`
  flex: 1;
  max-width: auto;
  align-items: center;
  padding: 20px;
`;

export const ContainerTable = styled.div`
  width: auto;
  align-items: center;
  margin: 0px auto;
  margin-top: 15px;
  max-height: 52vh;
  overflow-x: auto;
  border-radius: 0.25rem 0.25rem 0 0;
  table {
    width: 100%;
    margin: 0 auto;
    border-collapse: collapse;
    border: 1px solid #006600;
    border-top: 0px;
    th {
      border-bottom: 1px solid #006600;
      justify-content: space-between;
      padding: 10px 10px;
      background-color: #006600;
      color: #fff;
      position: sticky;
      position: -webkit-sticky;
      z-index: 1;
      top: 0;
      white-space: nowrap;
    }
  }
  tbody {
    td {
      margin: 5px;
      padding: 5px;
      text-align: center;
      font-size: 14px;
      white-space: nowrap;

      label.ant-checkbox-wrapper {
        position: relative !important;
        z-index: 0 !important;
      }

      button {
        background-color: transparent;
        color: #fff;
        border: 0;
        & + button {
          margin-left: 20px;
        }
      }
    }
    tr {
      border-bottom: 1px solid #006600;
      /**funcao para fazer as linhas da tabela uma de cada cor de forma alternada.*/
      &:nth-child(even) {
        background-color: #323232;
      }
      &:hover {
        background-color: #454545;
      }
    }
  }
`;

export const ContainerButton = styled.div`
  max-width: 96%;
  margin-left: 140px;
  margin-top: 50px;
  button {
    margin-right: 10px;
    background-color: #439133;
    color: #fff;
    border-radius: 5px;
    padding: 10px;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

export const ContainerForm = styled.div`
  max-width: 100%;
  display: flex;
  align-items: center;
`;

export const ContainerDrop = styled.div`
  display: flex;
  align-items: center;
`;

export const ContainerPagination = styled.div`
  width: 100%;
  background-color: #006600;
  border-radius: 0 0 0.25rem 0.25rem;
  padding: 15px 0 15px 0;
  height: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  #divPaginacao {
    width: 100%;
    padding: 0 15px;

    display: flex;
    justify-content: center;
  }

  .btn {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-left: auto;
    border-radius: 0.25rem;
    width: 140px;
    background-color: #008f00;
    color: #fff;
    border: none;
    padding: 0 10px;
    font-weight: bold;
    transition: 0.2s;

    &:hover {
      background: ${shade(0.1, '#008f00')};
    }
  }
`;
