import React, { useCallback, useState, useEffect, ChangeEvent } from 'react';
import { FiCamera, FiSave, FiEdit2, FiXCircle, FiTrash } from 'react-icons/fi';
import Swal from 'sweetalert2';
import { useHistory } from 'react-router-dom';
import upload from '../../../assets/upload.png';
import api from '../../../services/api';
import { Loading } from '../../../components/Loading';
import ModalConexao from '../../../components/ModalConexao';
import { useAuth } from '../../../hooks/auth';

import {
  Container,
  ContainerButton,
  ContainerForm,
  ButtonBorder,
  ContainerHorizontal,
} from './styles';

interface FeedGet {
  ID: number;
  TITULO: string;
  IMAGEM: string;
}

interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

const pageAccess = {
  ID: 8,
  NOME: 'UPLOAD-FEED-NOTICIAS',
};

const UploadPost: React.FC = () => {
  const { modulos } = useAuth();
  const history = useHistory();
  const [feeds, setFeeds] = useState<FeedGet[]>([]);
  const [idFeed, setIdFeed] = useState<number>(0);
  const [edit, setEdit] = useState(true);
  const [urlAntiga, setUrlAntiga] = useState('');
  const [fileData, setFileData] = useState<FileList>();
  const [titulo, setTitulo] = useState('');
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);
    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        title: 'Você não tem permissão para acessar essa página!',
      });
    } else {
      setLoading(true);
      api
        .get(`feedNoticias`)
        .then((data) => {
          setFeeds(data.data);
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
          if (navigator.onLine) {
            Swal.fire({
              icon: 'info',
              text: 'Erro ao carregar dados, por favor atualize a página!',
            });
          }
        });
    }
  }, [modulos, history]);

  const handleImagemFeed = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      try {
        if (e.target.files) {
          setFileData(e.target.files);
          const url = URL.createObjectURL(e.target.files[0]);
          feeds.map((feed: FeedGet) => {
            if (feed.ID === idFeed) {
              setUrlAntiga(feed.IMAGEM);
              // eslint-disable-next-line no-param-reassign
              feed.IMAGEM = url;
            }
            return feed;
          });
          setFeeds(feeds);
        }
      } catch (err) {
        Swal.fire({
          icon: 'info',
          text: 'Erro ao carregar dados, tente novamente!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    },
    [feeds, idFeed],
  );

  const handleCancelEdit = useCallback(() => {
    try {
      if (urlAntiga !== '') {
        feeds.map((feed: FeedGet) => {
          if (feed.ID === idFeed) {
            setUrlAntiga(feed.IMAGEM);
            // eslint-disable-next-line no-param-reassign
            feed.IMAGEM = urlAntiga;
            setUrlAntiga('');
          }
          return feed;
        });
        setFeeds(feeds);
      }
    } catch (err) {
      Swal.fire({
        icon: 'info',
        text: 'Erro ao carregar dados, tente novamente!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [urlAntiga, feeds, idFeed]);

  const handleSalvar = useCallback(async () => {
    try {
      if (fileData) {
        setLoading(true);
        const data = new FormData();
        data.append('feed', fileData[0]);
        data.append('titulo', titulo);
        data.append('seq', idFeed.toString());
        await api
          .post('feedNoticias/insertFeed', data)
          .then(() => {
            Swal.fire({
              icon: 'success',
              text: 'Feed salvo com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            setFileData(undefined);
            setEdit(true);
            setTitulo('');
            setLoading(false);
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              text: 'Erro ao salvar feed!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            setLoading(false);
          });
      } else {
        Swal.fire({
          icon: 'info',
          text: 'Adicione uma imagem para salvar!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    } catch (err) {
      Swal.fire({
        icon: 'error',
        text: 'Erro ao salvar, tente novamente!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
      setLoading(false);
    }
  }, [fileData, titulo, idFeed]);

  const handleDeletar = useCallback(
    async (id: number) => {
      try {
        setLoading(true);
        await api
          .delete('feedNoticias/deleteFeed', { data: { seq: id } })
          .then(() => {
            Swal.fire({
              icon: 'success',
              text: 'Feed deletado com sucesso!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            setEdit(true);
            setTitulo('');
            setIdFeed(0);
            setRefresh(!refresh);
            setLoading(false);
          })
          .catch(() => {
            Swal.fire({
              icon: 'error',
              text: 'Erro ao deletar feed!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
            setLoading(false);
          });
      } catch (err) {
        Swal.fire({
          icon: 'error',
          text: 'Erro ao deletar, tente novamente!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
        setLoading(false);
      }
    },
    [refresh],
  );

  const handleCertezaDelete = useCallback(
    (id: number) => {
      Swal.fire({
        text: 'Tem certeza que deseja apagar?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleDeletar(id);
        }
      });
    },
    [handleDeletar],
  );

  return (
    <Container>
      <ModalConexao />
      <ContainerHorizontal>
        {feeds.map((feed: FeedGet) => (
          <ContainerForm key={feed.ID}>
            <div>
              <h1>Feed {feed.ID}</h1>
              <img src={feed.IMAGEM ? feed.IMAGEM : upload} alt="Imagem Feed" />

              {accessUser.ESCRITA && (
                <div>
                  <input
                    type="text"
                    accept="image/*"
                    placeholder={feed.TITULO}
                    onChange={(e) => setTitulo(e.target.value)}
                  />
                </div>
              )}
            </div>
            {accessUser.ESCRITA && (
              <ContainerButton>
                {!edit && idFeed === feed.ID && (
                  <div>
                    <ButtonBorder>
                      <label htmlFor={feed.ID.toString()}>
                        <FiCamera />
                        <input
                          type="file"
                          id={feed.ID.toString()}
                          onChange={(e) => handleImagemFeed(e)}
                        />
                      </label>
                    </ButtonBorder>
                    <ButtonBorder
                      onClick={() => {
                        if (titulo === '') {
                          setTitulo(feed.TITULO);
                        }
                        setIdFeed(feed.ID);
                        handleSalvar();
                      }}
                    >
                      <FiSave />
                    </ButtonBorder>
                    <ButtonBorder
                      onClick={() => {
                        setEdit(true);
                        setIdFeed(feed.ID);
                        handleCancelEdit();
                      }}
                    >
                      <FiXCircle />
                    </ButtonBorder>
                  </div>
                )}
                {edit && (
                  <div>
                    <ButtonBorder
                      onClick={() => {
                        setEdit(false);
                        setIdFeed(feed.ID);
                      }}
                    >
                      <FiEdit2 />
                    </ButtonBorder>
                    <ButtonBorder
                      onClick={() => {
                        handleCertezaDelete(feed.ID);
                      }}
                    >
                      <FiTrash />
                    </ButtonBorder>
                  </div>
                )}
              </ContainerButton>
            )}
          </ContainerForm>
        ))}
      </ContainerHorizontal>
      {loading && <Loading />}
    </Container>
  );
};

export default UploadPost;
