import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
  useRef,
} from 'react';
import Swal from 'sweetalert2';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { ptBR } from 'date-fns/locale';
import DatePicker, { registerLocale } from 'react-datepicker';
import {
  FiEye,
  FiFileText,
  FiCheckSquare,
  FiSquare,
  FiEyeOff,
  FiSearch,
} from 'react-icons/fi';
import { AiFillCaretLeft, AiFillCaretDown } from 'react-icons/ai';
import { RiFileExcel2Line } from 'react-icons/ri';
import { BsArrowLeft, BsQuestionCircleFill } from 'react-icons/bs';
import { GoFilePdf } from 'react-icons/go';

import { useHistory } from 'react-router-dom';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import ModalConexao from '../../../components/ModalConexao';
import ModalAbono from './modalAbono/index';
import { Loading } from '../../../components/Loading';
import HeaderTable from '../../../components/HeaderTable';
import HeaderPonto from './header/index';
import ModalFindUser from './ModalFindUser/index';
import { maskCpf } from '../../../components/Input/mask';
import DropDown from '../../../components/DropDown';
import Pagination from '../../../components/Pagination';
import Checkbox from '../../../components/CheckBox';
import TabelaExibicao from './tabelaExibicao/index';
import TablePonto from './TablesExportExcel/pontoEspecifico';
import { exportPdfGeral } from './ExportPdf/tableGeralPdf';
import { BottomsExportAndAbonoIndex, Icon } from './ComponentsForIndex';
import {
  formatarDataAnoMesDia,
  formatarData,
  MostrarHoraMinSeg,
  formatarMaxData,
} from '../../../components/Formulas/formulas';
import { ResponseGet } from './Interface/index';
import {
  arrayUnique,
  arrayUniqueFalta,
  arrayUniquePontoDivergente,
  arrayUniqueAtraso,
  // arrayUniqueAfastamento,
  // arrayUniqueFerias,
  arrayUniqueHoraExtra,
  arrayUniqueHoraDivergente,
  arrayUniqueAbono,
} from './ArrayUnique/index';
import api from '../../../services/api';
import { useAuth } from '../../../hooks/auth';
import ModalData from './ModalData/indexModal';
import ModalInformacao from '../../../components/ModalInformacao/index';
import ModalAbonoBatida from './ModalAbonoBatida/index';
import ModalAbonoDia from './ModalAbonoDia';
import ModalAbonoPeriodo from './ModalAbonoPeriodo';
import 'react-datepicker/dist/react-datepicker.css';

import {
  Container,
  ContainerSearchTodos,
  ContainerPesquisaGeral,
  ContainerFilter,
  ContainerSupFilter,
  ContainerInfFilter,
  ContainerInfFilterGeral,
  ContainerFiltro,
  ContainerAbaDeButtons,
  ContainerFilterGeral,
  Aba,
  AbaDeButtons,
  DivAbaDeButtons,
  ContainerTable,
  ContainerPagination,
  ContainerTableEscala,
  ContainerFormulario,
  ContainerSpace,
  ButtonFilter,
  TdIcons,
  DivButtonExcel,
  Search,
} from './styles';

registerLocale('pt-BR', ptBR);

interface CheckBoxInterface {
  key: number;
  label: string;
}

interface AbonoInterface {
  ID: number;
  NOME: string;
}

interface DropdownInterface {
  id: number;
  valor: string;
}

interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

interface ResponseGetFilial {
  valor: string;
  id: number;
  codigo: string;
  // ATIVO: boolean;
  // BAIRRO: string;
  // CEP: string;
  // CIDADE: string;
  // CODIGO_FILIAL: string;
  // ENDERECO: string;
  // ESTADO: string;
  // ID: number;
  // NUMERO: string;
}

const pageAccess = {
  ID: 14,
  NOME: 'PONTO-ELETRONICO',
};

const checkboxes = [
  {
    key: 1,
    label: 'Ponto',
    textInfo: 'Todos que possuem ao menos 1 batida no período.',
    larguraInfo: 280,
  },
  {
    key: 13,
    label: 'Sem ponto',
    textInfo: 'Todos que não possuem nenhum batida no período.',
    larguraInfo: 280,
  },
  {
    key: 2,
    label: 'Hora extra',
    textInfo: 'Todos que possuem ao menos 1 hora extra no período.',
    larguraInfo: 280,
  },
  {
    key: 3,
    label: 'Falta',
    textInfo: 'Todos que possuem ao menos 1 falta no período.',
    larguraInfo: 280,
  },
  {
    key: 4,
    label: 'Atraso',
    textInfo: 'Todos que possuem ao menos 1 atraso no período.',
    larguraInfo: 280,
  },
  /* {
    key: 5,
    label: 'Suspensão',
  }, */
  // {
  //   key: 6,
  //   label: 'Férias',
  //   textInfo: 'Todos que estão de férias no período.',
  //   larguraInfo: 280,
  // },
  /* {
    key: 7,
    label: 'Licença',
  }, */
  // {
  //   key: 8,
  //   label: 'Afastamento',
  //   textInfo: 'Todos que possuem afastamento no período.',
  //   larguraInfo: 280,
  // },
  // {
  //   key: 9,
  //   label: 'Local divergente',
  //   textInfo: 'Todos que possuem ao menos 1 local divergente no período.',
  //   larguraInfo: 330,
  // },
  {
    key: 11,
    label: 'Hora divergente',
    textInfo: 'Todos que possuem ao menos 1 hora divergente no período.',
    larguraInfo: 330,
  },
  {
    key: 12,
    label: 'Abono',
    textInfo: 'Todos que possuem ao menos 1 abono no período.',
    larguraInfo: 280,
  },
  // {
  //   key: 10,
  //   label: 'Todos',
  //   textInfo: 'Busca por todos os filtros.',
  //   larguraInfo: 200,
  // },
];

const ViewPonto: React.FC = () => {
  const { user, modulos } = useAuth();
  const history = useHistory();
  const [dropDownFilial, setDropDownFilial] = useState<ResponseGetFilial[]>([]);
  // const [searchCliente, setSearchCliente] = useState('');
  // const [searchContrato, setSearchContrato] = useState('');
  // const [searchPosto, setSearchPosto] = useState('');
  const [searchNome, setSearchNome] = useState('');
  const [searchCPF, setSearchCPF] = useState('');
  // const [searchClienteSimple, setSearchClienteSimple] = useState('');
  // const [searchContratoSimple, setSearchContratoSimple] = useState('');
  // const [searchPostoSimple, setSearchPostoSimple] = useState('');
  const [searchNomeSimple, setSearchNomeSimple] = useState('');
  const [searchCPFSimple, setSearchCPFSimple] = useState('');
  const [loading, setLoading] = useState(false);
  const [filial, setFilial] = useState('');
  const [maxDate, setMaxDate] = useState('');
  const [dataInicial, setDataInicial] = useState();
  const [dataFinal, setDataFinal] = useState();
  const [dataInicialGet, setDataInicialGet] = useState('');
  const [dataFinalGet, setDataFinalGet] = useState('');
  const [responseAbono, setResponseAbono] = useState<AbonoInterface[]>([]);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [responseSimple, setResponseSimple] = useState<ResponseGet[]>([]);
  const [porcentagem, setPorcentagem] = useState(0);
  const [todosCheck, setTodosCheck] = useState(false);
  const [refreshTable, setRefreshTable] = useState(false);
  const [resultadoPesquisa, setResultadoPesquisa] = useState(false);
  const [verGeral, setVerGeral] = useState(false);
  const [verEscala, setVerEscala] = useState(false);
  const [verTodos, setVerTodos] = useState(false);
  const [checkBox, setCheckBox] = useState<number[]>([]);
  const [responseUser, setResponseUser] = useState<ResponseGet[]>([]);
  const [copiaResponseUser, setCopiaResponseUser] = useState<ResponseGet[]>([]);
  const [opcaoAba, setOpcaoAba] = useState<number>(0);
  const [checkBoxAba, setCheckBoxAba] = useState<number[]>([]);
  const [tabelaExibicao, setTabelaExibicao] = useState<number>(0);
  const [verTabelaExibicao, setVerTabelaExibicao] = useState(false);
  const [voltarTabelaExibicao, setVoltarTabelaExibicao] = useState(false);
  const [totalItemsGeral, setTotalItemsGeral] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalTabela, setTotalTabela] = useState(0);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState<number>(20);

  const [verModalInformacao, setVerModalInformacao] = useState(false);
  const [verModalAbono, setVerModalAbono] = useState(false);
  const [verModalAbonoBatida, setVerModalAbonoBatida] = useState(false);
  const [verModalAbonoDia, setVerModalAbonoDia] = useState(false);
  const [verModalAbonoPeriodo, setVerModalAbonoPeriodo] = useState(false);
  const [visualizarModalPdf, setVisualizarModalPdf] = useState(false);
  // const [acessoOak] = useState(false);

  const [refreshIconTable, setRefreshIconTable] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [consulta, setConsulta] = useState(false);

  const [abonoDropDown] = useState<DropdownInterface[]>([]);

  const [idTipoArray, setIdTipoArray] = useState(0);
  const [arrayIdPonto, setArrayIdPonto] = useState<number[]>([]);
  const [arrayVerAbono] = useState<string[]>([]);
  const [arrayApagarAbono] = useState<any[]>([]);
  const [arrayDataApagarAbono] = useState<string[]>([]);

  const [isModalFindUser, setIsModalFindUser] = useState(false);
  const [setaExportar, setSetaExportar] = useState('AiFillCaretLeft');
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });

  const refDivTable = useRef<HTMLDivElement>(null);

  const dropDown = [
    { valor: '20', id: 20 },
    { valor: '40', id: 40 },
    { valor: '80', id: 80 },
    { valor: '160', id: 160 },
    { valor: 'todos', id: 1 },
  ];

  const headers = [
    // { name: '', field: 'visualizar', id: 0 },
    // { name: 'Cliente', field: 'cliente', id: 0 },
    { name: 'Nome', field: 'nome', id: 0 },
    { name: 'CPF', field: 'cpf', id: 0 },
    // { name: 'Contrato', field: 'contrato', id: 0 },
    // { name: 'Posto', field: 'posto', id: 0 },
    { name: 'Ponto', field: 'ponto', id: 1 },
    { name: 'Hora Extra', field: 'he', id: 2 },
    { name: 'Faltas', field: 'falta', id: 3 },
    { name: 'Atraso', field: 'atraso', id: 4 },
    // { name: 'Suspensão', field: 'suspensao' },
    // { name: 'Férias', field: 'ferias', id: 6 },
    // { name: 'Licença', field: 'licenca' },
    // { name: 'Afastamento', field: 'afasta', id: 8 },
    // { name: 'Local Divergente', field: 'pontoDivergente', id: 9 },
    { name: 'Hora Divergente', field: 'horaDivergente', id: 11 },
    { name: 'Abono', field: 'abono', id: 12 },
  ];

  const headersEscala = [
    { name: 'Ver Abono', field: 'verAbono' },
    { name: 'Data Escala', field: 'dataEscala' },
    // { name: 'Latitude Ponto', field: 'latitudePonto' },
    // { name: 'Longitude Ponto', field: 'longitudePonto' },
    { name: 'Tipo Ponto', field: 'tipoPonto' },
    { name: 'Data', field: 'data' },
    { name: 'Hora', field: 'hora' },
    { name: 'Foto', field: 'foto' },
    // { name: 'Latitude', field: 'latitude' },
    // { name: 'Longitude', field: 'longitude' },
    // { name: 'Diferença', field: 'diferenca' },
    { name: 'Abonar', field: 'abonar' },
  ];

  const headersEscalaAbono = [
    { name: 'Ver Abono', field: 'verAbono' },
    { name: 'Data Escala', field: 'dataEscala' },
    // { name: 'Latitude Ponto', field: 'latitudePonto' },
    // { name: 'Longitude Ponto', field: 'longitudePonto' },
    { name: 'Tipo Ponto', field: 'tipoPonto' },
    { name: 'Data', field: 'data' },
    { name: 'Hora', field: 'hora' },
    { name: 'Foto', field: 'foto' },
    // :{ name: 'Latitude', field: 'latitude' },
    // { name: 'Longitude', field: 'longitude' },
    { name: 'Diferença', field: 'diferenca' },
    { name: 'Motivo', field: 'motivo' },
    { name: 'Abonar', field: 'abonar' },
  ];

  const headersHoraExtra = [
    { name: 'Data da Falta', field: 'data' },
    { name: 'Horas à Trabalhar', field: 'horasATrabalhar' },
    { name: 'Horas Trabalhadas', field: 'horaTrabalhada' },
    { name: 'Hora Entrada', field: 'horaEntrada' },
    { name: 'Hora Pausa', field: 'horaPausa' },
    { name: 'Hora Volta', field: 'horaVolta' },
    { name: 'Hora Saída', field: 'horaSaida' },
  ];

  const headersFalta = [
    { name: 'Ver Abono', field: 'verAbono' },
    { name: 'Data da Falta', field: 'data' },
    { name: 'Abonar', field: 'abonar' },
  ];

  const headersFaltaAbono = [
    { name: 'Ver Abono', field: 'verAbono' },
    { name: 'Data da Falta', field: 'data' },
    { name: 'Motivo', field: 'motivo' },
    { name: 'Abonar', field: 'abonar' },
  ];

  const headersAtraso = [
    { name: 'Ver Abono', field: 'verAbono' },
    { name: 'Data', field: 'data' },
    { name: 'Horas à Trabalhar', field: 'horasATrabalhar' },
    { name: 'Horas Trabalhadas', field: 'horaTrabalhada' },
    { name: 'Hora Entrada', field: 'horaEntrada' },
    { name: 'Hora Pausa', field: 'horaPausa' },
    { name: 'Hora Volta', field: 'horaVolta' },
    { name: 'Hora Saída', field: 'horaSaida' },
    { name: 'Abonar', field: 'abonar' },
  ];

  const headersAtrasoAbono = [
    { name: 'Ver Abono', field: 'verAbono' },
    { name: 'Data', field: 'data' },
    { name: 'Horas à Trabalhar', field: 'horasATrabalhar' },
    { name: 'Horas Trabalhadas', field: 'horaTrabalhada' },
    { name: 'Hora Entrada', field: 'horaEntrada' },
    { name: 'Hora Pausa', field: 'horaPausa' },
    { name: 'Hora Volta', field: 'horaVolta' },
    { name: 'Hora Saída', field: 'horaSaida' },
    { name: 'Motivo', field: 'motivo' },
    { name: 'Abonar', field: 'abonar' },
  ];

  // const headersFerias = [
  //   { name: 'Cliente', field: 'cliente' },
  //   { name: 'Nome', field: 'nome' },
  //   { name: 'CPF', field: 'cpf' },
  //   { name: 'Contrato', field: 'contrato' },
  //   { name: 'Posto', field: 'posto' },
  //   { name: 'Função', field: 'funcao' },
  //   { name: 'Data Inicial', field: 'dataInicial' },
  //   { name: 'Data Final', field: 'dataFinal' },
  //   { name: 'Data Volta', field: 'dataVolta' },
  // ];

  // const headersAfastamento = [
  //   { name: 'Cliente', field: 'cliente' },
  //   { name: 'Nome', field: 'nome' },
  //   { name: 'CPF', field: 'cpf' },
  //   { name: 'Contrato', field: 'contrato' },
  //   { name: 'Posto', field: 'posto' },
  //   { name: 'Função', field: 'funcao' },
  //   { name: 'Data Inicial', field: 'dataInicial' },
  //   { name: 'Data Final', field: 'dataFinal' },
  //   { name: 'Data Volta', field: 'dataVolta' },
  //   { name: 'Motivo Afastamento', field: 'afastamento' },
  // ];

  const headersPontoDivergente = [
    { name: 'Ver Abono', field: 'verAbono' },
    { name: 'Data', field: 'data' },
    // { name: 'Latitude Posto', field: 'latitudePosto' },
    // { name: 'Longitude Posto', field: 'longitudePosto' },
    // { name: 'Latitude Real', field: 'latitudeReal' },
    // { name: 'Longitude Real', field: 'longitudeReal' },
    // { name: 'Distância', field: 'distancia' },
    { name: 'Abonar', field: 'abonar' },
  ];

  const headersPontoDivergenteAbono = [
    { name: 'Ver Abono', field: 'verAbono' },
    { name: 'Data', field: 'data' },
    // { name: 'Latitude Posto', field: 'latitudePosto' },
    // { name: 'Longitude Posto', field: 'longitudePosto' },
    // { name: 'Latitude Real', field: 'latitudeReal' },
    // { name: 'Longitude Real', field: 'longitudeReal' },
    // { name: 'Distância', field: 'distancia' },
    { name: 'Motivo', field: 'motivo' },
    { name: 'Abonar', field: 'abonar' },
  ];

  const headersHoraDivergente = [
    { name: 'Ver Abono', field: 'verAbono' },
    { name: 'Data', field: 'data' },
    { name: 'Hora Inicial', field: 'horaInicial' },
    { name: 'Hora Final', field: 'horaFinal' },
    { name: 'Hora Ponto', field: 'horaPonto' },
    { name: 'Abonar', field: 'abonar' },
  ];

  const headersHoraDivergenteAbono = [
    { name: 'Ver Abono', field: 'verAbono' },
    { name: 'Data', field: 'data' },
    { name: 'Hora Inicial', field: 'horaInicial' },
    { name: 'Hora Final', field: 'horaFinal' },
    { name: 'Hora Ponto', field: 'horaPonto' },
    { name: 'Motivo', field: 'motivo' },
    { name: 'Abonar', field: 'abonar' },
  ];

  const headersAbono = [
    { name: 'Data', field: 'data' },
    { name: 'Motivo', field: 'motivo' },
    { name: 'Data Aceite Abono', field: 'dataAceiteAbono' },
    { name: 'Responsável Abono', field: 'responsavelAbono' },
  ];

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);

    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;
      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      history.push('/dashboard');
      Swal.fire({
        icon: 'info',
        text: 'Você não tem permissão para acessar essa página!',
      });
    }
  }, [history, modulos]);

  useEffect(() => {
    setLoading(true);

    api
      .get('filiais/showFiliais')
      .then((res) => {
        const dropDownFilialAux: ResponseGetFilial[] = [];

        dropDownFilialAux.push({
          valor: 'Nenhum',
          id: 0,
          codigo: '000000',
        });

        res.data.forEach((filialTemp: any) => {
          dropDownFilialAux.push({
            valor: `${filialTemp.CODIGO_FILIAL}`,
            id: filialTemp.ID,
            codigo: `${filialTemp.CODIGO_FILIAL}`,
          });
        });

        setDropDownFilial(dropDownFilialAux);

        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  }, []);

  const transfomaNomeToCodigo = useCallback(
    (value: string): string => {
      const data = dropDownFilial.filter((r) => r.valor === value);
      if (data.length > 0) {
        return data[0].codigo;
      }
      return '';
    },
    [dropDownFilial],
  );

  useEffect(() => {
    // na primeira vez que entrar na pagina nao entra aqui.
    if (consulta) {
      const codigo: string = transfomaNomeToCodigo(filial);
      window.scrollTo(0, 0);
      setPorcentagem(0);
      setLoading(true);
      try {
        api
          .get(
            `/pontoEletronico/getEscalaWeb/${codigo}/${dataInicialGet}/${dataFinalGet}`,
            {
              onDownloadProgress(progress) {
                setPorcentagem((progress.loaded * 100) / progress.total);
              },
            },
          )
          .then(async (data) => {
            // console.log('getEscalaWeb: ', data.data);

            setResponse(data.data);
            await api
              .get(
                `/pontoEletronico/getEscalaWebPorFuncionario/${codigo}/${dataInicialGet}/${dataFinalGet}`,
              )
              .then((dados) => {
                // console.log('getEscalaWebPorFuncionario: ', dados.data);

                setResponseSimple(dados.data);
                setRefreshIconTable(!refreshIconTable);
                setRefreshTable(!refreshTable);
                setConsulta(false);
                setTodosCheck(!todosCheck);
                setLoading(false);
              })
              .catch(() => {
                setLoading(false);
                setConsulta(false);
                Swal.fire({
                  icon: 'info',
                  text: 'Erro ao atualizar dados, por favor atualize a página!',
                  showClass: {
                    popup: 'animate__animated animate__fadeInDown',
                  },
                  hideClass: {
                    popup: 'animate__animated animate__fadeOutUp',
                  },
                });
              });
          })
          .catch(() => {
            setLoading(false);
            setConsulta(false);
            Swal.fire({
              icon: 'info',
              text: 'Erro ao atualizar dados, por favor atualize a página!',
              showClass: {
                popup: 'animate__animated animate__fadeInDown',
              },
              hideClass: {
                popup: 'animate__animated animate__fadeOutUp',
              },
            });
          });
      } catch (error) {
        setLoading(false);
        setConsulta(false);
        Swal.fire({
          icon: 'info',
          text: 'Erro ao atualizar dados, por favor atualize a página!',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh, consulta]);

  // funcao para transfomar o array do get abono para um array no formato aceito pelo dropDown
  // essa funcao tambem coloca o item 'nenhum' no array
  // por ultimo essa funcao pode restringir o dropdown para aparecer apenas o itens escolhidos.
  const preencherDropDown = useCallback(
    (drop: AbonoInterface[], idDrop: string) => {
      const array = JSON.parse(JSON.stringify(abonoDropDown));
      array.forEach((a: DropdownInterface) => {
        abonoDropDown.splice(abonoDropDown.indexOf(a), 1);
      });
      abonoDropDown.push({
        id: -1,
        valor: 'Nenhum',
      });
      drop.forEach((a) => {
        if (a.NOME === idDrop || idDrop === 'Todos') {
          abonoDropDown.push({
            id: a.ID,
            valor: a.NOME,
          });
        }
      });
    },
    [abonoDropDown],
  );

  const consultaAbono = useCallback(
    (
      tipoButtonNumero: number,
      warningAccessLevel: boolean | string | undefined,
    ) => {
      if (
        // setorAcesso.NivelACesso < nivelDeAcessoDaTela.nivel &&
        // setorAcesso?.ESCRITA === false &&
        !accessUser.ESCRITA &&
        warningAccessLevel === true
      ) {
        Swal.fire({
          icon: 'info',
          text:
            'Seu nível de acesso é de somente LEITURA, porém essa tela requer o nível de ESCRITA.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
        return;
      }

      api
        .get(`/pontoEletronico/getMotivoAbonoWeb/1`)
        .then((data) => {
          setResponseAbono(data.data);
          switch (tipoButtonNumero) {
            case 1: {
              setVerModalAbonoBatida(true);
              break;
            }
            case 2: {
              setVerModalAbonoDia(true);
              break;
            }
            case 3: {
              setVerModalAbonoPeriodo(true);
              break;
            }
            default:
              break;
          }
        })
        .catch(() => {
          if (navigator.onLine) {
            if (!(tipoButtonNumero === 0)) {
              Swal.fire({
                icon: 'info',
                text: 'Erro ao carregar dados, por favor atualize a página!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            }
          }
        });
    },
    [accessUser.ESCRITA],
  );

  const consultaAbonoTrue = useCallback(
    (tipoButtonNumero: number, warningAccessLevel: boolean) => {
      if (
        // setorAcesso.NivelACesso < nivelDeAcessoDaTela.nivel &&
        // setorAcesso?.ESCRITA === false &&
        !accessUser.ESCRITA &&
        warningAccessLevel === true
      ) {
        Swal.fire({
          icon: 'info',
          text:
            'Seu nível de acesso é de somente LEITURA, porém essa tela requer o nível de ESCRITA.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
        return;
      }

      switch (tipoButtonNumero) {
        case 1: {
          setVerModalAbonoBatida(true);
          break;
        }
        case 2: {
          setVerModalAbonoDia(true);
          break;
        }
        case 3: {
          setVerModalAbonoPeriodo(true);
          break;
        }
        default:
          break;
      }
    },
    [accessUser.ESCRITA],
  );

  const preencherArraysDeleteAbono = useCallback(() => {
    arrayIdPonto.forEach((num) => {
      if (num > 0) {
        arrayApagarAbono.push(num);
      } else {
        arrayApagarAbono.push(null);
      }
    });
    copiaResponseUser.forEach((dado) => {
      if (arrayIdPonto.includes(dado.Id_PontoEletronico)) {
        arrayDataApagarAbono.push(dado.DATA_INICIAL_DIA_AGENDA);
      }
    });
  }, [arrayApagarAbono, arrayDataApagarAbono, arrayIdPonto, copiaResponseUser]);

  const handleApagarAbono = useCallback(async () => {
    preencherArraysDeleteAbono();
    try {
      setLoading(true);
      await api
        .delete('/pontoEletronico/deleteAbono/', {
          data: {
            idPontoEletronico: arrayApagarAbono,
            dataAbono: arrayDataApagarAbono,
            cpf: copiaResponseUser[0].CPFSEMFORMATO,
          },
        })
        .then(() => {
          setLoading(false);
          Swal.fire({
            icon: 'success',
            text: 'Abono apagado com sucesso!',
            showConfirmButton: false,
            timer: 1500,
          });
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'error',
            text: 'Erro, ao apagar o abono.',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    } catch (err) {
      setLoading(false);
      Swal.fire({
        icon: 'info',
        text: 'Erro ao apagar dados, tente novamente!',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    }
  }, [
    arrayApagarAbono,
    arrayDataApagarAbono,
    copiaResponseUser,
    preencherArraysDeleteAbono,
  ]);

  const verificaSeTemAbono = useCallback(
    (dados: ResponseGet[]) => {
      let resultado = false;
      dados.forEach((dado) => {
        if (arrayIdPonto.includes(dado.Id_PontoEletronico)) {
          if (
            !(
              dado.PERIODO_ABONADO ||
              dado.DIA_ABONADO ||
              dado.FALTA_ABONADA ||
              dado.ENTRADA_ABONADA ||
              dado.PAUSA_ABONADA ||
              dado.VOLTA_ABONADA ||
              dado.SAIDA_ABONADA
            )
          ) {
            resultado = true;
          }
        }
      });
      if (!(arrayIdPonto.length > 0)) {
        Swal.fire({
          icon: 'info',
          text: 'Favor marcar pelo menos um item para realizar o abono.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      } else if (resultado) {
        Swal.fire({
          icon: 'info',
          text:
            'Existem um ou mais itens selecionador que não possuem abono. Favor desmarcar.',
          showClass: {
            popup: 'animate__animated animate__fadeInDown',
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp',
          },
        });
      } else {
        Swal.fire({
          text: 'Tem certeza que deseja apagar o abono?',
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: 'Não',
          cancelButtonColor: '#d33',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Sim',
        }).then((result) => {
          if (result.isConfirmed) {
            handleApagarAbono();
          }
        });
      }
    },
    [arrayIdPonto, handleApagarAbono],
  );

  const handleWhichBottomAbonoWasClicked = useCallback(
    (isDeleteAbono: boolean, whichFilter: string, whichAbono: number) => {
      if (isDeleteAbono === true) {
        verificaSeTemAbono(copiaResponseUser);
      } else {
        preencherDropDown(responseAbono, whichFilter);
        if (!(responseAbono.length > 0)) {
          consultaAbono(whichAbono, true);
        } else {
          consultaAbonoTrue(whichAbono, true);
        }
      }
    },
    [
      responseAbono,
      copiaResponseUser,
      verificaSeTemAbono,
      preencherDropDown,
      consultaAbono,
      consultaAbonoTrue,
    ],
  );

  const verificaCheck = useCallback(
    (dado: CheckBoxInterface): boolean => {
      const data = checkBox.filter((r) => r === dado.key);
      if (data.length > 0) {
        return true;
      }
      return false;
    },
    [checkBox],
  );

  const handleChangeCheck = useCallback(
    (dados: CheckBoxInterface, value: boolean) => {
      // if (dados.key === 10) {
      //   if (checkBox.includes(10)) {
      //     checkboxes.forEach(() => {
      //       checkBox.splice(checkBox.indexOf(dados.key), 1);
      //     });
      //   } else {
      //     checkboxes.forEach((check) => {
      //       if (!verificaCheck(check)) {
      //         checkBox.push(check.key);
      //       }
      //     });
      //   }
      // } else

      checkboxes.forEach((box) => {
        if (box.key === dados.key) {
          if (value) {
            checkBox.push(box.key);
          } else {
            checkBox.splice(checkBox.indexOf(dados.key), 1);
          }
        }
      });
      setTodosCheck(!todosCheck);
    },
    [checkBox, todosCheck],
  );

  // codigo para ver qual aba está sendo clicada.
  const handleChangeCheckAba = useCallback(
    (num: number) => {
      if (!checkBoxAba.includes(num)) {
        checkBoxAba.push(num);
        checkBoxAba.splice(checkBoxAba.indexOf(opcaoAba), 1);
        setOpcaoAba(num);
      }
    },
    [checkBoxAba, opcaoAba],
  );

  const handlePesquisar = useCallback(async () => {
    if (
      filial === '' ||
      filial === 'Nenhum' ||
      !(
        dataInicial !== (undefined || null) && dataFinal !== (undefined || null)
      )
    ) {
      Swal.fire({
        icon: 'info',
        text: 'Preencha os dados para pesquisar',
        showClass: {
          popup: 'animate__animated animate__fadeInDown',
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp',
        },
      });
    } else {
      const codigo: string = transfomaNomeToCodigo(filial);
      window.scrollTo(0, 0);
      setPorcentagem(0);
      setLoading(true);
      await api
        .get(
          `/pontoEletronico/getEscalaWeb/${codigo}/${dataInicialGet}/${dataFinalGet}`,
          {
            onDownloadProgress(progress) {
              setPorcentagem((progress.loaded * 100) / progress.total);
            },
          },
        )
        .then(async (data) => {
          // console.log('getEscalaWeb: ', data.data);
          setResponse(data.data);

          await api
            .get(
              `/pontoEletronico/getEscalaWebPorFuncionario/${codigo}/${dataInicialGet}/${dataFinalGet}`,
            )
            .then((dados) => {
              // console.log('getEscalaWebPorFuncionario: ', dados.data);
              setResponseSimple(dados.data);
              setVerEscala(false);
              setVerTodos(false);
              setVerGeral(true);
              setResultadoPesquisa(true);
              setTabelaExibicao(0);
              setVerTabelaExibicao(false);
              setVoltarTabelaExibicao(false);
              setCheckBox([]);
              setResponseUser([]);
              setOpcaoAba(0);
              setCheckBoxAba([]);
              setSearchCPF('');
              // setSearchCliente('');
              setSearchNome('');
              // //setSearchPosto('');
              // setSearchContrato('');
              setTodosCheck(!todosCheck);
              setLoading(false);
              consultaAbono(0, false);
            })
            .catch(() => {
              setLoading(false);
              Swal.fire({
                icon: 'error',
                text: 'Erro ao carregar dados, por favor atualize a página!',
                showClass: {
                  popup: 'animate__animated animate__fadeInDown',
                },
                hideClass: {
                  popup: 'animate__animated animate__fadeOutUp',
                },
              });
            });
        })
        .catch(() => {
          setLoading(false);
          Swal.fire({
            icon: 'info',
            text: 'Erro ao carregar dados, por favor atualize a página!',
            showClass: {
              popup: 'animate__animated animate__fadeInDown',
            },
            hideClass: {
              popup: 'animate__animated animate__fadeOutUp',
            },
          });
        });
    }
  }, [
    consultaAbono,
    dataFinal,
    dataFinalGet,
    dataInicial,
    dataInicialGet,
    filial,
    todosCheck,
    transfomaNomeToCodigo,
  ]);

  const filterUserResponse = useCallback(
    (data: ResponseGet): ResponseGet[] => {
      let computedFilter: ResponseGet[] = response;
      computedFilter = computedFilter.filter(
        (res) => res.CPFSEMFORMATO === data.CPFSEMFORMATO,
      );
      setCopiaResponseUser(JSON.parse(JSON.stringify(computedFilter)));
      return computedFilter;
    },
    [response],
  );

  const responseDataSimple = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = responseSimple;
    if (checkBox.length > 0 && !verTabelaExibicao) {
      checkBox.forEach((num) => {
        switch (num) {
          case 1: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.COM_PONTO,
            );
            break;
          }
          case 2: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.EXTRA,
            );
            break;
          }
          case 3: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.SEM_PONTO,
            );
            break;
          }
          case 4: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.ATRASO,
            );
            break;
          }
          // case 6: {
          //   computedResponses = computedResponses.filter(
          //     (res: ResponseGet) => res.FERIAS,
          //   );
          //   break;
          // }
          // case 8: {
          //   computedResponses = computedResponses.filter(
          //     (res: ResponseGet) => res.AFASTAMENTO,
          //   );
          //   break;
          // }
          // case 9: {
          //   computedResponses = computedResponses.filter(
          //     (res: ResponseGet) => res.PONTO_DIVERGENTE,
          //   );
          //   break;
          // }
          case 11: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.HORA_DIVERGENTE,
            );
            break;
          }
          case 12: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.ABONO || res.FALTA_ABONADA,
            );
            break;
          }
          case 13: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => !res.COM_PONTO,
            );
            break;
          }
          default: {
            break;
          }
        }
      });
    }
    // if (searchClienteSimple) {
    //   computedResponses = computedResponses.filter((res: ResponseGet) =>
    //     res.NOME_CLIENTE.toLowerCase().includes(
    //       searchClienteSimple.toLowerCase(),
    //     ),
    //   );
    // }
    // if (searchContratoSimple) {
    //   computedResponses = computedResponses.filter((res: ResponseGet) =>
    //     res.CONTRATO.toLowerCase().includes(searchContratoSimple.toLowerCase()),
    //   );
    // }
    if (searchNomeSimple) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME.toLowerCase().includes(searchNomeSimple.toLowerCase()),
      );
    }
    if (searchCPFSimple) {
      let CPF: string = searchCPFSimple.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CPFSEMFORMATO.toLowerCase().includes(CPF.toLowerCase()),
      );
    }
    // if (searchPostoSimple) {
    //   computedResponses = computedResponses.filter((res: ResponseGet) =>
    //     res.POSTO.toLowerCase().includes(searchPostoSimple.toLowerCase()),
    //   );
    // }
    setTotalTabela(computedResponses.length);
    setTotalItemsGeral(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ITEMS_PER_PAGE,
    checkBox,
    currentPage,
    responseSimple,
    searchCPFSimple,
    // searchClienteSimple,
    // searchContratoSimple,
    searchNomeSimple,
    // searchPostoSimple,
    verTabelaExibicao,
    todosCheck,
  ]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response;
    if (checkBox.length > 0) {
      checkBox.forEach((num) => {
        switch (num) {
          case 1: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => !res.SEM_PONTO,
            );
            break;
          }
          case 2: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.EXTRA,
            );
            break;
          }
          case 3: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.SEM_PONTO,
            );
            break;
          }
          case 4: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.ATRASO,
            );
            break;
          }
          // case 6: {
          //   computedResponses = computedResponses.filter(
          //     (res: ResponseGet) => res.FERIAS,
          //   );
          //   break;
          // }
          // case 8: {
          //   computedResponses = computedResponses.filter(
          //     (res: ResponseGet) => res.AFASTAMENTO,
          //   );
          //   break;
          // }
          // case 9: {
          //   computedResponses = computedResponses.filter(
          //     (res: ResponseGet) => res.PONTO_DIVERGENTE,
          //   );
          //   break;
          // }
          case 11: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.HORA_DIVERGENTE,
            );
            break;
          }
          case 12: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.ABONO || res.FALTA_ABONADA,
            );
            break;
          }
          case 13: {
            computedResponses = computedResponses.filter(
              (res: ResponseGet) => res.SEM_PONTO,
            );
            break;
          }
          default: {
            break;
          }
        }
      });
    }
    if (verTabelaExibicao) {
      switch (tabelaExibicao) {
        case 1: {
          computedResponses = arrayUnique(computedResponses);
          computedResponses = computedResponses.filter(
            (res: ResponseGet) => !res.SEM_PONTO,
          );
          return computedResponses;
        }
        case 2: {
          computedResponses = arrayUniqueHoraExtra(computedResponses);
          computedResponses = computedResponses.filter(
            (res: ResponseGet) => res.EXTRA,
          );
          return computedResponses;
        }
        case 3: {
          computedResponses = arrayUniqueFalta(computedResponses);
          computedResponses = computedResponses.filter(
            (res: ResponseGet) => res.SEM_PONTO,
          );
          return computedResponses;
        }
        case 4: {
          computedResponses = arrayUniqueAtraso(computedResponses);
          computedResponses = computedResponses.filter(
            (res: ResponseGet) => res.ATRASO,
          );
          return computedResponses;
        }
        // case 6: {
        //   computedResponses = arrayUniqueFerias(computedResponses);
        //   computedResponses = computedResponses.filter(
        //     (res: ResponseGet) => res.FERIAS,
        //   );
        //   return computedResponses;
        // }
        // case 8: {
        //   computedResponses = arrayUniqueAfastamento(computedResponses);
        //   computedResponses = computedResponses.filter(
        //     (res: ResponseGet) => res.COD_SITUACAO === 3,
        //   );
        //   return computedResponses;
        // }
        // case 9: {
        //   computedResponses = arrayUniquePontoDivergente(computedResponses);
        //   computedResponses = computedResponses.filter(
        //     (res: ResponseGet) => res.PONTO_DIVERGENTE,
        //   );
        //   return computedResponses;
        // }
        case 11: {
          computedResponses = arrayUniqueHoraDivergente(computedResponses);
          computedResponses = computedResponses.filter(
            (res: ResponseGet) => res.HORA_DIVERGENTE,
          );
          return computedResponses;
        }
        case 12: {
          computedResponses = arrayUniqueAbono(computedResponses);
          computedResponses = computedResponses.filter(
            (res: ResponseGet) => res.ABONO || res.FALTA_ABONADA,
          );

          return computedResponses;
        }
        case 13: {
          computedResponses = arrayUniqueFalta(computedResponses);
          computedResponses = computedResponses.filter(
            (res: ResponseGet) => res.SEM_PONTO,
          );
          return computedResponses;
        }
        default: {
          return computedResponses;
        }
      }
    }

    if (verTodos) {
      computedResponses = response;
    }
    // if (searchCliente) {
    //   computedResponses = computedResponses.filter((res: ResponseGet) =>
    //     res.NOME_CLIENTE.toLowerCase().includes(searchCliente.toLowerCase()),
    //   );
    // }
    // if (searchContrato) {
    //   computedResponses = computedResponses.filter((res: ResponseGet) =>
    //     res.CONTRATO.toLowerCase().includes(searchContrato.toLowerCase()),
    //   );
    // }
    if (searchNome) {
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.NOME.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }
    if (searchCPF) {
      let CPF: string = searchCPF.replace('.', '');
      CPF = CPF.replace('.', '');
      CPF = CPF.replace('-', '');
      computedResponses = computedResponses.filter((res: ResponseGet) =>
        res.CPFSEMFORMATO.toLowerCase().includes(CPF.toLowerCase()),
      );
    }
    // if (searchPosto) {
    //   computedResponses = computedResponses.filter((res: ResponseGet) =>
    //     res.POSTO.toLowerCase().includes(searchPosto.toLowerCase()),
    //   );
    // }

    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }

    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ITEMS_PER_PAGE,
    checkBox,
    currentPage,
    response,
    searchCPF,
    // searchCliente,
    // searchContrato,
    searchNome,
    // searchPosto,
    tabelaExibicao,
    verTabelaExibicao,
    verTodos,
    todosCheck,
  ]);

  const verificaCheckAbono = useCallback(
    (dado: string): boolean => {
      const data = arrayVerAbono.filter((r) => r.includes(dado));
      if (data.length > 0) {
        return true;
      }
      return false;
    },
    [arrayVerAbono],
  );

  const AdicionarCheckAbono = useCallback(
    (data: string) => {
      let result = false;
      if (arrayVerAbono.length > 0) {
        arrayVerAbono.forEach((box) => {
          if (box.includes(data)) {
            result = true;
          }
        });
      }
      if (result) {
        arrayVerAbono.splice(arrayVerAbono.indexOf(data), 1);
        setRefreshTable(!refreshTable);
        result = false;
      } else {
        arrayVerAbono.push(data);
        setRefreshTable(!refreshTable);
      }
    },
    [arrayVerAbono, refreshTable],
  );

  const addIdPontoNoArray = useCallback(
    (dados: ResponseGet, opcao: number) => {
      const array = JSON.parse(JSON.stringify(arrayIdPonto));
      if (idTipoArray !== opcao) {
        array.forEach((a: number) => {
          arrayIdPonto.splice(arrayIdPonto.indexOf(a), 1);
        });
      }
      if (arrayIdPonto.includes(dados.Id_PontoEletronico)) {
        arrayIdPonto.splice(arrayIdPonto.indexOf(dados.Id_PontoEletronico), 1);
        setRefreshIconTable(!refreshIconTable);
      } else {
        arrayIdPonto.push(dados.Id_PontoEletronico);
        setRefreshIconTable(!refreshIconTable);
      }
      setIdTipoArray(opcao);
    },
    [arrayIdPonto, idTipoArray, refreshIconTable],
  );

  const verificaIdArray = useCallback(
    (id: number, opcao: number): boolean => {
      if (arrayIdPonto.includes(id) && opcao === idTipoArray) {
        return true;
      }
      return false;
    },
    [arrayIdPonto, idTipoArray],
  );

  const preencherObjeto = useCallback(() => {
    let numeroId = -1;
    copiaResponseUser.forEach((dado) => {
      if (dado.Id_PontoEletronico === null) {
        dado.Id_PontoEletronico = numeroId;
        numeroId += -1;
      }
    });
  }, [copiaResponseUser]);

  const verificaAbono = useCallback(
    (dado: ResponseGet, tipo: number): boolean => {
      let result = false;
      switch (tipo) {
        case 1: {
          result =
            (dado.ENTRADA_ABONADA ||
              dado.DIA_ABONADO ||
              dado.PERIODO_ABONADO ||
              dado.FALTA_ABONADA) &&
            verificaCheckAbono(dado.DATA_INICIAL_DIA_AGENDA);
          break;
        }
        case 2: {
          result =
            (dado.PAUSA_ABONADA ||
              dado.DIA_ABONADO ||
              dado.PERIODO_ABONADO ||
              dado.FALTA_ABONADA) &&
            verificaCheckAbono(dado.DATA_INICIAL_DIA_AGENDA);
          break;
        }
        case 3: {
          result =
            (dado.VOLTA_ABONADA ||
              dado.DIA_ABONADO ||
              dado.PERIODO_ABONADO ||
              dado.FALTA_ABONADA) &&
            verificaCheckAbono(dado.DATA_INICIAL_DIA_AGENDA);
          break;
        }
        case 4: {
          result =
            (dado.SAIDA_ABONADA ||
              dado.DIA_ABONADO ||
              dado.PERIODO_ABONADO ||
              dado.FALTA_ABONADA) &&
            verificaCheckAbono(dado.DATA_INICIAL_DIA_AGENDA);
          break;
        }
        default:
          break;
      }
      return result;
    },
    [verificaCheckAbono],
  );

  const horaEscalaPonto = useCallback(
    (dado: ResponseGet, tipo: number): string => {
      let result = dado.ESCALA.split('-');
      let resposta = result[1];
      switch (tipo) {
        case 1: {
          result = resposta.split(' ');
          resposta = result[tipo];
          break;
        }
        case 2: {
          result = resposta.split(' ');
          resposta = result[tipo];
          break;
        }
        case 3: {
          result = resposta.split(' ');
          resposta = result[tipo];
          break;
        }
        case 4: {
          result = resposta.split(' ');
          resposta = result[tipo];
          break;
        }
        default:
          break;
      }
      return resposta;
    },
    [],
  );

  !!refDivTable.current &&
    refDivTable.current.addEventListener('wheel', (e) => {
      if (refDivTable.current) {
        if (e.deltaY > 0) refDivTable.current.scrollLeft += 1;
        else refDivTable.current.scrollLeft -= 1;
      }
    });

  return (
    <Container>
      <ModalConexao />
      <ContainerPesquisaGeral>
        <ContainerSupFilter>
          <Tooltip
            title="Clique aqui para pesquisar informações específicas de um usuário"
            arrow
            TransitionComponent={Zoom}
          >
            <ButtonFilter>
              <button
                type="button"
                onClick={() => setIsModalFindUser(!isModalFindUser)}
              >
                <FiSearch />
              </button>
            </ButtonFilter>
          </Tooltip>

          <p>Filial: </p>
          {/* {acessoOak && (
            <DropDown
              onChangeItems={(value: string) => {
                setFilial(value);
              }}
              objetoEnum={dropDownFilialOAK}
            />
          )} */}
          <DropDown
            onChangeItems={(value: string) => {
              setFilial(value);
            }}
            objetoEnum={dropDownFilial}
          />
          <p>Período: </p>
          <DatePicker
            locale="pt-BR"
            selected={dataInicial}
            onChange={(date) => {
              setDataInicialGet(formatarData(date?.toLocaleString()));
              setDataInicial(date);
              setMaxDate(formatarMaxData(date?.toLocaleString()));
            }}
            selectsStart
            startDate={dataInicial}
            endDate={dataFinal}
            dateFormat="P"
            placeholderText="dd/mm/aaaa"
          />
          <DatePicker
            locale="pt-BR"
            selected={dataFinal}
            onChange={(date) => {
              setDataFinalGet(formatarData(date?.toLocaleString()));
              setDataFinal(date);
            }}
            selectsEnd
            startDate={dataInicial}
            endDate={dataFinal}
            minDate={dataInicial}
            maxDate={new Date(maxDate)}
            dateFormat="P"
            placeholderText="dd/mm/aaaa"
          />
          <ContainerInfFilter>
            <button type="button" onClick={() => handlePesquisar()}>
              Pesquisar
            </button>
          </ContainerInfFilter>
        </ContainerSupFilter>
      </ContainerPesquisaGeral>

      {resultadoPesquisa && (
        <>
          <ContainerFilterGeral>
            <ContainerInfFilterGeral>
              {/* <p>Filtros:</p> */}
              {checkboxes.map((item) => (
                <Tooltip title={item.textInfo} arrow TransitionComponent={Zoom}>
                  <section key={item.key}>
                    {/* {todosCheck && ( */}
                    <Checkbox
                      onChangeCheck={(value: boolean) => {
                        handleChangeCheck(item, value);
                      }}
                      valorCheck={verificaCheck(item)}
                    />
                    {/* )} */}
                    {/* {!todosCheck && (
                      <Checkbox
                        onChangeCheck={(value: boolean) => {
                          handleChangeCheck(item, value);
                        }}
                        valorCheck={verificaCheck(item)}
                      />
                    )} */}

                    <BsQuestionCircleFill className={`icon${item.label}`} />

                    <div>
                      <p>{item.label}</p>
                    </div>
                  </section>
                </Tooltip>
              ))}
            </ContainerInfFilterGeral>
          </ContainerFilterGeral>
        </>
      )}

      {verGeral && (
        <ContainerFiltro>
          <ContainerSearchTodos>
            {/* <Search
              onSearch={(value: string) => {
                setSearchClienteSimple(value);
              }}
              nomePlaceHolder="Buscar cliente"
            /> */}
            <Search
              onSearch={(value: string) => {
                setSearchNomeSimple(value);
              }}
              nomePlaceHolder="Buscar nome"
            />
            <Search
              onSearch={(value: string) => {
                setSearchCPFSimple(value);
              }}
              nomePlaceHolder="Buscar CPF"
              typeProps="cpf"
            />
            {/* <Search
              onSearch={(value: string) => {
                setSearchContratoSimple(value);
              }}
              nomePlaceHolder="Buscar contrato"
            /> */}
            {/* <Search
              onSearch={(value: string) => {
                setSearchPostoSimple(value);
              }}
              nomePlaceHolder="Buscar posto"
            /> */}
          </ContainerSearchTodos>
        </ContainerFiltro>
      )}
      {verGeral && responseDataSimple.length > 0 && (
        <>
          <ContainerTable ref={refDivTable}>
            <table id="geral">
              <HeaderPonto
                headers={headers}
                onChange={(value: number) => {
                  setTabelaExibicao(value);
                  setCheckBox([value]);
                  setTodosCheck(!todosCheck);
                  setResultadoPesquisa(false);
                  setVerTabelaExibicao(true);
                  setVerGeral(false);
                  setVoltarTabelaExibicao(true);
                  setSearchCPF('');
                  // setSearchCliente('');
                  setSearchNome('');
                  // //setSearchPosto('');
                  // setSearchContrato('');
                  setSearchCPFSimple('');
                  // setSearchClienteSimple('');
                  setSearchNomeSimple('');
                  // setSearchPostoSimple('');
                  // setSearchContratoSimple('');
                }}
                onModal={() => {
                  window.scrollTo(0, 0);
                  setVerModalInformacao(true);
                }}
              />
              <tbody>
                {responseDataSimple.map((res: ResponseGet, index) => (
                  <tr key={res.CPFSEMFORMATO + index.toString()}>
                    {/* <td>{res.NOME_CLIENTE}</td> */}
                    <td>{res.NOME}</td>
                    <td>{maskCpf(res.CPFSEMFORMATO)}</td>
                    {/* <td>{res.CONTRATO}</td> */}
                    {/* <td>{res.POSTO}</td> */}
                    <TdIcons>
                      {res.COM_PONTO ? (
                        <Icon whichIcons="Check" />
                      ) : (
                        <Icon whichIcons="Close" />
                      )}
                      <p hidden>{res.COM_PONTO ? 'Sim' : 'Não'}</p>
                    </TdIcons>
                    <TdIcons>
                      {res.EXTRA ? (
                        <Icon whichIcons="Check" />
                      ) : (
                        <Icon whichIcons="Close" />
                      )}
                      <p hidden>{res.EXTRA ? 'Sim' : 'Não'}</p>
                    </TdIcons>
                    <TdIcons>
                      {res.SEM_PONTO ? (
                        <Icon whichIcons="Check" />
                      ) : (
                        <Icon whichIcons="Close" />
                      )}
                      <p hidden>{res.SEM_PONTO ? 'Sim' : 'Não'}</p>
                    </TdIcons>
                    <TdIcons>
                      {res.ATRASO ? (
                        <Icon whichIcons="Check" />
                      ) : (
                        <Icon whichIcons="Close" />
                      )}
                      <p hidden>{res.ATRASO ? 'Sim' : 'Não'}</p>
                    </TdIcons>
                    {/* <TdIcons>
                      {res.FERIAS ? (
                        <Icon whichIcons="Check" />
                      ) : (
                        <Icon whichIcons="Close" />
                      )}
                      <p hidden>{res.FERIAS ? 'Sim' : 'Não'}</p>
                    </TdIcons> */}
                    {/* <TdIcons>
                      {res.AFASTAMENTO ? (
                        <Icon whichIcons="Check" />
                      ) : (
                        <Icon whichIcons="Close" />
                      )}
                      <p hidden>{res.AFASTAMENTO ? 'Sim' : 'Não'}</p>
                    </TdIcons> */}
                    {/* <TdIcons>
                      {res.PONTO_DIVERGENTE ? (
                        <Icon whichIcons="Check" />
                      ) : (
                        <Icon whichIcons="Close" />
                      )}
                      <p hidden>{res.PONTO_DIVERGENTE ? 'Sim' : 'Não'}</p>
                    </TdIcons> */}
                    <TdIcons>
                      {res.HORA_DIVERGENTE ? (
                        <Icon whichIcons="Check" />
                      ) : (
                        <Icon whichIcons="Close" />
                      )}
                      <p hidden> {res.HORA_DIVERGENTE ? 'Sim' : 'Não'}</p>
                    </TdIcons>
                    <TdIcons>
                      {res.ABONO ? (
                        <Icon whichIcons="Check" />
                      ) : (
                        <Icon whichIcons="Close" />
                      )}
                      <p hidden>{res.ABONO ? 'Sim' : 'Não'}</p>
                    </TdIcons>
                  </tr>
                ))}
              </tbody>
            </table>
          </ContainerTable>
          <ContainerPagination>
            <div className="divPaginacao">
              <Pagination
                total={totalItemsGeral}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page: number) => setCurrentPage(page)}
              />
              <DropDown
                onChangeItems={(value: string) => {
                  setCurrentPage(1);
                  if (value === 'todos') {
                    setITEMS_PER_PAGE(1);
                  } else {
                    setITEMS_PER_PAGE(Number(value));
                  }
                }}
                objetoEnum={dropDown}
                minWidth={30}
              />
              <Tooltip
                title="Exportar em Excel"
                arrow
                TransitionComponent={Zoom}
              >
                <div>
                  <ReactHTMLTableToExcel
                    id="export-excel"
                    table="geral"
                    filename="gerenciamento_ponto"
                    sheet="tablexls"
                    buttonText={
                      // eslint-disable-next-line react/jsx-wrap-multilines
                      <DivButtonExcel>
                        <RiFileExcel2Line style={{ height: 25, width: 25 }} />
                      </DivButtonExcel>
                    }
                  />
                </div>
              </Tooltip>
              <Tooltip title="Exportar em PDF" arrow TransitionComponent={Zoom}>
                <button
                  className="buttonPdf"
                  type="button"
                  onClick={() => {
                    exportPdfGeral(
                      responseDataSimple,
                      filial,
                      formatarDataAnoMesDia(dataInicialGet),
                      formatarDataAnoMesDia(dataFinalGet),
                    );
                  }}
                >
                  <GoFilePdf />
                </button>
              </Tooltip>
            </div>

            <div style={{}}>
              <p>Pessoas: {totalTabela}</p>
            </div>
          </ContainerPagination>
        </>
      )}
      {verGeral && responseDataSimple.length <= 0 && (
        <ContainerFilter>
          <h3>Não existe dados para essa filtragem.</h3>
        </ContainerFilter>
      )}
      {verTabelaExibicao && (
        <TabelaExibicao
          dados={responseData}
          dadosCompletos={response}
          empresa={filial}
          dataInicial={formatarDataAnoMesDia(dataInicialGet)}
          dataFinal={formatarDataAnoMesDia(dataFinalGet)}
          valor={tabelaExibicao}
          onChange={() => {
            setTabelaExibicao(0);
            setCheckBox([]);
            setVerEscala(false);
            setVerTodos(false);
            setResultadoPesquisa(true);
            setVerGeral(true);
            setVerTabelaExibicao(false);
            setVoltarTabelaExibicao(false);
            setSearchCPF('');
            // setSearchCliente('');
            setSearchNome('');
            // //setSearchPosto('');
            // setSearchContrato('');
          }}
          onVisualize={(res) => {
            setVerEscala(true);
            setVerTodos(true);
            setResultadoPesquisa(false);
            setVerTabelaExibicao(false);
            setVoltarTabelaExibicao(true);
            setCheckBoxAba([tabelaExibicao]);
            setCheckBox([tabelaExibicao]);
            setOpcaoAba(checkBoxAba[0]);
            setVerGeral(false);
            setSearchCPF('');
            // setSearchCliente('');
            setSearchNome('');
            // //setSearchPosto('');
            // setSearchContrato('');
            setResponseUser(filterUserResponse(res));
          }}
        />
      )}
      {verEscala && (
        <>
          <ContainerAbaDeButtons>
            <AbaDeButtons>
              <div className="geralAba">
                <DivAbaDeButtons className="aba" cor={false}>
                  {voltarTabelaExibicao && (
                    // <Tooltip
                    //   title="Voltar para seleção de filtros."
                    //   className="Branco"
                    //   tamanho={230}
                    //   marginLeftComponent={50}
                    //   marginRightComponent={56}
                    //   marginBottomComponent={0}
                    //   widthComponent={0}
                    // >
                    <Tooltip
                      title="Voltar para seleção de filtros"
                      arrow
                      TransitionComponent={Zoom}
                    >
                      <button
                        className="buttonVoltar"
                        type="button"
                        onClick={() => {
                          setCheckBoxAba([]);
                          setVerTabelaExibicao(true);
                          setVerGeral(false);
                          setResultadoPesquisa(false);
                          setVerEscala(false);
                          setVoltarTabelaExibicao(false);
                          setSearchCPF('');
                          // setSearchCliente('');
                          setSearchNome('');
                          // setSearchPosto('');
                          // setSearchContrato('');
                        }}
                      >
                        <BsArrowLeft size={40} color="#ffffff" />
                      </button>
                    </Tooltip>
                  )}
                </DivAbaDeButtons>
                {checkboxes.map(
                  (check) =>
                    checkBox.includes(check.key) && (
                      <DivAbaDeButtons
                        key={check.key}
                        className="aba"
                        cor={checkBoxAba.includes(check.key)}
                      >
                        <button
                          type="button"
                          onClick={() => {
                            handleChangeCheckAba(check.key);
                          }}
                        >
                          {check.label}
                        </button>
                      </DivAbaDeButtons>
                    ),
                )}
              </div>
            </AbaDeButtons>
          </ContainerAbaDeButtons>
          {responseUser.length > 0 && checkBoxAba.includes(1) && (
            <>
              {preencherObjeto()}
              <div style={{ display: 'none' }}>
                <TablePonto dados={responseUser} />
              </div>

              {/* ------------------------LINHA 1{/*------------------------*/}

              <Aba>
                <div className="dropdown">
                  <button
                    type="button"
                    className="dropbtn"
                    onFocus={(): void => {
                      setSetaExportar('AiFillCaretDown');
                    }}
                    onMouseOver={(): void => {
                      setSetaExportar('AiFillCaretDown');
                    }}
                    onBlur={(): void => {
                      setSetaExportar('AiFillCaretLeft');
                    }}
                    onMouseOut={(): void => {
                      setSetaExportar('AiFillCaretLeft');
                    }}
                  >
                    <p style={{ margin: 0, padding: 0 }}>Exportar Relatório</p>
                    {setaExportar === 'AiFillCaretLeft' ? (
                      <AiFillCaretLeft />
                    ) : (
                      <AiFillCaretDown />
                    )}
                  </button>
                  <div
                    className="dropdown-content"
                    onFocus={(): void => {
                      setSetaExportar('AiFillCaretDown');
                    }}
                    onMouseOver={(): void => {
                      setSetaExportar('AiFillCaretDown');
                    }}
                    onBlur={(): void => {
                      setSetaExportar('AiFillCaretLeft');
                    }}
                    onMouseOut={(): void => {
                      setSetaExportar('AiFillCaretLeft');
                    }}
                  >
                    <ReactHTMLTableToExcel
                      id="export-excel"
                      className="btn"
                      table="PontoExcel"
                      filename={`ponto_${responseUser[0].NOME}`}
                      sheet="tablexls"
                      buttonText="Exportar Excel"
                    />
                    <button
                      type="button"
                      onClick={() => {
                        setVisualizarModalPdf(true);
                        window.scrollTo(0, 0);
                      }}
                    >
                      Exportar PDF
                    </button>
                  </div>
                </div>
                <BottomsExportAndAbonoIndex
                  isJustBottom
                  returnAbonoBatida={() => {
                    handleWhichBottomAbonoWasClicked(false, 'Todos', 1);
                  }}
                  returnAbonoDia={() => {
                    handleWhichBottomAbonoWasClicked(false, 'Todos', 2);
                  }}
                  returnAbonoPeriodo={() => {
                    handleWhichBottomAbonoWasClicked(false, 'Todos', 3);
                  }}
                  returnApagarAbono={() => {
                    handleWhichBottomAbonoWasClicked(true, '', 0);
                  }}
                />
              </Aba>
              {/* ------------------------LINHA 1{/*------------------------*/}

              <ContainerTableEscala>
                <table>
                  <tbody>
                    <tr key="a">
                      {/* <td>Cliente: {responseUser[0].NOME_CLIENTE}</td> */}
                      <td>Nome: {responseUser[0].NOME}</td>
                    </tr>
                    {/* <tr key="b">
                      <td>CPF: {maskCpf(responseUser[0].CPFSEMFORMATO)}</td>
                      <td>Contrato: {responseUser[0].CONTRATO}</td>
                    </tr> */}
                    {/* <tr key="c">
                      <td>Posto: {responseUser[0].POSTO}</td>
                      <td>Função: {responseUser[0].FUNCAO}</td>
                    </tr> */}
                    <tr key="d">
                      <td>Escala: {responseUser[0].ESCALA}</td>
                      <td>
                        {`Hora Inicial: ${responseUser[0].HORA_INICIAL}    Hora Final: ${responseUser[0].HORA_FINAL}`}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ContainerTableEscala>

              <ContainerTable>
                {(refreshTable || !refreshTable) && (
                  <table id="EscalaEsp">
                    <HeaderTable
                      headers={
                        arrayVerAbono.length > 0
                          ? headersEscalaAbono
                          : headersEscala
                      }
                    />
                    <tbody>
                      {copiaResponseUser.map((res: ResponseGet, index) => (
                        <tr key={res.CPFSEMFORMATO + index.toString()}>
                          <td style={{ maxWidth: 30 }}>
                            {(res.ABONO || res.FALTA_ABONADA) && (
                              <button
                                type="button"
                                onClick={() => {
                                  AdicionarCheckAbono(
                                    res.DATA_INICIAL_DIA_AGENDA,
                                  );
                                }}
                              >
                                {!verificaCheckAbono(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) && <FiEye />}
                                {verificaCheckAbono(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) && <FiEyeOff />}
                              </button>
                            )}
                            {!(res.ABONO || res.FALTA_ABONADA) && <p />}
                          </td>
                          <td style={{ minWidth: 100 }}>
                            {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
                          </td>
                          {/* <td>{res.LOCAL_LATITUDE}</td>
                          <td>{res.LOCAL_LONGITUDE}</td> */}
                          <td>
                            <p>Entrada</p>
                            {verificaAbono(res, 1) && (
                              <p style={{ color: 'orange' }}> Entrada</p>
                            )}
                            <p>Pausa</p>
                            {verificaAbono(res, 2) && (
                              <p style={{ color: 'orange' }}> Pausa</p>
                            )}
                            <p>Volta</p>
                            {verificaAbono(res, 3) && (
                              <p style={{ color: 'orange' }}> Volta</p>
                            )}
                            <p>Saída</p>
                            {verificaAbono(res, 4) && (
                              <p style={{ color: 'orange' }}> Saída</p>
                            )}
                          </td>
                          <td style={{ minWidth: 100 }}>
                            <div>
                              <p>
                                {res.PONTO_DATA_ENTRADA
                                  ? formatarDataAnoMesDia(
                                      res.PONTO_DATA_ENTRADA,
                                    )
                                  : '-'}
                              </p>
                              {verificaAbono(res, 1) && (
                                <p style={{ color: 'orange' }}>
                                  {formatarDataAnoMesDia(
                                    res.DATA_INICIAL_DIA_AGENDA,
                                  )}
                                </p>
                              )}
                              <p>
                                {res.PONTO_DATA_PAUSA
                                  ? formatarDataAnoMesDia(res.PONTO_DATA_PAUSA)
                                  : '-'}
                              </p>
                              {verificaAbono(res, 2) && (
                                <p style={{ color: 'orange' }}>
                                  {formatarDataAnoMesDia(
                                    res.DATA_INICIAL_DIA_AGENDA,
                                  )}
                                </p>
                              )}
                              <p>
                                {res.PONTO_DATA_VOLTA
                                  ? formatarDataAnoMesDia(res.PONTO_DATA_VOLTA)
                                  : '-'}
                              </p>
                              {verificaAbono(res, 3) && (
                                <p style={{ color: 'orange' }}>
                                  {formatarDataAnoMesDia(
                                    res.DATA_INICIAL_DIA_AGENDA,
                                  )}
                                </p>
                              )}
                              <p>
                                {res.PONTO_DATA_SAIDA
                                  ? formatarDataAnoMesDia(res.PONTO_DATA_SAIDA)
                                  : '-'}
                              </p>
                              {verificaAbono(res, 4) && (
                                <p style={{ color: 'orange' }}>
                                  {formatarDataAnoMesDia(
                                    res.DATA_INICIAL_DIA_AGENDA,
                                  )}
                                </p>
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              <p>
                                {res.PONTO_HORA_ENTRADA
                                  ? res.PONTO_HORA_ENTRADA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 1) && (
                                <p style={{ color: 'orange' }}>
                                  {horaEscalaPonto(res, 1)}
                                </p>
                              )}
                              <p>
                                {res.PONTO_HORA_PAUSA
                                  ? res.PONTO_HORA_PAUSA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 2) && (
                                <p style={{ color: 'orange' }}>
                                  {horaEscalaPonto(res, 2)}
                                </p>
                              )}
                              <p>
                                {res.PONTO_HORA_VOLTA
                                  ? res.PONTO_HORA_VOLTA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 3) && (
                                <p style={{ color: 'orange' }}>
                                  {horaEscalaPonto(res, 3)}
                                </p>
                              )}
                              <p>
                                {res.PONTO_HORA_SAIDA
                                  ? res.PONTO_HORA_SAIDA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 4) && (
                                <p style={{ color: 'orange' }}>
                                  {horaEscalaPonto(res, 4)}
                                </p>
                              )}
                            </div>
                          </td>
                          <td>
                            <div>
                              <p>
                                <a
                                  href={res.PONTO_LINK_FOTO_ENTRADA}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FiFileText />
                                </a>
                              </p>
                              {verificaAbono(res, 1) && (
                                <p style={{ color: 'orange' }}>-</p>
                              )}
                              <p>
                                <a
                                  href={res.PONTO_LINK_FOTO_PAUSA}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FiFileText />
                                </a>
                              </p>
                              {verificaAbono(res, 2) && (
                                <p style={{ color: 'orange' }}>-</p>
                              )}
                              <p>
                                <a
                                  href={res.PONTO_LINK_FOTO_VOLTA}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FiFileText />
                                </a>
                              </p>
                              {verificaAbono(res, 3) && (
                                <p style={{ color: 'orange' }}>-</p>
                              )}
                              <p>
                                <a
                                  href={res.PONTO_LINK_FOTO_SAIDA}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  <FiFileText />
                                </a>
                              </p>
                              {verificaAbono(res, 4) && (
                                <p style={{ color: 'orange' }}>-</p>
                              )}
                            </div>
                          </td>
                          {/* <td>
                            <div>
                              <p>
                                {res.PONTO_LATITUDE_ENTRADA
                                  ? res.PONTO_LATITUDE_ENTRADA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 1) && (
                                <p style={{ color: 'orange' }}>
                                  {res.LOCAL_LATITUDE}
                                </p>
                              )}
                              <p>
                                {res.PONTO_LATITUDE_PAUSA
                                  ? res.PONTO_LATITUDE_PAUSA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 2) && (
                                <p style={{ color: 'orange' }}>
                                  {res.LOCAL_LATITUDE}
                                </p>
                              )}
                              <p>
                                {res.PONTO_LATITUDE_VOLTA
                                  ? res.PONTO_LATITUDE_VOLTA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 3) && (
                                <p style={{ color: 'orange' }}>
                                  {res.LOCAL_LATITUDE}
                                </p>
                              )}
                              <p>
                                {res.PONTO_LATITUDE_SAIDA
                                  ? res.PONTO_LATITUDE_SAIDA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 4) && (
                                <p style={{ color: 'orange' }}>
                                  {res.LOCAL_LATITUDE}
                                </p>
                              )}
                            </div>
                          </td> */}
                          {/* <td>
                            <div>
                              <p>
                                {res.PONTO_LONGITUDE_ENTRADA
                                  ? res.PONTO_LONGITUDE_ENTRADA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 1) && (
                                <p style={{ color: 'orange' }}>
                                  {res.LOCAL_LONGITUDE}
                                </p>
                              )}
                              <p>
                                {res.PONTO_LONGITUDE_PAUSA
                                  ? res.PONTO_LONGITUDE_PAUSA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 2) && (
                                <p style={{ color: 'orange' }}>
                                  {res.LOCAL_LONGITUDE}
                                </p>
                              )}
                              <p>
                                {res.PONTO_LONGITUDE_VOLTA
                                  ? res.PONTO_LONGITUDE_VOLTA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 3) && (
                                <p style={{ color: 'orange' }}>
                                  {res.LOCAL_LONGITUDE}
                                </p>
                              )}
                              <p>
                                {res.PONTO_LONGITUDE_SAIDA
                                  ? res.PONTO_LONGITUDE_SAIDA
                                  : '-'}
                              </p>
                              {verificaAbono(res, 4) && (
                                <p style={{ color: 'orange' }}>
                                  {res.LOCAL_LONGITUDE}
                                </p>
                              )}
                            </div>
                          </td> */}
                          {/* <td>
                            <div>
                              <p>
                                {res.PONTO_DIFERENCA_METROS_ENTRADA
                                  ? `${res.PONTO_DIFERENCA_METROS_ENTRADA} m`
                                  : '-'}
                              </p>
                              {verificaAbono(res, 1) && (
                                <p style={{ color: 'orange' }}>-</p>
                              )}
                              <p>
                                {res.PONTO_DIFERENCA_METROS_PAUSA
                                  ? `${res.PONTO_DIFERENCA_METROS_PAUSA} m`
                                  : '-'}
                              </p>
                              {verificaAbono(res, 2) && (
                                <p style={{ color: 'orange' }}>-</p>
                              )}
                              <p>
                                {res.PONTO_DIFERENCA_METROS_VOLTA
                                  ? `${res.PONTO_DIFERENCA_METROS_VOLTA} m`
                                  : '-'}
                              </p>
                              {verificaAbono(res, 3) && (
                                <p style={{ color: 'orange' }}>-</p>
                              )}
                              <p>
                                {res.PONTO_DIFERENCA_METROS_SAIDA
                                  ? `${res.PONTO_DIFERENCA_METROS_SAIDA} m`
                                  : '-'}
                              </p>
                              {verificaAbono(res, 4) && (
                                <p style={{ color: 'orange' }}>-</p>
                              )}
                            </div>
                          </td> */}
                          {arrayVerAbono.length > 0 &&
                            arrayVerAbono.includes(
                              res.DATA_INICIAL_DIA_AGENDA,
                            ) &&
                            (res.ENTRADA_ABONADA ||
                              res.PAUSA_ABONADA ||
                              res.VOLTA_ABONADA ||
                              res.SAIDA_ABONADA) && (
                              <td style={{ minWidth: 200 }}>
                                {/* alinhada com a linha original sem abono */}
                                <p>-</p>
                                {res.ENTRADA_ABONADA && (
                                  <p style={{ color: 'orange' }}>
                                    {res.DESC_ENTRADA}
                                  </p>
                                )}
                                {/* alinhada com a linha original sem abono */}
                                <p>-</p>
                                {res.PAUSA_ABONADA && (
                                  <p style={{ color: 'orange' }}>
                                    {res.DESC_PAUSA}
                                  </p>
                                )}
                                {/* alinhada com a linha original sem abono */}
                                <p>-</p>
                                {res.VOLTA_ABONADA && (
                                  <p style={{ color: 'orange' }}>
                                    {res.DESC_VOLTA}
                                  </p>
                                )}
                                {/* alinhada com a linha original sem abono */}
                                <p>-</p>
                                {res.SAIDA_ABONADA && (
                                  <p style={{ color: 'orange' }}>
                                    {res.DESC_SAIDA}
                                  </p>
                                )}
                              </td>
                            )}
                          {arrayVerAbono.length > 0 &&
                            arrayVerAbono.includes(
                              res.DATA_INICIAL_DIA_AGENDA,
                            ) &&
                            (res.DIA_ABONADO ||
                              res.PERIODO_ABONADO ||
                              res.FALTA_ABONADA) && (
                              <td style={{ minWidth: 200 }}>
                                {res.FALTA_ABONADA && (
                                  <p style={{ color: 'orange' }}>
                                    {res.DESC_FALTA}
                                  </p>
                                )}
                                {res.DIA_ABONADO && (
                                  <p style={{ color: 'orange' }}>
                                    {res.DESC_DIA}
                                  </p>
                                )}
                                {res.PERIODO_ABONADO && (
                                  <p style={{ color: 'orange' }}>
                                    {res.DESC_PERIODO}
                                  </p>
                                )}
                              </td>
                            )}
                          {arrayVerAbono.length > 0 &&
                            ((!(
                              res.ENTRADA_ABONADA ||
                              res.PAUSA_ABONADA ||
                              res.VOLTA_ABONADA ||
                              res.SAIDA_ABONADA
                            ) &&
                              !(
                                res.DIA_ABONADO ||
                                res.PERIODO_ABONADO ||
                                res.FALTA_ABONADA
                              )) ||
                              !arrayVerAbono.includes(
                                res.DATA_INICIAL_DIA_AGENDA,
                              )) && <td style={{ minWidth: 200 }}> </td>}
                          {(refreshIconTable || !refreshIconTable) && (
                            <td>
                              <ContainerFormulario
                                cor={verificaIdArray(res.Id_PontoEletronico, 1)}
                              >
                                <button
                                  className="buttonOptions"
                                  type="button"
                                  onClick={() => {
                                    addIdPontoNoArray(res, 1);
                                  }}
                                >
                                  {verificaIdArray(
                                    res.Id_PontoEletronico,
                                    1,
                                  ) && <FiCheckSquare />}
                                  {!verificaIdArray(
                                    res.Id_PontoEletronico,
                                    1,
                                  ) && <FiSquare />}
                                </button>
                              </ContainerFormulario>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                )}
              </ContainerTable>
              <ContainerPagination>
                <Pagination
                  total={responseUser.length}
                  itemsPerPage={ITEMS_PER_PAGE}
                  currentPage={currentPage}
                  onPageChange={(page: number) => setCurrentPage(page)}
                />
                <DropDown
                  onChangeItems={(value: string) => {
                    setCurrentPage(1);
                    if (value === 'todos') {
                      setITEMS_PER_PAGE(1);
                    } else {
                      setITEMS_PER_PAGE(Number(value));
                    }
                  }}
                  objetoEnum={dropDown}
                  minWidth={30}
                />
              </ContainerPagination>

              {visualizarModalPdf && (
                <ModalData
                  cpf={responseUser[0].CPFSEMFORMATO}
                  onClose={() => setVisualizarModalPdf(false)}
                />
              )}
            </>
          )}
          {responseUser.length > 0 && checkBoxAba.includes(2) && (
            <>
              <ContainerTableEscala>
                <table>
                  <tbody>
                    <tr key="a">
                      {/* <td>Cliente: {responseUser[0].NOME_CLIENTE}</td> */}
                      <td>Nome: {responseUser[0].NOME}</td>
                    </tr>
                    {/* <tr key="b">
                      <td>CPF: {maskCpf(responseUser[0].CPFSEMFORMATO)}</td>
                      <td>Contrato: {responseUser[0].CONTRATO}</td>
                    </tr> */}
                    {/* <tr key="c">
                      <td>Posto: {responseUser[0].POSTO}</td>
                      <td>Função: {responseUser[0].FUNCAO}</td>
                    </tr> */}
                    <tr key="d">
                      <td>Escala: {responseUser[0].ESCALA}</td>
                      <td> </td>
                    </tr>
                  </tbody>
                </table>
              </ContainerTableEscala>
              <ContainerTable>
                <table id="horaExtra">
                  <HeaderTable headers={headersHoraExtra} />
                  <tbody>
                    {responseUser.map(
                      (res: ResponseGet, index) =>
                        res.EXTRA && (
                          <tr key={res.CPFSEMFORMATO + index.toString()}>
                            <td style={{ minWidth: 100 }}>
                              {formatarDataAnoMesDia(
                                res.DATA_INICIAL_DIA_AGENDA,
                              )}
                            </td>
                            {/* horas a trabalhar */}
                            <td>{res.HORAS_A_TRABALHAR}</td>
                            {/* horas trabalhadas */}
                            <td>{res.HORAS_TRABALHADAS_MENOS_HORA_PAUSA}</td>
                            <td>{res.PONTO_HORA_ENTRADA}</td>
                            <td>{res.PONTO_HORA_PAUSA}</td>
                            <td>{res.PONTO_HORA_VOLTA}</td>
                            <td>{res.PONTO_HORA_SAIDA}</td>
                          </tr>
                        ),
                    )}
                  </tbody>
                </table>
              </ContainerTable>
              <ContainerSpace />
            </>
          )}
          {responseUser.length > 0 && checkBoxAba.includes(3) && (
            <>
              {preencherObjeto()}
              {/* ------------------------LINHA 2{/*------------------------*/}
              <BottomsExportAndAbonoIndex
                isJustBottom={false}
                isAbonoBatida={false}
                returnAbonoDia={() => {
                  handleWhichBottomAbonoWasClicked(false, 'Falta', 2);
                }}
                returnAbonoPeriodo={() => {
                  handleWhichBottomAbonoWasClicked(false, 'Falta', 3);
                }}
                returnApagarAbono={() => {
                  handleWhichBottomAbonoWasClicked(true, '', 0);
                }}
              />
              {/* ------------------------LINHA 2{/*------------------------*/}

              <ContainerTableEscala>
                <table>
                  <tbody>
                    <tr key="a">
                      {/* <td>Cliente: {responseUser[0].NOME_CLIENTE}</td> */}
                      <td>Nome: {responseUser[0].NOME}</td>
                    </tr>
                    {/* <tr key="b">
                      <td>CPF: {maskCpf(responseUser[0].CPFSEMFORMATO)}</td>
                      <td>Contrato: {responseUser[0].CONTRATO}</td>
                    </tr> */}
                    {/* <tr key="c">
                      <td>Posto: {responseUser[0].POSTO}</td>
                      <td>Função: {responseUser[0].FUNCAO}</td>
                    </tr> */}
                  </tbody>
                </table>
              </ContainerTableEscala>
              <ContainerTable>
                <table id="falta">
                  <HeaderTable
                    headers={
                      arrayVerAbono.length > 0
                        ? headersFaltaAbono
                        : headersFalta
                    }
                  />
                  <tbody>
                    {copiaResponseUser.map(
                      (res: ResponseGet, index) =>
                        res.SEM_PONTO && (
                          <tr key={res.CPFSEMFORMATO + index.toString()}>
                            <td style={{ maxWidth: 30 }}>
                              {(res.ABONO || res.FALTA_ABONADA) && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    AdicionarCheckAbono(
                                      res.DATA_INICIAL_DIA_AGENDA,
                                    );
                                  }}
                                >
                                  {!verificaCheckAbono(
                                    res.DATA_INICIAL_DIA_AGENDA,
                                  ) && <FiEye />}
                                  {verificaCheckAbono(
                                    res.DATA_INICIAL_DIA_AGENDA,
                                  ) && <FiEyeOff />}
                                </button>
                              )}
                              {!(res.ABONO || res.FALTA_ABONADA) && <p />}
                            </td>
                            <td>
                              {formatarDataAnoMesDia(
                                res.DATA_INICIAL_DIA_AGENDA,
                              )}
                              {arrayVerAbono.length > 0 &&
                                arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) &&
                                res.FALTA_ABONADA && (
                                  <p style={{ color: 'orange' }}>
                                    Falta Abonada
                                  </p>
                                )}
                            </td>
                            {arrayVerAbono.length > 0 &&
                              res.FALTA_ABONADA &&
                              arrayVerAbono.includes(
                                res.DATA_INICIAL_DIA_AGENDA,
                              ) && (
                                <td style={{ minWidth: 200 }}>
                                  {res.FALTA_ABONADA && (
                                    <p style={{ color: 'orange' }}>
                                      {res.DESC_FALTA}
                                    </p>
                                  )}
                                </td>
                              )}
                            {arrayVerAbono.length > 0 &&
                              (!res.FALTA_ABONADA ||
                                !arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                )) && <td style={{ minWidth: 200 }}> </td>}
                            {(refreshIconTable || !refreshIconTable) && (
                              <td>
                                <ContainerFormulario
                                  cor={verificaIdArray(
                                    res.Id_PontoEletronico,
                                    3,
                                  )}
                                >
                                  <button
                                    className="buttonOptions"
                                    type="button"
                                    onClick={() => {
                                      addIdPontoNoArray(res, 3);
                                    }}
                                  >
                                    {verificaIdArray(
                                      res.Id_PontoEletronico,
                                      3,
                                    ) && <FiCheckSquare />}
                                    {!verificaIdArray(
                                      res.Id_PontoEletronico,
                                      3,
                                    ) && <FiSquare />}
                                  </button>
                                </ContainerFormulario>
                              </td>
                            )}
                          </tr>
                        ),
                    )}
                  </tbody>
                </table>
              </ContainerTable>
            </>
          )}
          {responseUser.length > 0 && checkBoxAba.includes(4) && (
            <>
              {/* ------------------------LINHA 3{/*------------------------*/}
              <BottomsExportAndAbonoIndex
                isJustBottom={false}
                isAbonoBatida={false}
                returnAbonoDia={() => {
                  handleWhichBottomAbonoWasClicked(false, 'Atraso', 2);
                }}
                returnAbonoPeriodo={() => {
                  handleWhichBottomAbonoWasClicked(false, 'Atraso', 3);
                }}
                returnApagarAbono={() => {
                  handleWhichBottomAbonoWasClicked(true, '', 0);
                }}
              />
              {/* ------------------------LINHA 3{/*------------------------*/}

              <ContainerTableEscala>
                <table>
                  <tbody>
                    <tr key="a">
                      {/* <td>Cliente: {responseUser[0].NOME_CLIENTE}</td> */}
                      <td>Nome: {responseUser[0].NOME}</td>
                    </tr>
                    {/* <tr key="b">
                      <td>CPF: {maskCpf(responseUser[0].CPFSEMFORMATO)}</td>
                      <td>Contrato: {responseUser[0].CONTRATO}</td>
                    </tr> */}
                    {/* <tr key="c">
                      <td>Posto: {responseUser[0].POSTO}</td>
                      <td>Função: {responseUser[0].FUNCAO}</td>
                    </tr> */}
                  </tbody>
                </table>
              </ContainerTableEscala>
              <ContainerTable>
                <table id="atraso" style={{ marginBottom: 2 }}>
                  <HeaderTable
                    headers={
                      arrayVerAbono.length > 0
                        ? headersAtrasoAbono
                        : headersAtraso
                    }
                  />
                  <tbody>
                    {copiaResponseUser.map(
                      (res: ResponseGet, index) =>
                        res.ATRASO && (
                          <tr key={res.CPFSEMFORMATO + index.toString()}>
                            <td style={{ maxWidth: 30 }}>
                              {(res.ABONO || res.FALTA_ABONADA) && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    AdicionarCheckAbono(
                                      res.DATA_INICIAL_DIA_AGENDA,
                                    );
                                  }}
                                >
                                  {!verificaCheckAbono(
                                    res.DATA_INICIAL_DIA_AGENDA,
                                  ) && <FiEye />}
                                  {verificaCheckAbono(
                                    res.DATA_INICIAL_DIA_AGENDA,
                                  ) && <FiEyeOff />}
                                </button>
                              )}
                              {!(res.ABONO || res.FALTA_ABONADA) && <p />}
                            </td>
                            <td style={{ minWidth: 100 }}>
                              {formatarDataAnoMesDia(
                                res.DATA_INICIAL_DIA_AGENDA,
                              )}
                            </td>
                            {/* horas a trabalhar */}
                            <td>{res.HORAS_A_TRABALHAR}</td>
                            {/* horas trabalhadas */}
                            <td>
                              {res.HORAS_TRABALHADAS_MENOS_HORA_PAUSA}
                              {res.ABONO &&
                                arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) && (
                                  <p style={{ color: 'orange' }}>
                                    {res.HORAS_A_TRABALHAR}
                                  </p>
                                )}
                            </td>
                            <td>
                              {res.PONTO_HORA_ENTRADA}
                              {verificaAbono(res, 1) &&
                                arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) && (
                                  <p style={{ color: 'orange' }}>
                                    {horaEscalaPonto(res, 1)}
                                  </p>
                                )}
                              {!verificaAbono(res, 1) &&
                                arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) && <p style={{ color: 'orange' }}>-</p>}
                            </td>
                            <td>
                              {res.PONTO_HORA_PAUSA}
                              {verificaAbono(res, 2) &&
                                arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) && (
                                  <p style={{ color: 'orange' }}>
                                    {horaEscalaPonto(res, 2)}
                                  </p>
                                )}
                              {!verificaAbono(res, 2) &&
                                arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) && <p style={{ color: 'orange' }}>-</p>}
                            </td>
                            <td>
                              {res.PONTO_HORA_VOLTA}
                              {verificaAbono(res, 3) &&
                                arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) && (
                                  <p style={{ color: 'orange' }}>
                                    {horaEscalaPonto(res, 3)}
                                  </p>
                                )}
                              {!verificaAbono(res, 3) &&
                                arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) && <p style={{ color: 'orange' }}>-</p>}
                            </td>
                            <td>
                              {res.PONTO_HORA_SAIDA}
                              {verificaAbono(res, 4) &&
                                arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) && (
                                  <p style={{ color: 'orange' }}>
                                    {horaEscalaPonto(res, 4)}
                                  </p>
                                )}
                              {!verificaAbono(res, 4) &&
                                arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                ) && <p style={{ color: 'orange' }}>-</p>}
                            </td>

                            {arrayVerAbono.length > 0 &&
                              arrayVerAbono.includes(
                                res.DATA_INICIAL_DIA_AGENDA,
                              ) &&
                              res.ABONO && (
                                <td style={{ minWidth: 200 }}>
                                  {res.ENTRADA_ABONADA && (
                                    <p style={{ color: 'orange' }}>
                                      Entrada: {res.DESC_ENTRADA}
                                    </p>
                                  )}
                                  {res.PAUSA_ABONADA && (
                                    <p style={{ color: 'orange' }}>
                                      Pausa: {res.DESC_PAUSA}
                                    </p>
                                  )}
                                  {res.VOLTA_ABONADA && (
                                    <p style={{ color: 'orange' }}>
                                      Volta: {res.DESC_VOLTA}
                                    </p>
                                  )}
                                  {res.SAIDA_ABONADA && (
                                    <p style={{ color: 'orange' }}>
                                      Saída: {res.DESC_SAIDA}
                                    </p>
                                  )}
                                  {res.DIA_ABONADO && (
                                    <p style={{ color: 'orange' }}>
                                      {res.DESC_DIA}
                                    </p>
                                  )}
                                  {res.PERIODO_ABONADO && (
                                    <p style={{ color: 'orange' }}>
                                      {res.DESC_PERIODO}
                                    </p>
                                  )}
                                </td>
                              )}
                            {arrayVerAbono.length > 0 &&
                              (!res.ABONO ||
                                !arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                )) && <td style={{ minWidth: 200 }}> </td>}
                            {(refreshIconTable || !refreshIconTable) && (
                              <td>
                                <ContainerFormulario
                                  cor={verificaIdArray(
                                    res.Id_PontoEletronico,
                                    4,
                                  )}
                                >
                                  <button
                                    className="buttonOptions"
                                    type="button"
                                    onClick={() => {
                                      addIdPontoNoArray(res, 4);
                                    }}
                                  >
                                    {verificaIdArray(
                                      res.Id_PontoEletronico,
                                      4,
                                    ) && <FiCheckSquare />}
                                    {!verificaIdArray(
                                      res.Id_PontoEletronico,
                                      4,
                                    ) && <FiSquare />}
                                  </button>
                                </ContainerFormulario>
                              </td>
                            )}
                          </tr>
                        ),
                    )}
                  </tbody>
                </table>
              </ContainerTable>
            </>
          )}
          {responseUser.length > 0 && checkBoxAba.includes(6) && (
            <>
              {/* <ContainerTable>
                <table id="ferias">
                  <HeaderTable headers={headersFerias} />
                  <tbody>
                    <tr key={responseUser[0].CPFSEMFORMATO}>
                      <td style={{ minWidth: 250 }}>
                        {responseUser[0].NOME_CLIENTE}
                      </td>
                      <td style={{ minWidth: 250 }}>{responseUser[0].NOME}</td>
                      <td style={{ minWidth: 120 }}>
                        {maskCpf(responseUser[0].CPFSEMFORMATO)}
                      </td>
                      <td>{responseUser[0].CONTRATO}</td>
                      <td style={{ minWidth: 150 }}>{responseUser[0].POSTO}</td>
                      <td>{responseUser[0].FUNCAO}</td>
                      <td style={{ minWidth: 100 }}>
                        {formatarDataAnoMesDia(
                          responseUser[0].DATA_INICIAL_FERIAS_AFASTAMENTO,
                        )}
                      </td>
                      <td style={{ minWidth: 100 }}>
                        {formatarDataAnoMesDia(
                          responseUser[0].DATA_FINAL_FERIAS_AFASTAMENTO,
                        )}
                      </td>
                      <td style={{ minWidth: 100 }}>
                        {formatarDataAnoMesDia(
                          responseUser[0].DATA_VOLTA_FERIAS_AFASTAMENTO,
                        )}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ContainerTable> */}
            </>
          )}
          {responseUser.length > 0 && checkBoxAba.includes(8) && (
            <>
              {/* <ContainerTable>
                <table id="afastamento">
                  <HeaderTable headers={headersAfastamento} />
                  <tbody>
                    <tr key={responseUser[0].CPFSEMFORMATO}>
                      <td style={{ minWidth: 250 }}>
                        {responseUser[0].NOME_CLIENTE}
                      </td>
                      <td style={{ minWidth: 250 }}>{responseUser[0].NOME}</td>
                      <td style={{ minWidth: 120 }}>
                        {maskCpf(responseUser[0].CPFSEMFORMATO)}
                      </td>
                      <td>{responseUser[0].CONTRATO}</td>
                      <td style={{ minWidth: 150 }}>{responseUser[0].POSTO}</td>
                      <td>{responseUser[0].FUNCAO}</td>
                      <td style={{ minWidth: 100 }}>
                        {responseUser[0].DATA_INICIAL_FERIAS_AFASTAMENTO
                          ? formatarDataAnoMesDia(
                              responseUser[0].DATA_INICIAL_FERIAS_AFASTAMENTO,
                            )
                          : '-'}
                      </td>
                      <td style={{ minWidth: 100 }}>
                        {responseUser[0].DATA_FINAL_FERIAS_AFASTAMENTO
                          ? formatarDataAnoMesDia(
                              responseUser[0].DATA_FINAL_FERIAS_AFASTAMENTO,
                            )
                          : '-'}
                      </td>
                      <td style={{ minWidth: 100 }}>
                        {responseUser[0].DATA_VOLTA_FERIAS_AFASTAMENTO
                          ? formatarDataAnoMesDia(
                              responseUser[0].DATA_VOLTA_FERIAS_AFASTAMENTO,
                            )
                          : '-'}
                      </td>
                      <td>
                        {responseUser[0].MOTIVO_AFASTAMENTO
                          ? responseUser[0].MOTIVO_AFASTAMENTO
                          : '-'}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </ContainerTable> */}
            </>
          )}
          {responseUser.length > 0 && checkBoxAba.includes(9) && (
            <>
              {/* ------------------------LINHA 4{/*------------------------*/}
              <BottomsExportAndAbonoIndex
                isJustBottom={false}
                returnAbonoBatida={() => {
                  handleWhichBottomAbonoWasClicked(
                    false,
                    'Ponto Divergente',
                    2,
                  );
                }}
                returnAbonoDia={() => {
                  handleWhichBottomAbonoWasClicked(
                    false,
                    'Ponto Divergente',
                    2,
                  );
                }}
                returnAbonoPeriodo={() => {
                  handleWhichBottomAbonoWasClicked(
                    false,
                    'Ponto Divergente',
                    3,
                  );
                }}
                returnApagarAbono={() => {
                  handleWhichBottomAbonoWasClicked(true, '', 0);
                }}
              />
              {/* ------------------------LINHA 4{/*------------------------*/}

              <ContainerTableEscala>
                <table>
                  <tbody>
                    <tr key="a">
                      {/* <td>Cliente: {responseUser[0].NOME_CLIENTE}</td> */}
                      <td>Nome: {responseUser[0].NOME}</td>
                    </tr>
                    {/* <tr key="b">
                      <td>CPF: {maskCpf(responseUser[0].CPFSEMFORMATO)}</td>
                      <td>Contrato: {responseUser[0].CONTRATO}</td>
                    </tr> */}
                    {/* <tr key="c">
                      <td>Posto: {responseUser[0].POSTO}</td>
                      <td>Função: {responseUser[0].FUNCAO}</td>
                    </tr> */}
                  </tbody>
                </table>
              </ContainerTableEscala>
            </>
          )}
          {responseUser.length > 0 && checkBoxAba.includes(11) && (
            <>
              {/* ------------------------LINHA 5{/*------------------------*/}
              <BottomsExportAndAbonoIndex
                isJustBottom={false}
                returnAbonoBatida={() => {
                  handleWhichBottomAbonoWasClicked(false, 'Hora Divergente', 2);
                }}
                returnAbonoDia={() => {
                  handleWhichBottomAbonoWasClicked(false, 'Hora Divergente', 2);
                }}
                returnAbonoPeriodo={() => {
                  handleWhichBottomAbonoWasClicked(false, 'Hora Divergente', 3);
                }}
                returnApagarAbono={() => {
                  handleWhichBottomAbonoWasClicked(true, '', 0);
                }}
              />
              {/* ------------------------LINHA 5{/*------------------------*/}

              <ContainerTableEscala>
                <table>
                  <tbody>
                    <tr key="a">
                      {/* <td>Cliente: {responseUser[0].NOME_CLIENTE}</td> */}
                      <td>Nome: {responseUser[0].NOME}</td>
                    </tr>
                    {/* <tr key="b">
                      <td>CPF: {maskCpf(responseUser[0].CPFSEMFORMATO)}</td>
                      <td>Contrato: {responseUser[0].CONTRATO}</td>
                    </tr> */}
                    {/* <tr key="c">
                      <td>Posto: {responseUser[0].POSTO}</td>
                      <td>Função: {responseUser[0].FUNCAO}</td>
                    </tr> */}
                    <tr key="d">
                      <td>Escala: {responseUser[0].ESCALA}</td>
                      <td> </td>
                    </tr>
                  </tbody>
                </table>
              </ContainerTableEscala>
              <ContainerTable>
                <table id="HoraDivergente">
                  <HeaderTable
                    headers={
                      arrayVerAbono.length > 0
                        ? headersHoraDivergenteAbono
                        : headersHoraDivergente
                    }
                  />
                  <tbody>
                    {responseUser.map(
                      (res: ResponseGet, index) =>
                        res.HORA_DIVERGENTE && (
                          <tr key={res.CPFSEMFORMATO + index.toString()}>
                            <td style={{ maxWidth: 30 }}>
                              {(res.ABONO || res.FALTA_ABONADA) && (
                                <button
                                  type="button"
                                  onClick={() => {
                                    AdicionarCheckAbono(
                                      res.DATA_INICIAL_DIA_AGENDA,
                                    );
                                  }}
                                >
                                  {!verificaCheckAbono(
                                    res.DATA_INICIAL_DIA_AGENDA,
                                  ) && <FiEye />}
                                  {verificaCheckAbono(
                                    res.DATA_INICIAL_DIA_AGENDA,
                                  ) && <FiEyeOff />}
                                </button>
                              )}
                              {!(res.ABONO || res.FALTA_ABONADA) && <p />}
                            </td>
                            <td style={{ minWidth: 100 }}>
                              {formatarDataAnoMesDia(
                                res.DATA_INICIAL_DIA_AGENDA,
                              )}
                            </td>
                            <td>{res.HORA_INICIAL}</td>
                            <td>{res.HORA_FINAL}</td>
                            <td style={{ minWidth: 150 }}>
                              <div
                                style={{
                                  textAlign: 'left',
                                  width: 120,
                                  margin: 'auto',
                                }}
                              >
                                <p>
                                  Entrada:{' '}
                                  {res.PONTO_HORA_ENTRADA
                                    ? res.PONTO_HORA_ENTRADA
                                    : '-'}
                                </p>
                                {verificaAbono(res, 1) && (
                                  <p style={{ color: 'orange' }}>
                                    Entrada: {horaEscalaPonto(res, 1)}
                                  </p>
                                )}
                                <p>
                                  Pausa:{' '}
                                  {res.PONTO_HORA_PAUSA
                                    ? res.PONTO_HORA_PAUSA
                                    : '-'}
                                </p>
                                {verificaAbono(res, 2) && (
                                  <p style={{ color: 'orange' }}>
                                    Pausa: {horaEscalaPonto(res, 2)}
                                  </p>
                                )}
                                <p>
                                  Volta:{' '}
                                  {res.PONTO_HORA_VOLTA
                                    ? res.PONTO_HORA_VOLTA
                                    : '-'}
                                </p>
                                {verificaAbono(res, 3) && (
                                  <p style={{ color: 'orange' }}>
                                    Volta: {horaEscalaPonto(res, 3)}
                                  </p>
                                )}
                                <p>
                                  Saída:{' '}
                                  {res.PONTO_HORA_SAIDA
                                    ? res.PONTO_HORA_SAIDA
                                    : '-'}
                                </p>
                                {verificaAbono(res, 4) && (
                                  <p style={{ color: 'orange' }}>
                                    Saída: {horaEscalaPonto(res, 4)}
                                  </p>
                                )}
                              </div>
                            </td>
                            {arrayVerAbono.length > 0 &&
                              arrayVerAbono.includes(
                                res.DATA_INICIAL_DIA_AGENDA,
                              ) &&
                              (res.ENTRADA_ABONADA ||
                                res.PAUSA_ABONADA ||
                                res.VOLTA_ABONADA ||
                                res.SAIDA_ABONADA) && (
                                <td style={{ minWidth: 200 }}>
                                  {/* alinhada com a linha original sem abono */}
                                  <p>-</p>
                                  {res.ENTRADA_ABONADA && (
                                    <p style={{ color: 'orange' }}>
                                      {res.DESC_ENTRADA}
                                    </p>
                                  )}
                                  {/* alinhada com a linha original sem abono */}
                                  <p>-</p>
                                  {res.PAUSA_ABONADA && (
                                    <p style={{ color: 'orange' }}>
                                      {res.DESC_PAUSA}
                                    </p>
                                  )}
                                  {/* alinhada com a linha original sem abono */}
                                  <p>-</p>
                                  {res.VOLTA_ABONADA && (
                                    <p style={{ color: 'orange' }}>
                                      {res.DESC_VOLTA}
                                    </p>
                                  )}
                                  {/* alinhada com a linha original sem abono */}
                                  <p>-</p>
                                  {res.SAIDA_ABONADA && (
                                    <p style={{ color: 'orange' }}>
                                      {res.DESC_SAIDA}
                                    </p>
                                  )}
                                </td>
                              )}
                            {arrayVerAbono.length > 0 &&
                              arrayVerAbono.includes(
                                res.DATA_INICIAL_DIA_AGENDA,
                              ) &&
                              (res.DIA_ABONADO || res.PERIODO_ABONADO) && (
                                <td style={{ minWidth: 200 }}>
                                  {res.DIA_ABONADO && (
                                    <p style={{ color: 'orange' }}>
                                      {res.DESC_DIA}
                                    </p>
                                  )}
                                  {res.PERIODO_ABONADO && (
                                    <p style={{ color: 'orange' }}>
                                      {res.DESC_PERIODO}
                                    </p>
                                  )}
                                </td>
                              )}
                            {arrayVerAbono.length > 0 &&
                              (!res.ABONO ||
                                !arrayVerAbono.includes(
                                  res.DATA_INICIAL_DIA_AGENDA,
                                )) && <td style={{ minWidth: 200 }}> </td>}
                            {(refreshIconTable || !refreshIconTable) && (
                              <td>
                                <ContainerFormulario
                                  cor={verificaIdArray(
                                    res.Id_PontoEletronico,
                                    11,
                                  )}
                                >
                                  <button
                                    className="buttonOptions"
                                    type="button"
                                    onClick={() => {
                                      addIdPontoNoArray(res, 11);
                                    }}
                                  >
                                    {verificaIdArray(
                                      res.Id_PontoEletronico,
                                      11,
                                    ) && <FiCheckSquare />}
                                    {!verificaIdArray(
                                      res.Id_PontoEletronico,
                                      11,
                                    ) && <FiSquare />}
                                  </button>
                                </ContainerFormulario>
                              </td>
                            )}
                          </tr>
                        ),
                    )}
                  </tbody>
                </table>
              </ContainerTable>
            </>
          )}
          {responseUser.length > 0 && checkBoxAba.includes(12) && (
            <>
              <ContainerTableEscala>
                <table>
                  <tbody>
                    <tr key="a">
                      {/* <td>Cliente: {responseUser[0].NOME_CLIENTE}</td> */}
                      <td>Nome: {responseUser[0].NOME}</td>
                    </tr>
                    {/* <tr key="b">
                      <td>CPF: {maskCpf(responseUser[0].CPFSEMFORMATO)}</td>
                      <td>Contrato: {responseUser[0].CONTRATO}</td>
                    </tr> */}
                    {/* <tr key="c">
                      <td>Posto: {responseUser[0].POSTO}</td>
                      <td>Função: {responseUser[0].FUNCAO}</td>
                    </tr> */}
                    <tr key="d">
                      <td>Escala: {responseUser[0].ESCALA}</td>
                      <td> </td>
                    </tr>
                  </tbody>
                </table>
              </ContainerTableEscala>
              <ContainerTable>
                <table id="Abono">
                  <HeaderTable headers={headersAbono} />
                  <tbody>
                    {responseUser.map(
                      (res: ResponseGet, index) =>
                        (res.ABONO || res.FALTA_ABONADA) && (
                          <tr key={res.CPFSEMFORMATO + index.toString()}>
                            <td style={{ minWidth: 100 }}>
                              {formatarDataAnoMesDia(
                                res.DATA_INICIAL_DIA_AGENDA,
                              )}
                            </td>
                            <td>
                              {res.ABONO &&
                                (res.ENTRADA_ABONADA ||
                                  res.PAUSA_ABONADA ||
                                  res.VOLTA_ABONADA ||
                                  res.SAIDA_ABONADA) && (
                                  <div>
                                    {res.DESC_ENTRADA ? (
                                      <p>Entrada: {res.DESC_ENTRADA}</p>
                                    ) : null}
                                    {res.DESC_PAUSA ? (
                                      <p>Pausa: {res.DESC_PAUSA}</p>
                                    ) : null}
                                    {res.DESC_VOLTA ? (
                                      <p>Volta: {res.DESC_VOLTA}</p>
                                    ) : null}
                                    {res.DESC_SAIDA ? (
                                      <p>Saída: {res.DESC_SAIDA}</p>
                                    ) : null}
                                  </div>
                                )}
                              {res.DIA_ABONADO && res.DESC_DIA}
                              {res.PERIODO_ABONADO && res.DESC_PERIODO}
                              {res.FALTA_ABONADA && res.DESC_FALTA}
                            </td>
                            <td>
                              {res.ABONO &&
                                `${formatarDataAnoMesDia(
                                  res.DATA_ABONO,
                                )} - ${MostrarHoraMinSeg(res.DATA_ABONO)}`}
                              {res.FALTA_ABONADA &&
                                `${formatarDataAnoMesDia(
                                  res.DATA_ABONO_FALTA,
                                )} - ${MostrarHoraMinSeg(
                                  res.DATA_ABONO_FALTA,
                                )}`}
                            </td>
                            <td style={{ minWidth: 150 }}>
                              {res.USER_ACEITOU_ABONO}
                            </td>
                          </tr>
                        ),
                    )}
                  </tbody>
                </table>
              </ContainerTable>
              <ContainerSpace />
            </>
          )}
          {responseUser.length === 0 && (
            <ContainerFilter>
              <h3>Não existe dados para essa filtragem.</h3>
            </ContainerFilter>
          )}
        </>
      )}
      {verModalAbono && (
        <ModalAbono
          onClose={() => setVerModalAbono(false)}
          onSave={() => {
            setVerModalAbono(false);
            setRefresh(!refresh);
            setConsulta(true);
          }}
          dados={copiaResponseUser}
          idUser={user.id}
          abono={abonoDropDown}
        />
      )}
      {verModalAbonoBatida && (
        <ModalAbonoBatida
          onClose={() => setVerModalAbonoBatida(false)}
          onSave={() => {
            setVerModalAbonoBatida(false);
            setRefresh(!refresh);
            setConsulta(true);
            setVerTabelaExibicao(false);
            setVerGeral(true);
            setResultadoPesquisa(true);
            setVerEscala(false);
            setVoltarTabelaExibicao(false);
            setIdTipoArray(0);
            setArrayIdPonto([]);
          }}
          dados={copiaResponseUser}
          arrayId={arrayIdPonto}
          abono={abonoDropDown}
        />
      )}
      {verModalAbonoDia && (
        <ModalAbonoDia
          onClose={() => setVerModalAbonoDia(false)}
          onSave={() => {
            setVerModalAbonoDia(false);
            setRefresh(!refresh);
            setConsulta(true);
            setVerTabelaExibicao(false);
            setVerGeral(true);
            setResultadoPesquisa(true);
            setVerEscala(false);
            setVoltarTabelaExibicao(false);
            setIdTipoArray(0);
            setArrayIdPonto([]);
          }}
          dados={copiaResponseUser}
          arrayId={arrayIdPonto}
          abono={abonoDropDown}
        />
      )}
      {verModalAbonoPeriodo && (
        <ModalAbonoPeriodo
          onClose={() => setVerModalAbonoPeriodo(false)}
          onSave={() => {
            setVerModalAbonoPeriodo(false);
            setRefresh(!refresh);
            setConsulta(true);
            setVerTabelaExibicao(false);
            setVerGeral(true);
            setResultadoPesquisa(true);
            setVerEscala(false);
            setVoltarTabelaExibicao(false);
            setIdTipoArray(0);
            setArrayIdPonto([]);
          }}
          dados={copiaResponseUser}
          arrayId={arrayIdPonto}
          abono={abonoDropDown}
        />
      )}
      {verModalInformacao && (
        <ModalInformacao
          onClose={() => setVerModalInformacao(false)}
          title="Ponto"
          content="Será exibido uma tabela com todos que possuem ponto no período."
        />
      )}
      {loading && <Loading porcentagem={porcentagem} />}
      {isModalFindUser && (
        <ModalFindUser onClose={() => setIsModalFindUser(!isModalFindUser)} />
      )}
      {/* <div style={{ height: 40 }} /> */}
    </Container>
  );
};

export default ViewPonto;
