import styled, { keyframes } from 'styled-components';
import { shade } from 'polished';

interface ContainerSelectProps {
  select: boolean;
}

const appearFromRight = keyframes`
  from {
    opacity: 0;
    transform: translateX(70px);
  }
  to{
    opacity: 1;
    transform: translateX(0);
  }
`;

export const Content = styled.div`
  padding: 20px;
`;

export const AnimationContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  animation: ${appearFromRight} 1s;

  h1 {
    margin-top: 14px;
    font-size: 25px;
  }

  form {
    margin: 30px 0;
  }
`;

export const ContainerInput = styled.div<ContainerSelectProps>`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 10px;

  select.selectCargo {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background: #232129;
    border: 2px solid #232129;
    padding: 0 10px;
    color: #fff;
    border: ${(props) => (props.select ? '2px solid #439133' : '')};
  }

  button {
    height: 100%;
    align-items: center;
    justify-content: center;
    background: #439133;
    border: none;
    border-radius: 10px;
    padding: 0 10px;
    margin-left: 10px;
    color: #ffffff;
    transition: background 0.2s;

    &:hover {
      background: ${shade(0.2, '#439133')};
    }
  }
`;

export const ContainerCodigo = styled.div`
  display: flex;
  label {
    display: flex;
    align-items: center;
    flex: 1;
    background: #232129;
    border-radius: 10px;
    border: 2px solid #605b71;
    color: #fff;
    padding: 0 10px;
    svg {
      color: #665360;
      margin-right: 10px;
    }
  }
`;
