/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import { uuid } from 'uuidv4';
import filesize from 'filesize';
import moment from 'moment';
import Swal from 'sweetalert2';
import { FormHandles } from '@unform/core';
import {
  HiOutlineDocument,
  HiOutlineDocumentRemove,
  HiOutlineDocumentAdd,
} from 'react-icons/hi';
import {
  AiOutlineUserAdd,
  AiOutlineDownload,
  AiFillEdit,
  AiOutlineFieldTime,
} from 'react-icons/ai';
import { RiFileList3Line } from 'react-icons/ri';
import { BsBuilding } from 'react-icons/bs';
import { FaFileCsv } from 'react-icons/fa';
import { Form } from '@unform/web';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import * as Yup from 'yup';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { Loading } from '../../../components/Loading';
import { useAuth } from '../../../hooks/auth';
import Input from '../../../components/Input';
import FileList from '../../../components/FileList';
import Upload from '../../../components/Upload';
import ModalConexao from '../../../components/ModalConexao';
import HeaderTable from '../../../components/HeaderTable';
import Pagination from '../../../components/Pagination';
import { SelectCustom } from '../../../components/SelectCustom';
import DropDownPagination from '../../../components/DropDownPagination';
import getValidationErrors from '../../../utils/getValidationErrors';
import { EditEscalaModal } from '../../../components/EditEscalaModal';
import { EditEscalaFdsModal } from '../../../components/EditEscalaFdsModal';
import { ModalResponseCsv } from '../../../components/ModalResponseCsv';
import { urlAccessBucketEscala } from '../../../utils/listUrlAccessAws';

import {
  Container,
  Content,
  Aba,
  ContainerAba,
  ContainerSearch,
  SearchCustom,
  AnimationContainer,
  ContainerCadastro,
  ConainerCadastroOption,
  RadioBox,
  ContentForm,
  ContainerButton,
  ContainerSemResultados,
  ContainerTable,
  ContainerPagination,
  ButtonEdit,
} from './styles';

interface Uploaded {
  file: File | null;
  id: string;
  name: string;
  readableSize: string;
  progress: number;
  uploaded: boolean;
  error: boolean;
  type: string;
}

export interface ResponseGet {
  ATIVO: boolean;
  CONSIDERAR_FINS_DE_SEMANA: boolean;
  EH_12X36: boolean;
  FERIADO_DIA_UTIL: boolean;
  FILIAL: string;
  HORA_INICIAL: string;
  HORA_PAUSA: string;
  HORA_SAIDA: string;
  HORA_VOLTA: string;
  ID: number;
  INTRA_JORNADA: boolean;
  NOME: string;
  VIRA_NOITE: boolean;
}

export interface ResponseGetFimDeSemana {
  ATIVO: boolean;
  NOME: string;
  HORA_INICIAL: string;
  HORA_PAUSA: string;
  HORA_VOLTA: string;
  HORA_SAIDA: string;
  FILIAL: string;
  Id_Dom_Escala: number;
  ID: number;
}

export interface SignUpFormData {
  nome: string;
  filial: string;
  horarioInicio: string;
  horarioPausa: string;
  horarioVolta: string;
  horarioSaida: string;
  intraJornada: string;
  eh12X36: string;
  considerarFinsDeSemana: string;
  considerarFeriadoDiaUtil: string;
  escalaNoturna: string;
  horarioInicioFds: string;
  horarioPausaFds: string;
  horarioVoltaFds: string;
  horarioSaidaFds: string;
  IdDomEscala: number;
}

export interface ResponseGetFilial {
  ATIVO: boolean;
  BAIRRO: string;
  CEP: string;
  CIDADE: string;
  CODIGO_FILIAL: string;
  ENDERECO: string;
  ESTADO: string;
  ID: number;
  NUMERO: string;
  NOME_EMPRESA: string;
  NOME_FANTASIA_EMPRESA: string;
  CNPJ: string;
}

export interface PageAccessUser {
  LEITURA: boolean;
  ESCRITA: boolean;
}

export const pageAccess = {
  ID: 14,
  NOME: 'PONTO-ELETRONICO',
};

const dropDown = [
  { valor: '20', id: 20 },
  { valor: '40', id: 40 },
  { valor: '80', id: 80 },
  { valor: '160', id: 160 },
  { valor: 'Todos', id: 1 },
];

const headers = [
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'Filial', field: 'FILIAL', sortable: true },
  { name: 'Horário de início', field: 'HORA_INICIAL', sortable: true },
  { name: 'Horário da pausa', field: 'HORA_PAUSA', sortable: true },
  { name: 'Horário da volta', field: 'HORA_VOLTA', sortable: true },
  { name: 'Horário da saída', field: 'HORA_SAIDA', sortable: true },
  {
    name: 'Intra jornada',
    field: 'INTRA_JORNADA',
    sortable: true,
  },
  { name: 'É 12X36', field: 'EH_12X36', sortable: true },
  {
    name: 'Considerar fins de semana',
    field: 'CONSIDERAR_FINS_DE_SEMANA',
    sortable: true,
  },
  {
    name: 'Considerar feriado como dia útil',
    field: 'FERIADO_DIA_UTIL',
    sortable: true,
  },
  { name: 'Escala noturna', field: 'VIRA_NOITE', sortable: true },
  { name: 'Status cadastral', field: 'ATIVO', sortable: true },
];

const headersFds = [
  { name: 'Nome', field: 'NOME', sortable: true },
  { name: 'Filial', field: 'FILIAL', sortable: true },
  { name: 'Horário de início', field: 'HORA_INICIAL', sortable: true },
  { name: 'Horário da pausa', field: 'HORA_PAUSA', sortable: true },
  { name: 'Horário da volta', field: 'HORA_VOLTA', sortable: true },
  { name: 'Horário da saída', field: 'HORA_SAIDA', sortable: true },
  { name: 'Status cadastral', field: 'ATIVO', sortable: true },
];

export const Escala: React.FC = () => {
  const { addToast } = useToast();
  const { go, push } = useHistory();
  const { modulos } = useAuth();
  const formRefIndividual = useRef<FormHandles>(null);
  const formRefEmLote = useRef<FormHandles>(null);
  const [loading, setLoading] = useState(false);
  const [cadastrar, setCadastrar] = useState(false);
  const [response, setResponse] = useState<ResponseGet[]>([]);
  const [responseFimDeSemana, setResponseFimDeSemana] = useState<
    ResponseGetFimDeSemana[]
  >([]);
  const [responseFiliais, setResponseFiliais] = useState<ResponseGetFilial[]>(
    [],
  );
  const [verificaResponse, setVerificaResponse] = useState(false);
  const [accessUser, setAccessUser] = useState<PageAccessUser>({
    LEITURA: false,
    ESCRITA: false,
  });
  const [intraJornadaStatus, setIntraJornadaStatus] = useState(true);
  const [
    considerarFimDeSemanaStatus,
    setConsiderarFimDeSemanaStatus,
  ] = useState(true);

  const [nomeForm, setNomeForm] = useState('');
  const [filialForm, setFilialForm] = useState('');
  const [horarioPausa, setHorarioPausa] = useState('');
  const [horarioVolta, setHorarioVolta] = useState('');
  const [horarioPausaFds, setHorarioPausaFds] = useState('');
  const [horarioVoltaFds, setHorarioVoltaFds] = useState('');

  const [searchNome, setSearchNome] = useState('');
  const [searchFilial, setSearchFilial] = useState('');
  const [searchHorarioInicio, setSearchHorarioInicio] = useState('');
  const [searchHorarioPausa, setSearchHorarioPausa] = useState('');
  const [searchHorarioVolta, setSearchHorarioVolta] = useState('');
  const [searchHorarioSaida, setSearchHorarioSaida] = useState('');

  const [searchNomeFds, setSearchNomeFds] = useState('');
  const [searchFilialFds, setSearchFilialFds] = useState('');
  const [searchHorarioInicioFds, setSearchHorarioInicioFds] = useState('');
  const [searchHorarioPausaFds, setSearchHorarioPausaFds] = useState('');
  const [searchHorarioVoltaFds, setSearchHorarioVoltaFds] = useState('');
  const [searchHorarioSaidaFds, setSearchHorarioSaidaFds] = useState('');

  const [download, setDownload] = useState('');
  const [registerType, setRegisterType] = useState('individual');
  const [uploadedFiles, setUploadedFiles] = useState<Uploaded[]>([]);
  const [dataCsvModal, setDataCsvModal] = useState<string[]>([]);
  const [verificaResponseCsv, setVerificaResponseCsv] = useState<number[]>([]);
  const [showModalResponse, setShowModalResponse] = useState(false);
  const [count, setCount] = useState(0);
  const [delimitadorForm, setDelimitadorForm] = useState(',');
  const urlToDownload = urlAccessBucketEscala;

  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [ITEMS_PER_PAGE, setITEMS_PER_PAGE] = useState(20);
  const [sorting, setSorting] = useState({ field: '', order: '' });
  const [totalItemsFds, setTotalItemsFds] = useState(0);
  const [currentPageFds, setCurrentPageFds] = useState(1);
  const [ITEMS_PER_PAGE_FDS, setITEMS_PER_PAGE_FDS] = useState(20);
  const [sortingFds, setSortingFds] = useState({ field: '', order: '' });
  const [trTableSelected, setTrTableSelected] = useState<ResponseGet>();
  const [trTableSelectedFds, setTrTableSelectedFds] = useState<
    ResponseGetFimDeSemana
  >();
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [showModalEditFds, setShowModalEditFds] = useState(false);

  const CustomTooltipUpdateStatus = withStyles((theme) => ({
    arrow: {
      color: !trTableSelected
        ? '#009900'
        : trTableSelected.ATIVO
        ? '#ff3333'
        : '#8080ff',
    },
    tooltip: {
      backgroundColor: !trTableSelected
        ? '#009900'
        : trTableSelected.ATIVO
        ? '#ff3333'
        : '#8080ff',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  const CustomTooltipUpdateStatusFds = withStyles((theme) => ({
    arrow: {
      color: !trTableSelectedFds
        ? '#009900'
        : trTableSelectedFds.ATIVO
        ? '#ff3333'
        : '#8080ff',
    },
    tooltip: {
      backgroundColor: !trTableSelectedFds
        ? '#009900'
        : trTableSelectedFds.ATIVO
        ? '#ff3333'
        : '#8080ff',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  const CustomTooltipEdit = withStyles((theme) => ({
    arrow: {
      color: !trTableSelected ? '#009900' : '#ff9900',
    },
    tooltip: {
      backgroundColor: !trTableSelected ? '#009900' : '#ff9900',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  const getInitResponses = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const listaEscalas = await api.get(`escala/getEscala`);
      const listaEscalasFimDeSemana = await api.get(
        `escala/getEscalaFimDeSemana?ATIVO=true`,
      );
      const listaFiliaisTemp = await api.get(`filiais/showFiliais`);
      const listaFiliais = await listaFiliaisTemp.data.filter(
        (filial: ResponseGetFilial) => filial.ATIVO,
      );

      setResponse(listaEscalas.data);
      setResponseFimDeSemana(listaEscalasFimDeSemana.data);
      setResponseFiliais(listaFiliais);
      setVerificaResponse(true);
    } catch (error) {
      if (navigator.onLine) {
        addToast({
          type: 'error',
          title: 'Erro',
          description: 'Erro ao carregar dados, por favor atualize a página!',
        });
      }
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  useEffect(() => {
    let semAcesso = true;
    const access = modulos.find((modulo) => modulo.ID_MODULO === pageAccess.ID);
    if (access && (access.ESCRITA || access.LEITURA)) {
      semAcesso = false;

      setAccessUser({
        LEITURA: access.LEITURA,
        ESCRITA: access.ESCRITA,
      });
    }

    if (semAcesso) {
      push('/dashboard');

      addToast({
        type: 'info',
        title: 'Acesso bloqueado',
        description: 'Você não tem permissão para acessar essa página',
      });
    } else {
      getInitResponses();
    }
  }, [addToast, getInitResponses, modulos, push]);

  const handleAlertMaster = useCallback(() => {
    if (verificaResponse && !!responseFiliais.length) {
      setCadastrar(true);
      setDownload('');
      setSearchNome('');
      setSearchFilial('');
      setSearchHorarioInicio('');
      setSearchHorarioPausa('');
      setSearchHorarioVolta('');
      setSearchHorarioSaida('');
      setIntraJornadaStatus(true);
      setConsiderarFimDeSemanaStatus(true);
    } else if (verificaResponse && !responseFiliais.length) {
      addToast({
        type: 'info',
        title: 'Lista de filiais vazia!',
        description:
          'Favor realizar o cadastro de pelo menos uma empresa/filial previamente.',
      });
    } else {
      addToast({
        type: 'error',
        title: 'Erro',
        description: 'Erro ao carregar dados, por favor atualize a página!',
      });
    }
  }, [addToast, responseFiliais.length, verificaResponse]);

  // ////////////////////////////////////////////////////////////////////////////////////
  const updateFile = useCallback((id, data) => {
    setUploadedFiles((estadoAnterior) =>
      estadoAnterior.map((file) =>
        id === file.id ? { ...file, ...data } : file,
      ),
    );
  }, []);

  const processUpload = useCallback(
    (uploadedFile: Uploaded) => {
      const data = new FormData();
      let contThen = 0;

      if (uploadedFile.file) {
        data.append('delimiter', delimitadorForm);
        data.append('csvEscala', uploadedFile.file);
      }

      api
        .post('/csvImportacao/escalaCSV', data, {
          onUploadProgress: (e) => {
            const progress = Math.round((e.loaded * 100) / e.total);

            updateFile(uploadedFile.id, {
              progress,
            });
          },
        })
        .then((res) => {
          updateFile(uploadedFile.id, {
            uploaded: true,
          });
          !!res.data.Message.length &&
            setDataCsvModal(dataCsvModal.concat(res.data.Message));
          contThen += 1;
        })
        .catch((err) => {
          updateFile(uploadedFile.id, {
            error: true,
          });

          if (
            err.response.data.errors.includes(
              'Error: column_mismatched. JSON Line number:',
            )
          ) {
            addToast({
              type: 'error',
              title: 'Arquivo incorreto',
              description: `A linha ${
                Number(err.response.data.errors.split(' ').pop()) + 2
              } do arquivo .CSV está incorreta. Favor verificar`,
            });
          }
        })
        .finally(() => {
          verificaResponseCsv.push(0);

          if (
            uploadedFiles.length === verificaResponseCsv.length &&
            contThen > 0
          ) {
            setShowModalResponse(true);

            setTimeout(() => {
              go(0);
            }, 1500);
          } else if (uploadedFiles.length === verificaResponseCsv.length) {
            setVerificaResponseCsv([]);
          }
        });
    },
    [
      addToast,
      dataCsvModal,
      delimitadorForm,
      go,
      updateFile,
      uploadedFiles.length,
      verificaResponseCsv,
    ],
  );

  const handleUpload = useCallback(
    (files) => {
      const uploadedFilesHandleUpload: Uploaded[] = files.map((file: File) => ({
        file,
        id: uuid(),
        name: file.name,
        readableSize: filesize(file.size),
        progress: 0,
        uploaded: false,
        error: false,
        type: '.csv, application/vnd.ms-excel, text/csv',
      }));

      setUploadedFiles(uploadedFiles.concat(uploadedFilesHandleUpload));
    },
    [uploadedFiles],
  );

  const clickHandleUpload = useCallback(() => {
    if (!uploadedFiles.length) {
      addToast({
        type: 'error',
        title: 'Erro ao cadastrar lista de escalas',
        description:
          'É necessário inserir um arquivo .CSV válido. Favor baixar o arquivo modelo pré-formatado para o preenchimento correto',
      });
    } else {
      uploadedFiles.forEach(processUpload);
    }
  }, [addToast, processUpload, uploadedFiles]);

  const handleDelete = useCallback(
    async (id) => {
      uploadedFiles.splice(
        uploadedFiles.findIndex((element) => element.id === id),
        1,
      );
      setUploadedFiles([...uploadedFiles]);
    },
    [uploadedFiles],
  );
  // ////////////////////////////////////////////////////////////////////////////////////

  // /////////////////////////////////////////////////////////////////////////////////////
  const handleSubmit = useCallback(
    async (data: SignUpFormData) => {
      try {
        formRefIndividual.current?.setErrors({});
        let schema: any;

        schema = Yup.object().shape({
          nome: Yup.string().required('Nome é obrigatório!'),
          filial: Yup.string().required('Filial é obrigatória!'),
          horarioInicio: Yup.string().required(
            'Horário de início é obrigatório!',
          ),
          horarioSaida: Yup.string().required(
            'Horário da saída é obrigatório!',
          ),
          intraJornada: Yup.string().required(
            'Confirmação se tem intra jornada é obrigatória!',
          ),
          eh12X36: Yup.string().required(
            'Confirmação se é 12X36 é obrigatória!',
          ),
          considerarFinsDeSemana: Yup.string().required(
            'Confirmação se deve considerar fins de semana é obrigatória!',
          ),
          considerarFeriadoDiaUtil: Yup.string().required(
            'Confirmação se deve considerar dia útil é obrigatória!',
          ),
          escalaNoturna: Yup.string().required(
            'Confirmação se tem escala noturna é obrigatória!',
          ),
        });

        if (data.intraJornada === 'Sim') {
          schema = Yup.object().shape({
            nome: Yup.string().required('Nome é obrigatório!'),
            filial: Yup.string().required('Filial é obrigatória!'),
            horarioInicio: Yup.string().required(
              'Horário de início é obrigatório!',
            ),
            horarioPausa: Yup.string().required(
              'Horário da pausa é obrigatório!',
            ),
            horarioVolta: Yup.string().required(
              'Horário da volta é obrigatório!',
            ),
            horarioSaida: Yup.string().required(
              'Horário da saída é obrigatório!',
            ),
            intraJornada: Yup.string().required(
              'Confirmação se tem intra jornada é obrigatória!',
            ),
            eh12X36: Yup.string().required(
              'Confirmação se é 12X36 é obrigatória!',
            ),
            considerarFinsDeSemana: Yup.string().required(
              'Confirmação se deve considerar fins de semana é obrigatória!',
            ),
            considerarFeriadoDiaUtil: Yup.string().required(
              'Confirmação se deve considerar dia útil é obrigatória!',
            ),
            escalaNoturna: Yup.string().required(
              'Confirmação se tem escala noturna é obrigatória!',
            ),
          });
        }

        if (
          considerarFimDeSemanaStatus &&
          data.intraJornada === 'Sim' &&
          data.horarioInicio !== '' &&
          data.horarioSaida !== '' &&
          data.horarioPausa !== '' &&
          data.horarioVolta !== ''
        ) {
          schema = Yup.object().shape({
            horarioInicioFds: Yup.string().required(
              'Horário de início é obrigatório!',
            ),
            horarioPausaFds: Yup.string().required(
              'Horário da pausa é obrigatório!',
            ),
            horarioVoltaFds: Yup.string().required(
              'Horário da volta é obrigatório!',
            ),
            horarioSaidaFds: Yup.string().required(
              'Horário da saída é obrigatório!',
            ),
          });
        } else if (
          considerarFimDeSemanaStatus &&
          data.intraJornada === 'Não' &&
          data.horarioInicio !== '' &&
          data.horarioSaida !== ''
        ) {
          schema = Yup.object().shape({
            horarioInicioFds: Yup.string().required(
              'Horário de início é obrigatório!',
            ),
            horarioSaidaFds: Yup.string().required(
              'Horário da saída é obrigatório!',
            ),
          });
        }

        await schema.validate(data, {
          abortEarly: false,
        });

        setLoading(true);
        await api
          .post('escala/saveEscala', {
            nome: data.nome,
            feriado_dia_util: data.considerarFeriadoDiaUtil === 'Sim',
            eh_12x36: data.eh12X36 === 'Sim',
            considerar_fins_de_semana: data.considerarFinsDeSemana === 'Sim',
            intra_jornada: data.intraJornada === 'Sim',
            hora_inicial: data.horarioInicio,
            hora_pausa: data.horarioPausa,
            hora_volta: data.horarioVolta,
            hora_saida: data.horarioSaida,
            filial: data.filial,
            vira_noite: data.escalaNoturna === 'Sim',
          })
          .then(async (res) => {
            if (data.considerarFinsDeSemana === 'Não') {
              setLoading(false);
              addToast({
                type: 'success',
                title: 'Cadastro efetuado',
                description: 'Escala cadastrada com sucesso',
              });
              setTimeout(() => {
                go(0);
              }, 1000);
            } else {
              await api
                .post('escala/saveEscalaFimDeSemana', {
                  nome: data.nome,
                  hora_inicial: data.horarioInicioFds,
                  hora_pausa: data.horarioPausaFds,
                  hora_volta: data.horarioVoltaFds,
                  hora_saida: data.horarioSaidaFds,
                  filial: data.filial,
                  Id_Dom_Escala: res.data.ID,
                })
                .then(() => {
                  setLoading(false);
                  addToast({
                    type: 'success',
                    title: 'Cadastro efetuado',
                    description: 'Escalas cadastradas com sucesso',
                  });
                  setTimeout(() => {
                    go(0);
                  }, 1000);
                })
                .catch(() => {
                  setLoading(false);
                  addToast({
                    type: 'error',
                    title: 'Erro no cadastro',
                    description: 'Erro ao cadastrar escalas',
                  });
                });
            }
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro no cadastro',
              description: 'Erro ao cadastrar escala',
            });
          });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          const errors = getValidationErrors(err);

          formRefIndividual.current?.setErrors(errors);
        }
        addToast({
          type: 'error',
          title: 'Erro no cadastro',
          description: 'Existem campos obrigatórios em branco.',
        });
        setLoading(false);
      }
    },
    [addToast, considerarFimDeSemanaStatus, go],
  );

  const handleChangeStatusUser = useCallback(
    async (type: string) => {
      setLoading(true);

      if (type === 'escala' && trTableSelected) {
        await api
          .put(`escala/updateEscala`, {
            id: trTableSelected.ID,
            // nome?: string;
            // feriado_dia_util?: boolean;
            // eh_12x36?: boolean;
            // considerar_fins_de_semana?: boolean;
            ativo: !trTableSelected.ATIVO,
            // intra_jornada?: boolean;
            // hora_inicial?: string;
            // hora_pausa?: string;
            // hora_volta?: string;
            // hora_saida?: string;
            // filial?: string;
            // vira_noite?: boolean;
          })
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Escala editada',
              description: 'Escala editada com sucesso',
            });
            setTimeout(() => {
              go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro na edição',
              description: 'Erro ao editar escala',
            });
          });
      } else if (trTableSelectedFds) {
        await api
          .put(`escala/updateEscalaFimDeSemana`, {
            id: trTableSelectedFds.ID,
            // nome?: string;
            // hora_inicial?: string;
            // hora_pausa?: string;
            // hora_volta?: string;
            // hora_saida?: string;
            // filial?: string;
            Id_Dom_Escala: trTableSelectedFds.Id_Dom_Escala,
            ativo: !trTableSelectedFds.ATIVO,
          })
          .then(() => {
            setLoading(false);
            addToast({
              type: 'success',
              title: 'Escala editada',
              description: 'Escala editada com sucesso',
            });
            setTimeout(() => {
              go(0);
            }, 1000);
          })
          .catch(() => {
            setLoading(false);
            addToast({
              type: 'error',
              title: 'Erro na edição',
              description: 'Erro ao editar escala',
            });
          });
      }
    },
    [trTableSelected, addToast, go, trTableSelectedFds],
  );

  const handleConfirmChangeStatusUser = useCallback(
    (type: string) => {
      Swal.fire({
        title: `Atualização de status`,
        text: 'Tem certeza que deseja alterar o status da escala selecionada?',
        icon: 'question',
        showCancelButton: true,
        cancelButtonText: 'Não',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim',
      }).then((result) => {
        if (result.isConfirmed) {
          handleChangeStatusUser(type);
        }
      });
    },
    [handleChangeStatusUser],
  );

  const handleAlertEdit = useCallback(
    (modalType: 'escala' | 'escalaFds') => {
      if (verificaResponse && !responseFiliais.length) {
        addToast({
          type: 'info',
          title: 'Lista de filiais vazia!',
          description:
            'Favor realizar o cadastro de pelo menos uma empresa/filial previamente.',
        });
      } else if (modalType === 'escala') {
        setShowModalEdit(true);
      } else {
        setShowModalEditFds(true);
      }
    },
    [addToast, responseFiliais.length, verificaResponse],
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [
    searchNome,
    searchFilial,
    searchHorarioInicio,
    searchHorarioPausa,
    searchHorarioVolta,
    searchHorarioSaida,
  ]);

  useEffect(() => {
    setCurrentPageFds(1);
  }, [
    searchNomeFds,
    searchFilialFds,
    searchHorarioInicioFds,
    searchHorarioPausaFds,
    searchHorarioVoltaFds,
    searchHorarioSaidaFds,
  ]);

  const responseData = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = response.length ? response : [];

    if (searchNome) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.NOME && res.NOME.toLowerCase().includes(searchNome.toLowerCase()),
      );
    }

    if (searchFilial) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.FILIAL &&
          res.FILIAL.toLowerCase().includes(searchFilial.toLowerCase()),
      );
    }

    if (searchHorarioInicio) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.HORA_INICIAL &&
          res.HORA_INICIAL.toLowerCase().includes(
            searchHorarioInicio.toLowerCase(),
          ),
      );
    }

    if (searchHorarioPausa) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.HORA_PAUSA &&
          res.HORA_PAUSA.toLowerCase().includes(
            searchHorarioPausa.toLowerCase(),
          ),
      );
    }

    if (searchHorarioVolta) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.HORA_VOLTA &&
          res.HORA_VOLTA.toLowerCase().includes(
            searchHorarioVolta.toLowerCase(),
          ),
      );
    }

    if (searchHorarioSaida) {
      computedResponses = computedResponses.filter(
        (res: ResponseGet) =>
          res.HORA_SAIDA &&
          res.HORA_SAIDA.toLowerCase().includes(
            searchHorarioSaida.toLowerCase(),
          ),
      );
    }

    if (sorting.field) {
      const reversed = sorting.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (a[sorting.field] !== null && b[sorting.field] !== null) {
          if (typeof a[sorting.field] === 'object') {
            return (
              reversed *
              a[sorting.field]
                .join(', ')
                .localeCompare(b[sorting.field].join(', '))
            );
          }
          if (typeof a[sorting.field] === 'string') {
            return reversed * a[sorting.field].localeCompare(b[sorting.field]);
          }
          return (
            reversed *
            a[sorting.field]
              .toString()
              .localeCompare(b[sorting.field].toString())
          );
        }
        return a;
      });
    }

    setTotalItems(computedResponses.length);
    if (ITEMS_PER_PAGE === 1) {
      return computedResponses;
    }
    return computedResponses.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      currentPage * ITEMS_PER_PAGE,
    );
  }, [
    ITEMS_PER_PAGE,
    currentPage,
    response,
    searchFilial,
    searchHorarioInicio,
    searchHorarioPausa,
    searchHorarioSaida,
    searchHorarioVolta,
    searchNome,
    sorting.field,
    sorting.order,
  ]);

  const responseDataFimDeSemana = useMemo(() => {
    let computedResponses: ResponseGetFimDeSemana[] = [];
    computedResponses = responseFimDeSemana.length ? responseFimDeSemana : [];

    if (searchNomeFds) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetFimDeSemana) =>
          res.NOME &&
          res.NOME.toLowerCase().includes(searchNomeFds.toLowerCase()),
      );
    }

    if (searchFilialFds) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetFimDeSemana) =>
          res.FILIAL &&
          res.FILIAL.toLowerCase().includes(searchFilialFds.toLowerCase()),
      );
    }

    if (searchHorarioInicioFds) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetFimDeSemana) =>
          res.HORA_INICIAL &&
          res.HORA_INICIAL.toLowerCase().includes(
            searchHorarioInicioFds.toLowerCase(),
          ),
      );
    }

    if (searchHorarioPausaFds) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetFimDeSemana) =>
          res.HORA_PAUSA &&
          res.HORA_PAUSA.toLowerCase().includes(
            searchHorarioPausaFds.toLowerCase(),
          ),
      );
    }

    if (searchHorarioVoltaFds) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetFimDeSemana) =>
          res.HORA_VOLTA &&
          res.HORA_VOLTA.toLowerCase().includes(
            searchHorarioVoltaFds.toLowerCase(),
          ),
      );
    }

    if (searchHorarioSaidaFds) {
      computedResponses = computedResponses.filter(
        (res: ResponseGetFimDeSemana) =>
          res.HORA_SAIDA &&
          res.HORA_SAIDA.toLowerCase().includes(
            searchHorarioSaidaFds.toLowerCase(),
          ),
      );
    }

    if (sortingFds.field) {
      const reversed = sortingFds.order === 'asc' ? 1 : -1;
      computedResponses = computedResponses.sort((a: any, b: any): any => {
        if (a[sortingFds.field] !== null && b[sortingFds.field] !== null) {
          if (typeof a[sortingFds.field] === 'object') {
            return (
              reversed *
              a[sortingFds.field]
                .join(', ')
                .localeCompare(b[sortingFds.field].join(', '))
            );
          }
          if (typeof a[sortingFds.field] === 'string') {
            return (
              reversed * a[sortingFds.field].localeCompare(b[sortingFds.field])
            );
          }
          return (
            reversed *
            a[sortingFds.field]
              .toString()
              .localeCompare(b[sortingFds.field].toString())
          );
        }
        return a;
      });
    }

    setTotalItemsFds(computedResponses.length);
    if (ITEMS_PER_PAGE_FDS === 1) {
      return computedResponses;
    }
    return computedResponses.slice(
      (currentPageFds - 1) * ITEMS_PER_PAGE_FDS,
      currentPageFds * ITEMS_PER_PAGE_FDS,
    );
  }, [
    ITEMS_PER_PAGE_FDS,
    currentPageFds,
    responseFimDeSemana,
    searchFilialFds,
    searchHorarioInicioFds,
    searchHorarioPausaFds,
    searchHorarioSaidaFds,
    searchHorarioVoltaFds,
    searchNomeFds,
    sortingFds.field,
    sortingFds.order,
  ]);

  useEffect(() => {
    setCurrentPage(1);
  }, [
    searchNome,
    searchFilial,
    searchHorarioInicio,
    searchHorarioPausa,
    searchHorarioVolta,
    searchHorarioSaida,
  ]);

  useEffect(() => {
    setCurrentPageFds(1);
  }, [
    searchNomeFds,
    searchFilialFds,
    searchHorarioInicioFds,
    searchHorarioPausaFds,
    searchHorarioVoltaFds,
    searchHorarioSaidaFds,
  ]);
  // /////////////////////////////////////////////////////////////////////////////////////

  return (
    <>
      <ModalConexao />
      <Container>
        {accessUser.ESCRITA && (
          <Aba>
            <ContainerAba cor={!cadastrar}>
              <button
                style={{ borderTopLeftRadius: 7, borderBottomLeftRadius: 7 }}
                type="button"
                onClick={() => setCadastrar(false)}
              >
                Listar escalas
              </button>
            </ContainerAba>
            <ContainerAba cor={cadastrar}>
              <button
                style={{ borderTopRightRadius: 7, borderBottomRightRadius: 7 }}
                type="button"
                onClick={() => handleAlertMaster()}
              >
                Cadastrar escalas
              </button>
            </ContainerAba>
          </Aba>
        )}

        <ContainerCadastro cor={!cadastrar}>
          {cadastrar && (
            <ConainerCadastroOption>
              <RadioBox
                type="button"
                onClick={() => setRegisterType('individual')}
                isActive={registerType === 'individual'}
                activeColor="green"
              >
                <AiOutlineUserAdd size={28} />
                Cadastro individual
              </RadioBox>
              <RadioBox
                type="button"
                onClick={() => setRegisterType('lote')}
                isActive={registerType === 'lote'}
                activeColor="red"
              >
                <FaFileCsv size={28} color="#fff" />
                Cadastro em lote
              </RadioBox>
            </ConainerCadastroOption>
          )}

          {cadastrar &&
            (registerType === 'individual' ? (
              <>
                <AnimationContainer>
                  <header>
                    <AiOutlineUserAdd size={24} />
                  </header>

                  <Form ref={formRefIndividual} onSubmit={handleSubmit}>
                    <ContentForm>
                      <Input
                        name="nome"
                        icon={BsBuilding}
                        widthProps="49%"
                        placeholder="Nome"
                        value={nomeForm}
                        onValue={(value) => setNomeForm(value)}
                      />
                      <SelectCustom
                        name="filial"
                        widthProps="49%"
                        icon={BsBuilding}
                        defaultValue="Filial"
                        optionsData={responseFiliais.map((filial) => {
                          return filial.CODIGO_FILIAL;
                        })}
                        onValue={(value) => setFilialForm(value)}
                      />
                    </ContentForm>

                    <ContentForm>
                      <SelectCustom
                        name="intraJornada"
                        widthProps="49%"
                        icon={BsBuilding}
                        defaultValue="Intra jornada"
                        optionsData={['Sim', 'Não']}
                        onValue={(value) => {
                          setIntraJornadaStatus(value === 'Sim');
                          setHorarioPausa('');
                          setHorarioVolta('');
                          setHorarioPausaFds('');
                          setHorarioVoltaFds('');
                        }}
                      />
                      <SelectCustom
                        name="eh12X36"
                        widthProps="49%"
                        icon={BsBuilding}
                        defaultValue="É 12X36"
                        optionsData={['Sim', 'Não']}
                      />
                    </ContentForm>

                    <ContentForm>
                      <SelectCustom
                        name="considerarFinsDeSemana"
                        widthProps="49%"
                        icon={BsBuilding}
                        defaultValue="Considerar fins de semana"
                        optionsData={['Sim', 'Não']}
                        onValue={(value) =>
                          setConsiderarFimDeSemanaStatus(value === 'Sim')}
                      />
                      <SelectCustom
                        name="considerarFeriadoDiaUtil"
                        widthProps="49%"
                        icon={BsBuilding}
                        defaultValue="Considerar feriado como dia útil"
                        optionsData={['Sim', 'Não']}
                      />
                    </ContentForm>

                    <ContentForm>
                      <SelectCustom
                        name="escalaNoturna"
                        widthProps="100%"
                        icon={BsBuilding}
                        defaultValue="Escala noturna"
                        optionsData={['Sim', 'Não']}
                      />
                    </ContentForm>

                    <ContentForm>
                      <div
                        style={{
                          width: '95%',
                          marginRight: '2%',
                          backgroundColor: '#3d3946',
                          padding: '10px',
                          borderRadius: '10px',
                        }}
                      >
                        <p>Horário de início</p>
                        <Input
                          type="time"
                          name="horarioInicio"
                          icon={BsBuilding}
                          widthProps="100%"
                          placeholder="Horário de início"
                        />
                      </div>

                      <div
                        style={{
                          width: '95%',
                          backgroundColor: '#3d3946',
                          padding: '10px',
                          borderRadius: '10px',
                        }}
                      >
                        <p>Horário da pausa</p>
                        <Input
                          type="time"
                          name="horarioPausa"
                          icon={BsBuilding}
                          widthProps="100%"
                          placeholder="Horário da pausa"
                          value={horarioPausa}
                          onValue={(value) => setHorarioPausa(value)}
                          disabled={!intraJornadaStatus}
                          style={{
                            background: !intraJornadaStatus ? '#18171c' : '',
                          }}
                        />
                      </div>
                    </ContentForm>

                    <ContentForm>
                      <div
                        style={{
                          width: '95%',
                          marginRight: '2%',
                          backgroundColor: '#3d3946',
                          padding: '10px',
                          borderRadius: '10px',
                        }}
                      >
                        <p>Horário da volta</p>
                        <Input
                          type="time"
                          name="horarioVolta"
                          icon={BsBuilding}
                          widthProps="100%"
                          placeholder="Horário da volta"
                          value={horarioVolta}
                          onValue={(value) => setHorarioVolta(value)}
                          disabled={!intraJornadaStatus}
                          style={{
                            background: !intraJornadaStatus ? '#18171c' : '',
                          }}
                        />
                      </div>

                      <div
                        style={{
                          width: '95%',
                          backgroundColor: '#3d3946',
                          padding: '10px',
                          borderRadius: '10px',
                        }}
                      >
                        <p>Horário da saída</p>
                        <Input
                          type="time"
                          name="horarioSaida"
                          icon={BsBuilding}
                          widthProps="100%"
                          placeholder="Horário da saída"
                        />
                      </div>
                    </ContentForm>

                    {considerarFimDeSemanaStatus && (
                      <>
                        <hr
                          style={{
                            borderRight: 'none',
                            borderLeft: 'none',
                            borderBottom: 'none',
                            marginBottom: '20px',
                          }}
                        />

                        <div
                          style={{
                            backgroundColor: '#312e38',
                            padding: '10px',
                            borderRadius: '10px',
                          }}
                        >
                          <h1>Fim de semana</h1>

                          <ContentForm>
                            <div
                              style={{
                                width: '95%',
                                marginRight: '2%',
                                backgroundColor: '#3d3946',
                                padding: '10px',
                                borderRadius: '10px',
                              }}
                            >
                              <p>Horário de início</p>
                              <Input
                                type="time"
                                name="horarioInicioFds"
                                placeholder="Horário de início"
                                icon={AiOutlineFieldTime}
                                widthProps="100%"
                              />
                            </div>

                            <div
                              style={{
                                width: '95%',
                                backgroundColor: '#3d3946',
                                padding: '10px',
                                borderRadius: '10px',
                              }}
                            >
                              <p>Horário da pausa</p>
                              <Input
                                type="time"
                                name="horarioPausaFds"
                                placeholder="Horário da pausa"
                                icon={AiOutlineFieldTime}
                                widthProps="100%"
                                value={horarioPausaFds}
                                onValue={(value) => setHorarioPausaFds(value)}
                                disabled={!intraJornadaStatus}
                                style={{
                                  background: !intraJornadaStatus
                                    ? '#18171c'
                                    : '',
                                }}
                              />
                            </div>
                          </ContentForm>
                          <ContentForm>
                            <div
                              style={{
                                width: '95%',
                                marginRight: '2%',
                                backgroundColor: '#3d3946',
                                padding: '10px',
                                borderRadius: '10px',
                              }}
                            >
                              <p>Horário da volta</p>
                              <Input
                                type="time"
                                name="horarioVoltaFds"
                                placeholder="Horário da volta"
                                icon={AiOutlineFieldTime}
                                widthProps="100%"
                                value={horarioVoltaFds}
                                onValue={(value) => setHorarioVoltaFds(value)}
                                disabled={!intraJornadaStatus}
                                style={{
                                  background: !intraJornadaStatus
                                    ? '#18171c'
                                    : '',
                                }}
                              />
                            </div>

                            <div
                              style={{
                                width: '95%',
                                backgroundColor: '#3d3946',
                                padding: '10px',
                                borderRadius: '10px',
                              }}
                            >
                              <p>Horário de saída</p>
                              <Input
                                type="time"
                                name="horarioSaidaFds"
                                placeholder="Horário de saída"
                                icon={AiOutlineFieldTime}
                                widthProps="100%"
                              />
                            </div>
                          </ContentForm>
                        </div>
                      </>
                    )}

                    <ContainerButton>
                      <button type="submit" id="btnCadastroIndividual">
                        Cadastrar
                      </button>
                    </ContainerButton>
                  </Form>
                </AnimationContainer>
              </>
            ) : (
              <>
                <AnimationContainer>
                  <header>
                    <FaFileCsv size={24} color="#fff" />
                  </header>

                  <Form ref={formRefEmLote} onSubmit={clickHandleUpload}>
                    <ContentForm style={{ marginBottom: '10px' }}>
                      <SelectCustom
                        name="delimitador"
                        icon={RiFileList3Line}
                        defaultValue="Delimitador"
                        optionsData={['Vírgula', 'Ponto e vírgula']}
                        onValue={(value) =>
                          setDelimitadorForm(value === 'Vírgula' ? ',' : ';')}
                      />
                    </ContentForm>

                    <Content>
                      <Upload
                        onUpload={(e: any) => handleUpload(e)}
                        paddingProps="70px"
                      />
                      <FileList
                        files={uploadedFiles}
                        heightProps="152px"
                        onDelete={(e: string) => handleDelete(e)}
                      />
                    </Content>
                    <ContainerButton>
                      <button type="submit">Cadastrar</button>
                      <button
                        type="button"
                        onClick={() => {
                          setDownload(urlToDownload);
                          setCount((old) => old + 1);
                        }}
                      >
                        <AiOutlineDownload size={24} />
                        Baixar Modelo
                      </button>
                      {download && (
                        <iframe
                          title="downloadModeloCsv"
                          src={`${download}?c=${count}`}
                          hidden
                        />
                      )}
                    </ContainerButton>
                  </Form>
                </AnimationContainer>
              </>
            ))}

          {cadastrar && !responseData.length && (
            <ContainerSemResultados>
              <h2>Não existem escalas cadastradas ainda.</h2>
            </ContainerSemResultados>
          )}

          {!cadastrar && !!responseData.length && (
            <>
              <h1>Escalas</h1>
              <ContainerSearch>
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchNome(value);
                  }}
                  nomePlaceHolder="Buscar nome"
                />
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchFilial(value);
                  }}
                  nomePlaceHolder="Buscar filial"
                />
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchHorarioInicio(value);
                  }}
                  nomePlaceHolder="Buscar horário de início"
                />
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchHorarioPausa(value);
                  }}
                  nomePlaceHolder="Buscar horário de pausa"
                />
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchHorarioVolta(value);
                  }}
                  nomePlaceHolder="Buscar horário de volta"
                />
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchHorarioSaida(value);
                  }}
                  nomePlaceHolder="Buscar horário de saída"
                />
              </ContainerSearch>
            </>
          )}

          {!cadastrar && !!responseData.length && (
            <div className="divTable">
              <ContainerTable>
                <table>
                  <HeaderTable
                    headers={headers}
                    onSorting={(field: string, order: string) =>
                      setSorting({ field, order })}
                  />
                  <tbody>
                    {responseData.map((res) => (
                      <tr
                        key={res.ID}
                        onClick={() =>
                          trTableSelected === res
                            ? setTrTableSelected(undefined)
                            : setTrTableSelected(res)}
                        style={{
                          background:
                            trTableSelected && trTableSelected.ID === res.ID
                              ? '#737373'
                              : '',
                        }}
                      >
                        <td>{res.NOME ? res.NOME : '-'}</td>
                        <td>{res.FILIAL ? res.FILIAL : '-'}</td>
                        <td>{res.HORA_INICIAL ? res.HORA_INICIAL : '-'}</td>
                        <td>{res.HORA_PAUSA ? res.HORA_PAUSA : '-'}</td>
                        <td>{res.HORA_VOLTA ? res.HORA_VOLTA : '-'}</td>
                        <td>{res.HORA_SAIDA ? res.HORA_SAIDA : '-'}</td>
                        <td>{res.INTRA_JORNADA ? 'Sim' : 'Não'}</td>
                        <td>{res.EH_12X36 ? 'Sim' : 'Não'}</td>
                        <td>{res.CONSIDERAR_FINS_DE_SEMANA ? 'Sim' : 'Não'}</td>
                        <td>{res.FERIADO_DIA_UTIL ? 'Sim' : 'Não'}</td>
                        <td>{res.VIRA_NOITE ? 'Sim' : 'Não'}</td>
                        <td style={{ padding: 5 }}>
                          <div
                            style={{
                              background: `${
                                !res.ATIVO
                                  ? 'rgb(255, 51, 51)'
                                  : 'rgb(128, 128, 255)'
                              }`,
                              width: '100%',
                              height: '100%',
                              borderRadius: 4,
                            }}
                          >
                            {!!res.ATIVO && 'Ativo'}
                            {!res.ATIVO && 'Inativo'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table id="tableListagemEscalas" style={{ display: 'none' }}>
                  <HeaderTable
                    headers={headers}
                    onSorting={(field: string, order: string) =>
                      setSorting({ field, order })}
                  />
                  <tbody>
                    {responseData.map((res) => (
                      <tr key={res.ID}>
                        <td>{res.NOME ? res.NOME : '-'}</td>
                        <td>{res.FILIAL ? res.FILIAL : '-'}</td>
                        <td>{res.HORA_INICIAL ? res.HORA_INICIAL : '-'}</td>
                        <td>{res.HORA_PAUSA ? res.HORA_PAUSA : '-'}</td>
                        <td>{res.HORA_VOLTA ? res.HORA_VOLTA : '-'}</td>
                        <td>{res.HORA_SAIDA ? res.HORA_SAIDA : '-'}</td>
                        <td>{res.INTRA_JORNADA ? 'Sim' : 'Não'}</td>
                        <td>{res.EH_12X36 ? 'Sim' : 'Não'}</td>
                        <td>{res.CONSIDERAR_FINS_DE_SEMANA ? 'Sim' : 'Não'}</td>
                        <td>{res.FERIADO_DIA_UTIL ? 'Sim' : 'Não'}</td>
                        <td>{res.VIRA_NOITE ? 'Sim' : 'Não'}</td>
                        <td>
                          {!!res.ATIVO && 'Ativo'}
                          {!res.ATIVO && 'Inativo'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ContainerTable>

              <ContainerPagination>
                {responseData.length > 0 && (
                  <div id="divPaginacao">
                    <Pagination
                      total={totalItems}
                      itemsPerPage={ITEMS_PER_PAGE}
                      currentPage={currentPage}
                      onPageChange={(page: number) => {
                        setCurrentPage(page);
                      }}
                    />
                    <DropDownPagination
                      onChangeItems={(value: string) => {
                        setCurrentPage(1);
                        if (value === 'Todos') {
                          setITEMS_PER_PAGE(1);
                        } else {
                          setITEMS_PER_PAGE(Number(value));
                        }
                      }}
                      objetoEnum={dropDown}
                      minWidth={30}
                    />

                    <div id="divBtnTable">
                      {accessUser.ESCRITA && (
                        <>
                          <CustomTooltipEdit
                            title={
                              !trTableSelected
                                ? 'Selecione uma escala primeiro'
                                : 'Editar'
                            }
                            arrow
                            TransitionComponent={Zoom}
                          >
                            <span>
                              <ButtonEdit
                                disabled={!trTableSelected}
                                style={{
                                  cursor: !trTableSelected ? 'not-allowed' : '',
                                  background: !trTableSelected ? '#009900' : '',
                                  filter: !trTableSelected
                                    ? 'brightness(1)'
                                    : '',
                                }}
                                onClick={() => handleAlertEdit('escala')}
                              >
                                <AiFillEdit size={26} />
                              </ButtonEdit>
                            </span>
                          </CustomTooltipEdit>

                          <CustomTooltipUpdateStatus
                            title={
                              !trTableSelected
                                ? 'Selecione uma escala primeiro'
                                : trTableSelected.ATIVO
                                ? 'Inativar escala'
                                : 'Ativar escala'
                            }
                            arrow
                            TransitionComponent={Zoom}
                          >
                            <span>
                              <ButtonEdit
                                disabled={!trTableSelected}
                                style={{
                                  cursor: !trTableSelected ? 'not-allowed' : '',
                                  background: !trTableSelected
                                    ? '#009900'
                                    : trTableSelected.ATIVO
                                    ? '#ff3333'
                                    : '#8080ff',
                                  filter: !trTableSelected
                                    ? 'brightness(1)'
                                    : '',
                                }}
                                onClick={() =>
                                  trTableSelected &&
                                  handleConfirmChangeStatusUser('escala')}
                              >
                                {!trTableSelected ? (
                                  <HiOutlineDocument size={26} />
                                ) : trTableSelected.ATIVO ? (
                                  <HiOutlineDocumentRemove size={26} />
                                ) : (
                                  <HiOutlineDocumentAdd size={26} />
                                )}
                              </ButtonEdit>
                            </span>
                          </CustomTooltipUpdateStatus>
                        </>
                      )}

                      <ReactHTMLTableToExcel
                        id="export-excel"
                        className="btn"
                        table="tableListagemEscalas"
                        filename={`listagem-escalas-funcionarios-${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        sheet="tablexls"
                        buttonText="Exportar Excel"
                      />
                    </div>
                  </div>
                )}
              </ContainerPagination>
            </div>
          )}

          {!cadastrar && !!responseFimDeSemana.length && (
            <>
              <h1 style={{ marginTop: 40 }}>Escalas de fim de semana</h1>
              <ContainerSearch>
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchNomeFds(value);
                  }}
                  nomePlaceHolder="Buscar nome"
                />
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchFilialFds(value);
                  }}
                  nomePlaceHolder="Buscar filial"
                />
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchHorarioInicioFds(value);
                  }}
                  nomePlaceHolder="Buscar horário de início"
                />
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchHorarioPausaFds(value);
                  }}
                  nomePlaceHolder="Buscar horário de pausa"
                />
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchHorarioVoltaFds(value);
                  }}
                  nomePlaceHolder="Buscar horário de volta"
                />
                <SearchCustom
                  onSearch={(value: string) => {
                    setSearchHorarioSaidaFds(value);
                  }}
                  nomePlaceHolder="Buscar horário de saída"
                />
              </ContainerSearch>
            </>
          )}

          {!cadastrar && !!responseDataFimDeSemana.length && (
            <div className="divTable">
              <ContainerTable>
                <table>
                  <HeaderTable
                    headers={headersFds}
                    onSorting={(field: string, order: string) =>
                      setSortingFds({ field, order })}
                  />
                  <tbody>
                    {responseDataFimDeSemana.map((res) => (
                      <tr
                        key={res.ID}
                        onClick={() =>
                          trTableSelectedFds === res
                            ? setTrTableSelectedFds(undefined)
                            : setTrTableSelectedFds(res)
                        }
                        style={{
                          background:
                            trTableSelectedFds &&
                            trTableSelectedFds.ID === res.ID
                              ? '#737373'
                              : '',
                        }}
                      >
                        <td>{res.NOME ? res.NOME : '-'}</td>
                        <td>{res.FILIAL ? res.FILIAL : '-'}</td>
                        <td>{res.HORA_INICIAL ? res.HORA_INICIAL : '-'}</td>
                        <td>{res.HORA_PAUSA ? res.HORA_PAUSA : '-'}</td>
                        <td>{res.HORA_VOLTA ? res.HORA_VOLTA : '-'}</td>
                        <td>{res.HORA_SAIDA ? res.HORA_SAIDA : '-'}</td>
                        <td style={{ padding: 5 }}>
                          <div
                            style={{
                              background: `${
                                !res.ATIVO
                                  ? 'rgb(255, 51, 51)'
                                  : 'rgb(128, 128, 255)'
                              }`,
                              width: '100%',
                              height: '100%',
                              borderRadius: 4,
                            }}
                          >
                            {!!res.ATIVO && 'Ativo'}
                            {!res.ATIVO && 'Inativo'}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                <table id="tableListagemEscalasFds" style={{ display: 'none' }}>
                  <HeaderTable
                    headers={headers}
                    onSorting={(field: string, order: string) =>
                      setSorting({ field, order })}
                  />
                  <tbody>
                    {responseData.map((res) => (
                      <tr key={res.ID}>
                        <td>{res.NOME ? res.NOME : '-'}</td>
                        <td>{res.FILIAL ? res.FILIAL : '-'}</td>
                        <td>{res.HORA_INICIAL ? res.HORA_INICIAL : '-'}</td>
                        <td>{res.HORA_PAUSA ? res.HORA_PAUSA : '-'}</td>
                        <td>{res.HORA_VOLTA ? res.HORA_VOLTA : '-'}</td>
                        <td>{res.HORA_SAIDA ? res.HORA_SAIDA : '-'}</td>
                        <td>{res.INTRA_JORNADA ? 'Sim' : 'Não'}</td>
                        <td>{res.EH_12X36 ? 'Sim' : 'Não'}</td>
                        <td>{res.CONSIDERAR_FINS_DE_SEMANA ? 'Sim' : 'Não'}</td>
                        <td>{res.FERIADO_DIA_UTIL ? 'Sim' : 'Não'}</td>
                        <td>{res.VIRA_NOITE ? 'Sim' : 'Não'}</td>
                        <td>
                          {!!res.ATIVO && 'Ativo'}
                          {!res.ATIVO && 'Inativo'}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </ContainerTable>

              <ContainerPagination>
                {responseDataFimDeSemana.length > 0 && (
                  <div id="divPaginacao">
                    <Pagination
                      total={totalItemsFds}
                      itemsPerPage={ITEMS_PER_PAGE_FDS}
                      currentPage={currentPageFds}
                      onPageChange={(page: number) => {
                        setCurrentPageFds(page);
                      }}
                    />
                    <DropDownPagination
                      onChangeItems={(value: string) => {
                        setCurrentPageFds(1);
                        if (value === 'Todos') {
                          setITEMS_PER_PAGE_FDS(1);
                        } else {
                          setITEMS_PER_PAGE_FDS(Number(value));
                        }
                      }}
                      objetoEnum={dropDown}
                      minWidth={30}
                    />

                    <div id="divBtnTable">
                      {accessUser.ESCRITA && (
                        <>
                          <CustomTooltipEdit
                            title={
                              !trTableSelectedFds
                                ? 'Selecione uma escala primeiro'
                                : 'Editar'
                            }
                            arrow
                            TransitionComponent={Zoom}
                          >
                            <span>
                              <ButtonEdit
                                disabled={!trTableSelectedFds}
                                style={{
                                  cursor: !trTableSelectedFds
                                    ? 'not-allowed'
                                    : '',
                                  background: !trTableSelectedFds
                                    ? '#009900'
                                    : '',
                                  filter: !trTableSelectedFds
                                    ? 'brightness(1)'
                                    : '',
                                }}
                                onClick={() => handleAlertEdit('escalaFds')}
                              >
                                <AiFillEdit size={26} />
                              </ButtonEdit>
                            </span>
                          </CustomTooltipEdit>

                          <CustomTooltipUpdateStatusFds
                            title={
                              !trTableSelectedFds
                                ? 'Selecione uma escala primeiro'
                                : trTableSelectedFds.ATIVO
                                ? 'Inativar escala'
                                : 'Ativar escala'
                            }
                            arrow
                            TransitionComponent={Zoom}
                          >
                            <span>
                              <ButtonEdit
                                disabled={!trTableSelectedFds}
                                style={{
                                  cursor: !trTableSelectedFds
                                    ? 'not-allowed'
                                    : '',
                                  background: !trTableSelectedFds
                                    ? '#009900'
                                    : trTableSelectedFds.ATIVO
                                    ? '#ff3333'
                                    : '#8080ff',
                                  filter: !trTableSelectedFds
                                    ? 'brightness(1)'
                                    : '',
                                }}
                                onClick={() =>
                                  trTableSelectedFds &&
                                  handleConfirmChangeStatusUser('escalaFds')}
                              >
                                {!trTableSelectedFds ? (
                                  <HiOutlineDocument size={26} />
                                ) : trTableSelectedFds.ATIVO ? (
                                  <HiOutlineDocumentRemove size={26} />
                                ) : (
                                  <HiOutlineDocumentAdd size={26} />
                                )}
                              </ButtonEdit>
                            </span>
                          </CustomTooltipUpdateStatusFds>
                        </>
                      )}

                      <ReactHTMLTableToExcel
                        id="export-excel"
                        className="btn"
                        table="tableListagemEscalasFds"
                        filename={`listagem-escalas-fimDeSemana-funcionarios-${moment().format(
                          'DD-MM-YYYY',
                        )}`}
                        sheet="tablexls"
                        buttonText="Exportar Excel"
                      />
                    </div>
                  </div>
                )}
              </ContainerPagination>
            </div>
          )}
        </ContainerCadastro>
      </Container>

      {loading && <Loading />}

      {showModalEdit && trTableSelected && (
        <EditEscalaModal
          data={trTableSelected}
          filiais={responseFiliais}
          onClose={(e) => setShowModalEdit(!e)}
        />
      )}

      {showModalEditFds && trTableSelectedFds && (
        <EditEscalaFdsModal
          data={trTableSelectedFds}
          filiais={responseFiliais}
          escalas={response}
          onClose={(e) => setShowModalEditFds(!e)}
        />
      )}

      {/* {showModalResponse && (
        <ModalResponseCsv dados={} onClose={setShowModalResponse(false)} />
      )} */}
    </>
  );
};
