/* eslint-disable no-nested-ternary */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { GoDiffAdded } from 'react-icons/go';
import { BiEdit } from 'react-icons/bi';
import Lottie from 'lottie-react-web';
import { withStyles } from '@material-ui/core/styles';
import { Tooltip } from '@material-ui/core';
import Zoom from '@material-ui/core/Zoom';
import {
  ModalRegisterSectorFunction,
  ResponsePost,
} from '../../../../components/ModalRegisterSectorFunction';
import { ResponseGet, PageAccessUser } from '../index';
import animationListUsers from './listUsers.json';

import { Container } from './styles';
import { ModalEditSectorFunction } from '../../../../components/ModalEditSectorFunction';

interface PainelProps {
  isActivePainel: boolean;
  titlePainel: string;
  dataType: 'setor' | 'funcao' | 'usuario';
  dataPainelUser?: ResponseGet[];
  titleModalPainel?: string;
  verificationMap: number | undefined;
  responsePostSetorModalProps: ResponsePost[];
  responsePostFuncaoModalProps: ResponsePost[];
  accessUserProps: PageAccessUser;
  onSelectPainelProps: (e: number) => void;
  onSelectPainelAllProps: (e: ResponsePost) => void;
}

export const Painel: React.FC<PainelProps> = ({
  isActivePainel,
  titlePainel,
  dataType,
  dataPainelUser,
  titleModalPainel,
  verificationMap,
  responsePostSetorModalProps,
  responsePostFuncaoModalProps,
  accessUserProps,
  onSelectPainelProps = (e: number) => {
    return e;
  },
  onSelectPainelAllProps = (e: ResponsePost) => {
    return e;
  },
}) => {
  const [showRegisterModal, setShowRegisterModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [titleModal, setTitleModal] = useState('');
  const [selectPainelIten, setSelectPainelIten] = useState<ResponsePost>();
  const [responsePostSetorModal, setResponsePostSetorModal] = useState<
    ResponsePost[]
  >(responsePostSetorModalProps);
  const [responsePostFuncaoModal, setResponsePostFuncaoModal] = useState<
    ResponsePost[]
  >(responsePostFuncaoModalProps);
  const CustomTooltipRegister = withStyles((theme) => ({
    arrow: {
      color: '#00b300',
    },
    tooltip: {
      backgroundColor: '#00b300',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);
  const CustomTooltipEdit = withStyles((theme) => ({
    arrow: {
      color: '#eba417',
    },
    tooltip: {
      backgroundColor: '#eba417',
      color: '#ffffff',
      boxShadow: theme.shadows[1],
      fontSize: 14,
    },
  }))(Tooltip);

  useEffect(() => {
    setResponsePostSetorModal(responsePostSetorModalProps);
    setResponsePostFuncaoModal(responsePostFuncaoModalProps);
  }, [responsePostSetorModalProps, responsePostFuncaoModalProps]);

  useEffect(() => {
    if (selectPainelIten) {
      onSelectPainelProps(selectPainelIten.ID);
      onSelectPainelAllProps(selectPainelIten);
    }
  }, [onSelectPainelAllProps, onSelectPainelProps, selectPainelIten]);

  useEffect(() => {
    !verificationMap && setSelectPainelIten({ ID: -1, NOME: '' });
  }, [verificationMap]);

  const filterEditDataPainel = useCallback(
    ({ ID, NOME, NIVEL }, type: string) => {
      if (type === 'setor') {
        const indexEdit = responsePostSetorModal.findIndex((setor) => {
          return setor.ID === ID;
        });

        const dataEditAux = {
          ID,
          NOME,
        };

        responsePostSetorModal[indexEdit] = dataEditAux;

        setResponsePostSetorModal([...responsePostSetorModal]);
      } else {
        const indexEdit = responsePostFuncaoModal.findIndex((funcao) => {
          return funcao.ID === ID;
        });

        const dataEditAux = {
          ID,
          NIVEL,
          NOME,
        };

        responsePostFuncaoModal[indexEdit] = dataEditAux;

        setResponsePostFuncaoModal([...responsePostFuncaoModal]);
      }
    },
    [responsePostFuncaoModal, responsePostSetorModal],
  );

  const filterDataPainel = useMemo(() => {
    let computedResponses: ResponsePost[] = [];
    computedResponses =
      dataType === 'setor'
        ? responsePostSetorModal
        : dataType === 'funcao'
        ? responsePostFuncaoModal
        : [];

    if (selectPainelIten && selectPainelIten.ID !== -1) {
      computedResponses = computedResponses.filter(
        (res: ResponsePost) => res.ID === selectPainelIten.ID,
      );
    }

    return computedResponses;
  }, [
    dataType,
    responsePostFuncaoModal,
    responsePostSetorModal,
    selectPainelIten,
  ]);

  const filterDataPainelUser = useMemo(() => {
    let computedResponses: ResponseGet[] = [];
    computedResponses = dataPainelUser || [];

    if (selectPainelIten && selectPainelIten.ID !== -1) {
      computedResponses = computedResponses.filter(
        (res: ResponsePost) => res.ID === selectPainelIten.ID,
      );
    }

    return computedResponses;
  }, [dataPainelUser, selectPainelIten]);

  return (
    <>
      {dataType !== 'usuario' ? (
        <Container isActive={isActivePainel}>
          <header>
            <h2>{titlePainel}</h2>
            {verificationMap && accessUserProps.ESCRITA && (
              <article>
                <CustomTooltipRegister
                  title={`Cadastrar ${dataType}`}
                  arrow
                  TransitionComponent={Zoom}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setTitleModal(titleModalPainel || '');
                      setShowRegisterModal(true);
                    }}
                  >
                    <GoDiffAdded />
                  </button>
                </CustomTooltipRegister>

                <CustomTooltipEdit
                  title={
                    !selectPainelIten || selectPainelIten.ID === -1
                      ? `Selecione um registro primeiro`
                      : `Editar ${dataType}`
                  }
                  arrow
                  TransitionComponent={Zoom}
                >
                  <span>
                    <button
                      type="button"
                      disabled={!selectPainelIten || selectPainelIten.ID === -1}
                      onClick={() => {
                        setTitleModal(titleModalPainel || '');
                        setShowEditModal(true);
                      }}
                      style={{
                        cursor:
                          !selectPainelIten || selectPainelIten.ID === -1
                            ? 'not-allowed'
                            : 'pointer',
                        filter:
                          !selectPainelIten || selectPainelIten.ID === -1
                            ? 'brightness(0.5)'
                            : '',
                      }}
                    >
                      <BiEdit />
                    </button>
                  </span>
                </CustomTooltipEdit>
              </article>
            )}
          </header>
          <div>
            {verificationMap &&
              filterDataPainel.map((data: ResponsePost) => (
                <button
                  className="btnSectionOccupation"
                  key={data.ID}
                  type="button"
                  onClick={() => {
                    selectPainelIten?.ID === data.ID
                      ? setSelectPainelIten({
                          ID: -1,
                          NOME: '',
                        })
                      : setSelectPainelIten(data);
                  }}
                  style={{
                    background:
                      selectPainelIten?.ID === data.ID ? '#312e38' : '',
                    borderColor:
                      selectPainelIten?.ID === data.ID ? '#888198' : '',
                  }}
                >
                  {data.NOME}
                </button>
              ))}

            {!filterDataPainel?.length && isActivePainel && (
              <article>
                <Lottie
                  options={{
                    animationData: animationListUsers,
                    loop: true,
                  }}
                  width={200}
                  speed={1}
                />
              </article>
            )}
          </div>
        </Container>
      ) : (
        <Container isActive={isActivePainel}>
          <header>
            <h2>{titlePainel}</h2>
          </header>
          <div>
            {verificationMap &&
              filterDataPainelUser.map((data: ResponseGet) => (
                <button
                  key={data.ID}
                  type="button"
                  onClick={() => {
                    selectPainelIten?.ID === data.ID
                      ? setSelectPainelIten({
                          ID: -1,
                          NOME: '',
                        })
                      : setSelectPainelIten(data);
                  }}
                  style={{
                    background:
                      selectPainelIten?.ID === data.ID ? '#312e38' : '',
                    borderColor:
                      selectPainelIten?.ID === data.ID ? '#888198' : '',
                  }}
                >
                  {data.NOME}
                </button>
              ))}

            {!filterDataPainelUser.length && isActivePainel && (
              <article>
                <Lottie
                  options={{
                    animationData: animationListUsers,
                    loop: true,
                  }}
                  width={200}
                  speed={1}
                />
              </article>
            )}
          </div>
        </Container>
      )}

      <ModalRegisterSectorFunction
        isOpen={showRegisterModal}
        titleProps={titleModal}
        onDataReturn={(e: ResponsePost) => {
          dataType === 'setor'
            ? setResponsePostSetorModal([...responsePostSetorModal, e])
            : setResponsePostFuncaoModal([...responsePostFuncaoModal, e]);
        }}
        onRequestClose={() => setShowRegisterModal(false)}
      />

      {selectPainelIten && (
        <ModalEditSectorFunction
          isOpen={showEditModal}
          titleProps={titleModal}
          data={selectPainelIten}
          onDataReturn={(e: ResponsePost) => filterEditDataPainel(e, dataType)}
          onRequestClose={() => setShowEditModal(false)}
        />
      )}
    </>
  );
};
