import React from 'react';

import { ContainerSearch, SearchCustom } from './styles';

interface Props {
  onSearchName: (e: string) => void;
  onSearchCpf: (e: string) => void;
  onSearchSetor: (e: string) => void;
  onSearchFuncao: (e: string) => void;
}

export const SearchContainer: React.FC<Props> = ({
  onSearchName = (e: string) => {
    return e;
  },
  onSearchCpf = (e: string) => {
    return e;
  },
  onSearchSetor = (e: string) => {
    return e;
  },
  onSearchFuncao = (e: string) => {
    return e;
  },
}) => (
  <ContainerSearch>
    <SearchCustom
      onSearch={(value: string) => {
        onSearchName(value);
      }}
      nomePlaceHolder="Buscar nome"
    />
    <SearchCustom
      onSearch={(value: string) => {
        onSearchCpf(value);
      }}
      nomePlaceHolder="Buscar CPF"
    />
    <SearchCustom
      onSearch={(value: string) => {
        onSearchSetor(value);
      }}
      nomePlaceHolder="Buscar setor"
    />
    <SearchCustom
      onSearch={(value: string) => {
        onSearchFuncao(value);
      }}
      nomePlaceHolder="Buscar função"
    />
  </ContainerSearch>
);
