import React from 'react';
import HeaderTable from '../../../../components/HeaderTable';
import {
  formatarDataAnoMesDia,
  formatarHoraMin,
} from '../../../../components/Formulas/formulas';
import { ContainerTable } from '../styles';
import { ResponseGet } from '../Interface/index';

interface DadosTable {
  dados: ResponseGet[];
}

const TablePonto: React.FC<DadosTable> = ({ dados }) => {
  const headersEscala = [
    { name: 'Data Escala', field: 'dataEscala' },
    { name: 'Latitude Ponto', field: 'latitudePonto' },
    { name: 'Longitude Ponto', field: 'longitudePonto' },
    { name: 'Tipo Ponto', field: 'tipoPonto' },
    { name: 'Data', field: 'data' },
    { name: 'Hora', field: 'hora' },
    { name: 'Foto', field: 'foto' },
    { name: 'Latitude', field: 'latitude' },
    { name: 'Longitude', field: 'longitude' },
    { name: 'Diferença', field: 'diferenca' },
  ];

  return (
    <ContainerTable>
      <table id="PontoExcel">
        <HeaderTable headers={headersEscala} />
        {dados.map((res: ResponseGet, index) => (
          <tbody key={`${res.CPFSEMFORMATO + index.toString()}.0`}>
            <tr key={`${res.CPFSEMFORMATO + index.toString()}.1`}>
              <td rowSpan={4} style={{ minWidth: 80 }}>
                {formatarDataAnoMesDia(res.DATA_INICIAL_DIA_AGENDA)}
              </td>
              {/* <td rowSpan={4}>{res.LOCAL_LATITUDE}</td>
              <td rowSpan={4}>{res.LOCAL_LONGITUDE}</td> */}
              <td>Entrada</td>
              <td style={{ minWidth: 80 }}>
                {res.PONTO_DATA_ENTRADA
                  ? formatarDataAnoMesDia(res.PONTO_DATA_ENTRADA)
                  : '-'}
              </td>
              <td>
                {res.PONTO_HORA_ENTRADA
                  ? formatarHoraMin(res.PONTO_HORA_ENTRADA)
                  : '-'}
              </td>
              <td>
                <a href={res.PONTO_LINK_FOTO_ENTRADA}>
                  {res.PONTO_LINK_FOTO_ENTRADA
                    ? res.PONTO_LINK_FOTO_ENTRADA
                    : 'Sem foto'}
                </a>
              </td>
              {/* <td>
                {res.PONTO_LATITUDE_ENTRADA ? res.PONTO_LATITUDE_ENTRADA : '-'}
              </td> */}
              {/* <td>
                {res.PONTO_LONGITUDE_ENTRADA
                  ? res.PONTO_LONGITUDE_ENTRADA
                  : '-'}
              </td> */}
              {/* <td>
                {res.PONTO_DIFERENCA_METROS_ENTRADA
                  ? `${res.PONTO_DIFERENCA_METROS_ENTRADA} m`
                  : '-'}
              </td> */}
            </tr>
            <tr key={`${res.CPFSEMFORMATO + index.toString()}.2`}>
              <td>Pausa</td>
              <td style={{ minWidth: 80 }}>
                {res.PONTO_DATA_PAUSA
                  ? formatarDataAnoMesDia(res.PONTO_DATA_PAUSA)
                  : '-'}
              </td>
              <td>
                {res.PONTO_HORA_PAUSA
                  ? formatarHoraMin(res.PONTO_HORA_PAUSA)
                  : '-'}
              </td>
              <td>
                <a href={res.PONTO_LINK_FOTO_PAUSA}>
                  {res.PONTO_LINK_FOTO_PAUSA
                    ? res.PONTO_LINK_FOTO_PAUSA
                    : 'Sem foto'}
                </a>
              </td>
              {/* <td>
                {res.PONTO_LATITUDE_PAUSA ? res.PONTO_LATITUDE_PAUSA : '-'}
              </td>
              <td>
                {res.PONTO_LONGITUDE_PAUSA ? res.PONTO_LONGITUDE_PAUSA : '-'}
              </td>
              <td>
                {res.PONTO_DIFERENCA_METROS_PAUSA
                  ? `${res.PONTO_DIFERENCA_METROS_PAUSA} m`
                  : '-'}
              </td> */}
            </tr>
            <tr key={`${res.CPFSEMFORMATO + index.toString()}.3`}>
              <td>Volta</td>
              <td style={{ minWidth: 80 }}>
                {res.PONTO_DATA_VOLTA
                  ? formatarDataAnoMesDia(res.PONTO_DATA_VOLTA)
                  : '-'}
              </td>
              <td>
                {res.PONTO_HORA_VOLTA
                  ? formatarHoraMin(res.PONTO_HORA_VOLTA)
                  : '-'}
              </td>
              <td>
                <a href={res.PONTO_LINK_FOTO_VOLTA}>
                  {res.PONTO_LINK_FOTO_VOLTA
                    ? res.PONTO_LINK_FOTO_VOLTA
                    : 'Sem foto'}
                </a>
              </td>
              {/* <td>
                {res.PONTO_LATITUDE_VOLTA ? res.PONTO_LATITUDE_VOLTA : '-'}
              </td>
              <td>
                {res.PONTO_LONGITUDE_VOLTA ? res.PONTO_LONGITUDE_VOLTA : '-'}
              </td>
              <td>
                {res.PONTO_DIFERENCA_METROS_VOLTA
                  ? `${res.PONTO_DIFERENCA_METROS_VOLTA} m`
                  : '-'}
              </td> */}
            </tr>
            <tr key={`${res.CPFSEMFORMATO + index.toString()}.4`}>
              <td>Saída</td>
              <td style={{ minWidth: 80 }}>
                {res.PONTO_DATA_SAIDA
                  ? formatarDataAnoMesDia(res.PONTO_DATA_SAIDA)
                  : '-'}
              </td>
              <td>
                {res.PONTO_HORA_SAIDA
                  ? formatarHoraMin(res.PONTO_HORA_SAIDA)
                  : '-'}
              </td>
              <td>
                <a href={res.PONTO_LINK_FOTO_SAIDA}>
                  {res.PONTO_LINK_FOTO_SAIDA
                    ? res.PONTO_LINK_FOTO_SAIDA
                    : 'Sem foto'}
                </a>
              </td>
              {/* <td>
                {res.PONTO_LATITUDE_SAIDA ? res.PONTO_LATITUDE_SAIDA : '-'}
              </td>
              <td>
                {res.PONTO_LONGITUDE_SAIDA ? res.PONTO_LONGITUDE_SAIDA : '-'}
              </td>
              <td>
                {res.PONTO_DIFERENCA_METROS_SAIDA
                  ? `${res.PONTO_DIFERENCA_METROS_SAIDA} m`
                  : '-'}
              </td> */}
            </tr>
          </tbody>
        ))}
      </table>
    </ContainerTable>
  );
};

export default TablePonto;
