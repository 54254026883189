import React, { SelectHTMLAttributes, useCallback, useState } from 'react';
import { Container } from './styles';

interface DropDownInterface {
  id: number;
  valor: string;
}

interface DadosInterface extends SelectHTMLAttributes<HTMLSelectElement> {
  objetoEnum: DropDownInterface[];
  onChangeItems: (arg: string) => void;
  minWidth?: number;
}

const DropDown: React.FC<DadosInterface> = ({
  onChangeItems,
  objetoEnum,
  minWidth,
}: DadosInterface) => {
  const [value, setValue] = useState('');

  const onChangeItem = useCallback(
    (valor: string) => {
      setValue(`${valor}`);
      onChangeItems(valor);
    },
    [onChangeItems],
  );

  return (
    <Container>
      <select
        style={{ minWidth }}
        value={value}
        onChange={(e) => {
          onChangeItem(e.target.value);
        }}
      >
        {objetoEnum.map((drop: DropDownInterface) => (
          <option key={drop.id} value={drop.valor}>
            {drop.valor}
          </option>
        ))}
      </select>
    </Container>
  );
};

export default DropDown;
